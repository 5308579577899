import React from "react";
import { Card, Col } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import PlusIcon from "../../../Icon/PlusIcon";
import { Link } from "react-router-dom";

const CountCard = ({ name, count, plus, refAction, changeItem, loading, customStyle, customColSize }) => {
  const { xs, sm, md, lg } = customColSize || { xs: 12, sm: 12, md: 12, lg: 6 };
  return (
    <Col span={6} xs={xs} sm={sm} md={md} lg={lg} style={customStyle || {}}>
      <Card style={{zIndex:2}}>
        <p>{name}</p>
        <div className="count-number">
          {loading ?
            <LoadingOutlined className="loader-count" />
          :count.toString()}
        </div>
        {plus && (
          <Link onClick={() => changeItem(refAction)} to={refAction || "#"} >
            <PlusIcon className="plus-count" color={"#fff"} />
          </Link>
        )}
      </Card>
      <div className="gradient-shadow">
      </div>
    </Col>
  );
};

export default CountCard;
