import React from "react";

const PwdIcon = ({ className = null, onClick = null, color = null }) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M5.625 23.625L21.375 23.625C21.9963 23.625 22.5 23.1213 22.5 22.5L22.5 11.25C22.5 10.6287 21.9963 10.125 21.375 10.125L5.625 10.125C5.00368 10.125 4.5 10.6287 4.5 11.25L4.5 22.5C4.5 23.1213 5.00368 23.625 5.625 23.625Z"
        stroke={color}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3846 10.125L16.6154 10.125C16.7588 10.125 16.875 10.0088 16.875 9.86539L16.875 6.75C16.875 4.88604 15.364 3.375 13.5 3.375C11.636 3.375 10.125 4.88604 10.125 6.75L10.125 9.86539C10.125 10.0088 10.2412 10.125 10.3846 10.125Z"
        stroke={color}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PwdIcon;
