import React from "react";

const WorldIcon = ({ className = null, onClick = null, color = null }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2C7.79058 2 5.79196 2.89434 4.34315 4.34315C2.89434 5.79196 2 7.79058 2 10C2 14.4183 5.58172 18 10 18C12.2094 18 14.208 17.1057 15.6569 15.6569C17.1057 14.208 18 12.2094 18 10C18 5.58172 14.4183 2 10 2ZM2.92893 2.92893C4.73748 1.12038 7.23885 0 10 0C15.5228 0 20 4.47715 20 10C20 12.7611 18.8796 15.2625 17.0711 17.0711C15.2625 18.8796 12.7611 20 10 20C4.47715 20 0 15.5228 0 10C0 7.23885 1.12038 4.73748 2.92893 2.92893Z" 
        fill={color || "#ffffffa6"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75959 1.11189C8.26904 0.858158 7.66568 1.05014 7.41194 1.54068C5.81969 4.61905 4.99479 7.16881 5.01185 9.84005C5.02882 12.4974 5.87911 15.157 7.40193 18.451C7.63368 18.9523 8.22795 19.1708 8.72925 18.939C9.23056 18.7073 9.44907 18.113 9.21732 17.6117C7.73569 14.4068 7.02606 12.0575 7.01181 9.82727C6.99766 7.61097 7.67026 5.39456 9.18838 2.45953C9.44211 1.96898 9.25013 1.36562 8.75959 1.11189Z" 
        fill={color || "#ffffffa6"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1914 19.0085C11.6793 19.2673 12.2846 19.0816 12.5434 18.5936C14.1673 15.5319 15.0185 12.9908 15.029 10.3195C15.0395 7.66216 14.2167 4.99391 12.728 1.68438C12.5014 1.18071 11.9094 0.956075 11.4057 1.18264C10.9021 1.40921 10.6774 2.00119 10.904 2.50486C12.3525 5.72488 13.0378 8.08137 13.029 10.3116C13.0203 12.528 12.3249 14.7373 10.7765 17.6565C10.5178 18.1444 10.7035 18.7497 11.1914 19.0085Z" 
        fill={color || "#ffffffa6"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0312 6.9931C19.0283 6.44082 18.5782 5.99549 18.0259 5.99841C15.0809 6.01403 12.7442 5.99297 10.4051 5.97189C10.3027 5.97097 10.2002 5.97005 10.0978 5.96913C7.65442 5.9472 5.18085 5.92701 1.99264 5.95063C1.44038 5.95473 0.995989 6.40575 1.00008 6.95802C1.00417 7.51029 1.45519 7.95467 2.00746 7.95058C5.17842 7.92708 7.63838 7.94713 10.0798 7.96905C10.183 7.96998 10.2862 7.97091 10.3894 7.97184C12.7278 7.99291 15.077 8.01408 18.0365 7.99839C18.5888 7.99546 19.0341 7.54538 19.0312 6.9931Z" 
        fill={color || "#ffffffa6"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0312 12.9931C19.0283 12.4408 18.5782 11.9955 18.0259 11.9984C15.0809 12.014 12.7442 11.993 10.4051 11.9719C10.3027 11.971 10.2002 11.97 10.0978 11.9691C7.65442 11.9472 5.18085 11.927 1.99264 11.9506C1.44038 11.9547 0.995989 12.4057 1.00008 12.958C1.00417 13.5103 1.45519 13.9547 2.00746 13.9506C5.17842 13.9271 7.63838 13.9471 10.0798 13.969C10.183 13.97 10.2862 13.9709 10.3894 13.9718C12.7278 13.9929 15.077 14.0141 18.0365 13.9984C18.5888 13.9955 19.0341 13.5454 19.0312 12.9931Z" 
        fill={color || "#ffffffa6"}
      />
    </svg>
  );
};

export default WorldIcon;
