import React, { useContext } from "react";
import { Row, Col, Card, Typography } from "antd";
import { LanguageContext } from "../../../../context/Language";
import { UserOutlined } from "@ant-design/icons";
import RoleIcon from "../../../Icon/RoleIcon";
import AddIcon from "../../../Icon/AddIcon";
import ReadView from "./ReadView";
import EditView from "./EditView";

/**
 * Contributors is a low level component that renders a list of contributors.
 * in two ways, like a list of selectors, or like strings for non-editable mode.
 * The component is actually hard, cuz the data is transformed in a specific way
 * you can see TrackContributors for more details about the data structure.
 *
 * @component
 * @param {Object} props
 * @param {string} props.title - Title to display.
 * @param {string} [props.textAction] - Text for the action button (default dictionary.add).
 * @param {Array} props.roleOptions - Options for roles. see Ant select component.
 * @param {Array} props.invertedRoles - Original roles inverted by value: key
 * @param {Array} props.contributorOptions - Options for contributors. see Ant select component.
 * @param {Object} props.contributors - List of contributors, can be artist, writer or producer.
 * @param {Array} props.labelArtists - List of unique contributors of the label.
 * @param {Function} props.onChange - Expect a dispatch function to update the state.
 * @param {boolean} props.disabled - Indicates if the component is disabled.
 * @param {boolean} props.multiple - Indicates if multiple selections are allowed.
 * @param {boolean} props.isEditMode - Choose between edit mode or read mode.
 * @param {boolean} props.withRoles - Indicates if the component should render the roles in read view.
 * @param {boolean} props.withFeatured - Indicates if the component should render ft artists in read view.
 * @param {string} props.addContributorKey - Indicates if the origin key of the contributor to add.
 */
const Contributors = ({
  title = "",
  textAction = "",
  // labelArtists is the list of unique contributors of the label
  labelArtists = [],
  roleOptions = [],
  invertedRoles = {},
  contributorOptions = [],
  contributors = {},
  originalContributors = {},
  onChange = () => {},
  disabled = false,
  // if multiple is true, the role select will be in multiple mode
  multiple = false,
  isEditMode = false,
  withRoles = true,
  withFeatured = false,
  addContributorKey = "",
  isLoading = false,
}) => {
  // TODO: missing add search in the contributor select and the role select
  const { dictionary } = useContext(LanguageContext);

  /**
   * Returns the first contributor that isn't in the contributors list.
   * @returns {Object}
   */
  const contributorAvailable = () => {
    for (const contributor of labelArtists)
      if (!Object.hasOwnProperty.call(contributors, contributor._id))
        return contributor;

    return null;
  };

  const headerFragment = () => {
    return (
      <React.Fragment key={"contributors-header-fragment"}>
        <Col xs={24} sm={12} md={12} lg={12} className="row-track-input name">
          <div className="row-preview-track">
            <div className="icon-head">
              <UserOutlined />
            </div>
            <div className="info-head">{dictionary.name}</div>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} className="row-track-input roles">
          <div className="row-preview-track role">
            <div className="icon-head">
              <RoleIcon />
            </div>
            <div className="info-head">{dictionary.role}</div>
          </div>
        </Col>
      </React.Fragment>
    );
  };

  const content = () => {
    if (isEditMode)
      return [
        headerFragment(),
        labelArtists.map((contributor) =>
          Object.hasOwnProperty.call(contributors, contributor._id) ? (
            <EditView
              key={contributor._id + addContributorKey}
              labelArtists={labelArtists}
              originalContributors={originalContributors}
              contributorId={contributor._id}
              contributorOptions={contributorOptions}
              contributorIterable={contributors[contributor._id]}
              roleOptions={roleOptions}
              invertedRoles={invertedRoles}
              disabled={disabled}
              multiple={multiple}
              onChange={onChange}
              addContributorKey={addContributorKey}
            />
          ) : null
        ),
      ];

    return (
      <ReadView
        labelArtists={labelArtists}
        contributors={contributors}
        withRoles={withRoles}
        withFeatured={withFeatured}
      />
    );
  };

  return (
    <React.Fragment>
      {title && <Typography.Title level={4}>{title}</Typography.Title>}
      <Card loading={isLoading} className="roles-cards">
        <Row>{content()}</Row>
        {isEditMode && (
          <div className="btn-submit">
            <button
              className="new-artist-add track-edit-btn"
              onClick={() => {
                onChange({
                  type: "contributors",
                  payload: {
                    type: "add",
                    original: originalContributors,
                    addKey: addContributorKey,
                    value: {
                      artist_id: contributorAvailable()?._id,
                      name: contributorAvailable()?.name,
                      role: roleOptions[0].value,
                      _id: "new_" + new Date().toISOString(),
                      origin: addContributorKey,
                    },
                  },
                });
              }}
              disabled={
                disabled ||
                labelArtists.length === Object.keys(contributors).length
              }
            >
              <AddIcon />
              {textAction === "" ? dictionary.add : textAction}
            </button>
          </div>
        )}
      </Card>
    </React.Fragment>
  );
};

export default Contributors;
