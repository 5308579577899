import React from "react";
import { Button } from "antd";
import "./style.css";

const BorderButton = ({
  styles = null,
  onClick = null,
  text,
  size = null,
}) => {
  return (
    <Button
      className={"border-btn " + (size ? size : "sm")}
      onClick={onClick}
      style={styles}
      type="text"
    >
      {text}
    </Button>
  );
};

export default BorderButton;
