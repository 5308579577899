import React, { useContext, useState, useEffect } from "react";
import { notification, Card, Dropdown, Spin } from "antd";
import { Link, useSearchParams } from "react-router-dom";
import { LanguageContext } from "../../../context/Language";
import moment from "moment";
import Input from "../../../components/Form/Input";
import Admin from "../../../classes/admin/admin.jsx";
import Search from "../../../components/Form/Search";
import SearchIcon from "../../../components/Icon/SearchIcon";
import Button from "../../../components/Buttons/GeneralButton";
import ModalContractStatus from "../../../components/Contracts/ModalContractStatus/ModalContractStatus.jsx";
import ModalSavePayee from "../../../components/Contracts/ModalSavePayee/ModalSavePayee.jsx";
import TableContractDetail from "../../../components/Contracts/TableContracts/Detail/TableContractDetail.jsx";
import "../contract.css";

const ContractDetail = () => {

  const { dictionary } = useContext(LanguageContext);
  const [searchParams] = useSearchParams();
  const labelId = searchParams.get("l");
  const [payee, setPayee] = useState("");
  const [curveId, setCurveId] = useState("");
  const [search, setSearch] = useState(null);
  const [loadList, setLoading] = useState(false);
  const [loadFull, setLoadFull] = useState(false);
  const [allCurveIds, setAllCurveIds] = useState([]);
  const [errorCurve, setErrorCurve] = useState(false);
  const [errorPayee, setErrorPayee] = useState(false);
  const [allContracts, setAllContracts] = useState([]);
  const [labelDetail, setLabelDetail] = useState(null);
  const [listContractAdmin, setContractAdmin] = useState([]);
  const [statusData, setstatusData] = useState({
    contractid: "",
    show: false,
    type: "",
    name: ""
  });
  const [payeeData, setPayeeData] = useState({
    id: "",
    show: false,
    name: ""
  });

  useEffect(() => {
    getLabelDetail();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    searchInContracts(search);
    // eslint-disable-next-line
  }, [search]);

  const getLabelDetail = () => {
    setLoading(true);
    setLabelDetail(null);
    try {
      Admin.getbyLabel(labelId).then((resp) => {
        if (resp.status === 200) {
          setLabelDetail(resp.body.label);
          setAllContracts(resp.body?.label?.contracts);
          const ids = resp?.body?.label?.contracts?.map(item => item?.curve_id) || [];
          setAllCurveIds([labelId,...ids]);
          setContractAdmin(getTableData(resp.body?.label?.contracts))
        } else {
          setLabelDetail(null);
          notification.open({
            message: dictionary.errorGeneral,
          });
        }
      });
    } catch (e) {
      console.log(e);
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const getTableData = (listSearched = []) => {
    if(listSearched.length > 0){
      return listSearched.map((contract) => {
        return {
          key: contract.curve_id,
          contract_id: contract.curve_id,
          name: 
            <Link
              target="_blank"
              rel="noopener noreferrer"
              className="link-table"
              to={"/contracts/update?c=" + contract._id}
            >
              {contract.name}
            </Link>,
          sign_date: 
          contract.sign_date
          ? moment(contract.sign_date).format("MMM DD, YYYY, h:mm a")
          : "-",
          status: contract.status,
          actions: changeStatus(contract._id, contract.status, contract.name),
        };
      });
    }
  };

  const changeStatus = (contId, contStatus, contName) => {
    return (
      <Dropdown
        menu={{
          items: [
            {
              key: "active",
              label: (
                <div className={" item-priority low "+(contStatus === "active" ? "unclick" :"")}>
                  {dictionary.activate}
                </div>),
              onClick: contStatus === "active" 
                ? "" 
                : () => showModalContractStatus(contId, "active", contName),
            },
            {
              key: "disable",
              label: (
              <div className={" item-priority none "+(contStatus === "suspended" ? "unclick" : "")}>
                {dictionary.suspend}
              </div>),
              onClick: contStatus === "suspended" 
                ? "" 
                : () => showModalContractStatus(contId, "suspend", contName),
            },
          ],
          selectable: true,
          defaultSelectedKeys: [contStatus],
        }}
        trigger={["click"]}
        placement="bottomRight"
        overlayClassName="drop-priorities drop-status"
      >
        <button className={"priority-btn " + contStatus}>
          {dictionary[contStatus]}
        </button>
      </Dropdown>
    );
  };

  const searchInContracts = (value) => {
    if (!value) {
      setContractAdmin(getTableData(allContracts));
      return;
    }
    let filteredNames = allContracts.filter(
      (el) =>
        el.name?.toLowerCase().includes(value.toLowerCase()) ||
        el.curve_id?.toLowerCase().includes(value.toLowerCase()) ||
        el.status?.toLowerCase().includes(value.toLowerCase()) ||
        moment(el.sign_date).format("MMM DD, YYYY, h:mm a").includes(value)
    );
    setContractAdmin(getTableData(filteredNames));
  };

  const onSearch = (search) => {
    setSearch(search.target.value);
    searchInContracts(search.target.value);
  };

  const showModalContractStatus = (contract_id = "", type, contract_name) => {
    setstatusData({
      contractid: contract_id,
      show: true,
      type: type,
      name: contract_name
    });
  };

  const showModalPayee = (payee_id = "", payee_name= "") => {
    setPayeeData({
      id: payee_id,
      show: true,
      name: payee_name
    });
  };

  const updatePayeeId = async (idLabel) => {
    setErrorCurve(false);
    setErrorPayee(false);
    setLoadFull(true);
    try {
      let validate = validateID(2, payee);
      if (!validate) {
        return;
      }
      hideModalPayee();
      Admin.updatePayee(idLabel, payee).then((resp) => {
        if (resp.status === 200) {
          successPayeeNotification();
          setLoadFull(false);
        } else {
          errorNotification();
          setLoadFull(false);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const successPayeeNotification = () => {
    notification.success({
      message: dictionary.payeeAddedSuccess,
      duration: 3,
      placement: "topRight",
    });
  };

  const validateID = (numb, item) => {
    if (!item || item.length === 0) {
      if (numb === 1) {
        setErrorCurve(dictionary.emptyField);
      } else {
        setErrorPayee(dictionary.emptyField);
      }
      return false;
    }
    return true;
  };

  const columns = [
    {
      title: dictionary.contractId,
      dataIndex: "contract_id",
      className: "title-category contract-tb tb-left",
    },
    {
      title: dictionary.name,
      dataIndex: "name",
      className: "title-category contract-tb tb-left",
    },
    {
      title: dictionary.signDate,
      dataIndex: "sign_date",
      className: "title-category",
    },
    {
      title: dictionary.status,
      dataIndex: "actions",
      className: "title-category",
    },
  ];

  const errorNotification = () => {
    notification.error({
      message: dictionary.errorGeneral,
      duration: 3,
      placement: "topRight",
    });
  };

  const successDisNotification = () => {
    notification.success({
      message: dictionary.disableContractSuccess,
      duration: 3,
      placement: "topRight",
    });
  };

  const successNotification = () => {
    notification.success({
      message: dictionary.enableContractSuccess,
      duration: 3,
      placement: "topRight",
    });
  };

  const hideModalStatus = () => {
    setstatusData({
      contractid: "",
      show: false,
      type: "",
      name: ""
    });
  };

  const hideModalPayee = () => {
    setPayeeData({
      id: "",
      show: false,
      name: ""
    });
  };

  const statusContract = (contractId, type) => {
    setLoading(true);
    let newStatus = type === "suspend" ? false : true;
    if (newStatus) {
      Admin.enableContract(contractId)
        .then((res) => {
          if (res.status === 200) {
            successNotification();
            getLabelDetail();
          }
        })
        .catch(() => {
          errorNotification();
        });
    } else {
      Admin.disableContract(contractId)
        .then((res) => {
          if (res.status === 200) {
            successDisNotification();
            getLabelDetail();
          }
        })
        .catch(() => {
          errorNotification();
        });
    }
    setLoading(false);
    hideModalStatus();
  };

  const createContract = (idLabel) => {
    setErrorCurve(false);
    setErrorPayee(false);
    let myerror = false;
    try {
      if(!curveId || curveId.length === 0){
        myerror = dictionary.emptyField;
        setErrorCurve(myerror);
        return;
      } else if(allCurveIds.includes(curveId)){
        myerror = dictionary.alreadyId;
        setErrorCurve(myerror);
        return;
      }
      setLoading(true);
      Admin.addContract(curveId, idLabel).then((resp) => {
        if (resp.status === 200) {
          setCurveId("");
          successNotification();
          getLabelDetail();
        } else {
          if (resp.status === 400) {
            if(resp?.errors[0]?.message){
              myerror = dictionary[resp?.errors[0]?.message];
            } else {
              myerror = dictionary.errorGeneral;
            }
          } else if (resp.status === 404) {
            errorNotification();
          }
        }
        setErrorCurve(myerror);
      }).catch(() => {
        errorNotification();
        setLoading(false);
      }).finally(() => {
        setCurveId("");
        setLoading(false);
      });
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="container-admin">
        <Card className="contract-card">
          <Spin spinning={loadFull} className="spinner">
            <div className="forms contract-input">
              <Input
                name="payee_id"
                value={payee}
                label={dictionary.payeeId}
                onChange={(e) => setPayee(e.target.value)}
                className={
                  (errorPayee ? "errorcurve " : "") + " input-general input-edit"
                }
                placeholder={dictionary.placeholderId}
              />
              {errorPayee ? <p className="errorPayee-text">{errorPayee}</p> : null}
              <Button
                size="sm"
                className="new-rel-btn save"
                text={dictionary.save}
                onClick={() => showModalPayee(labelDetail?._id, labelDetail?.name)}
              />
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to="https://app.curveroyaltysystems.com/#/payees/new"
                className="createpayee-btn"
              >
                <Button
                  size="md"
                  className="new-rel-btn"
                  text={dictionary.createPayee}
                />
              </Link>
            </div>
          </Spin>
        </Card>
        <div className="add-title">
          <h2>{dictionary.contractList}</h2>
          <Link
            rel="noopener noreferrer"
            className="createcontract-btn"
            to={`/contracts/create?labelId=${labelDetail?._id}`}
          >
            <Button
              size="md"
              className="new-rel-btn"
              text={dictionary.createContract}
            />
          </Link>
        </div>
        <div className="forms contractadd-input">
          <Input
            name="curveId"
            value={curveId}
            label={dictionary.addContractID}
            onChange={(e) => setCurveId(e.target.value)}
            className={
              (errorCurve ? "errorcurve " : "") + " input-general input-edit"
            }
            placeholder={dictionary.placeholderId}
          />
          {errorCurve ? <p className="errorCurve-text">{errorCurve}</p> : null}
          <Button
            size="sm"
            className="new-rel-btn save"
            text={dictionary.add}
            onClick={() => {
              createContract(labelDetail?._id);
            }}
          />
        </div>
        <div className="searcher-cont">
          <Search
            name="searcher"
            value={search}
            mobile="responsive"
            onSearch={onSearch}
            className="searcher sec back"
            enterButton={<SearchIcon />}
            placeholder={dictionary.search}
          />
        </div>
        <TableContractDetail
          columns={columns}
          contract={labelDetail}
          list={listContractAdmin}
          loadList={loadList}
        />
        <ModalContractStatus
          statusData={statusData}
          hideModalStatus={hideModalStatus}
          statusContract={statusContract}
        />
        <ModalSavePayee
          payeeData={payeeData}
          updatePayeeId={updatePayeeId}
          hideModalPayee={hideModalPayee}
        />
      </div>
    </React.Fragment>
  );
};

export default ContractDetail;
