import React, { useContext, useState } from "react";
import { Checkbox } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { LanguageContext } from "../../../context/Language";
import "../../../containers/Register/register.css";
import Button from "../../Buttons/GeneralButton";

const Terms = ({ state, setKeys, setInput, changeSection } ) => {
  const { dictionary } = useContext(LanguageContext);
  const [error, errorTerms] = useState(null);

  const onChangeCheckbox = (e) => {
    errorTerms(null);
    setInput({
      ...state,
      terms: e.target.checked,
    });
  };

  const validate = () => {
    errorTerms(null);
    const valid = state.terms;
    if (!valid) errorTerms(dictionary.checkedError);

    return valid;
  };

  const back = () => {
    changeSection(1);
  };

  const next = async () => {
    if (validate()) {
      setKeys(state);
      changeSection(3);
    }
  };

  return (
    <div className="terms">
      <h3>{dictionary.termsAndCond}</h3>
      <div className="terms-box">
        <p>AGREEMENT TO TERMS</p>
        <p>
          These Terms of Service constitute a legally binding agreement made
          between you, whether personally or on behalf of an entity (“you”) and
          [business entity name] (“we,” “us” or “our”), concerning your access
          to and use of the priamdigital.com website as well as any other media
          form, media channel, mobile website or mobile application related,
          linked, or otherwise connected thereto (collectively, the “Site”).
        </p>
        <p>
          You agree that by accessing the Site, you have read, understood, and
          agree to be bound by all of these Terms of Service. If you do not
          agree with all of these Terms of Service, then you are expressly
          prohibited from using the Site and you must discontinue use
          immediately.
        </p>
        <p>
          Supplemental Terms of Service or documents that may be posted on the
          Site from time to time are hereby expressly incorporated herein by
          reference. We reserve the right, in our sole discretion, to make
          changes or modifications to these Terms of Service at any time and for
          any reason.
        </p>
        <p>
          We will alert you about any changes by updating the “Last updated”
          date of these Terms of Service, and you waive any right to receive
          specific notice of each such change.
        </p>
      </div>
      <Checkbox
        checked={state.terms}
        className="check-terms"
        onChange={onChangeCheckbox}
      >
        {dictionary.agreeTerms}
      </Checkbox>
      {error ? <div className="error-field">{error}</div> : null}
      <div className="align-right reg-btn">
        <Button
          text={dictionary.back}
          size="md"
          icon={<ArrowLeftOutlined />}
          className="back-btn"
          onClick={back}
        />
        <Button text={dictionary.next} size="md" onClick={next} />
      </div>
    </div>
  );
};

export default Terms;
