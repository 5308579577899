import React from "react";

const ReviewIcon = ({ className = null, onClick = null, color = null }) => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.60608 0.080855C7.85763 -0.0269517 8.14237 -0.0269517 8.39392 0.080855L14.7878 2.82111C15.5232 3.13626 16 3.85934 16 4.6594V11.5181C16 14.0301 14.654 16.3495 12.473 17.5958L8.49614 19.8682C8.18871 20.0439 7.81129 20.0439 7.50386 19.8682L3.52703 17.5958C1.346 16.3495 0 14.0301 0 11.5181V4.6594C0 3.85934 0.476795 3.13626 1.21216 2.82111L7.60608 0.080855ZM11.7071 8.70711C12.0976 8.31658 12.0976 7.68342 11.7071 7.29289C11.3166 6.90237 10.6834 6.90237 10.2929 7.29289L7 10.5858L5.70711 9.29289C5.31658 8.90237 4.68342 8.90237 4.29289 9.29289C3.90237 9.68342 3.90237 10.3166 4.29289 10.7071L6.29289 12.7071C6.68342 13.0976 7.31658 13.0976 7.70711 12.7071L11.7071 8.70711Z"
        fill={color || "#ffffffa6"}
      />
    </svg>
  );
};

export default ReviewIcon;
