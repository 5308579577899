import React, { useContext } from "react";
import { Button, Col, Row, Select } from "antd";
import { useFormContext, Controller } from "react-hook-form";
import { DeleteOutlined } from "@ant-design/icons";
import { AppContext } from "../../../../../context/App/AppProvider";
import Input from "../../../../Form/Input/GeneralInput";

const EscalationEquivalent = ({ index, remove }) => {
  const formMethods = useFormContext();
  const { escalationEquivalentConfigurationOptions } = useContext(AppContext);
  const { control } = formMethods;

  return (
    <Row gutter={[16, 16]} justify={"center"} align={"middle"}>
      <Col span={14}>
        <Controller
          control={control}
          name={`escalationEquivalent.${index}.configuration`}
          rules={{
            required: "This field is required",
          }}
          render={({ field, fieldState }) => (
            <React.Fragment>
              <label className="allWidth">Configuration</label>
              <Select
                className={
                  "fullWidth selector-general" +
                  (fieldState.error ? " input-general-error" : "")
                }
                options={escalationEquivalentConfigurationOptions}
                {...field}
              />
              {fieldState.error && (
                <div className="text-error">{fieldState.error.message}</div>
              )}
            </React.Fragment>
          )}
        />
      </Col>
      <Col span={6}>
        <Controller
          control={control}
          name={`escalationEquivalent.${index}.amount`}
          rules={{
            required: "This field is required",
            min: "Field must be grather or equal than 0",
          }}
          render={({ field, fieldState }) => (
            <Input
              label={"Units"}
              placeholder={"Units"}
              {...field}
              type="number"
              min={0}
              error={fieldState.error?.message}
            />
          )}
        />
      </Col>
      <Col span={4}>
        <Button
          className="remove"
          type="danger"
          onClick={remove}
          icon={<DeleteOutlined />}
        />
      </Col>
    </Row>
  );
};

export default EscalationEquivalent;
