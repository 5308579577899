import React from "react";

const CancelIcon = ({ className = null, onClick = null }) => {
  return (
    <svg
      width="512.000000pt"
      height="512.000000pt"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M100 4509 c-14 -6 -36 -20 -48 -32 -55 -51 -52 34 -52 -1464 0 -1301
          1 -1390 18 -1423 9 -19 32 -45 51 -57 l34 -23 1458 0 1459 0 4 -22 c56 -259
          134 -407 300 -574 208 -207 446 -307 736 -308 232 0 420 57 606 185 85 59 215
          190 274 278 233 346 237 809 12 1157 -122 188 -321 350 -509 414 l-73 24 -2
          877 -3 877 -31 39 c-17 21 -46 43 -65 50 -44 15 -4130 17 -4169 2z m3968 -364
          c3 -66 1 -72 -25 -96 -15 -14 -435 -342 -933 -729 -498 -386 -910 -706 -916
          -711 -6 -5 -368 270 -950 722 l-939 731 -3 79 -3 79 1883 -2 1883 -3 3 -70z
          m-3295 -829 c246 -191 447 -351 447 -354 -1 -8 -896 -745 -911 -750 -5 -2 -9
          299 -9 733 0 589 3 736 13 728 6 -6 213 -166 460 -357z m3297 -120 l0 -483
          -88 -7 c-100 -7 -248 -44 -333 -82 -37 -17 -62 -23 -72 -17 -7 4 -109 86 -225
          182 l-211 174 461 358 c254 198 463 359 465 359 2 0 3 -218 3 -484z m-2285
          -667 c170 -133 322 -247 337 -255 35 -18 80 -18 121 0 18 8 171 123 341 255
          170 133 311 241 313 241 2 0 101 -79 219 -176 l214 -176 -43 -50 c-119 -140
          -203 -296 -238 -443 -12 -49 -23 -96 -25 -102 -3 -10 -283 -13 -1361 -13
          -1339 0 -1357 0 -1338 19 31 30 1139 941 1145 941 3 0 144 -108 315 -241z
          m2470 -143 c158 -42 336 -165 424 -295 99 -146 135 -263 135 -436 0 -141 -16
          -211 -75 -331 -127 -259 -384 -418 -674 -417 -347 2 -632 221 -726 558 -29
          103 -31 267 -4 370 73 285 297 501 584 561 82 17 252 12 336 -10z"
        />
        <path
          d="M3759 2007 c-41 -27 -69 -79 -69 -128 0 -43 39 -106 109 -173 l51
          -50 -70 -72 c-38 -39 -74 -83 -80 -98 -26 -70 5 -153 70 -186 78 -40 138 -20
          234 79 l60 60 72 -69 c79 -75 128 -97 188 -86 48 9 102 62 111 109 14 71 -1
          105 -81 187 l-74 76 65 64 c36 35 71 78 80 96 59 124 -62 252 -192 203 -15 -5
          -60 -41 -98 -80 l-71 -69 -64 66 c-36 36 -77 72 -92 80 -41 22 -109 18 -149
          -9z"
        />
      </g>
    </svg>
  );
};

export default CancelIcon;
