import React from "react";
import { useFieldArray, useFormContext, Controller } from "react-hook-form";
import ReserveSchedule from "../../../components/Contracts/Create/Reserves/ReserveSchedule";
import { Button, Card, Row, Col, Select } from "antd";

const CreateContractReserves = () => {
  const { control } = useFormContext();
  const {
    fields: reservefields,
    append: appendReserve,
    remove: removeReserve,
  } = useFieldArray({
    name: "reserve",
  });

  const addPeriod = () => {
    appendReserve({
      rate: 0,
    });
  };

  const drawReserves = () => {
    return reservefields.map((st, index) => {
      return (
        <ReserveSchedule
          key={st.id}
          data={st}
          index={index}
          remove={() => removeReserve(index)}
        />
      );
    });
  };

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} lg={12}>
        <Card bordered={false}>
          <h3>Reserve release schedule</h3>
          {drawReserves()}
          <Button type="primary" onClick={addPeriod} className="add-item">
            + Reserve period
          </Button>
        </Card>
      </Col>
      <Col xs={24} lg={12}>
        <Card bordered={false}>
          <h3>Reserve basis</h3>
          <p>
            When should the reserve be taken? Should it be from your revenues
            (`&apos;`Pre-Calculation`&apos;`), or the artist`&apos;`s share
            (`&apos;`Post-Calculation`&apos;`)?
          </p>

          <Controller
            control={control}
            name="reserve.basis"
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Select
                className={"fullWidth selector-general"}
                options={[
                  { value: "Pre-Calculation", name: "Pre-Calculation" },
                  { value: "Post-Calculation", name: "Post-Calculation" },
                ]}
                {...field}
              />
            )}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default CreateContractReserves;
