import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../../context/User";
import { Row, Col, Upload, message } from "antd";
import { LanguageContext } from "../../../context/Language";
import { AppContext } from "../../../context/App/AppProvider.js";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import "../set.css";
import Loader from "../../Loader";
import Input from "../../Form/Input";
import Phone from "../../Form/Phone";
import Select from "../../Form/Select";
import Button from "../../Buttons/GeneralButton";
import User from "../../../classes/user/user.jsx";
import useStateValidator from "../../../hooks/validators/useStateValidator";

const rules = {
  name: {
    type: "string", min: 2, max: 120, isAlphanumeric: true,
  },
  last: {
    type: "string", min: 1, max: 120, isAlphanumeric: true,
  },
  companyName: {
    type: "string", min: 3, max: 120, isAlphanumeric: true,
  },
  address: {
    type: "string", min: 3, max: 255, isAlphanumeric: true,
  },
  city: {
    type: "string", min: 1, max: 120, isAlpha: true,
  },
  state: {
    type: "string", min: 1, max: 120, isAlpha: true,
  },
  website: {
    type: "string", min: 1, max: 120, validEmpty: true, isURL: true,
  },
  zipCode: {
    type: "string", min: 1,
  },
  country: { 
    type: "string",length: 0, isAlpha: true,
  },
  phone: {
    type: "string", min: 3, numericStr: true,
  },
  image: {
    type: "omit", length: 0, validEmpty: true,
  },
  ladaCountry: {
    type: "string", length: 0, validEmpty: true, isAlpha: true,
  },
  ladaCode: {
    type: "number", length:0,  validEmpty: true, numericStr: true,
  },
};
const Profile = () => {
  const { dictionary } = useContext(LanguageContext);
  const { profileData, avatar, type, checkUser, parentId, parentLabel } = useContext(UserContext);
  const { allCountries, ladaOptions } = useContext(AppContext);
  const { validationErrorMessages, validate } = useStateValidator({rules});
  const [imageUrl, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [profile, setProfile] = useState({
    name: "",
    last: "",
    companyName: "",
    address: "",
    city: "",
    state: "",
    website: "",
    zipCode: "",
    country: 0,
    ladaCountry: "US",
    ladaCode: "",
    phone: "",
    image: "",
  });
  const [errors, setError] = useState({
    name: "",
    last: "",
    companyName: false,
    address: false,
    city: false,
    state: false,
    zipCode: false,
    country: false,
    phone: false,
  });

  useEffect(() => {
    if (profileData) {
      setProfile({
        name: profileData.name || "",
        last: profileData.surname || "",
        companyName: profileData.company_name || "",
        address: profileData.address.address || "",
        city: profileData.address.city || "",
        state: profileData.address.state || "",
        website: profileData.website || "",
        zipCode: profileData.address.zip || "",
        country: profileData.address.country || 0,
        ladaCountry: profileData.phone.country || "US",
        ladaCode: profileData.phone.code || 1,
        phone: profileData.phone.number || "",
        image: avatar || "",
      });
    }
    if (avatar) {
      setImage(avatar);
    }
  }, [profileData, avatar]);

  const ladaChangeHandler = (e, name) => {
    setProfile({
      ...profile,
      ladaCountry: e,
      ladaCode: name.code,
    });
  };

  const changeHandler = (e) => {
    setProfile({
      ...profile,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelect = (e, name) => {
    setProfile({
      ...profile,
      [name]: e,
    });
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
          color: "#8c8c8c",
        }}
      >
        {dictionary.upload}
      </div>
    </div>
  );

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleChange = ({ file }) => {
    setImageFile(file);
    if (file.status === "uploading") {
      setLoading(true);
      // Get this url from response in real world.
      getBase64(file.originFileObj, (imageUrl) => setImage(imageUrl));
    }
  };

  const success = () => {
    message.success({
      content: dictionary.profileSuccess,
      style: {
        marginTop: "50px",
      },
    });
  };

  const errorMsg = () => {
    message.error({
      content: dictionary.profileError,
      style: {
        marginTop: "50px",
      },
    });
  };

  const save = async () => {
    const errors = validate(profile);
    if (Object.keys(errors).length) {
      setError(errors);
      return;
    } else {
      setLoader(true);
      if (imageFile && imageFile.originFileObj) {
        const imageArtist = await User.uploadProfileAvatar(
          imageFile.originFileObj
        );
        if (imageArtist) {
          profile.image = imageArtist;
        }
      }
      const updated = await User.updateProfile(profile);
      if (updated.status === 200) {
        setLoader(false);
        success();
        checkUser();
      } else {
        setLoader(false);
        errorMsg();
      }
    }
  };

  return (
    <div className="content-set">
      <div className="forms">
        <Row className="gutter-row">
          <Col xs={24} sm={24} md={12} lg={12} className="col1">
            <Input
              name="name"
              required={true}
              value={profile.name}
              error={validationErrorMessages?.name}
              onChange={changeHandler}
              label={dictionary.firstName}
              className={validationErrorMessages?.name ? "inputErr" : ""}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col2">
            <Input
              name="last"
              required={true}
              value={profile.last}
              error={validationErrorMessages?.last}
              onChange={changeHandler}
              label={dictionary.lastName}
              className={validationErrorMessages?.last ? "inputErr" : ""}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col1">
            {!parentId ? (
                <Input
                name="companyName"
                required={true}
                value={profile.companyName}
                onChange={changeHandler}
                error={validationErrorMessages?.companyName}
                label={
                  type === "artist" 
                    ? dictionary.artistLabelName
                    : dictionary.companyName
                }
                className={validationErrorMessages?.companyName ? "inputErr" : ""}
              />
            ) 
            :(
              <Input
                name="labelParent"
                value={parentLabel[0].name}
                onChange={changeHandler}
                disabled={true}
                label={dictionary.belongsToCompany}
              />
              )}
            <Input
              name="address"
              required={true}
              value={profile.address}
              onChange={changeHandler}
              error={validationErrorMessages?.address}
              label={dictionary.address}
              className={validationErrorMessages?.address ? "inputErr" : ""}
            />
            <Input
              name="city"
              required={true}
              value={profile.city}
              error={validationErrorMessages?.city}
              label={dictionary.city}
              onChange={changeHandler}
              className={validationErrorMessages?.city ? "inputErr" : ""}
            />
            <Input
              name="state"
              required={true}
              value={profile.state}
              error={validationErrorMessages?.state}
              onChange={changeHandler}
              label={dictionary.state}
              className={validationErrorMessages?.state ? "inputErr" : ""}
            />
            <Input
              name="website"
              required={false}
              error={validationErrorMessages?.website}
              value={profile.website}
              onChange={changeHandler}
              label={dictionary.website}
              className={validationErrorMessages?.website ? "inputErr" : ""}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col2">
            <label className="allWidth">{dictionary.profilePicture}</label>
            <React.Fragment>
              <Upload
                name="avatar"
                accept={"image/*"}
                showUploadList={false}
                listType="picture-card"
                className="avatar-uploader avatar-profile"
                onChange={handleChange}
              >
                {imageUrl ? (
                  <img
                    alt="avatar"
                    src={imageUrl ? imageUrl : null}
                    className="avatar-profile"
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </React.Fragment>
            <Input
              name="zipCode"
              required={true}
              maxLength={10}
              value={profile.zipCode}
              onChange={changeHandler}
              error={errors.zipCode}
              label={dictionary.zip}
              className={errors.zipCode ? "inputErr" : ""}
            />
            <Select
              name="country"
              required={true}
              showSearch={true}
              value={profile.country}
              options={allCountries}
              placeholder={dictionary.select}
              label={dictionary.country}
              onChange={(e) => handleSelect(e, "country")}
              className={
                (errors.country ? "countErr " : "") + " selector-general"
              }
            />
            <Phone
              name="phone"
              required={true}
              maxLength={11}
              value={profile.phone}
              onChange={changeHandler}
              error={errors.phone}
              lada={profile.ladaCountry}
              onChangeSelect={ladaChangeHandler}
              phoneCountries={ladaOptions}
              label={dictionary.phoneNumber}
              className={errors.phone ? "inputErr" : ""}
            />
          </Col>
        </Row>
      </div>
      <div className="reg-btn">
        <Button size="md" onClick={save} text={dictionary.save} />
      </div>
      {loader ? <Loader /> : null}
    </div>
  );
};

export default Profile;
