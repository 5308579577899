import React, { useContext, useState, useEffect } from "react";
import { LanguageContext } from "../../../context/Language";
import { UserContext } from "../../../context/User";
import { AppContext } from "../../../context/App/AppProvider";
import { notification } from "antd";
import List from "../List";
import Tables from "../Tables";
import Modal from "../../Modal";
import AddLabel from "./AddLabel";
import Loader from "../../Loader";
import User from "../../../classes/user/user";
import Button from "../../Buttons/GeneralButton";
import Country from "../../../classes/country/country";

const LabelList = ({
  labels,
  loadList,
  getLabels,
  searchValues,
  getLabelsFunct,
}) => {
  const { dictionary } = useContext(LanguageContext);
  const { permission, type } = useContext(UserContext);
  const { validateNewLabel } = useContext(AppContext);
  const [listLabels, setLabelList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [labelDeletedResp, setLabelDeletedResp] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [phoneCountries, setPhoneCountries] = useState([]);
  const [dataCountries, setDataCountries] = useState([]);
  const [messageArtist, setMessageArtist] = useState("");

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    firstName: "",
    surname: "",
    password: "",
    confirmPassword: "",
    parent: "",
  });
  const [labelData, setLabelData] = useState({
    index: null,
    _id: null,
    name: "",
    email: "",
    firstName: "",
    surname: "",
    password: "",
    confirmPassword: "",
    countryPhone: "US",
    code: "1",
    number: "",
    address: "",
    city: "",
    state: "",
    country: 0,
    zip: "",
    update: false,
    parent: 0,
  });
  const [deleteData, setDeleteData] = useState({
    name: "",
    show: false,
  });

  useEffect(() => {
    setLabelList(labels);
  }, [labels]);

  useEffect(() => {
    if (dataCountries.length === 0) {
      getCountries();
    }
  }, [dataCountries.length]);

  useEffect(() => {
    if (searchValues) {
      let filteredNames = labels.filter((el) =>
        el.name.toLowerCase().includes(searchValues.toLowerCase())
      );
      setLabelList(filteredNames);
    } else {
      setLabelList(labels);
    }
  }, [searchValues, labels]);

  const columns = [
    {
      title: dictionary.picture,
      dataIndex: "details",
      className: "id-category",
    },
    {
      title: dictionary.name,
      dataIndex: "name",
      className: "title-category",
    },
    {
      title: dictionary.email,
      dataIndex: "desc",
      className: "title-category",
    },
    {
      title: dictionary.createdAt,
      dataIndex: "createdAt",
      className: "title-category",
    },
    {
      title: dictionary.updated,
      dataIndex: "updated",
      className: "title-category",
    },
    {
      title: dictionary.details,
      dataIndex: "detail",
      className: "id-category",
    },
  ];

  const removeParticipant = async () => {
    await setLoadingModal(true);
    const listCopy = [...listLabels];
    const deleteElement = listCopy[deleteData.index];
    const labelDeleteResp = await User.deleteLabel(deleteElement._id).then(
      (resp) => {
        if (resp.status === 200) {
          return resp.body;
        }
        return false;
      }
    );
    setTimeout(() => {
      hideModalDelete();
      setLabelDeletedResp(null);
      setLoadingModal(false);
    }, 3000);

    if (!labelDeleteResp) {
      setLabelDeletedResp("errorDeletedLabel");
      return false;
    } else if (labelDeleteResp.dictionaryMessage) {
      // could return successDeletedLabel, errorDeletedLabelContainsArtist
      setLabelDeletedResp(labelDeleteResp.dictionaryMessage);
      if (
        labelDeleteResp.dictionaryMessage === "errorDeletedLabelContainsArtist"
      )
        return false;
    }
    if (!deleteElement.artist_id && deleteElement.image) {
      const url = deleteElement.image;
      // delete image
      await User.deleteImageLabel(url);
    }
    listCopy.splice(deleteData.index, 1);
    setLabelList(listCopy);
  };

  const showModalDelete = (index, artist) => {
    setDeleteData({
      index,
      name: artist.name,
      show: true,
    });
  };

  const getCountries = async () => {
    const { body } = await Country.countries();
    if (body) {
      setDataCountries([...body.countries]);
    } else {
      setDataCountries([]);
    }
    const phoneCountries = Country.setPhoneCountries();
    setPhoneCountries([...phoneCountries]);
  };

  const hideModalDelete = () => {
    setDeleteData({
      name: "",
      index: null,
      show: false,
    });
  };

  const showModal = (label = null, ha = null, type) => {
    if (label) {
      if (type === "artist") {
        setMessageArtist(dictionary.cantEditLabel);
      }
      setLabelData({
        ...labelData,
        name: label.name,
        _id: label._id,
        hasRelease: label.hasRelease,
        update: true,
      });
    } else {
      setLabelData({
        ...labelData,
        name: "",
        _id: null,
        email: "",
        password: "",
        confirmPassword: "",
        countryPhone: "US",
        code: "1",
        number: "",
        address: "",
        city: "",
        state: "",
        country: 0,
        zip: "",
        update: false,
        parent: 0,
      });
    }
    setErrors({
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      _id: 0,
      address: "",
      city: "",
      state: "",
      zip: "",
      update: false,
      parent: "",
      number: "",
    });
    setIsModalVisible(true);
  };

  const handleSubmitUpdate = async () => {
    // post to update label
    let hasError = false;
    let errorsCopy = {};
    if (!labelData.name) {
      hasError = true;
      errorsCopy.name = "emptyField";
    }
    setErrors(errorsCopy);
    try {
      if (!hasError) {
        setLoading(true);
        let label = {
          label_id: labelData._id || labelData.label_id,
          name: labelData.name,
        };
        const updateLabel = await User.updateLabel(label);
        if (updateLabel) {
          getLabelsFunct();
          getLabels();
          setLabelList([...listLabels]);
          notification.success({
            message: dictionary.labelUpdated,
          });
        }
        handleCancel();
      }
    } catch (e) {
      console.error(e);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const handleSubmit = () => {
    let hasError = validateNewLabel(labelData, setErrors);
    try {
      if (!hasError) {
        setLoading(true);
        const newLabel = {
          name: labelData.name,
          parent_id: labelData.parent,
        };
        let user = {
          email: labelData.email,
          name: labelData.firstName,
          surname: labelData.surname,
          password: labelData.password,
          confirmPassword: labelData.confirmPassword,
          profile: {
            phone: {
              country: labelData.countryPhone,
              code: labelData.code,
              number: labelData.number,
            },
            address: {
              address: labelData.address,
              city: labelData.city,
              state: labelData.state,
              country: labelData.country,
              zip: labelData.zip,
            },
          },
        };
        User.addLabel(newLabel, user).then((createdLabel) => {
          if (createdLabel.status !== 200) {
            if (createdLabel.body?.errors?.zip) {
              setErrors({
                ...errors,
                zip: "zipErr",
              });
            } else if (
              createdLabel?.body?.errors &&
              createdLabel?.body?.errors[0].message === "alreadyEmail"
            ) {
              setErrors({
                ...errors,
                email: "alreadyEmail",
              });
            }
            return;
          } else if (createdLabel.status === 200) {
            handleCancel();
            newLabel.label_id = createdLabel._id;
            setLabelList([...listLabels, newLabel]);
            getLabelsFunct();
            getLabels();
            notification.success({
              message: dictionary.labelCreated,
            });
          }
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const changeHandler = (e, name) => {
    setErrors({
      ...errors,
      [name]: "",
    });
    const value = e.target.value;
    setLabelData({
      ...labelData,
      [name]: value,
    });
  };

  const selectChangeHandler = (e, name) => {
    setErrors({
      ...errors,
      [name]: "",
    });
    if (name === "code") {
      const getCode = phoneCountries.find((el) => el._id === e);
      setLabelData({
        ...labelData,
        countryPhone: getCode._id,
        code: String(getCode.phone_code),
      });
    } else {
      setLabelData({
        ...labelData,
        [name]: e,
      });
    }
  };

  return (
    <React.Fragment>
      {loading ? <Loader /> : null}
      {type === "admin" ? (
        <Tables
          error={false}
          typeTable={3}
          released={true}
          subjects={labels}
          columns={columns}
          loadList={loadList}
          showModal={showModal}
          list={listLabels || []}
          remove={showModalDelete}
          title={dictionary.labels}
          setNewList={setLabelList}
          canAdd={permission?.label?.add || false}
          canUpdate={permission?.label?.update || false}
        />
      ) : (
        <List
          type={type}
          plus={true}
          error={false}
          released={true}
          redirect={false}
          showModal={showModal}
          list={listLabels || []}
          remove={showModalDelete}
          title={dictionary.labels}
          emptyText={dictionary.noLabels}
          canAdd={permission?.label?.add || false}
          canUpdate={permission?.label?.update || false}
          canDelete={permission?.label?.delete || false}
        />
      )}
      {permission?.label?.add || permission?.label?.update ? (
        <AddLabel
          handleCancel={handleCancel}
          messageArtist={messageArtist}
          isModalVisible={isModalVisible}
          handleSubmitUpdate={handleSubmitUpdate}
          handleSubmit={handleSubmit}
          label={labelData}
          changeHandler={changeHandler}
          errors={errors}
          selectChangeHandler={selectChangeHandler}
          phoneCountries={phoneCountries}
          dataCountries={dataCountries}
          listLabels={listLabels}
        />
      ) : null}
      {permission?.label?.delete ? (
        <Modal
          idModal={"deleteArtist"}
          isModalVisible={deleteData.show}
          handleCancel={hideModalDelete}
          title={<div className="modal-title">{dictionary.deleteLabel}</div>}
          body={
            <div>
              {labelDeletedResp ? (
                <span style={{ color: "red" }}>
                  {dictionary[labelDeletedResp]}
                </span>
              ) : (
                dictionary.deleteLabelName.replace("--label--", deleteData.name)
              )}
            </div>
          }
          footer={
            <div className="modalAdd-btns">
              <Button
                key="cancel"
                size="sm"
                className="back-btn"
                text={dictionary.cancel}
                onClick={hideModalDelete}
              />
              <Button
                key="submit"
                size="md"
                text={dictionary.delete}
                className="footer-btn"
                disabled={loadingModal}
                onClick={() => removeParticipant()}
              />
            </div>
          }
        />
      ) : null}
    </React.Fragment>
  );
};

export default LabelList;
