import React, { useContext } from "react";
import Input from "../../../../Form/Input/GeneralInput";
import { useFormContext, Controller } from "react-hook-form";
import { Button, Select } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { AppContext } from "../../../../../context/App/AppProvider";

const CreateDeductionsTerms = ({ index, remove }) => {
  const formMethods = useFormContext();
  const {
    catTypeContractOptions,
    catGroupContractOptions,
    chanelContractOptions,
    priceCatContractOptions,
    sourceContractOptions,
    escalationEquivalentConfigurationOptions,
    dealTypeSalesContractOptions,
    deductionTypeContractOptions,
    countriesOptions,
  } = useContext(AppContext);
  const { control } = formMethods;

  return (
    <div className="input-row-group">
      <div className="input-row">
        <div className={"relative-input"}>
          <Controller
            control={control}
            name={`deductionTerms.${index}.catType`}
            rules={{ required: "This field is required" }}
            render={({ field, fieldState }) => (
              <React.Fragment>
                <label className="allWidth">Cat Type</label>
                <Select
                  className={
                    "fullWidth selector-general" +
                    (fieldState.error ? " input-general-error" : "")
                  }
                  options={catTypeContractOptions}
                  {...field}
                />
                {fieldState.error && (
                  <div className="text-error">{fieldState.error.message}</div>
                )}
              </React.Fragment>
            )}
          />
        </div>
        <div className={"relative-input"}>
          <Controller
            control={control}
            name={`deductionTerms.${index}.catalogueGroup`}
            rules={{ required: "This field is required" }}
            render={({ field, fieldState }) => (
              <React.Fragment>
                <label className="allWidth">Cat Group</label>
                <Select
                  className={
                    "fullWidth selector-general" +
                    (fieldState.error ? " input-general-error" : "")
                  }
                  options={catGroupContractOptions}
                  {...field}
                />
                {fieldState.error && (
                  <div className="text-error">{fieldState.error.message}</div>
                )}
              </React.Fragment>
            )}
          />
        </div>
        <div className={"relative-input"}>
          <Controller
            control={control}
            name={`deductionTerms.${index}.territory`}
            rules={{ required: "This field is required" }}
            render={({ field, fieldState }) => (
              <React.Fragment>
                <label className="allWidth">Territory</label>
                <Select
                  className={
                    "fullWidth selector-general" +
                    (fieldState.error ? " input-general-error" : "")
                  }
                  options={countriesOptions}
                  {...field}
                />
                {fieldState.error && (
                  <div className="text-error">{fieldState.error.message}</div>
                )}
              </React.Fragment>
            )}
          />
        </div>
        <div className={"relative-input"}>
          <Controller
            control={control}
            name={`deductionTerms.${index}.channel`}
            rules={{ required: "This field is required" }}
            render={({ field, fieldState }) => (
              <React.Fragment>
                <label className="allWidth">Channel</label>
                <Select
                  className={
                    "fullWidth selector-general" +
                    (fieldState.error ? " input-general-error" : "")
                  }
                  options={chanelContractOptions}
                  {...field}
                />
                {fieldState.error && (
                  <div className="text-error">{fieldState.error.message}</div>
                )}
              </React.Fragment>
            )}
          />
        </div>
        <div className={"relative-input"}>
          <Controller
            control={control}
            name={`deductionTerms.${index}.configuration`}
            rules={{ required: "This field is required" }}
            render={({ field, fieldState }) => (
              <React.Fragment>
                <label className="allWidth">Config</label>
                <Select
                  className={
                    "fullWidth selector-general" +
                    (fieldState.error ? " input-general-error" : "")
                  }
                  options={escalationEquivalentConfigurationOptions}
                  {...field}
                />
                {fieldState.error && (
                  <div className="text-error">{fieldState.error.message}</div>
                )}
              </React.Fragment>
            )}
          />
        </div>
        <div className={"relative-input"}>
          <Controller
            control={control}
            name={`deductionTerms.${index}.priceCategory`}
            rules={{ required: "This field is required" }}
            render={({ field, fieldState }) => (
              <React.Fragment>
                <label className="allWidth">Price Cat</label>
                <Select
                  className={
                    "fullWidth selector-general" +
                    (fieldState.error ? " input-general-error" : "")
                  }
                  options={priceCatContractOptions}
                  {...field}
                />
                {fieldState.error && (
                  <div className="text-error">{fieldState.error.message}</div>
                )}
              </React.Fragment>
            )}
          />
        </div>
        <div className={"relative-input"}>
          <Controller
            control={control}
            name={`deductionTerms.${index}.source`}
            rules={{ required: "This field is required" }}
            render={({ field, fieldState }) => (
              <React.Fragment>
                <label className="allWidth">Source</label>
                <Select
                  className={
                    "fullWidth selector-general" +
                    (fieldState.error ? " input-general-error" : "")
                  }
                  options={sourceContractOptions}
                  {...field}
                />
                {fieldState.error && (
                  <div className="text-error">{fieldState.error.message}</div>
                )}
              </React.Fragment>
            )}
          />
        </div>
      </div>
      <div className="input-row">
        <div className={"relative-input"}>
          <Controller
            control={control}
            name={`deductionTerms.${index}.deductionBasis`}
            rules={{ required: "This field is required" }}
            render={({ field, fieldState }) => (
              <React.Fragment>
                <label className="allWidth">Basis</label>
                <Select
                  className={
                    "fullWidth selector-general" +
                    (fieldState.error ? " input-general-error" : "")
                  }
                  options={dealTypeSalesContractOptions}
                  {...field}
                />
                {fieldState.error && (
                  <div className="text-error">{fieldState.error.message}</div>
                )}
              </React.Fragment>
            )}
          />
        </div>
        <div className={"relative-input"}>
          <Controller
            control={control}
            name={`deductionTerms.${index}.type`}
            rules={{ required: "This field is required" }}
            render={({ field, fieldState }) => (
              <React.Fragment>
                <label className="allWidth">Deduction Type</label>
                <Select
                  className={
                    "fullWidth selector-general" +
                    (fieldState.error ? " input-general-error" : "")
                  }
                  options={deductionTypeContractOptions}
                  {...field}
                />
                {fieldState.error && (
                  <div className="text-error">{fieldState.error.message}</div>
                )}
              </React.Fragment>
            )}
          />
        </div>
        <Controller
          control={control}
          name={`deductionTerms.${index}.unitRate`}
          rules={{
            required: "This field is required",
            min: "Field must be grather or equal than 0",
          }}
          render={({ field, fieldState }) => (
            <Input
              className={"form-box-input"}
              label={"Unit Rate"}
              placeholder={"Unit Rate"}
              type="number"
              min={0}
              {...field}
              error={fieldState.error?.message}
            />
          )}
        />
        <Controller
          control={control}
          name={`deductionTerms.${index}.rate`}
          rules={{
            required: "This field is required",
            min: "Field must be grather or equal than 0",
          }}
          render={({ field, fieldState }) => (
            <Input
              className={"form-box-input"}
              label={"Rate %"}
              placeholder={"Rate %"}
              type="number"
              min={0}
              {...field}
              error={fieldState.error?.message}
            />
          )}
        />
        <Button
          className="remove"
          type="danger"
          onClick={() => remove()}
          icon={<DeleteOutlined />}
        />
      </div>
    </div>
  );
};

export default CreateDeductionsTerms;
