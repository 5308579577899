import React from "react";

const PayeeIcon = ({ className = null, onClick = null, color = null}) => {
  return (
    <svg
      width="512.000000pt"
      height="512.000000pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={color || "#b4b4b4"}
        stroke="none"
      >
        <path 
          d="M3557 5110 c-374 -59 -692 -285 -862 -611 -188 -362 -179 -804 23
          -1144 179 -301 468 -502 807 -562 125 -21 348 -13 465 17 776 203 1126 1110
          688 1782 -168 258 -430 438 -733 504 -84 19 -307 27 -388 14z m301 -471 l3
          -87 36 -7 c40 -7 147 -54 177 -77 18 -14 17 -19 -29 -141 -26 -70 -50 -127
          -54 -127 -3 0 -36 16 -73 34 -128 65 -243 69 -270 10 -22 -47 0 -67 155 -143
          168 -82 231 -134 274 -226 25 -53 28 -71 28 -160 0 -95 -2 -103 -34 -163 -40
          -73 -112 -142 -176 -168 l-45 -19 0 -97 0 -98 -135 0 -135 0 0 88 0 88 -67 17
          c-72 18 -180 73 -196 99 -7 11 2 50 33 137 24 67 46 123 49 126 4 2 31 -12 61
          -31 162 -102 340 -95 340 12 0 50 -28 73 -181 147 -191 92 -257 162 -278 293
          -24 156 51 299 194 370 l55 26 0 94 0 95 133 -3 132 -3 3 -86z"
        />
        <path
          d="M1601 2239 c-257 -43 -488 -236 -627 -526 l-36 -75 202 -512 c111
          -281 211 -530 221 -554 l18 -43 673 3 c738 4 716 3 853 67 101 47 1988 1131
          2019 1159 35 33 66 97 66 137 0 46 -35 112 -71 134 -18 11 -68 30 -113 43 -70
          20 -103 23 -241 22 -143 0 -169 -3 -246 -27 -47 -14 -123 -44 -170 -65 -46
          -22 -250 -115 -454 -207 -203 -91 -426 -194 -495 -228 l-125 -62 -363 -3 -363
          -3 -34 34 c-62 62 -55 147 18 201 27 20 44 21 360 26 244 4 338 8 354 18 31
          17 60 78 69 139 9 71 -16 152 -59 190 -34 29 -35 30 -193 36 -87 4 -283 7
          -435 7 -304 0 -258 -6 -559 76 -89 24 -175 28 -269 13z"
        />
        <path
          d="M365 1630 c-149 -82 -229 -132 -227 -141 3 -13 582 -1481 586 -1486
          3 -3 459 246 469 256 9 9 -576 1494 -590 1497 -4 1 -111 -55 -238 -126z"
        />
      </g>
    </svg>
  );
};

export default PayeeIcon;