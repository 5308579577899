import React from "react";

const ContractIcon = ({ className = null, onClick = null, color = null}) => {
  return (
    <svg
      width="512.000000pt"
      height="512.000000pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={color || "#b4b4b4"}
        stroke="none"
      >
        <path
          d="M1210 4515 l0 -605 -605 0 -605 0 0 -1955 0 -1955 1960 0 1960 0 0
          1236 0 1236 -57 -52 c-456 -407 -671 -570 -908 -686 -171 -83 -312 -112 -440
          -90 l-40 7 -140 -141 -140 -140 -107 107 -108 108 136 136 136 136 -11 39
          c-69 249 174 677 746 1311 140 156 712 726 836 833 l97 84 0 498 0 498 -1355
          0 -1355 0 0 -605z m910 -1055 l0 -150 -755 0 -755 0 0 150 0 150 755 0 755 0
          0 -150z m-610 -600 l0 -150 -450 0 -450 0 0 150 0 150 450 0 450 0 0 -150z m0
          -600 l0 -150 -450 0 -450 0 0 150 0 150 450 0 450 0 0 -150z m-314 -783 c95
          -48 153 -148 188 -327 l21 -109 41 52 c24 31 62 64 95 82 46 26 64 30 129 30
          102 0 170 -33 265 -130 71 -71 171 -225 162 -248 -4 -11 -258 -131 -262 -124
          -1 1 -16 27 -35 57 -36 61 -98 140 -109 140 -4 0 -22 -32 -41 -71 -71 -151
          -155 -219 -271 -219 -43 0 -70 7 -110 28 -91 47 -130 133 -164 363 -19 131
          -42 212 -58 206 -38 -12 -97 -179 -124 -347 l-17 -111 -73 5 c-40 3 -107 9
          -149 12 l-75 6 6 56 c36 306 131 533 259 621 98 68 223 79 322 28z"
        />
        <path
          d="M495 4620 l-410 -410 413 0 412 0 0 410 c0 226 -1 410 -3 410 -1 0
          -187 -184 -412 -410z"
        />
        <path
          d="M4970 4496 c-238 -88 -721 -454 -1148 -869 l-93 -91 209 -208 208
          -209 91 93 c464 477 858 1016 880 1204 5 44 2 55 -16 73 -24 24 -76 27 -131 7z"
        />
        <path
          d="M3381 3185 c-558 -607 -841 -999 -843 -1172 -1 -60 22 -83 83 -83 27
          0 73 9 103 20 197 74 686 454 1125 873 l83 80 -208 208 c-115 115 -211 209
          -214 209 -3 -1 -61 -61 -129 -135z"
        />
      </g>
    </svg>
  );
};

export default ContractIcon;