export default () => {
  // eslint-disable-next-line no-undef
  self.onmessage = function (message) {
    const file = message.data;
    const objectURL = URL.createObjectURL(file);

    // eslint-disable-next-line no-undef
    self.postMessage({ objectURL: objectURL });
  };
};
