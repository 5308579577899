import React, { useContext, useState } from "react";
import { Input, notification } from "antd";
import Modal from "../../Modal";
import Button from "../../Buttons/GeneralButton";
import AdminCalls from "../../../classes/admin/admin";
import { LanguageContext } from "../../../context/Language";

const AudioSaladApiTokenModal = ({ onConfirm, onCancel, isModalVisible }) => {

  const { dictionary } = useContext(LanguageContext);
  const [token, setToken] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const sendHandler = () => {
    if (token.length <= 15) return;

    setIsLoading(true);

    AdminCalls.refreshAudioSaladApi(token)
      .then((rejectStatus) => {
        if (rejectStatus.status === 200) {
          notification.success({
            key: "audioSaladApiTokenSuccess",
            placement: "bottomRight",
            message: dictionary.processSuccess,
            duration: 5,
          });
        }
        onConfirm();
      })
      .catch(() => {
        notification.warning({
          key: "audioSaladApiTokenSuccess",
          placement: "bottomRight",
          message: dictionary.stripeError,
          duration: 5,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal
      idModal="refresh-audiosalad-token-modal"
      handleCancel={() => (!isLoading ? onCancel() : null)}
      isModalVisible={isModalVisible}
      title={
        <div className="modal-title">{dictionary.sendAudioSaladToken}</div>
      }
      body={
        <div id="refresh-audiosalad-token-modal-content">
          <label>{dictionary.addNewRefreshToken}</label>
          <Input
            value={token}
            onChange={(e) => {
              setToken(e.target.value);
            }}
          />
        </div>
      }
      footer={
        <div
          id="refresh-audiosalad-token-modal-actions"
          className="modalAdd-btns"
        >
          <Button
            key="cancel"
            className="back-btn"
            text={dictionary.cancel}
            onClick={onCancel}
          />
          <Button
            size="ms"
            className="reject"
            onClick={() => sendHandler()}
            text={dictionary.send}
            disabled={isLoading || token.length <= 15}
          />
        </div>
      }
    />
  );
};

export default AudioSaladApiTokenModal;
