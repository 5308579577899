import React, { useContext, useState, useEffect, useRef } from "react";
import Modal from "../../../../Modal";
//import { readFileInChunks } from "../../../../../hooks/files/files";
import { ArtistContext } from "../../../../../context/Artist";
import { LanguageContext } from "../../../../../context/Language";
import { UserContext } from "../../../../../context/User";
import TrackInfoFirst from "./TrackInfoFirst";
import TrackInfoSecond from "./TrackInfoSecond";
import TrackInfoThird from "./TrackInfoThird";
import Button from "../../../../Buttons/GeneralButton";
import TrackClass from "../../../../../classes/track/track";

const TrackInfo = ({
  trackDataToEdit,
  index,
  state,
  addTrack,
  isModalVisible,
  handleCancel,
  newNumber,
}) => {
  const { roles } = useContext(ArtistContext);
  const { dictionary } = useContext(LanguageContext);
  const { subGenreObjects } = useContext(UserContext);
  const [deleteShow, showDeleteModal] = useState(false);
  const [infoTrack, setInfoTrack] = useState(null);
  const [audioFile, setAudioFile] = useState(null);

  const [errors, setError] = useState({
    trackNameErr: false,
    trackVerErr: false,
    audioLangErr: false,
    genreTrackErr: false,
    isrcErr: false,
    typeTrackErr: false,
    artistTrackErr: false,
    roleArtistErr: false,
    writerTrackErr: false,
    roleWriterErr: false,
    prodTrackErr: false,
    roleProdErr: false,
    audioFileErr: false,
    infoCErr: false,
    yearCErr: false,
    infoPErr: false,
    yearPErr: false,
    holderErr: false,
    advocacyErr: false,
    fileErr: false,
    artist: false,
    producer: false,
    writer: false,
  });

  let nameRef = useRef(null);
  let audioRef = useRef(null);
  let isrcRef = useRef(null);
  let infoCRef = useRef(null);
  let infoPRef = useRef(null);
  let holderRef = useRef(null);
  let advocacyRef = useRef(null);

  const isrcRegex = /^[A-Z]{2}-?\w{3}-?\d{2}-?\d{5}$/g;

  const [step, setStep] = useState(0);

  useEffect(() => {
    if (trackDataToEdit) {
      const newInfoTrack = { ...trackDataToEdit };

      if (newInfoTrack.genres) {
        newInfoTrack.subgenres = newInfoTrack.genres.map(
          (elg) => subGenreObjects[elg.subgenre_id || elg.genre_id]
        );
      }

      setInfoTrack(newInfoTrack);
    } else {
      setInfoTrack({
        ...TrackClass.track_object(),
        audio_lang: state.lang,
        number: newNumber,
        release_id: state._id,
        copyright: {
          data: state.infoC,
          year: state.yearC,
        },
        phonographic: {
          data: state.infoP,
          year: state.yearP,
        },
        rights_holder: state.holder,
        artists: state.artists.artist,
        participants: state.artists.participant,
        writers: state.artists.writer,
        advocacy: "None",
        type: "original",
        permission: [
          { type: "track_sale", enabled: true, country: ["WW"] },
          { type: "stream", enabled: true, country: ["WW"] },
          { type: "download", enabled: true, country: ["WW"] },
        ],
      });
    }
  }, [trackDataToEdit, isModalVisible]);

  const closeModal = () => {
    setInfoTrack({});
    setAudioFile(null);
    setStep(0);
    handleCancel();
  };

  const permissionCheckboxChangeHandler = (e) => {
    const permissionStruct = {
      type: "",
      enabled: "",
      country: ["WW"],
    };
    const isChecked = e.target.checked;
    const name = e.target.name;
    const permission = [];
    const actualPermission = infoTrack.permission || [];

    let existKey = false;
    for (const ap of actualPermission) {
      if (ap.type === name) {
        existKey = true;
        ap.enabled = isChecked;
      }
      permission.push(ap);
    }
    if (!existKey) {
      permissionStruct.type = name;
      permissionStruct.enabled = isChecked;
      permission.push(permissionStruct);
    }

    setInfoTrack({
      ...infoTrack,
      permission,
    });
  };

  const changeHandler = (e, name) => {
    const value = name === "isrc" ? e.target.value.trim() : e.target.value;

    setInfoTrack({
      ...infoTrack,
      [name]: value,
    });
  };

  const handleSelect = (value, name) => {
    setInfoTrack({
      ...infoTrack,
      [name]: value,
    });
  };

  const changeHandlerCopyright = (e, name) => {
    const copyright = { ...infoTrack.copyright, [name]: e.target.value };
    setInfoTrack({ ...infoTrack, copyright });
  };

  const changeHandlerCopyrightYear = (_, dateString) => {
    changeHandlerCopyright({ target: { value: dateString } }, "year");
  };

  const changeHandlerPhonographic = (e, name) => {
    const phonographic = { ...infoTrack.phonographic, [name]: e.target.value };
    setInfoTrack({ ...infoTrack, phonographic });
  };

  const changeHandlerPhonographicYear = (_, dateString) => {
    changeHandlerPhonographic({ target: { value: dateString } }, "year");
  };

  const saveArtists = (artists) => {
    setInfoTrack({
      ...infoTrack,
      artists: artists.artist,
      writers: artists.writer,
      producers: artists.producer,
      participants: artists.participant,
    });
  };

  const handleChangeAudioUpload = ({ file }) => {
    if (file.status === "uploading") setAudioFile(file);
  };

  const openDelete = () => {
    showDeleteModal(true);
  };

  const cancelDelete = () => {
    showDeleteModal(false);
  };

  const acceptDelete = () => {
    setAudioFile(null);
    showDeleteModal(false);
    setInfoTrack({ ...infoTrack, dataFromAudioURI: null });
  };

  const orderParticipants = () => {
    let allinfoTrackArtists = {
      artist: infoTrack.artists,
      writer: infoTrack.writers,
      producer: infoTrack.producers || [],
      participant: infoTrack.participants || [],
    };
    let keys = Object.keys(allinfoTrackArtists);
    let all = [];
    let onlyArtist = [];
    let onlyProd = [];
    let onlyWrit = [];
    let onlyPart = [];

    keys.forEach((el) => {
      allinfoTrackArtists[el].forEach((element) => {
        all.push(element);
      });
    });

    all.forEach(async (el) => {
      for (let artistRole in roles.artist) {
        if (artistRole === el.role) {
          onlyArtist.push(el);
        }
      }
      for (let prodRole in roles.producer) {
        if (prodRole === el.role) {
          onlyProd.push(el);
        }
      }
      for (let writRole in roles.writer) {
        if (writRole === el.role) {
          onlyWrit.push(el);
        }
      }
      if (el.role === "participant" || !el.role) {
        delete el.role;
        onlyPart.push(el);
      }
    });

    infoTrack.artists = onlyArtist;
    infoTrack.producers = onlyProd;
    infoTrack.writers = onlyWrit;
    infoTrack.participants = onlyPart;
  };

  const nextStep = async (e) => {
    let errors = {
      trackNameErr: false,
      trackVerErr: false,
      audioLangErr: false,
      genreTrackErr: false,
      isrcErr: false,
      typeTrackErr: false,
      artistTrackErr: false,
      roleArtistErr: false,
      writerTrackErr: false,
      roleWriterErr: false,
      prodTrackErr: false,
      roleProdErr: false,
      audioFileErr: false,
      infoCErr: false,
      yearCErr: false,
      infoPErr: false,
      yearPErr: false,
      holderErr: false,
      advocacyErr: false,
      fileErr: false,
      artist: false,
      producer: false,
      writer: false,
    };
    setError({
      ...errors,
      errors,
    });

    let hasError = false;

    switch (step) {
      case 0:
        if (!infoTrack.title || infoTrack.title?.length === 0) {
          errors.trackNameErr = dictionary.emptyField;
          hasError = true;
          nameRef.current.focus();
        }
        if (!infoTrack.audio_lang) {
          errors.audioLangErr = dictionary.selectErr;
          hasError = true;
          audioRef.current.focus();
        }
        if (infoTrack.subgenres && infoTrack.subgenres.length === 0) {
          errors.genreTrackErr = dictionary.selectErr;
          hasError = true;
        }
        if (!state.isNew && !infoTrack.isrc.match(isrcRegex)) {
          errors.isrcErr = dictionary.isrcError;
          hasError = true;
          isrcRef.current.focus();
        }
        if (!audioFile && !infoTrack.asset[0]) {
          errors.fileErr = dictionary.audioErr;
          hasError = true;
        }
        break;
      case 1:
        orderParticipants();
        // eslint-disable-next-line no-case-declarations
        const hasMain = infoTrack.artists.find(
          (el) => el.role === "main" || el.role === "primary"
        );
        if (!hasMain) {
          errors.artist = dictionary.atLeastOneMainError;
          hasError = true;
        }
        // eslint-disable-next-line no-case-declarations
        const hasWriter = infoTrack.writers.find(
          (el) => el.role === "composer" || el.role === "song_writer"
        );
        if (!hasWriter) {
          errors.writer = dictionary.atLeastOneWriterError;
          hasError = true;
        }
        break;
      case 2:
        if (infoTrack.copyright.data.length === 0) {
          errors.infoCErr = dictionary.emptyField;
          hasError = true;
          infoCRef.current.focus();
        }
        if (!infoTrack.copyright.year) {
          errors.yearCErr = dictionary.emptyField;
          hasError = true;
        }
        if (infoTrack.phonographic.data.length === 0) {
          errors.infoPErr = dictionary.emptyField;
          hasError = true;
          infoPRef.current.focus();
        }
        if (!infoTrack.phonographic.year) {
          errors.yearPErr = dictionary.emptyField;
          hasError = true;
        }
        if (infoTrack.rights_holder.length === 0) {
          errors.yearPErr = dictionary.emptyField;
          hasError = true;
          holderRef.current.focus();
        }
        if (infoTrack.advocacy.length === 0) {
          errors.advocacyErr = dictionary.emptyField;
          hasError = true;
          advocacyRef.current.focus();
        }
        break;
      default:
        break;
    }
    if (hasError) {
      setError({
        ...errors,
        errors,
      });
    } else {
      if (step < 2) setStep(step + 1);
      else {
        e.currentTarget.disabled = true;
        addTrack(infoTrack, audioFile || false);
        setStep(0);
        acceptDelete();
        handleCancel();
      }
    }
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const deleteSongModal = () => {
    return (
      <Modal
        destroyOnClose
        maskClosable={false}
        idModal="deleteShow"
        className="deleteModal"
        handleCancel={cancelDelete}
        isModalVisible={deleteShow}
        title={null}
        body={<div className="modal-title">{dictionary.deleteTrack}?</div>}
        footer={
          <div className="modalAdd-btns">
            <Button
              key="cancel"
              size="sm"
              className="back-btn"
              text={dictionary.cancel}
              onClick={cancelDelete}
            />
            <Button
              size="md"
              key="delete"
              className="footer-btn"
              onClick={acceptDelete}
              text={dictionary.delete}
            />
          </div>
        }
      />
    );
  };

  const audio = infoTrack?.dataFromAudioURI || audioFile;

  return (
    <Modal
      idModal="trackInfo"
      destroyOnClose
      maskClosable={false}
      keyboard={false}
      handleCancel={closeModal}
      isModalVisible={isModalVisible}
      title={<h2 className="track-title">{dictionary.trackEdit}</h2>}
      body={
        <div className="forms trackAdd">
          {step === 0 ? (
            <TrackInfoFirst
              index={index}
              state={state}
              infoTrack={infoTrack}
              permissionCheckboxChangeHandler={permissionCheckboxChangeHandler}
              nameRef={nameRef}
              audioRef={audioRef}
              isrcRef={isrcRef}
              errorsTrack={errors}
              changeHandler={changeHandler}
              handleSelect={handleSelect}
              handleChangeAudioUpload={handleChangeAudioUpload}
              deleteSongModal={deleteSongModal}
              openDelete={openDelete}
              audio={audio}
              audioFile={audioFile}
              audioId="track-preview"
            />
          ) : null}
          {step === 1 ? (
            <TrackInfoSecond
              index={index}
              state={state}
              infoTrack={infoTrack}
              saveArtists={saveArtists}
              errorsTrack={errors}
            />
          ) : null}
          {step === 2 ? (
            <TrackInfoThird
              index={index}
              state={state}
              infoCRef={infoCRef}
              infoPRef={infoPRef}
              holderRef={holderRef}
              infoTrack={infoTrack}
              advocacyRef={advocacyRef}
              errorsTrack={errors}
              changeHandler={changeHandler}
              changeHandlerCopyright={changeHandlerCopyright}
              changeHandlerPhonographic={changeHandlerPhonographic}
              changeHandlerCopyrightYear={changeHandlerCopyrightYear}
              changeHandlerPhonographicYear={changeHandlerPhonographicYear}
            />
          ) : null}
        </div>
      }
      footer={
        <div className="modalAdd-btns order-btns">
          <Button
            key="cancel"
            size="sm"
            className="back-btn"
            text={dictionary.cancel}
            onClick={closeModal}
          />
          {step > 0 ? (
            <Button
              size="md"
              key="back"
              style={{ display: "none" }}
              className="backbtn footer-btn"
              onClick={step > 0 ? prevStep : null}
              text={dictionary.back}
            />
          ) : null}
          <Button
            size="md"
            key="next"
            className="footer-btn"
            onClick={nextStep}
            text={step < 2 ? dictionary.next : dictionary.save}
          />
        </div>
      }
    />
  );
};

export default TrackInfo;
