import React from "react";

const GenreIcon = ({ className = null, onClick = null }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M17 22H7C4 22 2 20.5 2 17V12C2 8.5 4 7 7 7H17C20 7 22 8.5 22 12V17C22 20.5 20 22 17 22Z"
        stroke="#F45C2E"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"/>
      <path
        d="M6 4.5H18"
        stroke="#F45C2E"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"/>
      <path
        d="M9 2H15"
        stroke="#F45C2E"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"/>
      <path
        d="M8.88953 19.1091C9.64616 19.1091 10.2595 18.4958 10.2595 17.7391C10.2595 16.9825 9.64616 16.3691 8.88953 16.3691C8.1329 16.3691 7.51953 16.9825 7.51953 17.7391C7.51953 18.4958 8.1329 19.1091 8.88953 19.1091Z"
        stroke="#F45C2E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"/>
      <path
        d="M15.2798 16.83V11.4799C15.2798 10.3399 14.5698 10.18 13.8398 10.38L11.0997 11.13C10.5997 11.27 10.2598 11.6599 10.2598 12.2299V13.18V13.82V17.74"
        stroke="#F45C2E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"/>
      <path
        d="M13.9091 18.2009C14.6657 18.2009 15.2791 17.5876 15.2791 16.8309C15.2791 16.0743 14.6657 15.4609 13.9091 15.4609C13.1524 15.4609 12.5391 16.0743 12.5391 16.8309C12.5391 17.5876 13.1524 18.2009 13.9091 18.2009Z"
        stroke="#F45C2E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"/>
      <path
        d="M10.2598 13.8309L15.2798 12.4609"
        stroke="#F45C2E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"/>
    </svg>
  );
};

export default GenreIcon;
