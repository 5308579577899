import React from "react";

const SettingsIcon = ({ className = null, color = null, onClick = null }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M6.89353 1.19079C6.89353 0.737169 7.21356 0.347946 7.66838 0.248408C9.20868 -0.088691 10.7895 -0.077773 12.2929 0.251196C12.7641 0.354306 13.1019 0.762527 13.1019 1.22885L13.1019 3.10273C13.1019 3.86422 13.9563 4.35213 14.6333 3.97729L16.3754 3.01269C16.7825 2.78729 17.3013 2.86665 17.627 3.20413C18.1477 3.7436 18.6136 4.3462 19.0121 5.008C19.4105 5.66978 19.7217 6.35806 19.9485 7.06017C20.0904 7.49941 19.9027 7.97 19.4956 8.1954L17.7534 9.16002C17.0764 9.53487 17.0898 10.4884 17.7775 10.8691L19.4697 11.8061C19.8908 12.0393 20.0906 12.5239 19.9481 12.9668C19.4934 14.3799 18.7129 15.6983 17.6383 16.8091C17.321 17.137 16.8095 17.2082 16.3999 16.9814L14.6092 15.9899C13.9323 15.6151 13.1019 16.0749 13.1019 16.8245L13.1019 18.8074C13.1019 19.261 12.7819 19.6503 12.3271 19.7498C10.7868 20.0869 9.20595 20.076 7.70256 19.747C7.23134 19.6439 6.89353 19.2357 6.89353 18.7693V16.8954C6.89353 16.1339 6.0392 15.646 5.36219 16.0209L3.62003 16.9855C3.21294 17.2109 2.6941 17.1315 2.36841 16.794C1.8478 16.2546 1.38185 15.652 0.983439 14.9902C0.585019 14.3284 0.273829 13.6401 0.0469818 12.938C-0.0949308 12.4987 0.0928202 12.0281 0.499907 11.8027L2.24204 10.8381C2.91904 10.4633 2.9056 9.50979 2.21795 9.12905L0.525776 8.19211C0.104667 7.95895 -0.0950676 7.47428 0.0474275 7.03137C0.502051 5.61827 1.28261 4.29988 2.35717 3.18916C2.67447 2.86119 3.18597 2.79 3.5956 3.01681L5.38628 4.00828C6.06316 4.38306 6.89353 3.92329 6.89353 3.17374L6.89353 1.19079ZM9.99896 13.0037C11.7292 13.0037 13.1318 11.6585 13.1318 9.99922C13.1318 8.33989 11.7292 6.99475 9.99896 6.99475C8.26871 6.99475 6.86608 8.33989 6.86608 9.99922C6.86608 11.6585 8.26871 13.0037 9.99896 13.0037Z" fill={color || "#292929"}/>
    </svg>
  );
};

export default SettingsIcon;
