import BaseClass from "..";

class TariffPlanCalls extends BaseClass {
  getTariffPlans() {
    this.setValues("/tariff_plan");
    return this.doGet();
  }
}

export default new TariffPlanCalls();
