import React, { useContext, useState, useEffect } from "react";
import {
  Row,
  Col,
  message,
  Divider,
  Input as AntInput,
  Card,
} from "antd";
import { AppContext } from "../../../context/App";
import { UserContext } from "../../../context/User";
import { LanguageContext } from "../../../context/Language";
import UserCalls from "../../../classes/user/user";
import CardPasswordLock from "../../Cards/PasswordLock";
import Input from "../../Form/Input";
import Select from "../../Form/Select";
import Button from "../../Buttons/GeneralButton";
import useStateValidator from "../../../hooks/validators/useStateValidator";
const { TextArea } = AntInput;
import "../set.css";
const dividerStyle = { borderTopColor: "#a5a5a599" };
const rules = {
  name: {
    type: "string",
    min: 3,
    max: 120,
  },
  email: {
    type: "string",
    min: 6,
    isEmail: true,
  },
  address: {
    type: "string",
    min: 3,
    max: 255,
  },
  country: {
    type: "string",
    length: 2,
    select: true,
  },
  alternate_name: {
    type: "string",
    min: 3,
    max: 120,
  },
  categories: {
    type: "array",
    min: 1,
    item_rules: {
      type: "string",
      min: 3,
    },
  },
  notes: {
    type: "string",
    max: 255,
  },
  payment_email: {
    type: "string",
    min: 6,
    isEmail: true,
  },
  payment_currency: {
    type: "string",
    length: 3,
  },
  iban: {
    type: "string",
    min: 6,
  },
  swift: {
    type: "string",
    min: 6,
  },
  bank_account: {
    type: "string",
    min: 4,
  },
  bank_address: {
    type: "string",
    min: 6,
    max: 120,
  },
  bank_name: {
    type: "string",
    min: 3,
  },
  sort_code: {
    type: "string",
    min: 3,
  },
  password: {
    type: "omit",
  },
};

const Payee = () => {

  const { alertPayee, getPayeeInfo }= useContext(UserContext);
  const { dictionary } = useContext(LanguageContext);
  const { allCountries } = useContext(AppContext);
  const { validationErrorMessages, validate } = useStateValidator({
    rules,
  });
  const [loading, setLoading] = useState(true);
  const [unloked, setUnloked] = useState(false);
  const [originalData, setOriginalData] = useState({});
  const [changedData, setChangedData] = useState({});

  useEffect(() => {
    UserCalls.getPayee()
      .then((res) => {
        if (res.status === 200) {
          setOriginalData(res.body);
        } else {
          console.log("error", res);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  const onCardUnblock = async (pass) => {
    UserCalls.getPayeeBankInfo(pass)
      .then((res) => {
        if (res.status === 200) {
          setChangedData({ password: pass });
          setOriginalData({ ...originalData, ...res.body });
        } else {
          console.log("error", res);
        }
      })
      .finally(() => setLoading(false));
  };

  const inputChangeHandler = (e) => {
    setChangedData({
      ...changedData,
      [e.target.name]: e.target.value,
    });
  };

  const selectChangeHandler = (value, tagName) => {
    setChangedData({
      ...changedData,
      [tagName]: value,
    });
  };

  const success = () => {
    message.success({
      content: dictionary.processSuccess,
      style: {
        marginTop: "50px",
      },
    });
  };

  const errorMsg = () => {
    message.error({
      content: dictionary.errorGeneral,
      style: {
        marginTop: "50px",
      },
    });
  };

  const save = () => {
    const erros = validate(changedData);
    if (Object.keys(erros).length) {
      return;
    }
    if(unloked){
      const banckDataRequired = {
        payment_email: changedData.payment_email ?? originalData.payment_email,
        payment_currency: changedData.payment_currency ?? originalData.payment_currency,
        swift: changedData.swift ?? originalData.swift,
        bank_account: changedData.bank_account ?? originalData.bank_account,
        bank_address: changedData.bank_address ?? originalData.bank_address,
        bank_name: changedData.bank_name ?? originalData.bank_name,
      }; 
      const errorsBank = validate(banckDataRequired)
      if (Object.keys(errorsBank).length) {
        return;
      } else {
        changedData.payee_status = true;
      }
    }
    setLoading(true);
    UserCalls.updatePayee(changedData)
      .then((updated) => {
        if (updated.status === 200) {
          setOriginalData({
            ...originalData,
            ...changedData,
            categories: ["Priam Platform"],
          });
          setChangedData({});
          getPayeeInfo();
          success();
        } else errorMsg();
      })
      .catch((err) => {
        console.log(err);
        errorMsg();
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="content-set">
      <h2>{dictionary.payeeStatermation}</h2>
      {alertPayee ? <div className="text-alert">{dictionary.alertPayee}</div> : null}

      <Card loading={loading} bordered={false} title={dictionary.overview}>
        <Row gutter={[16, 0]}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Input
              name="name"
              required={true}
              value={changedData.name ?? originalData.name}
              error={validationErrorMessages?.name?.join(" ") || ""}
              onChange={inputChangeHandler}
              label={dictionary.firstName}
              className={
                validationErrorMessages?.name?.length ? "inputErr" : ""
              }
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Input
              name="email"
              required={true}
              value={changedData.email ?? originalData.email}
              onChange={inputChangeHandler}
              label={dictionary.email}
              error={validationErrorMessages?.email?.join(" ") || ""}
              className={
                validationErrorMessages?.email?.length ? "inputErr" : ""
              }
            />
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Input
              name="address"
              required={true}
              value={changedData.address ?? originalData.address}
              error={validationErrorMessages?.address?.join(" ") || ""}
              onChange={inputChangeHandler}
              label={dictionary.address}
              className={
                validationErrorMessages?.address?.length ? "inputErr" : ""
              }
            />
          </Col>
          <Col xs={24} sm={24} md={12} l={12}>
            <Select
              name="country"
              required={true}
              showSearch={true}
              value={changedData.country ?? originalData.country}
              options={allCountries}
              placeholder={dictionary.select}
              label={dictionary.country}
              error={validationErrorMessages?.country?.join(" ") || ""}
              onChange={(e) => selectChangeHandler(e, "country")}
              className={
                (validationErrorMessages?.country?.length ? "countErr " : "") +
                " selector-general"
              }
            />
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Input
              name="alternate_name"
              value={changedData.alternate_name ?? originalData.alternate_name}
              onChange={inputChangeHandler}
              label={dictionary.alternateName}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <label className={"allWidth"}>{dictionary.categoryName}</label>
            <p className="mt-1">Priam Platform</p>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <label>{dictionary.notes}</label>
            <TextArea
              className={"text-area-general"}
              name="notes"
              showCount
              maxLength={200}
              value={changedData.notes ?? originalData.notes}
              onChange={(e) => selectChangeHandler(e, "notes")}
            />
          </Col>
        </Row>
      </Card>

      <Divider
        orientation="left"
        style={dividerStyle}
        className="divider-payee"
      >
        {dictionary.bankInfo}
      </Divider>

      <CardPasswordLock
        onSuccess={onCardUnblock}
        loading={loading}
        bordered={false}
        unloked={unloked}
        setUnloked={setUnloked}
      >
        <Row gutter={[16, 0]}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Input
              name="payment_email"
              value={changedData.payment_email ?? originalData.payment_email}
              onChange={inputChangeHandler}
              label={dictionary.paymentEmail}
              error={validationErrorMessages?.payment_email?.join(" ") || ""}
              className={
                validationErrorMessages?.payment_email?.length ? "inputErr" : ""
              }
              required={true}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Select
              required={true}
              name="payment_currency"
              value={
                changedData.payment_currency ||
                originalData.payment_currency ||
                "USD"
              }
              options={[
                { _id: "USD", name: "USD" },
                { _id: "EUR", name: "EUR" },
              ]}
              placeholder={dictionary.select}
              label={dictionary.paymentCurrency}
              onChange={(e) => selectChangeHandler(e, "payment_currency")}
              error={validationErrorMessages?.payment_currency?.join(" ") || ""}
              className={
                (validationErrorMessages?.payment_currency?.length
                  ? "countErr "
                  : "") + " selector-general"
              }
            />
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Input
              name="iban"
              value={changedData.iban ?? originalData.iban}
              error={validationErrorMessages?.iban?.join(" ") || ""}
              onChange={inputChangeHandler}
              label="IBAN"
              className={
                validationErrorMessages?.iban?.length ? "inputErr" : ""
              }
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Input
              required={true}
              name="swift"
              value={changedData.swift ?? originalData.swift}
              onChange={inputChangeHandler}
              label="SWIFT / BIC"
              error={validationErrorMessages?.swift?.join(" ") || ""}
              className={
                validationErrorMessages?.swift?.length ? "inputErr" : ""
              }
            />
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Input
              required={true}
              name="bank_account"
              value={changedData.bank_account ?? originalData.bank_account}
              error={validationErrorMessages?.bank_account?.join(" ") || ""}
              onChange={inputChangeHandler}
              label={dictionary.bankAccount}
              className={
                validationErrorMessages?.bank_account?.length ? "inputErr" : ""
              }
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Input
              required={true}
              name="bank_address"
              value={changedData.bank_address ?? originalData.bank_address}
              error={validationErrorMessages?.bank_address?.join(" ") || ""}
              onChange={inputChangeHandler}
              label={dictionary.bankAddress}
              className={
                validationErrorMessages?.bank_address?.length ? "inputErr" : ""
              }
            />
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Input
              required={true}
              name="bank_name"
              value={changedData.bank_name ?? originalData.bank_name}
              error={validationErrorMessages?.bank_name?.join(" ") || ""}
              onChange={inputChangeHandler}
              label={dictionary.bankName}
              className={
                validationErrorMessages?.bank_name?.length ? "inputErr" : ""
              }
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Input
              name="sort_code"
              value={changedData.sort_code ?? originalData.sort_code}
              error={validationErrorMessages?.sort_code?.join(" ") || ""}
              onChange={inputChangeHandler}
              label={dictionary.sortCode}
              className={
                validationErrorMessages?.sort_code?.length ? "inputErr" : ""
              }
            />
          </Col>
        </Row>
      </CardPasswordLock>
      {/*
      <Divider
        orientation="left"
        style={dividerStyle}
        className="divider-payee"
      >
        {dictionary.taxInfo}
      </Divider>
      <Card loading={loader} bordered={false}>
        <Row gutter={[16, 0]}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Input
              name="tax_id"
              required={true}
              value={changedData.tax_id || originalData.tax_id}
              error={validationErrorMessages?.tax_id?.join(" ") || ''}
              onChange={inputChangeHandler}
              label={dictionary.taxId}
              className={validationErrorMessages?.tax_id?.join(" ") || '' ? "inputErr" : ""}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Input
              name="tax_wording"
              value={changedData.tax_wording || originalData.tax_wording}
              error={validationErrorMessages?.tax_wording?.join(" ") || ''}
              onChange={inputChangeHandler}
              label={dictionary.taxWording}
              className={validationErrorMessages?.tax_wording?.join(" ") || '' ? "inputErr" : ""}
            />
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Input
              name="fees_invoice_tax_rate"
              required={true}
              value={changedData.fees_invoice_tax_rate || originalData.fees_invoice_tax_rate}
              error={validationErrorMessages?.fees_invoice_tax_rate?.join(" ") || ''}
              onChange={inputChangeHandler}
              label={dictionary.feesInvoiceTaxRate}
              className={validationErrorMessages?.fees_invoice_tax_rate?.join(" ") || '' ? "inputErr" : ""}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Input
              name="selfBillingTaxRate"
              value={changedData.self_billing_tax_rate || originalData.self_billing_tax_rate}
              onChange={inputChangeHandler}
              error={validationErrorMessages?.self_billing_tax_rate?.join(" ") || ''}
              label={dictionary.selfBillingTaxRate}
              className={validationErrorMessages?.self_billing_tax_rate?.join(" ") || '' ? "inputErr" : ""}
            />
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Input
              name="withholding_tax_rate"
              value={changedData.withholding_tax_rate || originalData.withholding_tax_rate}
              error={validationErrorMessages?.withholding_tax_rate?.join(" ") || ''}
              label={dictionary.withholdingTaxRate}
              onChange={inputChangeHandler}
              className={validationErrorMessages?.withholding_tax_rate?.join(" ") || '' ? "inputErr" : ""}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Input
              name="withholding_tax_wording"
              value={changedData.withholding_tax_wording || originalData.withholding_tax_wording}
              onChange={inputChangeHandler}
              error={validationErrorMessages?.withholding_tax_wording?.join(" ") || ''}
              label={dictionary.withholdingTaxWording}
              className={validationErrorMessages?.withholding_tax_wording?.join(" ") || '' ? "inputErr" : ""}
            />
          </Col>
        </Row>
      </Card>
            */}

      <div className="reg-btn">
        <Button
          disabled={loading || Object.keys(changedData).length === 0}
          size="md"
          onClick={save}
          text={dictionary.save}
        />
      </div>
    </div>
  );
};

export default Payee;
