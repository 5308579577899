import React, { useContext, useState, useRef } from "react";
import { Divider } from "antd";
import { Link } from "react-router-dom";
import { MailOutlined } from "@ant-design/icons";
import { UserContext } from "../../context/User";
import { LanguageContext } from "../../context/Language";
import SecureLS from "secure-ls";
import Input from "../Form/Input";
import Google from "../Buttons/GoogleBtn";
import Facebook from "../Buttons/FacebookBtn";
import Button from "../Buttons/GeneralButton";
import BorderButton from "../Buttons/BorderButton";
import Visitor from "../../classes/visitor/visitor.jsx";
import "../../containers/Home/home.css";

const SignUp = ({ email, setEmail, changeLogin, externalError }) => {

  let redirect = useRef("");
  const { dictionary, locale } = useContext(LanguageContext);
  const { setRegister } = useContext(UserContext);
  const [emailErr, setError] = useState(false);
  var ls = new SecureLS({ encodingType: "base64", isCompression: false });

  const setKeys = (info) => {
    ls.set("state", { email: info });
  };

  const changeHandler = (e) => {
    setEmail(e.target.value);
  };

  const validate = async () => {
    let data = null;
    let error = false;
    setError(false);
    if (
      email.length === 0 ||
      !email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    ) {
      setError(dictionary.mailErr);
      error = true;
    } 
    const verify = await Visitor.verifyEmail(email);
    if (verify && verify.body.message) {
      setError(dictionary[verify.body.message] || null);
      error = true;
    }
    
    if (!error) {
      data = {
        email: email,
      };
    }
    return data;
  };

  const keyEnter = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      save(e);
    }
  };

  const save = async (e) => {
    e.preventDefault();
    const data = await validate();
    if (data) {
      setKeys(email);
      setRegister(true);
      const language = locale.includes("es") ? "es" : "en";
      await Visitor.request_code(email,language);
      redirect?.current?.click();
    }
  };

  return (
    <div className="sign-box">
      <h2>{dictionary.signUp}</h2>
      {externalError ? (
        <p className="head-form-error">{externalError}</p>
      ) : null}
      <Input
        name="email"
        error={emailErr}
        value={email}
        icon={<MailOutlined />}
        onChange={changeHandler}
        onKeyUp={(e) => keyEnter(e)}
        placeholder={dictionary.email}
        className={(emailErr ? "inputErr " : "") + "email"}
      />
      <Link ref={redirect} to="/register">
        <Button text={dictionary.signUpBtn} size="fullWidth" onClick={save} />
      </Link>
      <Divider plain> {dictionary.or}</Divider>
      <div className="social-btns">
        <Facebook className="btn-click fb" onClick={Visitor.loginFacebook} />
        <Google className="btn-click gl" onClick={Visitor.loginGoogle} />
      </div>
      <p className="p-1">{dictionary.alreadyAcc}</p>
      <BorderButton
        text={dictionary.signIn}
        size="fullWidth"
        onClick={() => changeLogin(false)}
      />
    </div>
  );
};

export default SignUp;