import React from "react";

const EditUserIcon = ({ className = null, onClick = null }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <g clipPath="url(#clip0_119_3302)">
        <path d="M0 16.4357C0.0834957 15.9745 0.132202 15.5041 0.256517 15.0541C0.985713 12.4185 2.65238 10.6059 5.15493 9.54121C5.19761 9.52312 5.24121 9.50781 5.28342 9.48972C5.29687 9.48415 5.30847 9.47348 5.34512 9.44889C3.6367 8.21484 2.73773 6.5934 3.02022 4.45283C3.19835 3.10373 3.85333 1.99679 4.91372 1.14316C7.10502 -0.6207 10.25 -0.308939 12.1027 1.84184C13.8612 3.88313 13.9299 7.43497 10.956 9.45167C12.362 9.99401 13.5513 10.8193 14.5176 11.9912C14.5821 11.9304 14.6428 11.8762 14.7003 11.8191C15.2027 11.3176 15.699 10.8105 16.2074 10.315C16.8374 9.70127 17.586 9.45446 18.4331 9.74395C19.2949 10.0385 19.8284 10.6611 19.9745 11.5723C20.0756 12.2023 19.8752 12.76 19.4754 13.248C19.3877 13.3552 19.288 13.4531 19.1901 13.551C17.3857 15.357 15.5789 17.1613 13.7786 18.9715C13.6237 19.1274 13.4572 19.2146 13.2457 19.2573C12.0981 19.4874 10.9519 19.7263 9.80518 19.9629C9.77642 19.969 9.74998 19.9875 9.72214 20.0005H9.62937C9.88867 18.7562 10.1447 17.5111 10.411 16.2682C10.4365 16.1494 10.5028 16.0218 10.5877 15.9355C11.3948 15.1172 12.2089 14.3058 13.0239 13.4948C13.171 13.3482 13.3306 13.2141 13.4785 13.0791C12.1124 11.1863 9.59412 9.92998 6.97003 10.4932C3.49754 11.2392 1.75526 13.5449 1.43102 17.0374H0V16.4357ZM4.44429 5.16636C4.43733 7.22574 6.08127 8.88475 8.13341 8.88939C10.1818 8.89449 11.8392 7.25729 11.8508 5.21692C11.8624 3.15569 10.2236 1.49157 8.1733 1.48276C6.11235 1.47394 4.45125 3.1144 4.44475 5.16589L4.44429 5.16636ZM11.5567 18.0743C11.9492 18.0047 12.3045 17.9485 12.6556 17.8752C12.7535 17.8548 12.8597 17.8029 12.9302 17.7328C14.7054 15.9643 16.4769 14.1921 18.2471 12.4185C18.5926 12.0719 18.6065 11.6465 18.293 11.3338C17.9794 11.0216 17.5564 11.0383 17.2094 11.3849C15.4365 13.1552 13.6645 14.9274 11.894 16.7006C11.8415 16.753 11.7817 16.8179 11.7683 16.8857C11.6936 17.2629 11.6323 17.6428 11.5572 18.0738L11.5567 18.0743Z" fill="#F45C2E"/>
      </g>
      <defs>
        <clipPath id="clip0_119_3302">
          <rect width="20" height="20" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default EditUserIcon;
