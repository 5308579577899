import UpcCalls from "../calls/upc/upc.jsx";

class Upc {
  getAvailable = async () => {
    return await UpcCalls.getAvailableUpcs();
  };

  addUpc = async (upc) => {
    return await UpcCalls.addUpc(upc);
  };

  deleteUpc = async (upc) => {
    return await UpcCalls.deleteUpc(upc);
  };
}

export default new Upc();
