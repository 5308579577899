import React from "react";
import { Input } from "antd";
import "../form.css";

const Search = ({
  name = "",
  refs = null,
  icon = null,
  placeholder,
  label = null,
  value = null,
  mobile = null,
  error = false,
  required = false,
  onSearch = null,
  maxLength = 1300,
  className = null,
  enterButton = false,
}) => {
  const { Search } = Input;

  return (
    <div className={"relative-input " + mobile}>
      {label ? (
        <label>
          {label}
          <span>{required ? "*" : ""}</span>
        </label>
      ) : null}
      <Search
        ref={refs}
        name={name}
        prefix={icon}
        value={value}
        maxLength={maxLength}
        onChange={(e) => onSearch(e)}
        placeholder={placeholder}
        enterButton={enterButton}
        className={"input-general " + className}
      />
      {error ? <div className="text-error">{error}</div> : null}
    </div>
  );
};

export default Search;
