import React, { useMemo, useState } from "react";
import { Table, Spin } from "antd";
import ExcelIcon from "../../../components/Icon/ExcelIcon";
import PdfIcon from "../../../components/Icon/PdfIcon";
import moment from "moment";

const TableRoyalties = ({
  filter,
  columns,
  loading,
  statements = [],
  getSubalelStatements,
  formater,
}) => {
  // Estado para almacenar los sublabels de los registros expandidos
  const [expandedSublabels, setExpandedSublabels] = useState({});

  const getFormatedcreated = (itemDate) =>
    moment(itemDate).format(
      "MMM YYYY"
    );

  const tableData = useMemo(() => {
    return statements.map((item, index) => {
      let formatDate = `${item.year}-${item.accountingPeriod.replace("M", "0")}-01`;
      return {
        key: index,
        id: item.id,
        createdAt: getFormatedcreated(formatDate),
        name: item.name,
        netSales: formater.format(item.netAmount),
        periodId: item.periodId,
        reports: (
          <div className="link-files-royalty">
            <a target="_blank" href={item.pdfPublicLink} rel="noreferrer">
              <PdfIcon />
            </a>
            <a target="_blank" href={item.salesCsvPublicLink} rel="noreferrer">
              <ExcelIcon />
            </a>
          </div>
        ),
        hasSublabels: item?.hasSublabels,
      };
    });
  }, [statements]);

  const expandedRowRender = (record) => {
    return (
      <Table
        rowKey="id"
        columns={columns}
        dataSource={expandedSublabels[record.id] || []}
        pagination={false}
        className="content-subtable"
      />
    );
  };

  return (
    <div className="table-admin inline-contend">
      <Spin spinning={loading} className="spinner">
        <Table
          columns={columns}
          pagination={false}
          dataSource={tableData}
          className="content-table-admin users roylts"
          expandable={{
            expandedRowRender,
            onExpand: async (expanded, record) => {
              if (!expanded) return;

              // Obtener los sublabels solo si el registro se está expandiendo
              // const promises = record.sublabels.map((sub) =>
              //   getSubalelStatements(sub.id, filter)
              // );
              const periodId = record.periodId;
              const promises = record?.hasSublabels
                ? [
                    getSubalelStatements(record.id, {
                      year: filter.year,
                      periodId,
                    }),
                  ]
                : [];

              // Esperar a que todas las promesas se resuelvan
              const values = await Promise.all(promises);
              if (!values[0] || !values[0].length) return;
              // Actualizar el estado con los nuevos sublabels
              setExpandedSublabels({
                ...expandedSublabels,
                [record.id]: values[0].map((sub) => ({
                  key: sub.id,
                  id: sub.id,
                  name: sub.name,
                  createdAt: getFormatedcreated(sub),
                  netSales: formater.format(sub.netAmount),
                  labelId: sub.labelId,
                  reports: (
                    <div className="link-files-royalty">
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href={sub.pdfPublicLink}
                      >
                        <PdfIcon />
                      </a>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href={sub.salesCsvPublicLink}
                      >
                        <ExcelIcon />
                      </a>
                    </div>
                  ),
                })),
              });
            },
            rowExpandable: (record) => record?.hasSublabels,
          }}
        />
      </Spin>
    </div>
  );
};

export default TableRoyalties;
