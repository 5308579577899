import React, { useContext, useEffect, useMemo, useState } from "react";
import { Popconfirm, Table, Tag, Button, Dropdown, Space } from "antd";
import { DownOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { AppContext } from "../../../../context/App";
import { LanguageContext } from "../../../../context/Language";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import CrossFillIcon from "../../../Icon/CrossFillIcon";
import ReleaseCalls from "../../../../classes/release/release";
import ScheduleDeliveryActionModal from "../Modals/ScheduleDeliveryActionModal";
import "./stores.css";

const RequestedStores = ({
  isEditMode,
  releaseDate,
  releaseId,
  releaseAudioSaladIds,
}) => {
  const { dictionary } = useContext(LanguageContext);
  const { dataDsp, validateAudiosaladAPIstatus, faultImage } =
    useContext(AppContext);
  const [modalDsps, setModalDsps] = useState([]);
  const [releaseDsp, setReleaseDsp] = useState([]);
  const [modalAction, setModalAction] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showScheduleDeliveryModal, setShowScheduleDeliveryModal] =
    useState(false);

  const dataDSPById = useMemo(() => {
    if (dataDsp.length === 0) return {};
    const result = {};
    for (const dsp of dataDsp) result[dsp._id] = dsp;
    return result;
  }, [dataDsp]);

  /**
   * This manage two types of events, from the dropdwon and from the button
   * maybe this can be improved
   * @param {*} e
   */
  const setModaldata = (e) => {
    const newModalData = [];
    if (Object.prototype.hasOwnProperty.call(e, "rowData"))
      newModalData.push(dataDSPById[e.rowData.key]);
    else for (const key of selectedRowKeys) newModalData.push(dataDSPById[key]);

    setModalDsps(newModalData);
    setModalAction(e.key);
    setShowScheduleDeliveryModal(true);
  };

  const releaseDspObject = useMemo(() => {
    if (releaseDsp.length === 0) return {};

    const result = {};
    const statuses = [...releaseDsp];
    for (const status of statuses) {
      if (
        Object.prototype.hasOwnProperty.call(result, status.delivery_target_id)
      )
        result[status.delivery_target_id].push(status);
      else result[status.delivery_target_id] = [status];
    }
    return result;
  }, [releaseDsp]);

  useEffect(() => {
    if (releaseId) getAudiosaladStatus();
  }, [releaseId]);

  const getAudiosaladStatus = () => {
    ReleaseCalls.getDSPstatus(releaseId)
      .then((data) => setReleaseDsp(data?.statuses || []))
      .catch(() => {
        validateAudiosaladAPIstatus();
        setReleaseDsp({});
      });
  };

  const actionsObject = {
    add: {
      label: dictionary.add,
      key: "add",
    },
    clear: {
      label: dictionary.clear,
      key: "clear",
    },
    delete: {
      label: dictionary.delete,
      key: "delete",
    },
    full: {
      label: dictionary.fullUpdate,
      key: "full-update",
    },
    meta: {
      label: dictionary.metaUpdate,
      key: "meta-update",
    },
  };

  const actionsObjectMappper = (actions) => {
    return actions.split(",").map((item) => actionsObject[item]);
  };

  const columns = [
    {
      title: dictionary.store,
      dataIndex: "store",
      className: "title-category",
    },
    {
      title: dictionary.status,
      dataIndex: "status",
      className: "title-category",
    },
    {
      title: dictionary.releaseDate,
      dataIndex: "releaseDate",
      className: "title-category",
    },
    {
      title: dictionary.requestedBy,
      dataIndex: "requested",
      className: "title-category",
      sorter: (a, b) => a.requested - b.requested,
    },
    {
      title: dictionary.action,
      dataIndex: "action",
      className: "title-category",
      render: (text, rowData) => {
        const items = actionsObjectMappper(text);
        return (
          <Dropdown
            menu={{
              onClick: (e) => {
                e.rowData = rowData;
                setModaldata(e);
              },
              items,
            }}
            trigger={["click"]}
            disabled={!isEditMode}
          >
            <Button className="action-reqs-btn">
              <Space>
                {dictionary.action}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const getFeatures = () => {
    return (
      <div className="dsp-info">
        <h2>{dictionary.dspFeatures}</h2>
        <ul className="list-dsp-feats">
          {dictionary.dspFeaturesList.map((el, index) => {
            return (
              <li key={index}>
                <CrossFillIcon />
                {el}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const createStatus = (hasStatus, deliveryInfo) => {
    if (!hasStatus) return <Tag color="purple">{dictionary.none}</Tag>;

    const data = Object.keys(deliveryInfo).map((key) => {
      return (
        <p key={deliveryInfo.name + key}>{key + ": " + deliveryInfo[key]}</p>
      );
    });
    let color = "silver";

    if (deliveryInfo.delivery_status === "ok") color = "green";

    if (deliveryInfo.delivery_status === "error") color = "red";

    if (deliveryInfo.delivery_status === "pending") color = "orange";

    return (
      <div>
        <Tag color={color}>{deliveryInfo.delivery_action}</Tag>
        <Popconfirm
          placement="right"
          title={data}
          showCancel={false}
          icon={false}
          okText={dictionary.close}
          className="dsp-info-pop"
          okButtonProps={{ className: "dsp-info-btn" }}
        >
          <QuestionCircleOutlined className="plus-info" />
        </Popconfirm>
      </div>
    );
  };

  const createAction = (hasStatus, deliveryInfo) => {
    if (!hasStatus) return "add";

    if (deliveryInfo.delivery_status === "pending") return "clear";

    if (
      deliveryInfo.delivery_action === "delete" &&
      deliveryInfo.delivery_status === "ok"
    )
      return "add";

    if (
      deliveryInfo.delivery_action !== "delete" &&
      deliveryInfo.delivery_status === "ok"
    )
      return "full,meta,delete";

    return "add,clear,full,meta,delete";
  };

  const createRows = () => {
    return dataDsp.map((el, index) => {
      const statusExists =
        el.audiosalad_id && releaseDspObject[el.audiosalad_id];
      const lastStatusData = statusExists
        ? releaseDspObject[el.audiosalad_id][0]
        : [];

      let logo = "";
      try {
        //eslint-disable-next-line no-undef
        logo = require(`../../Create/Distribution/${el.logo}`);
      } catch (error) {
        logo = faultImage;
        // console.log(error.message);
      }

      let title = (
        <div>
          <img
            src={logo}
            alt={"DSP logo " + el.name}
            title={el.name}
            className="logo-dsp"
          />
          {el.name}
          <Popconfirm
            placement="right"
            title={getFeatures()}
            showCancel={false}
            icon={false}
            okText={dictionary.close}
            className="dsp-info-pop"
            okButtonProps={{ className: "dsp-info-btn" }}
          >
            <ExclamationCircleOutlined className="plus-info" />
          </Popconfirm>
        </div>
      );
      return {
        index: index,
        key: el._id,
        store: title,
        status: createStatus(statusExists, lastStatusData),
        releaseDate: moment(releaseDate).format("YYYY/MM/DD") || "-",
        requested: "Priam Digital",
        action: createAction(statusExists, lastStatusData),
      };
    });
  };

  const onSelectCheckbox = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setModalDsps(newSelectedRowKeys);
  };

  const drawActionMultiple = () => {
    const hasSelected = selectedRowKeys.length > 1;
    if (!hasSelected) return;

    const items = actionsObjectMappper("clear,full,meta");

    return (
      <div className="store-select-opts">
        <span>
          {`${selectedRowKeys.length}`} {dictionary.storesSelected}{" "}
        </span>
        <Button
          onClick={(e) => {
            e.key = "add";
            setModaldata(e);
          }}
        >
          {dictionary.add}
        </Button>
        <Button
          onClick={(e) => {
            e.key = "delete";
            setModaldata(e);
          }}
        >
          {dictionary.delete}
        </Button>
        <Dropdown
          disabled={!hasSelected}
          menu={{ onClick: setModaldata, items }}
          trigger={["click"]}
        >
          <Button>
            <Space>
              {dictionary.moreActions}
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      </div>
    );
  };

  return (
    <div className="requested-box">
      <ScheduleDeliveryActionModal
        releaseAudioSaladIds={releaseAudioSaladIds}
        isModalVisible={showScheduleDeliveryModal}
        dsps={modalDsps}
        action={modalAction}
        onCancel={() => setShowScheduleDeliveryModal(false)}
        onConfirm={() => getAudiosaladStatus()}
      />

      <div className="title">
        <h1>{dictionary.requestedStores}</h1>
        {drawActionMultiple()}
      </div>
      <div className="card-mask">
        <div className="list-requested">
          <Table
            rowSelection={{
              selectedRowKeys,
              onChange: onSelectCheckbox,
              getCheckboxProps: () => ({
                className: "checkbox-reqrow",
                disabled: !isEditMode,
              }),
            }}
            columns={columns}
            dataSource={createRows()}
            pagination={false}
            className="requested-table"
            sticky
            scroll={{ y: 290 }}
          />
        </div>
        {releaseAudioSaladIds.length === 0 && (
          <div className="card-mask-mask">
            <div className="card-mask-center">
              <h2>{dictionary.audioSaladLockDeliveryByNonId}</h2>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RequestedStores;
