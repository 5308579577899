import React from "react";

const UsersIcon = ({ className = null, onClick = null, color = null}) => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.62598 4.37621C5.62598 1.95929 7.58528 0 10.0022 0C12.4191 0 14.3784 1.95929 14.3784 4.37621C14.3784 6.79312 12.4191 8.75242 10.0022 8.75242C7.58528 8.75242 5.62598 6.79312 5.62598 4.37621ZM5 11.2541C2.23858 11.2541 0 13.4927 0 16.2541V17.0065C0 18.6634 1.34315 20.0065 3 20.0065H17.0065C18.6633 20.0065 20.0065 18.6634 20.0065 17.0065V16.2541C20.0065 13.4927 17.7679 11.2541 15.0065 11.2541H5Z"
        fill={color || "#b4b4b4"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9577 9.37849C10.9577 7.51414 12.467 6 14.3323 6C16.1976 6 17.7069 7.51414 17.7069 9.37849C17.7069 11.2428 16.1976 12.757 14.3323 12.757C12.467 12.757 10.9577 11.2428 10.9577 9.37849ZM11.1713 13.9277C8.86756 13.9277 7 15.7953 7 18.099C7 19.5271 8.15764 20.6847 9.58566 20.6847H19.0789C20.507 20.6847 21.6646 19.5271 21.6646 18.099C21.6646 15.7953 19.797 13.9277 17.4933 13.9277H11.1713Z"
        fill={color || "#b4b4b4"}
      />
      <path
        d="M14.3323 5.5C12.19 5.5 10.4577 7.23883 10.4577 9.37849H11.4577C11.4577 7.78944 12.744 6.5 14.3323 6.5V5.5ZM18.2069 9.37849C18.2069 7.23883 16.4746 5.5 14.3323 5.5V6.5C15.9206 6.5 17.2069 7.78944 17.2069 9.37849H18.2069ZM14.3323 13.257C16.4746 13.257 18.2069 11.5181 18.2069 9.37849H17.2069C17.2069 10.9675 15.9206 12.257 14.3323 12.257V13.257ZM10.4577 9.37849C10.4577 11.5181 12.19 13.257 14.3323 13.257V12.257C12.744 12.257 11.4577 10.9675 11.4577 9.37849H10.4577ZM7.5 18.099C7.5 16.0714 9.1437 14.4277 11.1713 14.4277V13.4277C8.59142 13.4277 6.5 15.5192 6.5 18.099H7.5ZM9.58566 20.1847C8.43378 20.1847 7.5 19.2509 7.5 18.099H6.5C6.5 19.8032 7.8815 21.1847 9.58566 21.1847V20.1847ZM19.0789 20.1847H9.58566V21.1847H19.0789V20.1847ZM21.1646 18.099C21.1646 19.2509 20.2308 20.1847 19.0789 20.1847V21.1847C20.7831 21.1847 22.1646 19.8032 22.1646 18.099H21.1646ZM17.4933 14.4277C19.5209 14.4277 21.1646 16.0714 21.1646 18.099H22.1646C22.1646 15.5192 20.0732 13.4277 17.4933 13.4277V14.4277ZM11.1713 14.4277H17.4933V13.4277H11.1713V14.4277Z"
        stroke={color ? "ffffff0f" : "#1C1C1C"}
      />
    </svg>

  );
};

export default UsersIcon;
