import React, { useState, useContext, useEffect } from "react";
import { LanguageContext } from "../../context/Language";
import Loader from "../../components/Loader";
import Core from "../../components/Register/Pricing/Core";
import StripeCalls from "../../classes/calls/payment/stripe";
import GeneralButton from "../../components/Buttons/GeneralButton";
import "../Register/register.css";

const Unpaid = () => {
  const { dictionary } = useContext(LanguageContext);
  const [showRedirectionAdvise, setShowRedirectionAdvise] = useState(false);
  const [loading, setloading] = useState(true);
  const [didMount, setDidMount] = useState(true);

  useEffect(() => {
    if (didMount)
      setTimeout(() => {
        confirmPayment();
        setDidMount(false);
      }, 3000);
    // eslint-disable-next-line
  }, [loading]);

  const goToPay = (retry = 3) => {
    setShowRedirectionAdvise(true);
    setTimeout(() => {
      StripeCalls.createCheckoutSession().then((url) => {
        if (url) window.location.href = url;
        else {
          console.log(url);
          if (retry > 0) goToPay(retry - 1);
        }
      });
    }, 3000);
  };

  // Funciones cuando se active el cambio de plan en el futuro
  // const planChange = () => {
  //   UserCalls.createContract(state.plan)
  //     .then((res) => {
  //       if (res) window.location.href = "/";
  //       else console.log(res);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // const continueHandler = () => {
  //   if (state.plan) {
  //     if (state.plan === "core") goToPay();
  //     else planChange();
  //   }
  // };

  const confirmPayment = (btn = false) => {
    setloading(true);
    StripeCalls.confirmPayment()
      .then((resp) => {
        if(resp){
          if (resp) window.location.href = "/";
        } 
        if (btn && !resp) {
          goToPay();
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setloading(false);
      });
  };

  const content = showRedirectionAdvise ? (
    <h3 className="p-4 center">
      {dictionary.redirectInN.replaceAll(/{{N}}/g, "3")}
    </h3>
  ) : (
    <Core
      hideSelectBtn={true}
    />
  );

  const checkPaymentOrGoToPay = () => {
    confirmPayment(true);
  };

  return (
    <React.Fragment>
      <div className="container-box">
        <div className="wizard-box">
          <h1>{dictionary.welcomeTo}</h1>
          <div className="pay-msg">{dictionary.paymentProblems}</div>
          <GeneralButton
            onClick={checkPaymentOrGoToPay}
            size={"lg"}
            text={dictionary.confirmPayment}
            className="confirm-btn"
          />
          {loading ? <Loader /> : content}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Unpaid;
