import React, { useContext } from "react";
import { Input as AntInput } from "antd";
import { LanguageContext } from "../../../context/Language";
import { AppContext } from "../../../context/App";
import Modal from "../../Modal";
import Select from "../../Form/Select";
import Button from "../../Buttons/GeneralButton";
const { TextArea } = AntInput;

const UpcAdd = ({
  upcErr,
  isrcErr,
  typeCode,
  submitCode,
  addVisible,
  currentCode,
  selectCode,
  handleCancel,
  changeHandler,
} ) => {

  const { dictionary } = useContext(LanguageContext);
  const { codesOptions } = useContext(AppContext);

  return (
    <Modal
      idModal="delete"
      className="add-upc"
      handleCancel={handleCancel}
      isModalVisible={addVisible}
      title={<div className="modal-title">{dictionary.add} {dictionary.code}</div>}
      body={
        <div className="forms">
          <Select
            name="label"
            required={true}
            value={typeCode}
            disableOpt={true}
            options={codesOptions}
            label={dictionary.type}
            placeholder={dictionary.select}
            className={" selector-general"}
            onChange={(e) => selectCode(e)}
          />
          <div className="text-area-label">
            {typeCode !== "upc" ? dictionary.isrcDesc : dictionary.upcDesc + dictionary.upcDesc2}
            </div>
          <TextArea
            className={((upcErr||isrcErr) ? "inputErr" : "") + " text-area-general"}
            name="description"
            value={currentCode}
            placeholder={dictionary.eg + (typeCode !== "upc" ? " US-ABC-12-34567, US ABC 12 34568, USABC1234569..." : " 123456789012, ...")}
            onChange={(e) => changeHandler(e, "upcs")}
          />
          {(upcErr || isrcErr) ? <div className="text-error">{(upcErr || isrcErr)}</div> : null}
        </div>
      }
      footer={
        <div className="modalAdd-btns">
          <Button
            key="cancel"
            size="sm"
            className="back-btn"
            text={dictionary.cancel}
            onClick={handleCancel}
          />
          <Button
            size="md"
            key="submit"
            className="footer-btn"
            onClick={submitCode}
            text={dictionary.submit}
          />
        </div>
      }
    />
  );
};

export default UpcAdd;
