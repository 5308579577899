import React from "react";

const DistribIcon = ({ className = null, onClick = null, color = null }) => {
  return (
    <svg
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.6967 2.6967C8.62563 -0.232233 13.3744 -0.232233 16.3033 2.6967C17.6719 4.06533 18.4011 5.83218 18.4906 7.62373C20.2207 8.06468 21.5 9.63257 21.5 11.5C21.5 13.7091 19.7091 15.5 17.5 15.5H5.5C2.73858 15.5 0.5 13.2614 0.5 10.5C0.5 8.32244 1.89164 6.47111 3.83316 5.78472C4.18155 4.65442 4.8031 3.5903 5.6967 2.6967ZM11.7071 4.29289C11.3166 3.90237 10.6834 3.90237 10.2929 4.29289L8.29289 6.29289C7.90237 6.68342 7.90237 7.31658 8.29289 7.70711C8.68342 8.09763 9.31658 8.09763 9.70711 7.70711L10 7.41421V11C10 11.5523 10.4477 12 11 12C11.5523 12 12 11.5523 12 11V7.41421L12.2929 7.70711C12.6834 8.09763 13.3166 8.09763 13.7071 7.70711C14.0976 7.31658 14.0976 6.68342 13.7071 6.29289L11.7071 4.29289Z"
      fill={color || "#ffffffa6"}
    />
    </svg>
  );
};

export default DistribIcon;
