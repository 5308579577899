import React, { useContext } from "react";
import { LanguageContext } from "../../../context/Language";
import Modal from "../../Modal";
import Button from "../../Buttons/GeneralButton";

const ModalResetPwd = ({
  isModalVisible,
  hideModalResetPwd,
  changePwd
} ) => {

  const { dictionary } = useContext(LanguageContext);

  return (
    <Modal
      destroyOnClose
      maskClosable={false}
      idModal="softDelete"
      isModalVisible={isModalVisible.show}
      handleCancel={hideModalResetPwd}
      title={<div className="modal-title">{dictionary.resetPassword}</div>}
      body={
        <div>
          <p>{dictionary.resetPwdDesc}</p>
          <p>{dictionary.resetPwdDesc2}</p>
        </div>
      }
      footer={
        <div className="modalAdd-btns">
          <Button
            key="cancel"
            size="sm"
            className="back-btn"
            text={dictionary.cancel}
            onClick={hideModalResetPwd}
          />
          <Button
            key="submit"
            size="md"
            text={dictionary.accept}
            disabled={isModalVisible.hasRelease > 0}
            className="footer-btn"
            onClick={() => changePwd(isModalVisible.userid, isModalVisible.email, isModalVisible.language)}
          />
        </div>
      }
    />
  );
};

export default ModalResetPwd;
