import React, { createContext, useState, useEffect, useMemo } from "react";
import Spanish from "./lang/es.json";
import English from "./lang/en.json";
import { IntlProvider } from "react-intl";
import Language from "../../classes/language/language";
export const LanguageContext = createContext();


const validAlphanumericLocales = new Set([
  "en-US", "bg-BG", "cs-CZ", "da-DK", "de-DE", "el-GR", "es-AR", "es-ES",
  "fr-FR", "it-IT", "hu-HU", "nb-NO", "nl-NL", "nn-NO", "pl-PL", "pt-PT",
  "ru-RU", "sl-SI", "sk-SK", "sr-RS@latin", "sr-RS", "sv-SE", "tr-TR",
  "uk-UA", "ku-IQ", "ar", "he", "fa-IR", "en-AU", "en-GB", "en-HK", "en-IN",
  "en-NZ", "en-ZA", "en-ZM", "ar-AE", "ar-BH", "ar-DZ", "ar-EG", "ar-IQ",
  "ar-JO", "ar-KW", "ar-LB", "ar-LY", "ar-MA", "ar-QM", "ar-QA", "ar-SA",
  "ar-SD", "ar-SY", "ar-TN", "ar-YE", "pt-BR", "pl-Pl"
]); // tuve que agregar esta lista porque no tenemos TS

const getValidLocale = (locale) => { // necesario para validator
  locale = locale.startsWith("es") ? "es-ES" : locale;
  return validAlphanumericLocales.has(locale)
    ? (locale)
    : "en-US"; // Default locale
};


let local = getValidLocale(navigator.language);
try {
  new Intl.DateTimeFormat(local); // Prueba si el locale es válido
} catch {
  local = "en-US"; // Asigna el valor predeterminado si no es válido
}
let lang = English;

let monthNames = Array.from({ length: 12 }, (e, i) =>
  new Intl.DateTimeFormat(local, { month: "long" }).format(new Date(2024, i))
);

export const LanguageProvider = ({ children }) => {
  const [locale, setLocale] = useState(local);
  const [languagesList, setLanguagesList] = useState([]);
  const [dictionary, setMessages] = useState(lang);

  const setLanguagesState = () => {
    Language.languages()
      .then((langData) => {
        if (langData.status === 200) setLanguagesList(langData.body.languages);
        else setLanguagesList([]);
      })
      .catch((err) => {
        console.log(err);
        setLanguagesList([]);
      });
  };

  // Options for language select
  const languageOptions = useMemo(() => {
    return languagesList.map((language) => {
      return { value: language._id, label: language.name };
    });
  }, [languagesList]);

  useEffect(() => {
    setLanguagesState();
  }, []);

  const selectLanguage = (e) => {
    const newLocale = e.target.value;
    setLocale(newLocale);
    if (newLocale === "en") {
      // get the locale region from the navigator
      // en-US, en-UK
      setMessages(English);
    } else if (newLocale === "es") {
      setMessages(Spanish);
    }
  };

  return (
    <LanguageContext.Provider
      value={{
        locale,
        dictionary,
        selectLanguage,
        setLanguagesState,
        languageOptions,
        languagesList,
        monthNames,
      }}
    >
      <IntlProvider dictionary={dictionary} locale={locale}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};
