import React from "react";

const ExcelIcon = ({ className = null, onClick = null }) => {
  return (
    <svg
      width="128"
      height="128"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <g/>
      <g id="Exel">
        <g>
          <g>
            <path
              fill="#007732"
              d="M80,96h-8.3l-8-13l-8,13H48l11.4-17.7L48.7,61.7h8l7.4,12.3l7.2-12.3h7.8l-10.8,17L80,96z"
            />
          </g>
          <g>
            <path
              fill="#007732"
              d="M104,126H24c-5.5,0-10-4.5-10-10V12c0-5.5,4.5-10,10-10h40.7c2.7,0,5.2,1,7.1,2.9l39.3,39.3     c1.9,1.9,2.9,4.4,2.9,7.1V116C114,121.5,109.5,126,104,126z M24,6c-3.3,0-6,2.7-6,6v104c0,3.3,2.7,6,6,6h80c3.3,0,6-2.7,6-6V51.3     c0-1.6-0.6-3.1-1.8-4.2L68.9,7.8C67.8,6.6,66.3,6,64.7,6H24z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ExcelIcon;
