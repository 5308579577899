import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../context/Language";
import RealoadIcon from "../../components/Icon/RealoadIcon";
import "./reload.css";

const Reload = () => {
  const { dictionary } = useContext(LanguageContext);

  return (
    <React.Fragment>
      <div className="container-box reload">
        <h1 className="title">Oops...</h1>
        <p>
          {dictionary.bitProblem},
          <Link to="/">
            <b>{dictionary.kindlyReload}.</b>
          </Link>
        </p>
        <p>
          {dictionary.errorPersist}
          <Link to="/">
            <b>{dictionary.contactSupport}.</b>
          </Link>
        </p>
        <RealoadIcon />
      </div>
    </React.Fragment>
  );
};

export default Reload;
