import BaseCalls from "../";

class VisitorCalls extends BaseCalls {
  login = (data, token = null) => {
    if (token) {
      data.token = token;
    }
    this.setValues("/user/login", data);
    return this.doPost();
  };

  signup = (data, token) => {
    data.token = token;
    this.setValues("/user/signup", data);
    return this.doPost();
  };

  loginFacebook = () => {
    this.setValues("/user/auth/external/facebook");
    return this.doGet();
  };

  loginGoogle = () => {
    this.setValues("/user/auth/external/google");
    return this.doGet();
  };

  verifyEmail = (data) => {
    this.setValues("/user/verify_email", data);
    return this.doPost();
  };

  request_code = (email, language) => {
    this.setValues("/user/request_code", { email: email, language: language});
    return this.doPost();
  };

  verify_code = (email, code) => {
    this.setValues("/user/verify_code/", { email: email, code: code });
    return this.doPost();
  };

  verifyAccount = (token, e) => {
    this.setValues("/user/verify/" + token + "/" + e);
    return this.doGet();
  };

  sendRecoverPwdMail = (email, language) => { 
    this.setValues("/user/password/recover", { email: email, language: language });
    return this.doPost();
  };

  resetPwdValidateToken = (token) => {
    this.setValues("/user/password/token_reset/" + token);
    return this.doGet();
  };

  resetPassword = (data) => {
    this.setValues("/user/password/reset", data);
    return this.doPut();
  };

  checkInvitation = (invitation) => {
    this.setValues(`/user/invitation/${invitation}`);
    return this.doGet();
  };

  deleteInviteToken = (token) => {
    this.setValues(`/user/invitation/${token}`);
    return this.doDelete();
  }
}

export default new VisitorCalls();
