import React, { useEffect, useCallback, useContext } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Button from "../Buttons/GeneralButton";
import { LanguageContext } from "../../context/Language";

const ReCaptcha = ({ setToken, expired, resent, setResent }) => {

  const { dictionary } = useContext(LanguageContext);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const resend = () => {
    handleReCaptchaVerify();
    setResent(true);
  };

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha();
    setToken(token);
    // eslint-disable-next-line
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  return expired && resent ? (
    <p>{dictionary.captchaResend}</p>
  ) : expired ? (
    <React.Fragment>
      <p>{dictionary.captchaExpired}</p>
      <Button
        text={dictionary.captchaButton}
        size="md"
        onClick={() => resend()}
      />
    </React.Fragment>
  ) : null;
};

export default ReCaptcha;
