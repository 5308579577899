import React, { useContext, useState, useEffect } from "react";
import { ArrowLeftOutlined, DownOutlined } from "@ant-design/icons";
import { Row, Col } from "antd";
import { LanguageContext } from "../../../../context/Language";
import Input from "../../../Form/Input";
import Date from "../../../Form/Date";
import Button from "../../../Buttons/GeneralButton";
import Release from "../../../../classes/release/release";
import Loader from "../../../Loader";

const Rights = ({
  state,
  setInput,
  setValid,
  validation,
  backSection,
  nextSection,
  validateStep,
  changeSection,
}) => {

  const { dictionary } = useContext(LanguageContext);
  const [errors, setError] = useState({
    infoC: false,
    yearCErr: false,
    infoP: false,
    yearPErr: false,
    holder: false,
  });
  const [generalError, setGeneralError] = useState(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    validateStep(next);
    // eslint-disable-next-line
  }, [state.infoC, state.yearC, state.infoP, state.yearP, state.holder]);

  const changeHandler = (e) => {
    setError({
      ...errors,
      [e.target.name]: false,
    });
    setInput({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const changeCDate = (date, dateString) => {
    setError({
      ...errors,
      yearCErr: false
    });
    setInput({
      ...state,
      yearC: dateString,
    });
  };

  const changePDate = (date, dateString) => {
    setError({
      ...errors,
      yearPErr: false
    });
    setInput({
      ...state,
      yearP: dateString,
    });
  };

  const validate = () => {
    let data = null;
    let error = false;
    let errors = {
      infoC: false,
      yearCErr: false,
      infoP: false,
      yearPErr: false,
      holder: false,
    };
    setError({
      ...errors,
      errors,
    });
    if (state.infoC.length === 0) {
      errors.infoC = dictionary.emptyField;
      error = true;
    }
    if (!state.yearC) {
      errors.yearCErr = dictionary.emptyField;
      error = true;
    }
    if (state.infoP.length === 0) {
      errors.infoP = dictionary.emptyField;
      error = true;
    }
    if (!state.yearP) {
      errors.yearPErr = dictionary.emptyField;
      error = true;
    }
    if (state.holder.length === 0) {
      errors.holder = dictionary.emptyField;
      error = true;
    }
    if (!error) {
      data = true;
    } else {
      setError({
        ...errors,
        errors,
      });
    }
    return data;
  };

  const back = () => {
    changeSection(1);
    backSection(2);
    window.scrollTo(0, 0);
  };

  const next = async (n = null) => {
    const data = validate();
    if (data) {
      setLoader(true);
      const saved = await Release.setRightsRelease(state);
      if (!saved.error) {
        setValid({
          ...validation,
          step2: true,
        });
        if (n) {
          changeSection(n);
          nextSection(n);
        }
        window.scrollTo(0, 0);
      } else {
        setGeneralError(dictionary[saved.error] || dictionary.releaseCheckData);
      }
      setLoader(false);
    }
  };

  return (
    <div>
      <div className="forms">
        <Row className="gutter-row">
          <Col xs={24} sm={24} md={12} lg={12} className="col1">
            <Input
              name="infoC"
              required={true}
              value={state.infoC}
              error={errors.infoC}
              onChange={changeHandler}
              label={"(C) " + dictionary.info}
              className={errors.infoC ? "inputErr " : ""}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col2">
            <Date
              picker="year"
              name="yearC"
              required={true}
              value={state.yearC ? state.yearC : 2022}
              onChange={changeCDate}
              error={errors.yearCErr}
              suffixIcon={<DownOutlined />}
              label={"(C) " + dictionary.year}
              className={errors.yearCErr ? "inputErr " : ""}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col1">
            <Input
              name="infoP"
              required={true}
              value={state.infoP}
              error={errors.infoP}
              onChange={changeHandler}
              label={"(P) " + dictionary.info}
              className={errors.infoP ? "inputErr " : ""}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col2">
            <Date
              picker="year"
              name="yearP"
              required={true}
              value={state.yearP ? state.yearP : 2022}
              error={errors.yearPErr}
              onChange={changePDate}
              suffixIcon={<DownOutlined />}
              label={"(P) " + dictionary.year}
              className={errors.yearPErr ? "inputErr " : ""}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Input
              name="holder"
              required={true}
              value={state.holder}
              error={errors.holder}
              onChange={changeHandler}
              label={dictionary.rightsHolder}
              className={errors.holder ? "inputErr " : ""}
            />
          </Col>
          {generalError ? (
            <div className="error-field">{generalError}</div>
          ) : null}
        </Row>
        {loader ? <Loader /> : null}
      </div>
      <div className="align-right reg-btn">
        <Button
          size="md"
          onClick={back}
          className="back-btn"
          text={dictionary.back}
          icon={<ArrowLeftOutlined />}
        />
        <Button size="md" onClick={() => next(3)} text={dictionary.next} />
      </div>
    </div>
  );
};

export default Rights;
