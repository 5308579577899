import React from "react";
import { Button, Col, Row } from "antd";
import { useFormContext, Controller } from "react-hook-form";
import { DeleteOutlined } from "@ant-design/icons";
import Input from "../../../../Form/Input/GeneralInput";

const ReserveSchedule = ({ index, remove }) => {
  const formMethods = useFormContext();
  const { control } = formMethods;

  return (
    <Row gutter={[16, 16]} justify={"center"} align={"middle"}>
      <Col span={20}>
        <Controller
          control={control}
          name={`reserve.${index}.rate`}
          rules={{
            required: "This field is required",
            min: "Field must be grather or equal than 0",
          }}
          render={({ field, fieldState }) => (
            <Input
              label={"Period " + (index * 1 + 1)}
              placeholder={"Period"}
              {...field}
              min={0}
              type="number"
              error={fieldState.error?.message}
            />
          )}
        />
      </Col>
      <Col span={4}>
        <Button
          className="remove"
          type="danger"
          onClick={remove}
          icon={<DeleteOutlined />}
        />
      </Col>
    </Row>
  );
};

export default ReserveSchedule;
