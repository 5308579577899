import React, { useState, useContext } from "react";
import { Row, Col, Checkbox } from "antd";
import Input from "../../../../../Form/Input";
import Radio from "../../../../../Form/Radio";
import Date from "../../../../../Form/Date";
import { LanguageContext } from "../../../../../../context/Language";
import { DownOutlined } from "@ant-design/icons";

const TrackInfoThird = ({
  state,
  infoCRef,
  infoPRef,
  holderRef,
  advocacyRef,
  infoTrack,
  errorsTrack,
  changeHandler,
  changeHandlerCopyright,
  changeHandlerPhonographic,
  changeHandlerCopyrightYear,
  changeHandlerPhonographicYear,
}) => {

  const { dictionary } = useContext(LanguageContext);
  const [enableParental, setEnableParental] = useState(
    state.parental !== "None"
  );

  const parentalRadio = [
    { value: "Clean", name: dictionary.clean },
    { value: "Explicit", name: dictionary.explicit },
  ];

  const changeEnableParental = (e) => {
    const isChecked = e.target.checked;
    const value = isChecked ? "Clean" : "None";

    setEnableParental(isChecked);
    changeHandler({ target: { value } }, "advocacy");
  };

  return (
    <Row className="gutter-row">
      <Col xs={24} sm={24} md={12} lg={12} className="col1">
        <Input
          refs={infoCRef}
          name="infoC"
          required={true}
          value={infoTrack?.copyright.data}
          error={errorsTrack.infoCErr}
          onChange={(e) => changeHandlerCopyright(e, "data")}
          label={"(C) " + dictionary.info}
          className={errorsTrack.infoCErr ? "inputErr " : ""}
        />
        <Date
          picker="year"
          name="yearC"
          required={true}
          value={infoTrack?.copyright.year}
          onChange={changeHandlerCopyrightYear}
          error={errorsTrack.yearCErr}
          suffixIcon={<DownOutlined />}
          label={"(C) " + dictionary.year}
          className={errorsTrack.yearCErr ? "inputErr " : ""}
        />
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} className="col1">
        <Input
          refs={infoPRef}
          name="infoP"
          required={true}
          value={infoTrack?.phonographic.data}
          error={errorsTrack.infoPErr}
          onChange={(e) => changeHandlerPhonographic(e, "data")}
          label={"(P) " + dictionary.info}
          className={errorsTrack.infoPErr ? "inputErr " : ""}
        />
        <Date
          picker="year"
          name="yearP"
          required={true}
          value={infoTrack?.phonographic.year}
          error={errorsTrack.yearPErr}
          onChange={changeHandlerPhonographicYear}
          suffixIcon={<DownOutlined />}
          label={"(P) " + dictionary.year}
          className={errorsTrack.yearPErr ? "inputErr " : ""}
        />
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} className="col1">
        <Input
          refs={holderRef}
          name="holder"
          required={true}
          value={infoTrack?.rights_holder || state.rights_holder}
          error={errorsTrack.holderErr}
          onChange={(e) => changeHandler(e, "rights_holder")}
          label={dictionary.rightsHolder}
          className={errorsTrack.holderErr ? "inputErr " : ""}
        />
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} className="col1">
        <Checkbox
          checked={enableParental}
          className="check-terms"
          onChange={changeEnableParental}
        >
          {dictionary.parentalAdvisory}
          {enableParental ? (
            <Radio
              refs={advocacyRef}
              required={true}
              onChange={(e) => changeHandler(e, "advocacy")}
              value={infoTrack?.advocacy}
              options={parentalRadio}
              error={errorsTrack.advocacyErr}
              className={
                (errorsTrack.advocacyErr ? "inputErr " : " ") + "radio-options"
              }
            />
          ) : null}
        </Checkbox>
      </Col>
    </Row>
  );
};

export default TrackInfoThird;
