import BaseClass from "../";

class StripeCalls extends BaseClass {
  /**
   * Generate a checkout url to stripe
   * @returns {Promise<string|boolean>}
   */
  async createCheckoutSession() {
    this.setRoute("/payment/create-checkout-session");
    const res = await this.doPost();

    return res.status === 200 && res.body ? res.body.url : false;
  }

  /**
   *
   * @param {string} sessionId
   * @returns {Promise<Object|boolean>}
   */
  async retrieveCheckoutSession(sessionId) {
    this.setValues("/payment/retrieve-checkout-session", { sid: sessionId });
    const res = await this.doPost();

    return res.status === 200 && res.body ? res.body : false;
  }

  /**
   *
   * @returns {Promise<Array<Object>|boolean>}
   */
  async retrievePaymentMethodList() {
    this.setValues("/payment/list-payment-method");
    const res = await this.doPost();

    return res.status === 200 && res.body ? res.body : false;
  }

  /**
   *
   * @param {Object} data
   * @returns {Promise<Object|boolean>}
   */
  async createPaymentMethod(data) {
    this.setValues("/payment/create-payment-method", data);
    const res = await this.doPost(data);

    return res.status === 200 && res.body ? res.body : false;
  }

  /**
   *
   * @param {string} pm_id
   * @returns {Promise<Object|boolean>}
   */
  async updatePaymentMethod(pm_id) {
    this.setValues("/payment/update-payment-method", { pm_id });
    const res = await this.doPost();

    return res.status === 200 && res.body ? res.body : false;
  }

  /**
   * Generate a checkout url to stripe
   * @returns {Promise<string|boolean>}
   */
  async confirmPayment() {
    this.setRoute("/payment/confirm-payment");
    const res = await this.doPost();

    return res.status === 200 && res.body ? res.body : false;
  }
}

export default new StripeCalls();
