import React, { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../../context/Language";
import Modal from "../../Modal";
import { Col, Row } from "antd";
import Input from "../../Form/Input";
import Select from "../../Form/Select";
import Phone from "../../Form/Phone";
import Password from "../../Form/InputPassword";
import Button from "../../Buttons/GeneralButton";

const AddLabel = ({
  handleCancel,
  isModalVisible,
  handleSubmit,
  changeHandler,
  label,
  errors,
  phoneCountries,
  selectChangeHandler,
  dataCountries,
  handleSubmitUpdate,
  listLabels,
  messageArtist,
} ) => {

  const [title, setTitle] = useState("");
  const [selectLabels, selSelectLabels] = useState([]);

  const { dictionary } = useContext(LanguageContext);

  useEffect(() => {
    if (label.update) setTitle(dictionary.updateLabel);
    else setTitle(dictionary.createLabel);
  }, [label.update, dictionary.createLabel, dictionary.updateLabel]);

  useEffect(() => {
    selSelectLabels(listLabels);
    // eslint-disable-next-line
  }, [listLabels.length]);

  const typeBehavior = () => {
    if (messageArtist) {
      return (
        <Col xs={24} sm={24} md={24} lg={24}>
          <div className="message">{messageArtist}</div>
        </Col>
      );
    } else if (label.update) {
      return (
        <>
          <Col xs={24} sm={24} md={12} lg={12} className="col1">
            {label.hasRelease > 0 ? (
              dictionary.unableUpdateLabel
            ) : (
              <Input
                name="name"
                value={label.name}
                onChange={(e) => changeHandler(e, "name")}
                label={dictionary.labelName}
                required={true}
                className={errors.name ? "inputErr" : ""}
                error={dictionary[errors.name]}
              />
            )}
          </Col>
        </>
      );
    } else {
      return (
        <>
          <Col xs={24} sm={24} md={12} lg={12} className="col1">
            <Input
              name="name"
              value={label.name}
              onChange={(e) => changeHandler(e, "name")}
              label={dictionary.labelName}
              required={true}
              className={errors.name ? "inputErr" : ""}
              error={dictionary[errors.name]}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col2">
            <Input
              name="email"
              value={label.email}
              onChange={(e) => changeHandler(e, "email")}
              label={dictionary.email}
              required={true}
              className={errors.email ? "inputErr" : ""}
              error={dictionary[errors.email]}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col1">
            <Input
              name="firstName"
              value={label.firstName}
              onChange={(e) => changeHandler(e, "firstName")}
              label={dictionary.name}
              required={true}
              className={errors.firstName ? "inputErr" : ""}
              error={dictionary[errors.firstName]}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col2">
            <Input
              name="surname"
              value={label.surname}
              onChange={(e) => changeHandler(e, "surname")}
              label={dictionary.lastName}
              required={true}
              className={errors.surname ? "inputErr" : ""}
              error={dictionary[errors.surname]}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col1">
            <Password
              name="password"
              style={{ background: "#F8F8F8" }}
              value={label.password}
              onChange={(e) => changeHandler(e, "password")}
              label={dictionary.password}
              className={errors.password ? "inputErr" : ""}
              error={dictionary[errors.password]}
              required={true}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col2">
            <Password
              style={{ background: "#F8F8F8" }}
              name="confirmPassword"
              value={label.confirmPassword}
              onChange={(e) => changeHandler(e, "confirmPassword")}
              label={dictionary.passwordConfirm}
              className={errors.confirmPassword ? "inputErr" : ""}
              error={dictionary[errors.confirmPassword]}
              required={true}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col1">
            <Select
              name="parent"
              label={dictionary.parentLabel}
              showSearch={true}
              value={label.parent}
              options={selectLabels}
              placeholder={dictionary.select}
              onChange={(e) => selectChangeHandler(e, "parent")}
              style={{ width: "100%" }}
              className={
                (errors.parent ? "countErr " : "") + " selector-general"
              }
              required={true}
              error={dictionary[errors.parent]}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Input
              name="address"
              value={label.address}
              onChange={(e) => changeHandler(e, "address")}
              label={dictionary.address}
              required={true}
              className={errors.address ? "inputErr" : ""}
              error={dictionary[errors.address]}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col1">
            <Input
              name="city"
              value={label.city}
              onChange={(e) => changeHandler(e, "city")}
              label={dictionary.city}
              required={true}
              className={errors.city ? "inputErr" : ""}
              error={dictionary[errors.city]}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col2">
            <Input
              name="zip"
              maxLength={10}
              value={label.zip}
              onChange={(e) => changeHandler(e, "zip")}
              label={"ZIP"}
              required={true}
              className={errors.zip ? "inputErr" : ""}
              error={dictionary[errors.zip]}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col1">
            <Select
              name="country"
              label={dictionary.country}
              showSearch={true}
              value={label.country}
              options={dataCountries}
              placeholder={dictionary.select}
              onChange={(e) => selectChangeHandler(e, "country")}
              style={{ width: "100%" }}
              className={
                (errors.country ? "countErr " : "") + " selector-general"
              }
              required={true}
              error={dictionary[errors.country]}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col2">
            <Input
              name="state"
              value={label.state}
              onChange={(e) => changeHandler(e, "state")}
              label={dictionary.state}
              required={true}
              className={errors.state ? "inputErr" : ""}
              error={dictionary[errors.state]}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col1">
            <Phone
              name="number"
              maxLength={30}
              value={label.number}
              onChange={(e) => changeHandler(e, "number")}
              lada={label.countryPhone}
              onChangeSelect={(e) => selectChangeHandler(e, "code")}
              phoneCountries={phoneCountries}
              label={dictionary.phoneNumber}
              required={true}
              error={dictionary[errors.number]}
            />
          </Col>
        </>
      );
    }
  };

  return (
    <Modal
      idModal="addLabelAcc"
      handleCancel={handleCancel}
      isModalVisible={isModalVisible}
      title={<div className="modal-title">{title}</div>}
      body={
        <div className="forms add-form">
          <Row className="gutter-row">{typeBehavior()}</Row>
        </div>
      }
      footer={
        messageArtist ? (
          <div className="modalAdd-btns">
          <Button
            key="cancel"
            size="sm"
            className="footer-btn"
            text={dictionary.close}
            onClick={handleCancel}
          />
          </div>
        ) : 
        <div className="modalAdd-btns">
          <Button
            key="cancel"
            size="sm"
            className="back-btn"
            text={dictionary.cancel}
            onClick={handleCancel}
          />
          <Button
            key="submit"
            size="md"
            text={label.update ? dictionary.update : dictionary.add}
            className="footer-btn"
            disabled={label.hasRelease > 0}
            onClick={label.update ? handleSubmitUpdate : handleSubmit}
          />
        </div>
      }
    />
  );
};
export default AddLabel;
