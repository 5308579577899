import { Row, Col } from "antd";
import React, { useContext, useEffect, useMemo, useState } from "react";
// import Button from "../../../../../Buttons/GeneralButton";
import { LanguageContext } from "../../../../../../context/Language";
import { ArtistContext } from "../../../../../../context/Artist";
import Contributors from "../../../Contributors";

const TrackContributors = ({
  trackData,
  isEditMode,
  dataToUpdateDispatcher,
  dataToUpdate,
  labelId,
}) => {

  const { dictionary } = useContext(LanguageContext);
  const {
    rolesByType,
    artistRoleOptions,
    writerRoleOptions,
    additionalRoleOptions,
    artistsByLabelId,
    invertedRoles,
  } = useContext(ArtistContext);
  const [artists, setArtists] = useState({});
  const [writers, setWriters] = useState({});
  const [additional, setAdditional] = useState({});
  // This come back to the reducer, cuz we need to update the data from server
  const [originalContributors, setOriginalContributors] = useState({
    artists: [],
    writers: [],
    producers: [],
  });

  const labelArtists = useMemo(() => {
    if (labelId) {
      return artistsByLabelId[labelId];
    }
    return [];
  }, [labelId]);

  const contributorOptions = useMemo(() => {
    return labelArtists.map((item) => {
      return { label: item.name, value: item._id };
    });
  }, [labelArtists]);

  useEffect(() => {
    if (Object.keys(trackData).length === 0) return;

    // We need to reconcile the data from the server with the data from the user
    // The item.origin is very important, cuz its used in the reducer with originalContributors
    const result = [
      ...(dataToUpdate?.artists || trackData?.artists || []).map((item) => {
        item.origin = "artists";
        return item;
      }),
      ...(dataToUpdate?.writers || trackData?.writers || []).map((item) => {
        item.origin = "writers";
        return item;
      }),
      ...(dataToUpdate?.producers || trackData?.producers || []).map((item) => {
        item.origin = "producers";
        return item;
      }),
    ];

    if (result.length === 0) return;

    // This was changed to objects cuz we need to use the contributorId as key
    // from the original data, so the contributorRole is the server structure
    // {contributorId: [contributorRole, contributorRole, ...]]}
    const artistList = {};
    const writerList = {};
    const additionalList = {};

    // For meet the requirements we need to restucture the data,
    // cuz the roles are diferent between the server and the presentation view,
    // (change the server data implies several changes in both sides)
    // you can see the ArtistContext.js file for more details about the roles
    for (const contributor of result) {
      let added = false;

      if (rolesByType["artist"].includes(contributor.role)) {
        if (Object.hasOwnProperty.call(artistList, contributor.artist_id)) {
          artistList[contributor.artist_id].push(contributor);
          added = true;
        } else {
          artistList[contributor.artist_id] = [contributor];
          added = true;
        }
      }

      if (rolesByType["writer"].includes(contributor.role)) {
        if (Object.hasOwnProperty.call(writerList, contributor.artist_id)) {
          writerList[contributor.artist_id].push(contributor);
          added = true;
        } else {
          writerList[contributor.artist_id] = [contributor];
          added = true;
        }
      }

      if (rolesByType["participant"].includes(contributor.role)) {
        if (Object.hasOwnProperty.call(additionalList, contributor.artist_id)) {
          additionalList[contributor.artist_id].push(contributor);
          added = true;
        } else {
          additionalList[contributor.artist_id] = [contributor];
          added = true;
        }
      }

      if (!added) {
        if (Object.hasOwnProperty.call(additionalList, contributor.artist_id))
          additionalList[contributor.artist_id].push(contributor);
        else additionalList[contributor.artist_id] = [contributor];
      }
    }

    setArtists(artistList);
    setWriters(writerList);
    setAdditional(additionalList);
    setOriginalContributors({
      artists: trackData.artists,
      writers: trackData.writers,
      producers: trackData.producers,
    });
  }, [
    JSON.stringify(trackData.artist),
    JSON.stringify(trackData.writers),
    JSON.stringify(trackData.producers),
    JSON.stringify(dataToUpdate.artists),
    JSON.stringify(dataToUpdate.writers),
    JSON.stringify(dataToUpdate.producers),
  ]);

  return (
    <div className="artist-contributors">
      <Row justify="space-between">
        <Col xs={24} sm={24} md={12} lg={12} span={24} className="col-contrib">
          <h2>{dictionary.ArtistAndContributors}</h2>
          <div className="artist-contributors-desc mb-3">
            {dictionary.ArtistAndContributorsDesc}
          </div>
          {/*<Button
            size="lg"
            text={dictionary.copyArtist}
            className="copyArtist-btn"
            onClick={() => {}}
  />*/}
          <Contributors
            labelArtists={labelArtists}
            isEditMode={isEditMode}
            title={dictionary.artist}
            textAction={dictionary.addArtist}
            originalContributors={originalContributors}
            addContributorKey="artists"
            contributors={artists}
            contributorOptions={contributorOptions}
            roleOptions={artistRoleOptions}
            invertedRoles={invertedRoles}
            withRoles={false}
            disabled={!isEditMode}
            onChange={dataToUpdateDispatcher}
          />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          span={24}
          className="col-contrib-right"
        >
          <Contributors
            labelArtists={labelArtists}
            isEditMode={isEditMode}
            title={dictionary.writersAndPublishers}
            textAction={dictionary.addWriter}
            originalContributors={originalContributors}
            addContributorKey="writers"
            contributors={writers}
            contributorOptions={contributorOptions}
            roleOptions={writerRoleOptions}
            invertedRoles={invertedRoles}
            disabled={!isEditMode}
            onChange={dataToUpdateDispatcher}
            multiple
          />
          <Contributors
            labelArtists={labelArtists}
            isEditMode={isEditMode}
            title={dictionary.additionalContributors}
            textAction={dictionary.addContributor}
            originalContributors={originalContributors}
            addContributorKey="producers"
            contributors={additional}
            contributorOptions={contributorOptions}
            roleOptions={additionalRoleOptions}
            invertedRoles={invertedRoles}
            disabled={!isEditMode}
            onChange={dataToUpdateDispatcher}
            multiple
          />
        </Col>
      </Row>
    </div>
  );
};

export default TrackContributors;
