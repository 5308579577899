import React, { useContext, useState, useRef } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { LanguageContext } from "../../../context/Language";
import "../../../containers/Register/register.css";
import Button from "../../Buttons/GeneralButton";
import Core from "./Core";
import Plus from "./Plus";

const Pricing = ({ state, setKeys, setInput, changeSection, signUp, hideBackBtn, confirm }) => {
  const { dictionary } = useContext(LanguageContext);

  const [error, errorPrice] = useState(null);
  let corePlan = useRef(null);
  let plusPlan = useRef(null);

  const selectPlan = (e, name) => {
    errorPrice(null);
    corePlan.current.classList.remove("selectedPlan");
    plusPlan.current.classList.remove("selectedPlan");
    let box;
    const parentBtn = e.target.parentElement;
    if (parentBtn.tagName === "BUTTON") {
      box = parentBtn.parentElement;
    } else {
      box = parentBtn;
    }
    box.classList.toggle("selectedPlan");
    setInput({
      ...state,
      plan: name,
    });
  };

  const validate = () => {
    errorPrice(null);
    const valid = state.plan;
    if (!valid) errorPrice(dictionary.planError);

    return valid;
  };

  const next = () => {
    if (validate()) {
      setKeys(state);
      signUp();
    }
  };

  const back = () => {
    changeSection(2);
  };

  return (
    <div>
      <h2>{dictionary.pricing}</h2>
      <div className="pricing-options">
        <Core
          corePlan={corePlan}
          active={state.plan}
          selectPlan={(e) => selectPlan(e, "core")}
        />
        <Plus
          plusPlan={plusPlan}
          active={state.plan}
          selectPlan={(e) => selectPlan(e, "plus")}
        />
      </div>
      {error ? <div className="error-field">{error}</div> : null}
      <div className={(confirm ? "align-center " : "align-right ") + " reg-btn"}>
        {hideBackBtn ? null : (
          <Button
            text={dictionary.back}
            size="md"
            icon={<ArrowLeftOutlined />}
            className="back-btn"
            onClick={back}
          />
        )}
        {confirm ? (
          <Button
            text={state.plan === "core" ? dictionary.confirmAndPay : dictionary.confirm}
            size="lg"
            onClick={next}
            className="confirm-btn"
            disabled={state.plan === null}
          />
        ) : (
          <Button
            text={state.plan === "core" ? dictionary.pay : dictionary.finish}
            size="md"
            onClick={next}
          />
        )}
      </div>
    </div>
  );
};

export default Pricing;
