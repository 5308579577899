import BaseClass from "../";

class LabelCalls extends BaseClass {
  dsps = (label) => {
    this.setValues("/label/dsp/" + label);
    return this.doGet();
  };
}

export default new LabelCalls();
