import React, { useContext } from "react";
import { LanguageContext } from "../../../context/Language";
import Modal from "../../Modal";
import Button from "../../Buttons/GeneralButton";

const ModalDeleteInvitation = ({
  isModalVisible,
  hideModalDeleteInvitation,
  deleteInvitation
}) => {

  const { dictionary } = useContext(LanguageContext);

  return (
    <Modal
      destroyOnClose
      maskClosable={false}
      idModal="deleteInvitation"
      isModalVisible={isModalVisible.show}
      handleCancel={hideModalDeleteInvitation}
      title={<div className="modal-title">{dictionary.deleteInvitation}</div>}
      body={
        <div>
          <p>{dictionary.deleteInvitationConfirm.replace("--email--", isModalVisible.email)}</p>
          <p>{dictionary.deleteInvitationDesc}</p>
        </div>
      }
      footer={
        <div className="modalAdd-btns">
          <Button
            key="cancel"
            size="sm"
            className="back-btn"
            text={dictionary.cancel}
            onClick={hideModalDeleteInvitation}
          />
          <Button
            key="submit"
            size="md"
            text={dictionary.accept}
            className="footer-btn"
            onClick={() => deleteInvitation(isModalVisible.id, isModalVisible.email)}
          />
        </div>
      }
    />
  );
};

export default ModalDeleteInvitation;
