import React from "react";

const SuccessIcon = ({ className = null }) => {
  return (
    <svg
      width="144"
      height="171"
      viewBox="0 0 144 171"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_1202_5443)">
        <path
          d="M55.7728 140.35C52.9075 137.406 48.7553 135.717 44.3808 135.717L44.2121 135.718C41.3308 135.719 38.5008 134.957 36.0117 133.511L35.7527 133.36L35.9614 133.146C37.0907 131.982 38.0338 130.652 38.7575 129.202C38.2435 129.679 37.6305 130.038 36.9618 130.253C36.2932 130.469 35.5852 130.535 34.8881 130.447C34.152 130.36 33.4454 130.107 32.8212 129.709C32.1971 129.311 31.6715 128.777 31.284 128.147C29.7551 125.675 27.9326 122.168 27.9326 119.496C27.935 117.237 28.4096 115.004 29.3261 112.938C30.2426 110.872 31.581 109.02 33.2559 107.498C34.3162 106.545 35.1632 105.381 35.7415 104.08C36.3198 102.779 36.6165 101.372 36.6123 99.9489L36.6115 99.7464C36.6112 97.8733 36.8409 96.0073 37.2955 94.1898L37.3416 94.0063L37.5311 94.0129C38.7531 94.0543 39.9758 93.9523 41.1738 93.7088C40.0501 93.5714 38.9507 93.2801 37.9069 92.843L37.7031 92.7608L37.7723 92.5526C42.5414 89.6102 53.3068 91.1339 59.5553 76.8883C64.622 65.337 82.4991 87.1424 82.4991 99.7464C82.4991 101.032 81.2218 102.732 82.178 103.582C92.5145 112.771 85.3825 118.672 83.1338 124.609C82.6003 126.018 83.7571 127.564 83.7571 129.088C83.7571 129.347 83.7505 129.614 83.7374 129.882L83.7175 130.294L83.3729 130.065C82.6911 129.62 82.0591 129.104 81.4877 128.524C82.0805 129.884 82.3599 131.358 82.3052 132.839C82.2506 134.32 81.8635 135.77 81.1722 137.083C80.0889 139.12 79.041 140.578 78.0574 141.417C74.9066 144.104 70.8448 145.49 66.7026 145.292C62.5604 145.093 58.6506 143.326 55.7728 140.35H55.7728Z"
          fill="#E6E6E6"
        />
        <path
          d="M73.0776 171C73.1481 171 73.2161 170.974 73.2693 170.928C73.3224 170.882 73.3572 170.819 73.3671 170.749C73.3795 170.662 74.5979 161.906 73.5037 150.27C72.4932 139.523 69.248 124.044 59.5268 111.331C59.5035 111.3 59.4744 111.275 59.4412 111.256C59.408 111.236 59.3712 111.224 59.3331 111.218C59.295 111.213 59.2562 111.216 59.219 111.225C59.1818 111.235 59.1469 111.252 59.1163 111.276C59.0857 111.299 59.06 111.328 59.0406 111.361C59.0213 111.394 59.0087 111.431 59.0036 111.469C58.9985 111.507 59.0009 111.545 59.0108 111.582C59.0206 111.619 59.0377 111.654 59.0611 111.685C68.6983 124.288 71.9173 139.653 72.9207 150.324C74.0085 161.893 72.7997 170.581 72.7874 170.667C72.7815 170.708 72.7846 170.75 72.7965 170.791C72.8084 170.831 72.8288 170.868 72.8563 170.899C72.8838 170.931 72.9178 170.956 72.956 170.973C72.9942 170.991 73.0357 171 73.0776 171V171Z"
          fill="#3F3D56"
        />
        <path
          d="M65.145 129.263C65.2068 129.263 65.2671 129.244 65.3171 129.208C65.3672 129.171 65.4045 129.12 65.4236 129.062C65.4427 129.003 65.4428 128.94 65.4237 128.881C65.4046 128.823 65.3674 128.772 65.3173 128.736C62.5474 126.867 59.555 125.349 56.4092 124.217C51.4944 122.424 44.0207 120.798 36.3267 123.239C36.29 123.25 36.2559 123.269 36.2264 123.293C36.197 123.318 36.1727 123.348 36.1549 123.382C36.1372 123.416 36.1264 123.454 36.1231 123.492C36.1199 123.53 36.1242 123.569 36.1359 123.605C36.1475 123.642 36.1663 123.676 36.1912 123.705C36.216 123.734 36.2464 123.758 36.2806 123.776C36.3149 123.794 36.3522 123.804 36.3906 123.807C36.4289 123.811 36.4676 123.806 36.5042 123.794C44.0406 121.405 51.3788 123.003 56.2078 124.765C59.3031 125.878 62.2475 127.37 64.9731 129.208C65.023 129.244 65.0832 129.263 65.145 129.263Z"
          fill="#3F3D56"
        />
        <path
          d="M78.5448 96.0078C78.5493 95.9463 78.5341 95.885 78.5015 95.8327C78.4689 95.7803 78.4205 95.7396 78.3632 95.7164C78.3059 95.6931 78.2427 95.6886 78.1827 95.7033C78.1226 95.7181 78.0688 95.7514 78.0289 95.7985C75.9594 98.4169 74.2246 101.282 72.8645 104.327C70.7159 109.082 68.5507 116.393 70.439 124.216C70.4479 124.253 70.4641 124.288 70.4867 124.319C70.5093 124.35 70.5378 124.376 70.5706 124.396C70.6034 124.417 70.6399 124.43 70.6779 124.436C70.7159 124.442 70.7547 124.44 70.7921 124.431C70.8295 124.422 70.8648 124.406 70.8959 124.384C70.927 124.361 70.9534 124.333 70.9734 124.3C70.9935 124.267 71.0069 124.231 71.0129 124.193C71.0188 124.155 71.0172 124.116 71.0082 124.079C69.1587 116.417 71.2873 109.239 73.3984 104.566C74.7355 101.57 76.4413 98.7512 78.4766 96.1746C78.5165 96.1276 78.5403 96.0692 78.5448 96.0078Z"
          fill="#3F3D56"
        />
        <path
          d="M135.016 170.691H111.709L111.609 170.51C111.311 169.972 111.023 169.401 110.754 168.815C108.354 163.693 107.339 157.836 106.444 152.668L105.769 148.772C105.688 148.301 105.749 147.815 105.946 147.379C106.143 146.942 106.466 146.574 106.874 146.321C107.282 146.068 107.756 145.943 108.237 145.96C108.717 145.978 109.181 146.138 109.569 146.419C113.111 148.988 116.654 151.553 120.199 154.117C121.541 155.09 123.074 156.216 124.542 157.433C124.684 156.748 124.832 156.06 124.98 155.382C125.082 154.914 125.322 154.487 125.669 154.156C126.016 153.824 126.455 153.604 126.929 153.522C127.402 153.441 127.89 153.502 128.328 153.698C128.767 153.894 129.137 154.216 129.391 154.622L132.118 158.988C134.107 162.176 135.862 165.318 135.504 168.706C135.506 168.747 135.503 168.788 135.495 168.829C135.424 169.385 135.291 169.933 135.099 170.46L135.016 170.691Z"
          fill="#F0F0F0"
        />
        <path
          d="M124.157 79.3882L119.659 62.7987C119.466 62.0964 119.004 61.4978 118.373 61.1319C117.741 60.7661 116.99 60.6624 116.283 60.8433C115.916 60.9369 115.573 61.1037 115.274 61.3338C114.975 61.5638 114.726 61.8521 114.542 62.1812C114.358 62.5104 114.243 62.8733 114.205 63.248C114.166 63.6227 114.205 64.0012 114.318 64.3606L119.521 81.4503L122.243 91.9674C121.846 92.269 121.553 92.6849 121.402 93.1586C121.251 93.6323 121.25 94.1409 121.399 94.6151C121.548 95.0894 121.84 95.5064 122.235 95.8095C122.631 96.1126 123.11 96.287 123.608 96.3092C124.107 96.3313 124.6 96.2001 125.021 95.9333C125.441 95.6665 125.77 95.2771 125.961 94.818C126.152 94.3588 126.196 93.8522 126.088 93.367C125.98 92.8818 125.725 92.4415 125.357 92.1059L124.157 79.3882Z"
          fill="#A0616A"
        />
        <path
          d="M86.3073 75.8156L99.974 65.335C100.552 64.8893 100.93 64.2351 101.028 63.5136C101.126 62.7921 100.935 62.0612 100.497 61.4786C100.271 61.1772 99.9848 60.9249 99.6571 60.7372C99.3294 60.5495 98.9668 60.4302 98.5913 60.3867C98.2159 60.3432 97.8355 60.3764 97.4733 60.4841C97.1112 60.5919 96.7748 60.772 96.4848 61.0135L82.6234 72.334L73.9097 78.8589C73.4785 78.6091 72.9806 78.4976 72.4836 78.5396C71.9866 78.5816 71.5146 78.775 71.1318 79.0936C70.7489 79.4122 70.4738 79.8405 70.3438 80.3203C70.2137 80.8002 70.2351 81.3083 70.4049 81.7756C70.5748 82.2429 70.8849 82.6468 71.2931 82.9324C71.7013 83.218 72.1878 83.3714 72.6866 83.3719C73.1854 83.3723 73.6722 83.2197 74.081 82.9349C74.4897 82.65 74.8005 82.2467 74.9712 81.7797L86.3073 75.8156Z"
          fill="#A0616A"
        />
        <path
          d="M97.4462 78.2248L96.8438 91.6313L115.759 87.5349C114.149 82.1478 113.054 81.229 114.264 77.48L97.4462 78.2248Z"
          fill="#A0616A"
        />
        <path
          d="M109.077 53.647H102.775L100.068 58.2621L98.7785 58.1912C98.1477 58.1565 97.5164 58.2503 96.9233 58.467C96.3301 58.6836 95.7876 59.0185 95.3287 59.4512C94.8699 59.8839 94.5044 60.4053 94.2546 60.9835C94.0048 61.5617 93.876 62.1846 93.876 62.8141L95.465 65.2683C95.465 65.2683 92.6231 70.2035 96.7168 72.6315C96.7168 72.6315 95.9867 77.206 96.7168 77.1568C97.447 77.1077 99.1288 76.1766 97.447 77.1077C95.7652 78.0387 95.8907 78.1637 96.1082 78.3805L96.3258 78.5973L114.01 81.5561C114.01 81.5561 114.527 78.1401 114.583 77.5308C114.638 76.9214 114.265 78.4111 114.638 76.9214C115.012 75.4318 115.573 75.9904 115.012 75.4318C114.452 74.8732 118.644 67.9527 116.922 65.2683L119.684 62.5839C119.684 60.0089 115.533 57.9215 112.949 57.9215H112.618L109.077 53.647Z"
          fill="#E6E6E6"
        />
        <path
          d="M93.8916 165.522H91.3405L90.127 155.718L93.8919 155.718L93.8916 165.522Z"
          fill="#A0616A"
        />
        <path
          d="M86.192 169.273C86.1933 169.558 86.3072 169.831 86.509 170.032C86.7109 170.234 86.9844 170.348 87.2702 170.35H92.0723L92.221 170.042L92.9003 168.646L93.1633 170.042L93.2216 170.35H95.0328L95.007 170.04L94.524 164.269L93.8922 164.23L91.1623 164.069L90.4917 164.028V165.752C89.9828 166.291 85.9893 168.33 86.192 169.273Z"
          fill="#2F2E41"
        />
        <path
          d="M131.804 158.864L129.659 160.24L123.314 152.648L126.481 150.619L131.804 158.864Z"
          fill="#A0616A"
        />
        <path
          d="M127.365 166.17C127.521 166.408 127.764 166.576 128.044 166.637C128.323 166.698 128.615 166.646 128.856 166.494L132.895 163.905L132.853 163.566L132.666 162.026L133.645 163.058L133.861 163.285L135.385 162.309L135.195 162.063L131.655 157.469L131.103 157.777L128.719 159.114L128.133 159.441L129.069 160.89C128.934 161.618 126.682 165.486 127.365 166.17Z"
          fill="#2F2E41"
        />
        <path
          d="M143.703 170.87L64.7137 170.947C64.6349 170.947 64.5594 170.915 64.5038 170.859C64.4482 170.804 64.417 170.728 64.417 170.65C64.417 170.571 64.4482 170.496 64.5038 170.44C64.5594 170.384 64.6349 170.353 64.7137 170.353L143.703 170.276C143.782 170.276 143.858 170.308 143.913 170.363C143.969 170.419 144 170.494 144 170.573C144 170.652 143.969 170.727 143.913 170.783C143.858 170.838 143.782 170.87 143.703 170.87Z"
          fill="#CACACA"
        />
        <path
          d="M115.463 86.0205C115.463 86.0205 103.372 92.3934 96.9772 88.0454C96.9772 88.0454 95.5783 94.7968 95.0177 95.3554C94.4571 95.914 93.564 96.1574 94.1974 96.7805C94.8309 97.4037 95.1835 98.2452 93.9794 99.3141C92.7754 100.383 91.5747 100.044 92.7356 101.144C93.8966 102.245 89.225 129.244 89.225 129.244C89.225 129.244 89.9724 154.352 89.225 155.112C88.4775 155.871 87.4784 156.041 88.4451 156.98C89.4118 157.919 89.4118 157.191 89.4118 158.486V159.781L89.8541 161.069H95.9214C95.9214 161.069 97.3856 159.374 95.9214 157.902C94.4572 156.43 96.367 156.027 96.8135 154.646C97.2601 153.264 98.0076 154.754 97.2601 153.264C96.5126 151.775 96.1536 152.058 96.1463 151.078C96.1389 150.099 107.164 111.741 107.164 111.741L108.499 132.972L124.561 156.222L129.289 152.689C129.289 152.689 125.847 147.861 126.652 147.197C127.456 146.533 124.279 145.701 125.281 144.952C126.283 144.203 123.871 143.449 124.78 142.456C125.689 141.463 123.528 138.962 122.275 138.962C121.023 138.962 119.265 134.792 119.265 134.792L121.889 101.848L115.463 86.0205Z"
          fill="#2F2E41"
        />
        <path
          d="M102.647 40.0954C102.562 40.2073 101.88 40.147 101.06 40.0277C99.4395 39.7919 99.2186 40.0721 98.0069 41.1704C96.2516 42.7614 97.2496 52.4651 96.3871 54.5676L96.1084 54.7722C96.6611 54.667 97.2039 54.5157 97.7312 54.3197C98.029 53.3834 98.3276 52.444 98.6282 51.5084C98.912 52.2364 99.0904 53.0008 99.1581 53.7789C99.5753 53.615 99.9902 53.2141 101.734 52.6766C102.611 52.0831 108.963 51.4864 109.779 51.2253C111.851 44.1997 108.512 41.3585 102.647 40.0954Z"
          fill="#2F2E41"
        />
        <path
          d="M104.792 50.9292C108.144 50.9292 110.861 48.2215 110.861 44.8814C110.861 41.5412 108.144 38.8335 104.792 38.8335C101.44 38.8335 98.7227 41.5412 98.7227 44.8814C98.7227 48.2215 101.44 50.9292 104.792 50.9292Z"
          fill="#A0616A"
        />
        <path
          d="M108.614 39.5623C108.272 39.512 107.942 39.397 107.644 39.2235C107.496 39.1344 107.376 39.0056 107.298 38.8518C107.22 38.698 107.186 38.5254 107.202 38.3538C107.277 37.9241 107.764 37.6675 108.201 37.6936C108.636 37.7561 109.05 37.9228 109.406 38.1793C109.367 37.764 109.188 37.3739 108.9 37.0716C108.612 36.7693 108.23 36.5723 107.815 36.5123C108.39 36.4815 108.987 36.4543 109.519 36.6745C110.05 36.8947 110.493 37.44 110.397 38.0054C111.29 37.8907 112.171 38.5109 112.54 39.3297C112.909 40.1485 112.836 41.1101 112.541 41.9582C112.512 42.0881 112.455 42.2102 112.375 42.3163C112.294 42.4224 112.192 42.5101 112.074 42.5732C111.773 42.6869 111.45 42.4963 111.189 42.3084C109.829 41.3266 108.59 40.1869 107.5 38.9131"
          fill="#2F2E41"
        />
        <path
          d="M111.26 51.311C111.003 51.4317 111.137 51.8808 110.937 52.0819C110.85 52.167 110.745 52.2319 110.63 52.2718C110.6 51.897 110.512 51.529 110.37 51.1806C110.344 51.1173 110.301 51.0466 110.234 51.0522C110.156 51.0615 110.135 51.1639 110.133 51.2402C110.117 51.6189 110.033 51.9917 109.885 52.3407C109.883 52.3444 109.881 52.35 109.879 52.3537C109.821 52.3519 109.761 52.35 109.705 52.3481C109.272 52.3314 108.311 51.2216 108.098 51.5977C108.849 50.2701 109.765 48.274 109.905 46.7565C109.954 46.4388 109.93 46.1143 109.836 45.8069C109.787 45.6539 109.695 45.5182 109.57 45.4159C109.446 45.3136 109.295 45.249 109.135 45.2296C109.056 45.226 108.978 45.2399 108.905 45.2702C108.832 45.3004 108.766 45.3464 108.713 45.4047C108.66 45.4631 108.621 45.5324 108.598 45.6077C108.575 45.683 108.569 45.7625 108.58 45.8404L108.472 45.8255C107.467 45.0099 108.334 43.5929 107.164 43.0325C106.768 42.8425 104.342 43.4775 103.914 43.3658C103.905 42.9166 103.813 42.4729 103.643 42.0568C103.617 41.9935 103.574 41.9227 103.507 41.9283C103.428 41.9376 103.408 42.04 103.406 42.1163C103.39 42.4951 103.306 42.8678 103.157 43.2168C102.565 43.133 101.961 43.1646 101.38 43.3099C100.868 43.444 100.383 43.6749 99.8687 43.7977C99.6146 43.8753 99.3474 43.9005 99.0833 43.8716C98.8192 43.8428 98.5638 43.7607 98.3326 43.6302C97.6805 43.1944 97.5665 42.2299 97.8916 41.5186C98.127 41.0622 98.445 40.6531 98.8297 40.312C99.1231 40.029 99.4277 39.7572 99.7322 39.4853C100.005 39.2414 100.278 38.9975 100.551 38.7535C100.894 38.4314 101.26 38.134 101.646 37.8635C102.261 37.4613 102.953 37.1888 103.678 37.0626C104.404 36.9365 105.147 36.9593 105.863 37.1298C106.723 37.3347 107.872 37.0461 108.659 37.4464C109.07 37.6568 109.98 38.4631 110.34 38.7498C111.264 39.4816 110.639 38.8853 111.649 39.3084C114.056 40.3173 112.991 50.4987 111.26 51.311Z"
          fill="#2F2E41"
        />
        <path
          d="M44.0578 95.454C64.8456 95.454 81.6975 78.6617 81.6975 57.9474C81.6975 37.2332 64.8456 20.4409 44.0578 20.4409C23.2699 20.4409 6.41797 37.2332 6.41797 57.9474C6.41797 78.6617 23.2699 95.454 44.0578 95.454Z"
          fill="white"
        />
        <path
          d="M44.0585 95.8282C23.0967 95.8282 6.04297 78.8349 6.04297 57.9473C6.04297 37.0597 23.0967 20.0664 44.0585 20.0664C65.0202 20.0664 82.074 37.0597 82.074 57.9473C82.074 78.8349 65.0202 95.8282 44.0585 95.8282ZM44.0585 20.8152C23.511 20.8152 6.7944 37.4726 6.7944 57.9473C6.7944 78.422 23.511 95.0794 44.0585 95.0794C64.6059 95.0794 81.3225 78.422 81.3225 57.9473C81.3225 37.4726 64.6059 20.8152 44.0585 20.8152Z"
          fill="#73C7FF"
        />
        <path
          d="M40.355 70.9732C39.9213 70.9732 39.4935 70.8738 39.1056 70.6828C38.7177 70.4919 38.3802 70.2146 38.1201 69.873L31.2677 60.8799C31.0476 60.591 30.8874 60.2622 30.7964 59.9124C30.7053 59.5625 30.6852 59.1985 30.7371 58.841C30.789 58.4835 30.9119 58.1395 31.0988 57.8288C31.2858 57.5181 31.5331 57.2466 31.8266 57.0299C32.1201 56.8133 32.4542 56.6556 32.8096 56.566C33.165 56.4764 33.5349 56.4566 33.8981 56.5076C34.2613 56.5587 34.6107 56.6797 34.9264 56.8637C35.2421 57.0477 35.5179 57.2911 35.738 57.5801L40.2211 63.4631L51.7353 46.4635C52.1465 45.8571 52.7856 45.4362 53.512 45.2935C54.2383 45.1507 54.9926 45.2977 55.6089 45.7021C56.2252 46.1065 56.6531 46.7353 56.7986 47.4502C56.9441 48.1651 56.7953 48.9075 56.3848 49.5144L42.68 69.7486C42.4321 70.1145 42.0986 70.4166 41.7076 70.6295C41.3166 70.8425 40.8795 70.96 40.4328 70.9723C40.4069 70.9728 40.3809 70.9732 40.355 70.9732Z"
          fill="#73C7FF"
        />
        <path
          d="M82.838 22.7843C82.9427 22.0672 82.189 21.3643 81.1545 21.2144C80.1201 21.0644 79.1966 21.5241 79.0919 22.2412C78.9872 22.9582 79.7409 23.6611 80.7754 23.8111C81.8098 23.9611 82.7333 23.5014 82.838 22.7843Z"
          fill="#E6E6E6"
        />
        <path
          d="M95.1544 21.5865C95.2591 20.8695 94.5054 20.1666 93.4709 20.0166C92.4365 19.8666 91.513 20.3264 91.4083 21.0434C91.3036 21.7605 92.0573 22.4634 93.0918 22.6134C94.1262 22.7633 95.0497 22.3036 95.1544 21.5865Z"
          fill="#3F3D56"
        />
        <path
          d="M79.465 12.8487C79.5697 12.1317 78.816 11.4288 77.7815 11.2788C76.747 11.1288 75.8236 11.5885 75.7189 12.3056C75.6142 13.0227 76.3679 13.7256 77.4023 13.8756C78.4368 14.0255 79.3603 13.5658 79.465 12.8487Z"
          fill="#FF6584"
        />
        <path
          d="M74.1137 8.77859C74.1187 8.12028 73.7502 7.58382 73.2907 7.58035C72.8311 7.57689 72.4545 8.10774 72.4495 8.76605C72.4445 9.42435 72.813 9.96082 73.2726 9.96428C73.7321 9.96774 74.1087 9.43689 74.1137 8.77859Z"
          fill="#E6E6E6"
        />
        <path
          d="M72.2993 1.19802C72.3043 0.539717 71.9358 0.003249 71.4762 -0.000213906C71.0166 -0.00367681 70.64 0.527177 70.635 1.18548C70.63 1.84378 70.9985 2.38025 71.4581 2.38371C71.9177 2.38717 72.2943 1.85632 72.2993 1.19802Z"
          fill="#3F3D56"
        />
        <path
          d="M86.4116 14.5393C86.4166 13.881 86.0481 13.3446 85.5885 13.3411C85.1289 13.3376 84.7523 13.8685 84.7473 14.5268C84.7423 15.1851 85.1108 15.7216 85.5704 15.725C86.03 15.7285 86.4066 15.1976 86.4116 14.5393Z"
          fill="#E6E6E6"
        />
        <path
          d="M80.4604 17.5003C80.4654 16.842 80.0969 16.3055 79.6373 16.302C79.1778 16.2986 78.8012 16.8294 78.7962 17.4877C78.7912 18.146 79.1597 18.6825 79.6192 18.686C80.0788 18.6894 80.4554 18.1586 80.4604 17.5003Z"
          fill="#FF6584"
        />
        <path
          d="M88.7456 22.9734C88.7506 22.3151 88.3821 21.7787 87.9225 21.7752C87.4629 21.7717 87.0863 22.3026 87.0813 22.9609C87.0763 23.6192 87.4448 24.1556 87.9044 24.1591C88.364 24.1626 88.7406 23.6317 88.7456 22.9734Z"
          fill="#3F3D56"
        />
        <path
          d="M12.2033 111.638C12.308 110.921 11.5542 110.218 10.5198 110.068C9.48533 109.918 8.56186 110.378 8.45716 111.095C8.35246 111.812 9.10617 112.515 10.1406 112.665C11.1751 112.815 12.0986 112.355 12.2033 111.638Z"
          fill="#E6E6E6"
        />
        <path
          d="M24.5196 110.441C24.6244 109.723 23.8706 109.021 22.8362 108.871C21.8017 108.721 20.8783 109.18 20.7736 109.897C20.6689 110.614 21.4226 111.317 22.457 111.467C23.4915 111.617 24.4149 111.158 24.5196 110.441Z"
          fill="#3F3D56"
        />
        <path
          d="M8.83118 101.703C8.93588 100.986 8.18217 100.283 7.14772 100.133C6.11326 99.9828 5.18979 100.443 5.08509 101.16C4.98039 101.877 5.7341 102.58 6.76856 102.73C7.80301 102.88 8.72648 102.42 8.83118 101.703Z"
          fill="#FF6584"
        />
        <path
          d="M3.47899 97.6326C3.48398 96.9743 3.11547 96.4378 2.6559 96.4344C2.19632 96.4309 1.81971 96.9617 1.81471 97.62C1.80971 98.2784 2.17823 98.8148 2.63781 98.8183C3.09738 98.8217 3.47399 98.2909 3.47899 97.6326Z"
          fill="#E6E6E6"
        />
        <path
          d="M1.66453 90.0525C1.66952 89.3942 1.30101 88.8578 0.841436 88.8543C0.38186 88.8508 0.00525195 89.3817 0.000256325 90.04C-0.0047393 90.6983 0.36377 91.2347 0.823345 91.2382C1.28292 91.2417 1.65953 90.7108 1.66453 90.0525Z"
          fill="#3F3D56"
        />
        <path
          d="M15.7778 103.393C15.7828 102.735 15.4143 102.199 14.9547 102.195C14.4951 102.192 14.1185 102.722 14.1135 103.381C14.1085 104.039 14.477 104.576 14.9366 104.579C15.3962 104.582 15.7728 104.052 15.7778 103.393Z"
          fill="#E6E6E6"
        />
        <path
          d="M9.82567 106.355C9.83067 105.696 9.46215 105.16 9.00258 105.157C8.543 105.153 8.16638 105.684 8.16139 106.342C8.15639 107.001 8.52491 107.537 8.98449 107.54C9.44406 107.544 9.82068 107.013 9.82567 106.355Z"
          fill="#FF6584"
        />
        <path
          d="M18.1108 111.827C18.1158 111.169 17.7473 110.633 17.2877 110.629C16.8281 110.626 16.4515 111.157 16.4465 111.815C16.4415 112.473 16.8101 113.01 17.2696 113.013C17.7292 113.017 18.1058 112.486 18.1108 111.827Z"
          fill="#3F3D56"
        />
      </g>
      <defs>
        <clipPath id="clip0_1202_5443">
          <rect width="144" height="171" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SuccessIcon;
