import React, { useState, useEffect, useContext } from "react";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../context/User";
import CreateRelease from "../../components/Release/Create";
import "./rel.css";

const NewRelease = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { permission } = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const [release_id, setReleaseId] = useState(null);

  useEffect(() => {
    if (permission) {
      const release = searchParams.get("r");
      if (release) {
        if (permission?.release?.update) {
          setReleaseId(release);
        } else if (permission) {
          navigate("/");
          // window.location = "/";
        } else {
          setReleaseId(null);
        }
      } else {
        if (!permission?.release?.add) {
          navigate("/");
          // window.location = "/";
        }
      }
    }
    // eslint-disable-next-line
  }, [JSON.stringify(permission), location.search]);

  return (
    <React.Fragment>
      <div className="newRel container-box dashboard">
        <CreateRelease releaseId={release_id} />
      </div>
    </React.Fragment>
  );
};

export default NewRelease;
