import React from "react";

const LogOutIcon = ({ className = null, color = null, onClick = null }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <g clipPath="url(#clip0_1719_2409)">
      <path d="M10.3097 0.0232916C10.7304 0.0842291 11.1511 0.285792 11.4407 0.567042C11.7356 0.854542 11.9063 1.17954 11.9735 1.57798C12.0114 1.80142 12.0114 9.19829 11.9735 9.42173C11.939 9.62798 11.889 9.77954 11.8011 9.94985C11.508 10.5092 10.958 10.8858 10.2976 10.9764C9.32868 11.1092 8.41144 10.5905 8.10454 9.73735C7.99419 9.43267 7.99937 9.64517 7.99937 5.49985C7.99937 1.35142 7.99419 1.56704 8.10454 1.26079C8.41144 0.409229 9.34592 -0.114208 10.3097 0.0232916Z" fill={color || "black"}/>
      <path d="M15.6723 3.82041C15.686 3.82353 15.7567 3.83447 15.8274 3.84385C16.186 3.89697 16.5688 4.07822 16.8412 4.3251C17.7947 5.18603 18.4378 5.97978 18.9636 6.9376C19.624 8.14385 19.9567 9.36885 19.9929 10.7345C20.0516 12.8267 19.2912 14.8938 17.8636 16.5313C17.3947 17.0704 16.7516 17.6517 16.1636 18.072C14.9343 18.9454 13.4654 19.5563 11.9429 19.8267C11.2429 19.9517 10.7757 19.9907 9.99984 19.9907C9.19466 19.9907 8.7326 19.9501 7.9826 19.8126C4.09984 19.1017 0.991216 16.2845 0.206733 12.7657C0.0550092 12.0845 0.0119057 11.6782 0.0101816 10.9454C0.0101816 10.3579 0.027423 10.1142 0.105009 9.63291C0.380871 7.91103 1.20329 6.29228 2.4688 4.98916C2.73432 4.71572 2.91708 4.54541 2.93777 4.55166C2.94811 4.55635 3.02225 4.51103 3.10329 4.45166C3.27742 4.32353 3.59811 4.17353 3.81018 4.11885C4.29811 3.99385 4.8688 4.05322 5.30501 4.2751C6.47397 4.86728 6.69811 6.28916 5.75501 7.1376C5.6757 7.20791 5.56708 7.29541 5.51191 7.33291C5.3326 7.45166 4.96018 7.90947 4.70329 8.32353C3.97742 9.5001 3.76708 10.8767 4.11191 12.1954C4.62225 14.147 6.30329 15.7032 8.45328 16.2188C9.29811 16.4204 10.2067 16.4517 11.0878 16.3126C11.9154 16.1813 12.7723 15.8579 13.4723 15.4157C14.005 15.0782 14.5602 14.5829 14.9412 14.1017C15.7257 13.1126 16.1188 11.8938 16.0498 10.6688C15.9826 9.47666 15.5102 8.39072 14.6516 7.45791C14.4705 7.26103 14.3947 7.19072 14.0723 6.9126C13.6929 6.58603 13.4895 6.17666 13.4705 5.69853C13.4602 5.4501 13.4791 5.31103 13.555 5.08603C13.6481 4.80635 13.8154 4.55635 14.0516 4.34385C14.3516 4.07041 14.7223 3.89853 15.1378 3.83603C15.2791 3.81572 15.5929 3.80635 15.6723 3.82041Z" fill={color || "black"}/>
      </g>
      <defs>
      <clipPath id="clip0_1719_2409">
        <rect width="20" height="20" fill="white" transform="matrix(-1 0 0 1 20 0)"/>
      </clipPath>
      </defs>
    </svg>
  );
};

export default LogOutIcon;
