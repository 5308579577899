import React, { useContext } from "react";
import { notification } from "antd";
import { Link } from "react-router-dom";
import { UserContext } from "../../context/User";
import { LanguageContext } from "../../context/Language";
import User from "../../classes/user/user.jsx";
import MailIcon from "../../components/Icon/mail.png";
import Button from "../../components/Buttons/GeneralButton";
import "./mailverif.css";

const MailVerify = () => {
  const { dictionary } = useContext(LanguageContext);
  const { email } = useContext(UserContext);
  const [api, contextHolder] = notification.useNotification();

  const resend = async () => {
    const verify = await User.resendVerify(email);
    if (verify.status === 200) {
      openNotificationWithIcon();
    }
  };

  const openNotificationWithIcon = () => {
    api["success"]({
      message: dictionary.sendMailsuccess,
      description: dictionary.sendMailsuccessDesc,
    });
  };

  return (
    <div>
      {contextHolder}
      <div className="container-box thanks-verify">
        <div className="verifymail">
          <h1>{dictionary.thanksFor}</h1>
          <h3>{dictionary.veiryMail}</h3>
          <div className="verifymail-body">
            <h3>
              {dictionary.didntReceive}
              <strong onClick={resend}>{dictionary.resendEmail}</strong>
            </h3>
          </div>
          <div className="verifymail-body">{dictionary.alreadyVerify}</div>
          <Link to="/">
            <Button
              text={dictionary.thanksUse}
              size="auto"
              className="goHome-btn"
            />
          </Link>
          <div className="verifymail-icon">
            <img src={MailIcon} alt="mail" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MailVerify;
