import React from "react";

const ResendIcon = ({ className = null, onClick = null }) => {
  return (
    <svg
      width="512.000000pt"
      height="512.000000pt"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#ffc51d" 
        stroke="none"
      >
        <path
          d="M69 4567 c-19 -12 -42 -38 -51 -57 -17 -33 -18 -122 -18 -1424 l0
          -1388 22 -39 c14 -25 37 -46 63 -59 41 -20 62 -20 1421 -20 l1380 0 44 23
          c100 51 108 192 13 254 -26 17 -96 18 -1335 23 l-1309 5 586 482 585 483 317
          -247 c174 -136 333 -252 353 -259 47 -16 99 -5 148 32 66 50 577 447 593 460
          13 12 48 -14 259 -187 134 -111 255 -205 268 -210 58 -22 124 -5 171 45 29 31
          40 111 21 156 -9 21 -86 91 -232 212 -120 99 -218 184 -216 189 4 10 900 709
          911 709 4 0 7 -230 7 -511 l0 -512 44 -43 c40 -41 47 -44 97 -44 70 0 114 26
          139 83 19 40 20 75 20 893 l0 851 -23 43 c-13 25 -37 51 -57 61 -33 18 -108
          19 -2111 19 l-2076 0 -34 -23z m4001 -351 l0 -73 -937 -729 c-515 -401 -940
          -730 -945 -732 -4 -2 -431 325 -948 727 l-940 732 0 74 0 75 1885 0 1885 0 0
          -74z m-3306 -817 c248 -193 452 -354 454 -358 2 -6 -831 -699 -900 -749 -17
          -13 -18 14 -18 722 0 405 3 736 6 736 3 0 209 -158 458 -351z"
        />
        <path 
          d="M4025 2313 c-194 -41 -392 -164 -511 -316 -149 -190 -204 -372 -204
          -671 l0 -130 -67 66 c-81 78 -101 90 -159 90 -109 0 -177 -114 -129 -216 20
          -42 412 -432 448 -445 39 -15 93 -14 125 3 15 8 122 110 239 228 178 179 213
          219 218 250 18 98 -47 178 -145 178 -54 0 -83 -17 -155 -89 -33 -34 -64 -61
          -69 -61 -15 0 0 317 18 384 45 164 164 309 316 383 99 48 162 63 268 63 328
          -1 602 -273 602 -600 0 -269 -197 -517 -465 -585 -32 -8 -82 -15 -110 -15
          -105 -1 -175 -60 -175 -150 0 -94 64 -150 170 -150 322 0 630 196 780 495 74
          148 93 229 93 405 1 119 -3 159 -21 225 -93 339 -357 590 -694 659 -96 20
          -278 20 -373 -1z"
        />
      </g>
    </svg>
  );
};

export default ResendIcon;
