import React, { useContext, useState, useEffect } from "react";
import { Divider, Alert } from "antd";
import { Link } from "react-router-dom";
import { UserContext } from "../../../context/User";
import { LanguageContext } from "../../../context/Language";
import moment from "moment";
import Loader from "../../Loader";
import Button from "../../Buttons/GeneralButton";
import PaymentMethodSelector from "./PaymentMethodSelector";
import "../set.css";

const Payment = () => {
  const { dictionary } = useContext(LanguageContext);
  const { membership } = useContext(UserContext);
  const [loader, setLoader] = useState(false);
  const [showPaymentEditModal, setShowPaymentEditModal] = useState(false);
  const creditCard = "XXXX XXXX XXXX ";
  const [membershipData, setMembData] = useState({
    planPrice: "",
    plan: "",
    secondary: "",
  });

  useEffect(() => {
    if (membership) {
      setLoader(true);
      if (membership.plan === "plus") {
        setMembData({
          ...membershipData,
          plan: "Priam Plus",
          secondary: " Priam Core",
          planPrice: dictionary.free,
        });
      } else if (membership.plan === "core") {
        setMembData({
          ...membershipData,
          plan: "Priam Core",
          secondary: " Priam Plus",
          planPrice:
            "$" +
            membership.price +
            " " +
            membership.currency +
            " / " +
            dictionary[membership.period],
        });
      }
    }
    setLoader(false);
    // eslint-disable-next-line
  }, [membership]);

  const drawPlusBenefits = () => {
    let benefitsP = [];
    if (benefitsP.length < 1) {
      for (var i = 0; i < 8; i++) {
        benefitsP.push(<li key={i}>{dictionary.plus.benefit[i]}</li>);
      }
    }
    return benefitsP;
  };

  const drawCoreBenefits = () => {
    let benefitsC = [];
    if (benefitsC.length < 1) {
      for (var i = 0; i < 8; i++) {
        benefitsC.push(<li key={i}>{dictionary.core.benefit[i]}</li>);
      }
    }
    return benefitsC;
  };

  const handleShowPaymentEditModal = (e) => {
    if (e) e.preventDefault();
    setShowPaymentEditModal(!showPaymentEditModal);
  };

  const actualBenefits = () => {
    if (membership.plan === "core") return drawCoreBenefits();
    else return drawPlusBenefits();
  };

  const drawCorePaymentDetail = () => {
    if (membership.plan !== "core" || !membership.payment_method) return null;

    const isCanceled = membership.subscription_status === "canceled";

    if (membership.scheduled_to_change_at)
      return (
        <Alert
          message={dictionary.subscriptionAboutToChange}
          description={dictionary.subscriptionAboutToChangeDetail
            .replace(
              "--date--",
              moment(membership.scheduled_to_change_at).format("D MMM, YYYY")
            )
            .replace("--plan--", "Plus")}
          type="warning"
          showIcon
        />
      );

    return (
      <>
        <div className="data">
          {dictionary.paymentMethod}:&nbsp;
          <div className="card">
            {membership?.payment_method
              ? creditCard + membership?.payment_method?.last4
              : dictionary.none}
          </div>
          <Button
            text={dictionary.update}
            size="xs"
            disabled={isCanceled}
            onClick={handleShowPaymentEditModal}
            className="update"
          />
        </div>
        {/*
        <div className="data">
          {isCanceled ? dictionary.expiredAt : dictionary.renewalDate}:&nbsp;
          <div className="card">
            {moment(membership.renewal_date).format("D MMM, YYYY") || ""}
          </div>
        </div>*/}
        <div className="data">
          {isCanceled ? dictionary.expiredAt : dictionary.renewalDate}:&nbsp;
          <div className="card">
            {membership?.subscription_period?.end
              ? moment(membership?.subscription_period?.end).format(
                  "D MMM, YYYY"
                )
              : dictionary.none}
          </div>
        </div>
        <div className="data">
          {dictionary.status}:&nbsp;
          <div className="card">
            {membership?.subscription_status
              ? dictionary.subscriptionStatuses[membership?.subscription_status]
              : membership?.subscription_status || ""}
          </div>
        </div>
      </>
    );
  };

  const secondaryPlan = () => {
    if (membership.plan === "core") {
      return (
        <div className="card">
          {dictionary.priamPlans.descriptionPlus}
          <ul>{drawPlusBenefits()}</ul>
        </div>
      );
    } else {
      return (
        <div className="card">
          {dictionary.priamPlans.descriptionCore}
          <ul>{drawCoreBenefits()}</ul>
        </div>
      );
    }
  };

  return (
    <div className="content-set pay">
      {loader ? <Loader /> : null}
      {membership ? (
        <React.Fragment>
          <h2>
            {dictionary.currPlan.replace("--plan--", membershipData.plan)}
          </h2>
          {membership.is_main ? (
            <React.Fragment>
              <PaymentMethodSelector
                show={showPaymentEditModal}
                defaultPaymentId={membership?.payment_method?.id}
                close={handleShowPaymentEditModal}
              />
              <div className="data">
                {dictionary.price}:&nbsp;
                <div className="card">{membershipData.planPrice}</div>
              </div>
              {drawCorePaymentDetail()}
              <div className="curr-benefits">
                <div>{dictionary.yourCurrentBenefits}:</div>
                {actualBenefits()}
              </div>
              <Divider />
              <div className="data bg">
                {dictionary.priamPlans.switch}
                <b>{membershipData.secondary}</b>?
                <Link
                  to="https://help.priamdigital.com/portal/en/newticket?departmentId=152968000000007061"
                  target="_blank"
                >
                  <div className="contact">
                    {dictionary.priamPlans.contactUs}
                  </div>
                </Link>
              </div>
              <div className="data sec-plan">
                {dictionary.priamPlans.whatIs}
                <b>{membershipData.secondary}</b>?
              </div>
              {secondaryPlan()}
            </React.Fragment>
          ) : null}
        </React.Fragment>
      ) : null}
    </div>
  );
};

export default Payment;
