import { message, Upload } from "antd";

export const URLFileName = (url) => url?.split("/").pop();

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
  return reader;
};

export const createFileObjectState = (file, setImage) => {
  // Get this url from response in real world.
  getBase64(file.originFileObj || file, (imageUrl) => {
    setImage([
      {
        url: imageUrl,
        name: file.name,
        uid: file.uid,
        status: "done",
      },
    ]);
  });
};

export const checkImageQuadrate = (file) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  return new Promise((resolve) => {
    reader.onload = function () {
      // eslint-disable-next-line no-undef
      let img = new Image();
      img.src = this.result;
      img.onload = function () {
        resolve({
          width: img.width,
          height: img.height,
        });
      };
    };
  });
};

export const verifyImageType = (file) => {
  return !!(
    file.type === "image/png" ||
    file.type === "image/jpg" ||
    file.type === "image/jpeg"
  );
};

export const beforeUploadImage = async (
  file,
  dictionary,
  options = { min: 3000, max: 6000 }
) => {
  const isImage = verifyImageType(file);

  const errorMessage = await checkImageQuadrate(file, options).then((resp) => {
    if (resp.width === resp.height) {
      if (resp.width < options.min) {
        return dictionary["imageMinError"].replace(/--min--/gi, options.min);
      } else if (resp.width > options.max) {
        return dictionary["imageMaxError"].replace(/--max--/gi, options.max);
      }
      return ""; // the image is fine
    } else {
      return dictionary["imageSizeError"];
    }
  });

  if (!isImage) {
    message.error(dictionary["imageValidation"].replace("--name--", file.name));
  } else if (errorMessage) {
    message.error(errorMessage);
  }

  return (isImage && !errorMessage) || Upload.LIST_IGNORE;
};

/**
 * Return the first chunk of a file or the whole file if cant be chunked
 * file.size / chunkSize >= 5
 * @param {File} file A file object to be chunked
 * @param {Number} size Size(MB) of each chunk in MB should be 50 > size > 0
 * @returns Promise
 */
export const readFileInChunks = (file, size = 20) => {
  return new Promise((resolve, reject) => {
    const fileSize = file.size;
    const chunkSize = 1024 * 1024 * size;
    // Check if the file is larger than 100 MB
    const hasChunks = fileSize / chunkSize >= 5;

    const readFileChunk = (chunk) => {
      return new Promise((resolveChunk, rejectChunk) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolveChunk(reader.result);
        };
        reader.onerror = rejectChunk;

        reader.readAsDataURL(chunk);
      });
    };

    if (hasChunks) {
      const chunk = file.slice(0, chunkSize);
      readFileChunk(chunk)
        .then((result) => {
          resolve([result]);
        })
        .catch(reject);
    } else {
      readFileChunk(file)
        .then((result) => {
          resolve([result]);
        })
        .catch(reject);
    }
  });
};

export const chunkFile = (file, callback) => {
  const fileSize = file.size;
  const chunkSize = 1024 * 1024 * 15; // 4 Mb aprox 22 s
  const returnFirst = fileSize / chunkSize > 4;
  let offset = 0;
  let chunkReaderBlock = null;
  let chunks = [];

  const readEventHandler = function (e) {
    if (e.target.error === null) {
      offset += e.target.result.length;
      chunks.push(e.target.result);
      // if the file is greater than 1 chunk, return the first chunk
      if (returnFirst) {
        callback([e.target.result]);
        return;
      }
    } else {
      console.log("Read error: " + e.target.error);
      return;
    }
    if (offset >= fileSize) {
      console.log("Done reading file");
      callback(chunks);
      return;
    }

    // of to the next chunk
    chunkReaderBlock(offset, chunkSize, file);
  };

  chunkReaderBlock = function (_offset, length, _file) {
    const r = new FileReader();
    const blob = _file.slice(_offset, length + _offset);
    r.onload = readEventHandler;
    r.readAsDataURL(blob);
  };

  // now let's start the read with the first block
  chunkReaderBlock(offset, chunkSize, file);
};
