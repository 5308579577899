import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../context/User";
import ReleasesList from "../../components/Catalog/ReleasesList";
import User from "../../classes/user/user.jsx";
import ReviewNoteModal from "../../components/Release/Detail/Modals/ReviewNoteModal";
import "../../components/Catalog/catalog.css";

/**
 * Cairo & Lino order priority ASC
 */
const voidReleases = {
  published: [], // 1
  rejected: [], // 2
  inReview: [], // 3
  readyToSent: [], // 4
  pendingDistribution: [], // 5
  delivered: [], // 6
  draft: [], // 7
};

const Releases = () => {
  const { isAdmin } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [showReviewNoteModal, setShowReviewNoteModal] = useState(false);
  // TODO:
  // in the future, this state can have {releaseId: releaseData} struture,
  // so we can crud multiple releases at the same time(good refactor)
  const [releaseSelected, setReleaseSelected] = useState({});
  const [releases, setReleases] = useState(voidReleases);

  useEffect(() => {
    getReleases();
    // eslint-disable-next-line
  }, []);

  const getReleases = async () => {
    setLoading(true);
    try {
      const resp = await User.getReleases();
      const releasesObject = resp.body.releases || voidReleases;
      setReleases(releasesObject);
      setLoading(false);
    } catch (e) {
      setReleases(voidReleases);
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className={isAdmin}>
        <ReleasesList
          filter={1}
          releases={releases}
          getReleases={getReleases}
          loadList={loading}
          canDelete={true}
        />
      </div>
      <ReviewNoteModal
        releaseId={releaseSelected._id}
        isModalVisible={showReviewNoteModal}
        releaseTitle={releaseSelected.title}
        reviewNotes={releaseSelected.review_note}
        onCancel={() => setShowReviewNoteModal(false)}
        onConfirm={() => {
          setShowReviewNoteModal(false);
          setReleaseSelected({});
          getReleases();
        }}
      />
    </React.Fragment>
  );
};

export default Releases;
