import React, { useContext, useState, useEffect } from "react";
import { notification } from "antd";
import { LanguageContext } from "../../context/Language";
import Admin from "../../classes/admin/admin";
import TableUsers from "../../components/Users/TableUsers/TableUsers.jsx";
import ModalStatus from "../../components/Users/ModalStatus/ModalStatus.jsx";
import "./users.css";

const Users = () => {

  const { dictionary } = useContext(LanguageContext);
  const [loadList, setLoading] = useState(false);
  const [listUserAdmin, setUsersAdmin] = useState([]);
  const [search, getSearch] = useState(null);
  const [initialized, setIinitialized] = useState(false);
  const [adduser, setAddUser] = useState(false);
  const [allEmails, setAllEmails] = useState([]);

  const [statusData, setstatusData] = useState({
    userid: "",
    name: "",
    show: false,
    type: "",
  });

  const getAllUsers = async () => {
    setUsersAdmin([]);
    try {
      const resp = await Admin.getAllUsers(); 
      setUsersAdmin(resp?.body?.users);
      const emails = resp?.body?.users?.map(item => item.email) || [];
      setAllEmails(emails);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getAllUsers();
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      title: dictionary.name,
      dataIndex: "name",
      className: "users-title-table",
    },
    {
      title: dictionary.email.split(" ")[0],
      dataIndex: "email",
      className: "title-category",
    },
    {
      title: dictionary.type,
      dataIndex: "type",
      className: "title-category",
    },
    {
      title: "plan",
      dataIndex: "plan",
      className: "title-category",
    },
    {
      title: dictionary.labels,
      dataIndex: "labels",
      className: "title-category album",
    },
    {
      title: dictionary.artists,
      dataIndex: "artists",
      className: "title-category album",
    },
    {
      title: dictionary.verified,
      dataIndex: "verified",
      className: "title-category",
    },
    {
      title: dictionary.status,
      dataIndex: "status",
      className: "title-category",
    },
    {
      title: dictionary.createdAt,
      dataIndex: "createdAt",
      className: "title-category",
    },
    {
      title: dictionary.actions,
      dataIndex: "detail",
      className: "title-category actions-det",
    },
    {
      title: dictionary.completedPayee,
      dataIndex: "completedPayee",
      className: "title-category ps",
    },
  ];



  const showModalUser = (user_id = "", user = null, type) => {
    if(type === "sendInvitation"){
      setAddUser(true);
      setstatusData({
        show: true,
        type: type,
      });
    } else{
      setAddUser(false);
      setstatusData({
        name: user,
        userid: user_id,
        show: true,
        type: type,
      });
    }
  }

  const hideModalStatus = () => {
    setstatusData({
      name: "",
      userid: "",
      show: false,
      type: "",
    });
  };

  const statusUser = (id ,type) => {
    setLoading(true);
    // setUsersAdmin([])
    let estatus = type === "disable" ? "disabled" : "active";
    Admin.userStatus(id, estatus).then((res) => {
      if (res.status === 200) {
        if (estatus === "active") {
          notification.success({
            message: dictionary.activeUserSuccess,
            duration: 3,
            placement: "topRight",
          });
        } else {
          notification.success({
            message: dictionary.delUserSuccess,
            duration: 3,
            placement: "topRight",
          });
        }
        setUsersAdmin((prevUsers) => 
          prevUsers.map((user) => 
            user._id === id ? { ...user, status: estatus } : user
          )
        );
      }
    }).catch(() => {
      notification.error({
        message: dictionary.errorGeneral,
        duration: 3,
        placement: "topRight",
      });
    }).finally(() => {
      setIinitialized(false);
      // getAllUsers();
      setLoading(false);
      hideModalStatus();
      getSearch(null);
    });
  };

  return (
    <React.Fragment>
      <div className="container-admin">
        <TableUsers
          search={search} 
          getSearch={getSearch}
          initialized={initialized}
          setIinitialized={setIinitialized}
          isAdmin={true}
          error={false}
          remove={false}
          released={true}
          canDelete={true}
          loadList={loadList}
          isUser={true}
          subjects={listUserAdmin || []}
          list={listUserAdmin || []}
          setNewList={setUsersAdmin}
          title={dictionary.users}
          columns={columns}
          showModalUser={showModalUser}
          getAllUsers={getAllUsers}
          setLoading={setLoading}
        />
      </div>
      <ModalStatus
        statusData={statusData}
        hideModalStatus={hideModalStatus}
        statusUser={statusUser}
        adduser={adduser}
        getSearch={getSearch}
        getAllUsers={getAllUsers}
        setIinitialized={setIinitialized}
        allEmails={allEmails}
        setUsersAdmin={setUsersAdmin}
      />
    </React.Fragment>
  );
};

export default Users;
