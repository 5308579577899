import React from "react";
import { Table, Spin } from "antd";

const TableContractDetail = ({ list, columns, loadList, error }) => {
  return (
    <div className="tables">
      <div className="top-actions-srch"></div>
      <div className="general-card-admin">
        <div className="table-admin inline-contend">
          <div className="header-tab-ad"></div>
          <Spin spinning={loadList} className="spinner spin-contract">
            <Table
              rowKey="index"
              columns={columns}
              pagination={true}
              dataSource={list}
              className="content-table-admin users contracts"
            />
          </Spin>
        </div>
        {error ? <p className="text-error relative-text">{error}</p> : null}
      </div>
    </div>
  );
};

export default TableContractDetail;
