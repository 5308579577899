import React from "react";
import { Input } from "antd";
import {
  UnlockOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import "../form.css";

const InputPassword = ({
  name = null,
  style = null,
  label = null,
  value = null,
  error = false,
  onKeyUp = null,
  onChange = null,
  className = null,
  required = false,
  placeholder = null,
}) => {
  return (
    <div>
      {label ? (
        <label>
          {label}
          <span>{required ? "*" : ""}</span>
        </label>
      ) : null}
      <Input.Password
        autoComplete="new-password"
        name={name}
        value={value}
        onKeyUp={onKeyUp}
        onChange={onChange}
        placeholder={placeholder}
        prefix={<UnlockOutlined />}
        className={"input-general pwd" + className}
        iconRender={(visible) =>
          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
        }
        style={style}
      />
      {error ? <div className="text-error">{error}</div> : null}
    </div>
  );
};

export default InputPassword;
