import React, { useContext } from "react";
import { Row } from "antd";
import { LanguageContext } from "../../../context/Language";
import { UserContext } from "../../../context/User";
import CountCard from "./CountCard";

const Counts = ({
  totalLabels,
  totalArtists,
  totalReleases,
  totalPublished,
  changeItem,
  loading,
}) => {
  const { dictionary } = useContext(LanguageContext);
  const { type } = useContext(UserContext);

  return (
    <Row gutter={16} className="counts-cards">
      {type !== "artist" && (
        <CountCard
          name={dictionary.labels}
          plus={true}
          count={totalLabels}
          refAction={"/label"}
          changeItem={changeItem}
          loading={loading.label}
        />
      )}
      <CountCard
        name={dictionary.artists}
        plus={true}
        count={totalArtists}
        refAction={"/artist"}
        changeItem={changeItem}
        loading={loading.artist}
      />
      <CountCard
        name={dictionary.releases}
        plus={true}
        count={totalReleases}
        refAction={"/release"}
        changeItem={changeItem}
        loading={loading.release}
      />
      <CountCard
        name={dictionary.publishedReleases}
        plus={false}
        count={totalPublished}
        changeItem={changeItem}
        loading={loading.release}
      />
    </Row>
  );
};

export default Counts;
