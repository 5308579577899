import { Button, Typography, Popconfirm, Spin, notification } from "antd";
import React, { useContext, useState } from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { LanguageContext } from "../../../../../../context/Language";
import AdminCalls from "../../../../../../classes/admin/admin";

const ISRCSetter = ({
  trackId,
  disabled,
  isrc,
  onSuccess = () => {},
  setShowAddUpcIsrcModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { dictionary } = useContext(LanguageContext);
  const [localIsrc, setLocalIsrc] = useState(isrc);

  const assignISRC = () => {
    setIsLoading(true);
    AdminCalls.trackISRC(trackId)
      .then((response) => {
        if (!response.error) {
          notification.success({
            key: "releaseDetailNotification",
            placement: "bottomRight",
            message: dictionary.successGeneral,
            duration: 5,
          });
          setLocalIsrc(response.isrc);
          onSuccess(response.isrc);
        } else {
          if (response.message === "notAvailableIsrc")
            notification.error({
              key: "releaseDetailnotAvailableUpcNotification",
              placement: "bottomRight",
              message: dictionary.notAvailableIsrcAdd,
              duration: 30,
              onClick: () => setShowAddUpcIsrcModal(true),
            });
          else
            notification.error({
              key: "releaseDetailNotification",
              placement: "bottomRight",
              message: dictionary.errorGeneral,
              duration: 5,
            });
        }
      })
      .catch((error) => {
        console.log(error);
        notification.error({
          key: "releaseDetailNotification",
          placement: "bottomRight",
          message: dictionary.errorGeneral,
          duration: 5,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLoading)
    return (
      <Typography.Title level={3}>
        ISRC:&nbsp;
        <Spin />
      </Typography.Title>
    );

  if (isrc || localIsrc)
    return (
      <Typography.Title level={3}>
        <div className="isrc-titl">{dictionary.assignedIsrc}</div>
        <div className="assigned">{isrc || localIsrc}</div>
      </Typography.Title>
    );

  if (disabled)
    return (
      <Typography.Title level={3}>
        <div className="isrc-titl">ISRC</div>
        <Button
          disabled={disabled}
          type={"primary"}
          className="assign-irsc-btn assign-ISRC"
        >
          {dictionary.assignISRC}
        </Button>
      </Typography.Title>
    );

  return (
    <Typography.Title level={3}>
      ISRC
      <Popconfirm
        placement="top"
        title={dictionary.isrcRequestConfirm}
        onConfirm={assignISRC}
        okText={dictionary.yes}
        cancelText={dictionary.cancel}
        icon={
          <ExclamationCircleFilled style={{ color: "var(--malibu-orig)" }} />
        }
      >
        <Button type={"primary"} className="assign-irsc-btn">
          {dictionary.assignISRC}
        </Button>
      </Popconfirm>
    </Typography.Title>
  );
};

export default ISRCSetter;
