import { Card, Progress, Timeline } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { UploadServiceContext } from "../../context/Services";
import "./UploadCard.css";

const UploadCard = () => {
  const { uploadStatus, clearFiles, showProgressCard } =
    useContext(UploadServiceContext);

  if (!showProgressCard) return null;

  const [releases, setReleases] = useState({});

  // TODO: add the ability group correctly the releases and
  // get the name of the release and the track or cover
  useEffect(() => {
    if (Object.keys(uploadStatus).length === 0) return;
    const newReleases = { ...releases };
    const { trackId, releaseId, type, progress } = uploadStatus;

    newReleases[releaseId] = {
      ...(newReleases[releaseId] || {}),
      [type === "cover" ? "cover" : trackId]: progress,
    };

    setReleases(newReleases);
  }, [JSON.stringify(uploadStatus)]);

  const drawProgress = () => {
    const result = [];
    for (const releaseId of Object.keys(releases)) {
      const releaseItems = (
        <>
          <p>Release: {releaseId}</p>
          <Timeline>
            {Object.keys(releases[releaseId]).map((trackId) => (
              <Timeline.Item key={"upload" + releaseId + trackId}>
                {trackId} <Progress percent={releases[releaseId][trackId]} />
              </Timeline.Item>
            ))}
          </Timeline>
        </>
      );
      result.push(releaseItems);
    }

    return result;
  };

  // TODO: Improve the design of the card
  // Add the ability to clean the sate @see Services provider
  return Object.keys(releases).length !== 0 ? (
    <Card
      title={"Upload queue"}
      id="upload-card"
      extra={
        <button
          onClick={() => {
            clearFiles();
            setReleases({});
          }}
        >
          Close
        </button>
      }
    >
      <div>{drawProgress()}</div>
    </Card>
  ) : null;
};
export default UploadCard;
