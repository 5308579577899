import React from "react";
import { Radio } from "antd";
import "../form.css";

const RadioInput = ({
  label = null,
  value = null,
  name = null,
  options = null,
  onChange = null,
  className = null,
  required = false,
  error = null,
  disabled = false,
}) => {
  return (
    <div className="relative-input">
      {label ? (
        <label className="allWidth">
          {label}
          <span>{required ? "*" : ""}</span>
        </label>
      ) : null}
      <Radio.Group
        onChange={onChange}
        defaultValue={value}
        className={className}
        name={name}
        disabled={disabled}
      >
        {options.map((el, index) => {
          return (
            <Radio key={index} value={el.value ? el.value : el._id}>
              {el.name}
            </Radio>
          );
        })}
      </Radio.Group>
      {error ? <div className="text-error">{error}</div> : null}
    </div>
  );
};
export default RadioInput;
