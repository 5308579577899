import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Checkbox } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { AppContext } from "../../../../context/App";
import { LanguageContext } from "../../../../context/Language";
import Loader from "../../../Loader";
import Select from "../../../Form/Select";
import Label from "../../../../classes/label/label.jsx";
import Button from "../../../Buttons/GeneralButton";
import Release from "../../../../classes/release/release.jsx";

const Distribution = ({
  state,
  setInput,
  setValid,
  validation,
  setCanMove,
  backSection,
  nextSection,
  changeSection,
}) => {

  const CheckboxGroup = Checkbox.Group;
  const { dictionary } = useContext(LanguageContext);
  const { albumTier, trackPricing, faultImage } = useContext(AppContext);
  const [dataDsp, setDsps] = useState([]);
  const [albumTiers, setAlbumTiers] = useState([]);
  const [tracksTiers, setTrackTiers] = useState([]);
  const [loader, setLoading] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [errorData, setErrorData] = useState(false);
  const [checkedList, setCheckedList] = useState(state.distros || []);
  const [allRelDistro, setAllRelDistro] = useState([]);
  const [errors, setError] = useState({
    albumPrice: false,
    trackPrice: false,
    dspErr: false,
  });

  useEffect(() => {
    getTiers();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getDistributors(state.label);
    // eslint-disable-next-line
  }, [state.label]);

  const setReleaseId = (id) => {
    setInput({
      ...state,
      _id: id,
    });
  };

  const getDistributors = (labels = []) => {
    try {
      Label.dsps(labels).then((res) => {
        setDsps(res);
        getDistributorsOptions(res);
        let dspAv = res?.filter((all) => all.status === "active") || [];
        if (state.distros && state.distros.length) {
          const selectedDSPS = res?.filter((dspSelected) =>
            state.distros.includes(dspSelected._id)
          );
          let allDistros = [
            selectedDSPS.map((selectedDSPS) => selectedDSPS._id),
          ][0];
          if (selectedDSPS.length === dspAv.length) {
            setCheckAll(true);
          }
          setCheckedList(allDistros);
        }
      });
    } catch (e) {
      setDsps([]);
    }
  };

  const getDistributorsOptions = (dataDsp = []) => {
    let distros = [];
    distros = dataDsp?.map((dspResp, index) => {
      let logo = "";
      try {
        //eslint-disable-next-line no-undef
        logo = require("./" + dspResp.logo);
      } catch (error) {
        logo = faultImage;
      }
      return {
        label: (
          <div key={index} disabled={true}>
            <img className="logo-distro" src={logo} alt={dspResp.name} />
            {dspResp.name}
            <div className="request-dsp">
              {dspResp.visible === "none"
                ? dictionary.requestStore
                : dspResp.visible === "some-active"
                ? dictionary.storeSomeActive
                : dspResp.visible === "some-none"
                ? dictionary.storeSomeDenied
                : null}
            </div>
          </div>
        ),
        value: dspResp._id,
        disabled: dspResp.visible === "none",
      };
    });
    setAllRelDistro(distros);
  };

  const getTiers = async () => {
    try {
      let priceRelId = Object.keys(albumTier).map((key) => [
        key,
        albumTier[key],
      ]);
      let priceTrackId = Object.keys(trackPricing).map((key) => [
        key,
        trackPricing[key],
      ]);
      let priceArr = [];
      let trackArr = [];
      if (priceRelId) {
        priceRelId.map((el) => {
          return priceArr.push({ _id: el[1], name: el[1] });
        });
        setAlbumTiers(priceArr);
      }
      if (priceTrackId) {
        priceTrackId.map((el) => {
          return trackArr.push({ _id: el[1], name: el[1] });
        });
        setTrackTiers(trackArr);
      }
    } catch (e) {
      setAlbumTiers([]);
      setTrackTiers([]);
    }
  };

  const handleSelect = (e, name) => {
    setError({
      ...errors,
      [name]: false
    });
    setInput({
      ...state,
      [name]: e,
    });
  };

  const onChangeCheckbox = (list) => {
    setError({
      ...errors,
      dspErr: false,
    });
    setCheckedList(list);
    const totalWithoutBeat = dataDsp.length - 1;
    setCheckAll(list.length === totalWithoutBeat);
    setInput({
      ...state,
      distros: list,
    });
  };

  const onCheckAll = (e) => {
    if (e.target.checked) {
      let dspAv = dataDsp.filter((all) => all.status === "active");
      let allDistros = [dspAv.map((dataDsp) => dataDsp._id)][0];
      setCheckedList(allDistros);
      setInput({
        ...state,
        distros: allDistros,
      });
    } else {
      setCheckedList([]);
      setInput({
        ...state,
        distros: [],
      });
    }
    setCheckAll(e.target.checked);
  };

  const validate = () => {
    let error = false;
    let errors = {
      albumPrice: false,
      trackPrice: false,
      dspErr: false,
    };
    setError({
      ...errors,
      errors,
    });
    if (state.albumPrice === 0) {
      errors.albumPrice = true;
      error = true;
    }
    if (state.trackPrice === 0) {
      errors.trackPrice = true;
      error = true;
    }
    if (checkedList.length === 0) {
      errors.dspErr = dictionary.dspErr;
      error = true;
    }
    if (error) {
      setError({
        ...errors,
        errors,
      });
    } else {
      return true;
    }
  };

  const back = () => {
    changeSection(5);
    backSection(6);
    window.scrollTo(0, 0);
  };

  const publish = async () => {
    const data = validate();
    if (data) {
      setLoading(true);
      window.scrollTo(0, 0);
      const saved = await Release.setReleaseDelivery(state);
      if (!saved.error) {
        setValid({
          ...validation,
          step6: true,
        });
        setReleaseId(saved);
        setLoading(false);
        changeSection(7);
        nextSection(7);
        setCanMove(false);
      } else {
        setErrorData(dictionary[saved.error]);
        setLoading(false);
      }
    }
  };

  return (
    <div className="dist">
      <h1>{dictionary.ReleaseTrackPrice}</h1>
      <div className="sub-trr">
        {dictionary.ReleaseTrackPriceDesc}
        <Link to={"/tiers"} target="_blank">
          {" "}
          <b>{dictionary.seePricingTable}</b>
        </Link>
      </div>
      <div className="forms">
        <Row className="gutter-row">
          <Col xs={24} sm={24} md={12} lg={12} className="col1">
            <Select
              name="albumPrice"
              required={true}
              value={state.albumPrice}
              options={albumTiers}
              placeholder={dictionary.select}
              label={dictionary.albumPricingTier}
              onChange={(e) => handleSelect(e, "albumPrice")}
              className={
                (errors.albumPrice ? "countErr " : "") + " selector-general"
              }
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col1">
            <Select
              name="trackPrice"
              required={true}
              value={state.trackPrice}
              options={tracksTiers}
              placeholder={dictionary.select}
              label={dictionary.trackPricingTier}
              onChange={(e) => handleSelect(e, "trackPrice")}
              className={
                (errors.trackPrice ? "countErr " : "") + " selector-general"
              }
            />
          </Col>
        </Row>
        <Checkbox
          indeterminate={false}
          onChange={onCheckAll}
          checked={checkAll}
          className="check-all-dsp"
        >
          {dictionary.selectAll}
        </Checkbox>
        <CheckboxGroup
          value={checkedList}
          className="group-dsp"
          onChange={onChangeCheckbox}
          options={allRelDistro}
        />
        {errors.dspErr ? (
          <div className="dsp-error">{errors.dspErr}</div>
        ) : null}
        {loader ? <Loader /> : null}
      </div>
      {errorData ? <div className="error-field">{errorData}</div> : null}
      <div className="align-right reg-btn">
        <Button
          size="md"
          onClick={back}
          className="back-btn"
          text={dictionary.back}
          icon={<ArrowLeftOutlined />}
        />
        <Button size="auto" onClick={publish} text={dictionary.sendToReview} />
      </div>
    </div>
  );
};

export default Distribution;
