import React from "react";

const RoleIcon = () => {
  return (
    <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66797 10.6743H12.5013"
        stroke="#F45C2E"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66797 14.176H10.318"
        stroke="#F45C2E"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33464 5.25085H11.668C13.3346 5.25085 13.3346 4.37585 13.3346 3.50085C13.3346 1.75085 12.5013 1.75085 11.668 1.75085H8.33464C7.5013 1.75085 6.66797 1.75085 6.66797 3.50085C6.66797 5.25085 7.5013 5.25085 8.33464 5.25085Z"
        stroke="#F45C2E"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3333 3.51709C16.1083 3.67459 17.5 4.75084 17.5 8.74959V13.9996C17.5 17.4996 16.6667 19.2496 12.5 19.2496H7.5C3.33333 19.2496 2.5 17.4996 2.5 13.9996V8.74959C2.5 4.75959 3.89167 3.67459 6.66667 3.51709"
        stroke="#F45C2E"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>

  );
};

export default RoleIcon;
