import React, { useContext } from "react";
import { LanguageContext } from "../../context/Language";
import Modal from "../Modal";
import Button from "../Buttons/GeneralButton";

const ModalDelete = ({ visible, message, onCancel, onDelete }) => {
  const { dictionary } = useContext(LanguageContext);

  return (
    <Modal
      idModal="delete"
      className="deleteModal"
      handleCancel={onCancel}
      title={null}
      isModalVisible={visible}
      body={<div className="modal-title">{message}</div>}
      footer={
        <div className="modalAdd-btns">
          <Button
            key="cancel"
            size="sm"
            className="back-btn"
            text={dictionary.cancel}
            onClick={onCancel}
          />
          <Button
            size="md"
            key="delete"
            className="footer-btn"
            onClick={onDelete}
            text={dictionary.delete}
          />
        </div>
      }
    />
  );
};

export default ModalDelete;
