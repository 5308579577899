import React from "react";

const ArtistIcon = ({ className = null, onClick = null}) => {
  return (
    <svg
      width="700pt"
      height="700pt"
      version="1.1"
      viewBox="0 0 700 550"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <g>
        <path d="m601.97 365.12c-5.8477-5.043-12.254-10.559-12.23-10.625-52.461-42.598-129.42-69.137-151.7-76.238v-0.003907c-9.5156-3.793-20.277-2.7773-28.914 2.7305-17.395 11.898-38.062 18.078-59.133 17.68-21.07 0.39844-41.742-5.7812-59.137-17.68-8.6328-5.5039-19.391-6.5195-28.906-2.7305-51.148 16.168-99.621 39.82-143.84 70.184-30.504 22.797-48.363 58.727-48.113 96.805v30.754c0 2.4766 0.98438 4.8477 2.7344 6.6016 1.75 1.75 4.125 2.7305 6.5977 2.7305l303.38-0.011719c1.2383 0 2.4258-0.49219 3.3008-1.3672s1.3672-2.0625 1.3672-3.3008v-98c0-5.1523 4.1797-9.332 9.332-9.332h202.21c1.9492 0 3.6953-1.2109 4.375-3.0391 0.67969-1.8242 0.15234-3.8828-1.3242-5.1562z" />
        <path d="m350 270.67c66.742 0 111.58-78.203 111.58-151.24v-0.003906c1.0625-29.629-8.1055-58.723-25.961-82.395-21.242-24.965-52.879-38.645-85.617-37.027-32.738-1.6172-64.375 12.062-85.617 37.027-17.855 23.672-27.023 52.766-25.961 82.398 0 73.039 44.836 151.24 111.58 151.24z" />
        <path d="m555.33 494.67c0 5.1523-4.1758 9.332-9.332 9.332s-9.332-4.1797-9.332-9.332c0-5.1562 4.1758-9.3359 9.332-9.3359s9.332 4.1797 9.332 9.3359" />
        <path d="m480.67 513.33c0 5.1562-4.1797 9.3359-9.3359 9.3359-5.1523 0-9.332-4.1797-9.332-9.3359 0-5.1523 4.1797-9.332 9.332-9.332 5.1562 0 9.3359 4.1797 9.3359 9.332" />
        <path d="m620.67 392h-205.34c-5.1523 0-9.332 4.1797-9.332 9.332v149.34c0 2.4727 0.98438 4.8477 2.7344 6.5977s4.125 2.7344 6.5977 2.7344h205.34c2.4727 0 4.8477-0.98438 6.5977-2.7344s2.7344-4.125 2.7344-6.5977v-149.34c0-2.4727-0.98438-4.8477-2.7344-6.5977s-4.125-2.7344-6.5977-2.7344zm-46.668 102.67c-0.019531 9.0039-4.3555 17.453-11.656 22.723-7.3047 5.2695-16.691 6.7188-25.242 3.8945-8.4297-2.6836-15.035-9.2891-17.719-17.719-2.8242-8.5508-1.375-17.938 3.8906-25.242 5.2695-7.3047 13.723-11.637 22.727-11.656 3.1875 0.03125 6.3438 0.61328 9.332 1.7188v-28.188c0-1.5273-0.75-2.9609-2.0078-3.8359-1.2578-0.87109-2.8633-1.0703-4.2969-0.53125l-46.668 17.5h0.003906c-1.8242 0.68359-3.0312 2.4219-3.0312 4.3672v54.504c0.12109 6.6367-2.0547 13.109-6.1602 18.324-4.1094 5.2148-9.8945 8.8477-16.375 10.285-8.5195 1.8438-17.41-0.43359-23.992-6.1445-6.5859-5.7109-10.098-14.188-9.4805-22.883 0.62109-8.6953 5.2969-16.59 12.625-21.312 7.1016-5.3086 16.438-6.6016 24.719-3.418v-39.055c0-3.8906 2.4141-7.375 6.0547-8.7422l74.668-28c2.8672-1.0742 6.0781-0.67187 8.5938 1.0703 2.5156 1.7461 4.0156 4.6094 4.0156 7.6719z" />
      </g>
    </svg>
  );
};

export default ArtistIcon;
