import React, { useContext } from "react";
import { UserContext } from "../../context/User";
import { LanguageContext } from "../../context/Language";
import LogOutImg from "../../components/Icon/LogOutImg";
import Button from "../../components/Buttons/GeneralButton";
import "./logout.css";

const Logout = () => {

  const { dictionary } = useContext(LanguageContext);
  const { logoutExceptInvite } = useContext(UserContext);

  return (
    <React.Fragment>
      <div className="container-box logged">
          <div className="logged-body">
            <p>{dictionary.logoutTitle}</p>
            {dictionary.logoutDesc}
          </div>
          <Button
            text={dictionary.logOut}
            size="lg"
            className="goHome-btn"
            onClick={() => logoutExceptInvite()}
          />
          <LogOutImg className="logout-img"/>
        </div>

    </React.Fragment>
  );
};

export default Logout;
