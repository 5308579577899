import React, { createContext, useState, useEffect } from "react";
import Cookies from "js-cookie";
import SecureLS from "secure-ls";
import User from "../../classes/user/user";
import Util from "../..//classes/util/util";
import Admin from "../../classes/admin/admin.jsx";
import Visitor from "../../classes/visitor/visitor";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userId, handleUser] = useState(null);
  const [email, handleEmail] = useState(null);
  const [avatar, handleAvatar] = useState(null);
  const [profileData, handleProfile] = useState(null);
  const [parentId, handleParentId] = useState(null);
  const [parentLabel, handleParentLabel] = useState(null);
  const [username, handleUsername] = useState(null);
  const [membership, handleMembership] = useState(null);
  const [status, handleStatus] = useState(null);
  const [type, handleType] = useState(null);
  const [sessionAuth, handleSession] = useState(false);
  const [authLoading, setAuthLoading] = useState(true);
  const [permission, setPermission] = useState(null);
  const [emailVerified, setEmailVerified] = useState(false);
  const [mainLabel, setMainLabel] = useState(0);
  const [isAdmin, setIsAdmin] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [ls, setLs] = useState(
    new SecureLS({ encodingType: "base64", isCompression: false })
  );
  const [register, setRegister] = useState(false);
  const [subGenre, setSubGenres] = useState([]);
  const [subGenreObjects, setSubGenresObjects] = useState({});
  const [alertPayee, setAlertPayee] = useState(true);
  const [payeeInfo, setPayeeInfo] = useState(true);

  useEffect(() => {
    checkUser();
    let userCookie = Cookies.get("userInfo");
    setRegister((!!ls?.get("state")?.email || !!userCookie) && !sessionAuth);
    if (sessionAuth)
      getMembership().catch(() =>
        console.log("Can´t get membership info at this time")
      );

    if (sessionAuth && subGenre.length === 0)
      subGenreSetData().catch(() => {
        ls.remove("subGenres");
        subGenreSetData().catch(() =>
          console.log("Can´t get genres at this time")
        );
      });
    // eslint-disable-next-line
  }, [sessionAuth]);

  useEffect(() => {
    if (sessionAuth) {
      if (type === "admin") {
        getAllUsers();
      } else {
        getPayeeInfo();
      }
    }
  }, [sessionAuth, type]);

  const getMembership = async () => {
    const membership = await User.getMembership();
    handleMembership(membership);
  };

  const subGenreObjectSetData = (genreSubGenre) => {
    const result = {};
    for (const sg of genreSubGenre) {
      const { genre, subgenres } = sg;
      for (const subgenre of subgenres)
        result[subgenre._id] = JSON.stringify({
          is_genre: false,
          _id: subgenre._id,
          genre_id: genre._id,
        });

      result[genre._id] = JSON.stringify({ is_genre: true, _id: genre._id });
    }
    setSubGenresObjects(result);
  };

  const subGenreSetData = async () => {
    const subGenres = await Util.genreSubGenreSelect().catch(() => []);
    setSubGenres(subGenres);
    subGenreObjectSetData(subGenres);
  };

  const authenticate = async () => {
    let auth = await User.auth();
    if (auth.status === 200 && auth.data !== null && auth.body.user) {
      return auth.body;
    }
    return false;
  };

  const logout = async () => {
    const logout = await User.logout();
    if (logout.status === 200) {
      ls.removeAll();
      Cookies.remove("userInfo");
      setRegister(false);
      handleSession(false);
      window.location.assign("/");
    }
  };

  const logoutExceptInvite = async () => {
    const logout = await User.logout();
    if (logout.status === 200) {
      // Remove all keys except "inviteToken"
      Object.keys(ls).forEach((key) => {
        if (key !== "inviteToken" && key !== "state") {
          ls.removeItem(key);
        }
      });
      Cookies.remove("userInfo");
      setRegister(false);
      handleSession(false);
      window.location.assign("/");
    }
  };

  const checkIfVerifying = async () => {
    let lastpath = window.location.pathname;
    let token = lastpath.split("/")[5];
    let e = lastpath.substring(lastpath.lastIndexOf("/") + 1);
    const verified = await Visitor.verifyAccount(token, e);
    if (verified.status === 200) {
      window.location.assign("/verifiedEmail");
    } else {
      window.location.assign("/");
    }
  };

  const verifyInviteToken = (token) => {
    const regex = /^[a-f0-9]{10}$/;
    return regex.test(token);
  };

  const checkUser = async () => {
    try {
      let session = Cookies.get("qid");
      const urlParams = new URLSearchParams(window.location.search);
      const inviteToken = urlParams.get("invite");
      if (inviteToken && verifyInviteToken(inviteToken)) {
        const res = await Visitor.checkInvitation(inviteToken);
        if (res.status === 200 && res.body) {
          setRegister(true);
          const { profile, email, verified } = res.body;
          ls.set("state", {
            email: email,
            verified: verified,
            name: profile?.name || "",
            last: profile?.surname || "",
            lada: profile?.phone?.country || "US",
            phone: profile?.phone?.number || "",
            company: profile?.company_name || "",
            address: profile?.address?.address || "",
            city: profile?.address?.city || "",
            state: profile?.address?.state || "",
            zipCode: profile?.address?.zip || "",
            country: profile?.address?.country || "",
            website: profile?.website || "",
          });
          ls.set("inviteToken", inviteToken);
          window.location.href = "/register";
          return;
        }
      }

      if (window.location.pathname.includes("/user/verify")) {
        checkIfVerifying();
      }
      if (session) {
        const auth = await authenticate();
        if (auth && auth.user) {
          const userData = auth.user;
          handleUser(userData.id || null);
          setEmailVerified(!!userData?.verified);
          handleSession(true);
          handleEmail(userData.email);
          handleUsername(userData.name);
          handleStatus(userData.status);
          handleAvatar(userData.profile.image || null);
          handleProfile(userData.profile || null);
          handleParentId(userData.parent_id || null);
          handleParentLabel(userData.parentLabel || null);
          handleType(userData.type || null);
          setMainLabel(userData.mainLabel || 0);
          setAuthLoading(false);
          setPermission(userData.permission);
          setIsAdmin(
            userData.type !== "admin"
              ? "container-box dashboard"
              : "container-admin"
          );
        } else {
          throw new Error("No authenticated");
        }
      } else {
        throw new Error("No session");
      }
    } catch (e) {
      Cookies.remove("qid");
      handleType(null);
      handleUser(null);
      handleEmail(null);
      handleAvatar(null);
      handleProfile(null);
      handleUsername(null);
      handleStatus(null);
      handleSession(false);
      handleMembership(null);
      setAuthLoading(false);
      setPermission(false);
    }
  };

  const getAllUsers = async () => {
    setAllUsers([]);
    try {
      const resp = await Admin.getAllUsers();
      setAllUsers(resp?.body?.users);
    } catch (e) {
      console.log(e);
    }
  };

  const getPayeeInfo = async () => {
    User.getPayeeCompleted()
      .then((res) => {
        if (res.status === 200) {
          setPayeeInfo(res.body);
          if (res.body.name !== "" && res.body.bank_account !== "") {
            setAlertPayee(false);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <UserContext.Provider
      value={{
        ls,
        type,
        email,
        setLs,
        logout,
        logoutExceptInvite,
        userId,
        avatar,
        parentId,
        parentLabel,
        username,
        status,
        checkUser,
        membership,
        sessionAuth,
        profileData,
        emailVerified,
        handleSession,
        setAuthLoading,
        authLoading,
        permission,
        register,
        setRegister,
        mainLabel,
        subGenre,
        subGenreObjects,
        isAdmin,
        allUsers,
        setAllUsers,
        getAllUsers,
        alertPayee,
        payeeInfo,
        getPayeeInfo,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
