import React from "react";
import { Input } from "antd";
import Select from "../Select";
import "../form.css";

const PhoneInput = ({
  name = "",
  icon = null,
  lada = null,
  value = null,
  label = null,
  error = false,
  disabled = false,
  onChange = null,
  required = false,
  maxLength = 1300,
  className = null,
  placeholder = null,
  phoneCountries = [],
  onChangeSelect = null,
  classNameLada = null,
}) => {

  return (
    <div className="phone-input">
      {label ? (
        <label>
          {label}
          <span>{required ? "*" : ""}</span>
        </label>
      ) : null}
      <div className="phone-div">
        <Select
          name="phoneCountry"
          defaultValue={"US"}
          value={lada || "US"}
          onChange={onChangeSelect}
          options={phoneCountries}
          className={classNameLada + " selector-general "}
          showSearch={true}
          disabled={disabled}
        />
        <Input
          name={name}
          prefix={icon}
          defaultValue={value}
          value={value}
          maxLength={maxLength}
          onChange={onChange}
          placeholder={placeholder}
          className={className + 
            (error ? " inputErr input-general " : " input-general ")
          }
          disabled={disabled}
        />
      </div>
      {error ? <div className="text-error">{error}</div> : null}
    </div>
  );
};

export default PhoneInput;
