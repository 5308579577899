import BaseClass from "../";

class UpcCalls extends BaseClass {
  getAvailableUpcs = () => {
    this.setValues("/upc/availables");
    return this.doGet();
  };

  addUpc = (upc) => {
    this.setValues("/upc/add/", upc);
    return this.doPost();
  };

  deleteUpc = (upc) => {
    this.setValues("/upc/delete/" + upc);
    return this.doDelete();
  };
}

export default new UpcCalls();
