import React, { useContext, useEffect } from "react";
import Input from "../../../../Form/Input/GeneralInput";
import { useFieldArray, useFormContext, Controller } from "react-hook-form";
import { Button, Select } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import CreateCostsEscalation from "./Escalation";
import { AppContext } from "../../../../../context/App/AppProvider";

const CreateCostsTerms = ({ index, remove }) => {
  const formMethods = useFormContext();
  const {
    costTypeContractOptions,
    countriesOptions,
    openedContract,
    setOpenedContract,
  } = useContext(AppContext);
  const { control } = formMethods;

  const {
    fields: costsEscalationTerms,
    append: appendCostsEscalationTerms,
    remove: removeCostsEscalationTerms,
    replace: replaceCostsEscalationTerms,
  } = useFieldArray({
    name: `costsTerms-escalation_${index}`,
  });

  useEffect(() => {
    if (
      openedContract?.salesTerms &&
      openedContract?.salesTerms[index]?.escalation
    ) {
      replaceCostsEscalationTerms(
        openedContract[`costsTerms-escalation_${index}`]
      );
      delete openedContract[`costsTerms-escalation_${index}`];
      setOpenedContract({
        ...openedContract,
      });
    }
  }, [openedContract]);

  const addCostsEscalation = () => {
    appendCostsEscalationTerms(
      {
        rate: 0,
      },
      {
        shouldFocus: false,
      }
    );
  };

  const drawCostsEscalations = () => {
    return costsEscalationTerms.map((st, i) => {
      return (
        <CreateCostsEscalation
          key={st.id}
          data={st}
          index={i}
          parentIndex={index}
          remove={() => removeCostsEscalationTerms(i)}
        />
      );
    });
  };
  return (
    <div className="input-row-group">
      <div className="input-row">
        <div className={"relative-input"}>
          <Controller
            control={control}
            name={`costsTerms.${index}.territory`}
            rules={{
              required: true,
            }}
            render={({ field, fieldState }) => (
              <React.Fragment>
                <label className="allWidth">Territory</label>
                <Select
                  className={
                    "fullWidth selector-general" +
                    (fieldState.error ? " input-general-error" : "")
                  }
                  options={countriesOptions}
                  {...field}
                />
                {fieldState.error && (
                  <div className="text-error">{fieldState.error.message}</div>
                )}
              </React.Fragment>
            )}
          />
        </div>
        <div className={"relative-input"}>
          <Controller
            control={control}
            name={`costsTerms.${index}.type`}
            rules={{
              required: true,
            }}
            render={({ field, fieldState }) => (
              <React.Fragment>
                <label className="allWidth">Type</label>
                <Select
                  className={
                    "fullWidth selector-general" +
                    (fieldState.error ? " input-general-error" : "")
                  }
                  options={costTypeContractOptions}
                  {...field}
                />
                {fieldState.error && (
                  <div className="text-error">{fieldState.error.message}</div>
                )}
              </React.Fragment>
            )}
          />
        </div>
        <Controller
          control={control}
          name={`costsTerms.${index}.rate`}
          rules={{
            required: "This field is required",
            min: "Field must be grather or equal than 0",
          }}
          render={({ field, fieldState }) => (
            <Input
              className={"form-box-input"}
              label={"Rate %"}
              placeholder={"Rate %"}
              type="number"
              min={0}
              {...field}
              error={fieldState.error?.message}
            />
          )}
        />
        <Button
          className="remove"
          type="danger"
          onClick={() => remove()}
          icon={<DeleteOutlined />}
        />
      </div>
      {drawCostsEscalations()}
      <div className="input-row">
        <Button
          type="primary"
          onClick={() => addCostsEscalation()}
          className="secondary"
        >
          Add Escalation
        </Button>
      </div>
    </div>
  );
};

export default CreateCostsTerms;
