import React from "react";

const CrossIcon = ({ className = null, color = null, onClick = null }) => {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M33.7499 33.7499L11.25 11.25"
        stroke={color ? color : "#FF6767"}
        strokeWidth="2.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.7499 11.25L11.2499 33.7501"
        stroke={color ? color : "#FF6767"}
        strokeWidth="2.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CrossIcon;
