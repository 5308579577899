import React, { useState, useContext } from "react";
import { LanguageContext } from "../../../context/Language";
import { Card } from "antd";
import InputPassword from "../../Form/InputPassword";
import UserCalls from "../../../classes/user/user";
import "./index.css";

const CardPasswordLock = ({
  actions,
  loading,
  bordered,
  title,
  children,
  unloked,
  setUnloked,
  onSuccess = () => {},
}) => {
  const { dictionary } = useContext(LanguageContext);
  const [pass, setPass] = useState("");
  const [verifyng, setVerifyng] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleUnlock = () => {
    if (pass.length < 8) {
      setErrorMessage(dictionary.passErr);
      return;
    }
    setVerifyng(true);
    UserCalls.verifyPassword(pass)
      .then((res) => {
        if (res.body) {
          setErrorMessage("");
          setUnloked(true);
          onSuccess(pass);
        } else setErrorMessage(dictionary.errorGeneral);
      })
      .catch(() => setErrorMessage(dictionary.errorGeneral))
      .finally(() => setVerifyng(false));
  };

  const mask = unloked ? null : (
    <div className="card-mask-mask">
      <div className="card-mask-center">
        <InputPassword
          disabled={verifyng}
          name="password"
          value={pass}
          error={errorMessage ? errorMessage : ""}
          onChange={(e) => setPass(e.target.value)}
          label={dictionary.password}
          className={errorMessage !== "" ? " inputErr" : ""}
        />
        <button className="general-btn" disabled={verifyng} onClick={handleUnlock}>
          {dictionary.unlock}
        </button>
      </div>
    </div>
  );

  return (
    <Card
      className="card-mask"
      actions={actions}
      loading={loading}
      bordered={bordered}
      title={title}
    >
      {children}
      {mask}
    </Card>
  );
};

export default CardPasswordLock;
