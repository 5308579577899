import UserCalls from "../calls/user/user.jsx";
import uploadS3Calls from "../calls/amazon/uploadS3";
import { v4 as uuidv4 } from "uuid";

class User {
  async logout() {
    return await UserCalls.logout();
  }

  async request_verify() {
    return await UserCalls.request_verify();
  }

  async verify() {
    return await UserCalls.verify();
  }

  async resendVerify() {
    return await UserCalls.resendVerify();
  }

  async auth() {
    return await UserCalls.auth();
  }

  async getArtists() {
    return await UserCalls.getArtists();
  }

  async createArtist(artist) {
    return await UserCalls.createArtist(artist);
  }

  async createContract(plan) {
    return await UserCalls.createContract(plan);
  }

  async getLabels() {
    return await UserCalls.getLabels();
  }

  async getLabelList() {
    return await UserCalls.getLabelList();
  }

  async getTotalLabels() {
    return await UserCalls.getTotalLabels();
  }

  async deleteLabel(label) {
    return await UserCalls.deleteLabel(label);
  }

  async deleteImageLabel(url) {
    const fileName = url.substring(url.lastIndexOf("/") + 1);
    const uploadS3 = new uploadS3Calls("labels", fileName);
    const deleted = await uploadS3.deleteFile();
    return !!deleted;
  }

  async addLabel(label, user) {
    const body = { label, user };
    return await UserCalls.addLabel(body);
  }

  async updateLabel(label) {
    const body = { label };
    return await UserCalls.updateLabel(body);
  }

  async getTotalArtists() {
    return await UserCalls.getTotalArtists();
  }

  async getReleases() {
    return await UserCalls.getReleases();
  }

  async changePassword(data) {
    return await UserCalls.changePassword(data);
  }

  async updateProfile(profile) {
    return await UserCalls.updateProfile(profile);
  }

  async uploadProfileAvatar(image) {
    const ext = image.name.split(".")[1];
    const fileName = uuidv4() + "." + ext;
    const uploadS3 = new uploadS3Calls("users", fileName);
    const url = await uploadS3.createSignedUrl(image);

    const uploaded = await uploadS3.uploadFile(url, image, null);
    return uploaded.url || null;
  }

  async getMembership() {
    const membership = await UserCalls.getMembership();
    if (membership.status === 200) {
      return membership.body.membership;
    }
    return null;
  }

  async getUserDetails(user) {
    const data = await UserCalls.getUserDetails(user);
    if (data.status === 200) {
      return data.body;
    }
    return { error: true, status: data.status };
  }

  async getMembershipById(user) {
    const membership = await UserCalls.getMembershipById(user);
    if (membership.status === 200) {
      return membership.body.membership;
    }
    return null;
  }

  async getUserArtists(user) {
    const data = await UserCalls.getUserArtists(user);
    if (data.status === 200) {
      return data.body;
    }
    return { error: true, status: data.status };
  }

  async getUserLabels(user) {
    const data = await UserCalls.getUserLabels(user);
    if (data.status === 200) {
      return data.body;
    }
    return { error: true, status: data.status };
  }

  async userUpdate(userId, user, prevData) {
    return await UserCalls.userUpdate(userId, user, prevData);
  }

  async verifyPassword(pass) {
    return await UserCalls.verifyPassword(pass);
  }

  async getPayee() {
    return await UserCalls.getPayee();
  }

  async getPayeeBankInfo(pass) {
    return await UserCalls.getPayeeBankInfo(pass);
  }

  async updatePayee(payee) {
    return await UserCalls.updatePayee(payee);
  }

  async getPayeeCompleted() {
    return await UserCalls.getPayeeCompleted();
  }
}

export default new User();
