import React, { useState, useContext, useEffect } from "react";
import { Table, message, Tabs } from "antd";
import { DndProvider } from "react-dnd";
import { PlusOutlined } from "@ant-design/icons";
import { HTML5Backend } from "react-dnd-html5-backend";
import { LanguageContext } from "../../context/Language";
import validator from "validator";
import UpcCalls from "../../classes/upc/upc";
import IsrcCalls from "../../classes/isrc/isrc";
import UpcAdd from "../../components/Upc/UpcAdd";
import UpcDelete from "../../components/Upc/UpcDelete";
import CrossIcon from "../../components/Icon/CrossIcon";
import "./upc.css";

const Upc = () => {
  const { dictionary } = useContext(LanguageContext);
  const [upcErr, setUpcErr] = useState(false);
  const [isrcErr, setIsrcErr] = useState(false);
  const [addVisible, modalAdd] = useState(false);
  const [dataSource, setTableData] = useState([]);
  const [isrcSource, setTableDataISRC] = useState([]);
  const [currentCode, setCurrentCode] = useState("");
  const [totalUpcs, setTotalUpcs] = useState(null);
  const [totalIsrcs, setTotalIsrcs] = useState(0);
  const [deleteVisible, modalDelete] = useState(false);
  const [typeCode, setTypeCode] = useState("upc");
  const regexUpc = /^[0-9, ]*$/;
  const isrcFieldValidation = /^[A-Za-z0-9\s,-]*$/;

  useEffect(() => {
    getAvailableUpcs();
    getAvailableIsrcs();
    // eslint-disable-next-line
  }, []);

  const getAvailableUpcs = async () => {
    const upcs = await UpcCalls.getAvailable().then((res) => {
      let allupc = res.body.upcs || [];
      let options = [];
      allupc.forEach((key) => {
        options.push({
          value: key._id,
          label: key._id,
        });
      });
      return options;
    });

    let upcArray = [];
    upcs.map((el, index) => {
      return upcArray.push({
        index: index,
        key: el.value || index,
        id: index + 1,
        title: el.value,
        details: (
          <div className="track-details">
            <CrossIcon onClick={() => showDelete(el.value)} />
          </div>
        ),
      });
    });
    setTableData(upcArray);
    setTotalUpcs(upcArray.length);
  };

  const getAvailableIsrcs = async () => {
    const getIsrc = await IsrcCalls.getAvailableIsr();
    const allisrc = getIsrc.body.isrcs || [];
    let isrcArray = [];
    allisrc.map((el, index) => {
      return isrcArray.push({
        index: index,
        key: el._id || index,
        id: index + 1,
        title: el._id,
        details: (
          <div className="track-details">
            <CrossIcon onClick={() => showDelete(el._id)} />
          </div>
        ),
      });
    });
    setTableDataISRC(isrcArray);
    setTotalIsrcs(isrcArray.length);
  };

  const columns = [
    {
      title: dictionary.code,
      dataIndex: "title",
      className: "pad-code",
    },
    {
      title: dictionary.delete,
      dataIndex: "details",
    },
  ];

  const changeHandler = (e) => {
    setUpcErr(false);
    setIsrcErr(false);
    if (typeCode === "upc") {
      if (regexUpc.test(e.target.value)) {
        const value = e.target.value;
        setCurrentCode(value);
      } else {
        setUpcErr(dictionary.upcErr);
      }
    } else {
      if (isrcFieldValidation.test(e.target.value)) {
        const value = e.target.value;
        setCurrentCode(value);
      } else {
        setIsrcErr(dictionary.isrcErr);
      }
    }
  };

  const showAdd = () => {
    setCurrentCode("");
    setUpcErr(false);
    modalAdd(true);
  };

  const showDelete = (e) => {
    setCurrentCode(e);
    modalDelete(true);
  };

  const handleCancel = () => {
    modalAdd(false);
    modalDelete(false);
  };

  const validateUpc = () => {
    setUpcErr(false);
    if (validator.isLength(currentCode, 0, 0)) {
      setUpcErr(dictionary.emptyField);
      return false;
    } else {
      const cleanedValues = currentCode.replace(/\s/g, "").split(",");
      const cleanArray = currentCode.replace(/\s/g, "");
      let arrayErrors = [];
      cleanedValues.forEach((value) => {
        if (value.length < 12 || !validator.isNumeric(value)) {
          setUpcErr(dictionary.upcErr2);
          arrayErrors.push(false);
          return false;
        }
      });
      if (!arrayErrors.includes(false)) {
        return cleanArray;
      } else {
        return false;
      }
    }
  };

  const validateISRC = (isrc) => {
    const regex = /^[a-zA-Z]{2}[a-zA-Z0-9]{3}[0-9]{2}[0-9a-zA-Z]{5}$/;
    setIsrcErr(false);
    if (validator.isLength(currentCode, 0, 0)) {
      setUpcErr(dictionary.emptyField);
      return false;
    } else {
      let isrcClean = isrc.replace(/[\s-]/g, "").split(",");
      const cleanArray = currentCode.replace(/[\s-]/g, "");
      let arrayErrors = [];
      isrcClean.forEach((value) => {
        if (value.length < 12) {
          setUpcErr(dictionary.isrcErr2);
          arrayErrors.push(false);
          return false;
        } else if (!regex.test(value)) {
          setUpcErr(dictionary.isrcError);
          arrayErrors.push(false);
          return false;
        } else {
          return true;
        }
      });
      if (!arrayErrors.includes(false)) {
        return cleanArray;
      } else {
        return false;
      }
    }
  };

  const selectCode = (e) => {
    setTypeCode(e);
  };

  const submitCode = async () => {
    if (typeCode === "isrc") {
      let isrcClean = validateISRC(currentCode);
      if (isrcClean) {
        let added = await IsrcCalls.addIsrc({ isrc: isrcClean });
        if (added.body.saved >= 1) {
          getAvailableIsrcs();
          modalAdd(false);
          successAdd();
        } else if (added.body.saved === 0) {
          setIsrcErr(dictionary.isrcAlreadyUse);
          return false;
        }
      }
    } else {
      let upcsClean = validateUpc();
      if (upcsClean) {
        let added = await UpcCalls.addUpc({ upc: upcsClean });
        if (added.body.saved >= 1) {
          getAvailableUpcs();
          modalAdd(false);
          successAdd();
        } else if (added.body.saved === 0) {
          setUpcErr(dictionary.upcAlreadyUse);
          return false;
        }
      }
    }
  };

  const eraseCode = () => {
    if (typeCode === "isrc") {
      IsrcCalls.deleteIsrc(currentCode).then((res) => {
        if (res.body.deleted) {
          getAvailableIsrcs();
          modalDelete(false);
          successDelete();
        } else {
          errorMsg();
        }
      });
    } else {
      UpcCalls.deleteUpc(currentCode).then((res) => {
        if (res.body.deleted) {
          getAvailableUpcs();
          modalDelete(false);
          successDelete();
        } else {
          errorMsg();
        }
      });
    }
  };

  const addBtn = () => {
    return (
      <div className="add-track upcadd general-btn" onClick={() => showAdd()}>
        <PlusOutlined /> &nbsp; {dictionary.addNewCode}
      </div>
    );
  };

  const successAdd = () => {
    message.success(dictionary.codeAdded);
  };

  const successDelete = () => {
    message.success(dictionary.codeDeleted);
  };

  const errorMsg = () => {
    message.error(dictionary.errorGeneral);
  };

  const items = [
    {
      key: "upc",
      label: dictionary.upcCodes,
      children: (
        <React.Fragment>
          {addBtn()}
          <div className="tracks upcs">
            <DndProvider backend={HTML5Backend}>
              <Table
                rowKey="index"
                columns={columns}
                pagination={false}
                dataSource={dataSource}
                className="table-tracks"
              />
            </DndProvider>
            <div className="align-right save-upc">
              <div className="available">
                {totalUpcs} {dictionary.upcAvailable}
              </div>
            </div>
          </div>
        </React.Fragment>
      ),
    },
    {
      key: "0",
      label: "|",
      children: null,
      disabled: true,
    },
    {
      key: "isrc",
      label: dictionary.isrc,
      children: (
        <React.Fragment>
          {addBtn()}
          <div className="tracks upcs">
            <DndProvider backend={HTML5Backend}>
              <Table
                rowKey="index"
                columns={columns}
                pagination={false}
                dataSource={isrcSource}
                className="table-tracks"
              />
            </DndProvider>
            <div className="align-right save-upc">
              <div className="available">
                {totalIsrcs} {dictionary.isrcAvailable}
              </div>
            </div>
          </div>
        </React.Fragment>
      ),
    },
  ];

  return (
    <React.Fragment>
      <div className="container-box dashboard ">
        <Tabs
          defaultActiveKey="1"
          centered
          className="tabs-top tab-codes"
          items={items}
          onChange={(e) => setTypeCode(e)}
        />
      </div>
      {addVisible && (
        <UpcAdd
          upcErr={upcErr}
          isrcErr={isrcErr}
          typeCode={typeCode}
          submitCode={submitCode}
          addVisible={addVisible}
          currentCode={currentCode}
          selectCode={selectCode}
          handleCancel={handleCancel}
          setCurrentCode={setCurrentCode}
          changeHandler={changeHandler}
        />
      )}
      <UpcDelete
        eraseCode={eraseCode}
        type={typeCode}
        currentCode={currentCode}
        handleCancel={handleCancel}
        deleteVisible={deleteVisible}
      />
    </React.Fragment>
  );
};

export default Upc;
