import React, { useContext} from "react";
import { LanguageContext } from "../../../context/Language";
import Modal from "../../Modal";
import Button from "../../Buttons/GeneralButton";

const ModalSavePayee = ({payeeData, hideModalPayee, updatePayeeId}) => {

  const { dictionary } = useContext(LanguageContext);

  return (
    <Modal
      destroyOnClose
      maskClosable={false}
      idModal="softDelete"
      isModalVisible={payeeData.show}
      handleCancel={hideModalPayee}
      addclassName="modalConfirmContract"
      title={<div className="modal-title">{dictionary.changePayee}</div>}
      body={
        <div>
          {dictionary.changePayeeDesc.replace(
            "--label--",
            payeeData.name
          )}
        </div>
      }
      footer={
        <div className="modalAdd-btns">
          <Button
            key="cancel"
            size="sm"
            className="back-btn"
            text={dictionary.cancel}
            onClick={hideModalPayee}
          />
          <Button
            key="submit"
            size="md"
            text={dictionary.confirm}
            className="footer-btn"
            onClick={() => updatePayeeId(payeeData.id)}
          />
        </div>
      }
    />
  );
};

export default ModalSavePayee;
