import BaseClass from "../";

class UserCalls extends BaseClass {
  logout = () => {
    this.setValues("/user/logout");
    return this.doGet();
  };

  request_verify = () => {
    this.setValues("/user/request_verify");
    return this.doGet();
  };

  verify = (token) => {
    this.setValues("/user/request_verify/" + token);
    return this.doGet();
  };

  resendVerify = () => {
    this.setValues("/user/resendVerify");
    return this.doGet();
  };

  auth = () => {
    this.setValues("/user/auth/");
    return this.doGet();
  };

  getArtists() {
    this.setValues("/artist/get/many/");
    return this.doGet();
  }

  createArtist(data) {
    this.setValues("/artist/create");
    return this.doPost(data, {
      artist: {
        label_id: data.label_id,
        name: data.name,
        description: data.description,
        is_featured: data.is_featured,
      },
    });
  }

  async createContract(plan) {
    this.setValues("/user/create/contract", { plan });
    const res = await this.doPost();
    return res.status === 200 && res.body;
  }

  getLabels() {
    this.setValues("/user/labels/many");
    return this.doGet();
  }

  getLabelList() {
    this.setValues("/user/labels/list");
    return this.doGet();
  }

  getTotalLabels() {
    this.setValues("/user/labels/total");
    return this.doGet();
  }

  deleteLabel(label) {
    this.setValues("/label/delete/" + label);
    return this.doDelete();
  }

  addLabel(body) {
    this.setValues("/label/create", body);
    return this.doPost();
  }

  updateLabel(body) {
    this.setValues("/label/update", body);
    return this.doPatch();
  }

  getTotalArtists() {
    this.setValues("/user/artists/total");
    return this.doGet();
  }

  getReleases() {
    this.setValues("/user/releases/list");
    return this.doGet();
  }

  getMembership() {
    this.setValues("/user/profile/membership");
    return this.doGet();
  }

  changePassword = (data) => {
    this.setValues("/user/password", {
      password_old: data.password_old,
      password_new: {
        new: data.password_new,
        confirm: data.confirm,
      },
    });
    return this.doPut();
  };

  updateProfile = (data) => {
    const profile = {
      name: data.name,
      surname: data.last,
      company_name: data.companyName,
      phone: {
        country: data.ladaCountry,
        code: data.ladaCode.toString(),
        number: data.phone,
      },
      address: {
        address: data.address,
        city: data.city,
        state: data.state,
        country: data.country,
        zip: data.zipCode,
      },
    };
    if (data.website) {
      profile.website = data.website;
    }
    if (data.image) {
      profile.image = data.image;
    }
    this.setValues("/user/profile/", { profile });
    return this.doPut();
  };

  getUserDetails(user) {
    this.setValues("/user/details/" + user);
    return this.doGet();
  }

  getMembershipById(user) {
    this.setValues("/user/profile/membership/" + user);
    return this.doGet();
  }

  getUserArtists(user) {
    this.setValues("/user/userAtists/" + user);
    return this.doGet();
  }

  getUserLabels(user) {
    this.setValues("/user/userLabels/" + user);
    return this.doGet();
  }

  userUpdate = (userId, data, prevData) => {
    const user = {
      userId: userId,
      profile: {
        address: {
          address: data.address ?? prevData.profile.address.address,
          city: data.city ?? prevData.profile.address.city,
          state: data.state ?? prevData.profile.address.state,
          country: data.country ?? prevData.profile.address.country,
          zip: data.zip ?? prevData.profile.address.zip,
        },
        company_name: data.company_name ?? prevData.profile.company_name,
        image: prevData.profile.image,
        name: data.name ?? prevData.profile.name,
        surname: data.surname ?? prevData.profile.surname,
        phone: {
          country: data.ladaCountry ?? prevData.profile.phone.country,
          code: data?.code?.toString() ?? prevData.profile.phone.code,
          number: data.number ?? prevData.profile.phone.number,
        },
        website: data.website ?? prevData.profile.website,
      },
      email: data.email ?? prevData.email,
      verified: data.verified ?? prevData.verified,
    };
    this.setValues("/user/updateUser/", { user });
    return this.doPut();
  };

  async verifyPassword(password) {
    this.setValues("/user/password/verify", { password });
    return this.doPost();
  }

  async getPayee() {
    this.setValues("/user/profile/payee");
    return this.doGet();
  }

  async getPayeeBankInfo(password) {
    this.setValues("/user/profile/payee/bank", { password });
    return this.doPost();
  }

  async updatePayee(payee) {
    this.setValues("/user/profile/payee", payee);
    return this.doPatch();
  }

  async getPayeeCompleted() {
    this.setValues("/user/payee/completed");
    return this.doGet();
  }
}

export default new UserCalls();
