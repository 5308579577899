import React from "react";
import { Input } from "antd";
import "../form.css";

const GeneralInput = ({
  name = "",
  refs = null,
  icon = null,
  placeholder,
  label = null,
  value = undefined,
  error = false,
  required = false,
  onKeyUp = null,
  onChange = null,
  maxLength = 1300,
  showCount = false,
  className = null,
  disabled = false,
  min = undefined,
  type = "text",
}) => {
  return (
    <div className="relative-input">
      {label ? (
        <label className={"allWidth"}>
          {label}
          <span>{required ? "*" : ""}</span>
        </label>
      ) : null}
      <Input
        type={type}
        autoComplete="off"
        ref={refs}
        name={name}
        prefix={icon}
        value={value}
        onKeyUp={onKeyUp}
        showCount={showCount}
        onChange={onChange}
        maxLength={maxLength}
        placeholder={placeholder}
        className={
          "input-general " + className + (error ? " input-general-error" : "")
        }
        disabled={disabled}
        min={min}
      />
      {error ? <div className="text-error">{error}</div> : null}
    </div>
  );
};

export default GeneralInput;
