import React, { useContext, useState, useEffect } from "react";
import { LanguageContext } from "../../../../context/Language";
import { Row, Col, Upload, Checkbox, Divider, Button } from "antd";
import {
  ArrowLeftOutlined,
  PlusOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import validator from "validator";
import Input from "../../../Form/Input";
import Date from "../../../Form/Date";
import Radio from "../../../Form/Radio";
// Nice tip, alias a default export
import { default as ThemeButton } from "../../../Buttons/GeneralButton";
import Release from "../../../../classes/release/release";
import AdvanceInfo from "./AdvanceInfo";
import Loader from "../../../Loader";
import { beforeUploadImage } from "../../../../hooks/files/files";
import moment from "moment";
import GenreSubGenreSelect from "../../../Form/GenreSubGenreSelect";
import Broken from "../../../Icon/broken.png";

const Information = ({
  state,
  setInput,
  setValid,
  backSection,
  nextSection,
  validateStep,
  changeSection,
  imageUrl,
  setImage,
  createRelease,
}) => {
  const { dictionary } = useContext(LanguageContext);
  const [enableParental, setEnableParental] = useState(
    state.parental !== "None"
  );
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showAdvanceInfo, setShowAdvanceInfo] = useState(false);
  const [generalError, setGeneralError] = useState(null);
  const [file, setFile] = useState(null);
  const [errors, setError] = useState({
    relNameErr: false,
    versionErr: false,
    relDateErr: false,
    genreErr: false,
    orgRelDateErr: false,
    metadataErr: false,
    image: false,
    upcErr: false,
  });

  useEffect(() => {
    validateStep(next);
    // eslint-disable-next-line
  }, [state.relName, state.relDate, state.version, state.subgenres]);

  useEffect(() => {
    if (state.parental !== "None") setEnableParental(true);

    // eslint-disable-next-line
  }, [state.parental]);

  const parentalRadio = [
    { value: "Clean", name: dictionary.clean },
    { value: "Explicit", name: dictionary.explicit },
  ];

  const changeEnableParental = (e) => {
    const isChecked = e.target.checked;
    const value = isChecked ? "Clean" : "None";

    setEnableParental(isChecked);
    setInput({
      ...state,
      parental: value,
    });
  };

  const changeRadio = (e) => {
    setInput({
      ...state,
      parental: e.target.value,
    });
  };

  const changeHandler = (e) => {
    if (e.target.name === "relName") {
      setError({
        ...errors,
        relNameErr: false,
      });
    }
    setInput({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelect = (e, name) => {
    setError({
      ...errors,
      genreErr: false,
    });
    setInput({
      ...state,
      [name]: e,
    });
  };

  const onChangeRelDate = (date, dateString) => {
    setError({
      ...errors,
      relDateErr: false,
    });
    setInput({
      ...state,
      relDate: dateString,
    });
  };

  const onChangeOriginalRelDate = (date, dateString) => {
    setInput({
      ...state,
      orgRelDate: dateString,
    });
  };

  const handleCoverSelector = async ({ file }) => {
    setLoading(true);
    try {
      setImage(null);
      setFile(null);
      setError({
        ...errors,
        image: false,
      });
      const url = URL.createObjectURL(file.originFileObj || file);
      if (url) {
        setFile(file);
        setImage(url);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 700);
    }
  };

  const previewImg = () => {
    document.getElementById("imgRelease").src = Broken;
  };

  const uploadImage = async (id) => {
    if (!file) {
      return { ...state, _id: id };
    }

    try {
      setError(prev => ({
        ...prev,
        image: false,
      }));

      const asset = await Release.uploadCover(file.originFileObj || file, id);
      
      if (!asset.url) {
        return { ...state, _id: id, asset: [] };
      }

      return {
        ...state,
        _id: id,
        asset: [{
          ...asset.asset,
          type: "image"
        }]
      };

    } catch (error) {
      console.error('Error uploading image:', error);
      return { ...state, _id: id, asset: [] };
    }
  };

  const validate = () => {
    const newErrors = {
      relNameErr: false,
      versionErr: false,
      relDateErr: false,
      genreErr: false,
      orgRelDateErr: false,
      metadataErr: false,
      image: false,
      upcErr: false,
    };

    let hasErrors = false;

    if (!state.relName) {
      newErrors.relNameErr = dictionary.emptyField;
      hasErrors = true;
    }

    if (!state.relDate) {
      newErrors.relDateErr = dictionary.emptyField;
      hasErrors = true;
    }

    if (!state.subgenres.length) {
      newErrors.genreErr = dictionary.selectErr;
      hasErrors = true;
    }

    if (!state.metadata) {
      newErrors.metadataErr = dictionary.selectErr;
      hasErrors = true;
      window.scrollTo(0, document.body.scrollHeight);
    }

    if (!imageUrl) {
      newErrors.image = true;
      hasErrors = true;
      window.scrollTo(0, 150);
    }

    if (!state.isNew) {
      if (!state.orgRelDate) {
        newErrors.orgRelDateErr = dictionary.emptyField;
        hasErrors = true;
        window.scrollTo(0, document.body.scrollHeight);
      }
      if (state.upc.length !== 12 || !validator.isNumeric(state.upc)) {
        newErrors.upcErr = dictionary.upcErr;
        hasErrors = true;
      }
    }

    setError(newErrors);
    return !hasErrors;
  };

  const back = () => {
    changeSection(0);
    backSection(1);
    window.scrollTo(0, 0);
  };

  const next = async (n = null) => {
    try {
      const isValid = validate();
      if (!isValid) return;

      setLoader(true);
      setGeneralError(null);

      // Creamos el release
      const created = await createRelease();
      if (!created || created.error) {
        setGeneralError(dictionary[created.error] || dictionary.releaseCheckData);
        return;
      }

      // Subimos la imagen y obtenemos el nuevo estado con el asset
      const stateWithImage = await uploadImage(created.id);
      if (!stateWithImage.asset?.length) {
        setGeneralError(dictionary.releaseCheckData);
        return;
      }

      // Guardamos la información del release con la imagen
      const saved = await Release.setInfoRelease(stateWithImage);
      if (saved.error) {
        setGeneralError(dictionary[saved.error] || dictionary.releaseCheckData);
        return;
      }

      // Actualizamos primero el estado y esperamos a que se complete
      await new Promise(resolve => {
        setInput(prevState => {
          const newState = {
            ...prevState,
            ...stateWithImage,
          };
          resolve(newState);
          return newState;
        });
      });

      // Marcamos el paso como válido y esperamos
      await new Promise(resolve => {
        setValid(prevValid => {
          const newValid = {
            ...prevValid,
            step1: true,
          };
          resolve(newValid);
          return newValid;
        });
      });

      // Esperamos un tick adicional para asegurar que React haya procesado los cambios de estado
      await new Promise(resolve => setTimeout(resolve, 0));

      // Solo después de que todo esté actualizado, navegamos al siguiente paso
      if (n !== null) {
        // Cambiamos la sección y esperamos a que se complete
        await changeSection(n);
        // Actualizamos la barra de progreso
        await nextSection(n);
        // Finalmente hacemos scroll
        window.scrollTo(0, 0);
      }

    } catch (error) {
      console.error('Error in next:', error);
      setGeneralError(dictionary.releaseCheckData);
    } finally {
      setLoader(false);
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
          color: "#8c8c8c",
        }}
      >
        {dictionary.upload}
      </div>
    </div>
  );

  return (
    <div className="information">
      <div className="forms">
        <Row className="gutter-row">
          <Col xs={24} sm={24} md={12} lg={12} className="col1">
            <Input
              name="relName"
              required={true}
              value={state.relName}
              onChange={changeHandler}
              error={errors.relNameErr}
              label={dictionary.releaseName}
              className={errors.relNameErr ? "inputErr " : ""}
            />
            <Input
              name="version"
              value={state.version}
              onChange={changeHandler}
              error={errors.versionErr}
              label={dictionary.version}
              placeholder={dictionary.exVersion}
              className={errors.versionErr ? "inputErr " : ""}
            />
            <Date
              required={true}
              value={state.relDate}
              error={errors.relDateErr}
              onChange={onChangeRelDate}
              label={dictionary.releaseDate}
              placeholder={dictionary.selectDate}
              disabledDate={(current) => {
                return current < moment().endOf("day");
              }}
              className={errors.relDateErr ? "inputErr " : ""}
            />
            {!state.isNew ? (
              <React.Fragment>
                <Date
                  required={true}
                  value={state.orgRelDate}
                  error={errors.orgRelDateErr}
                  label={dictionary.originalRelDate}
                  onChange={onChangeOriginalRelDate}
                  placeholder={dictionary.selectDate}
                  disabledDate={(current) => {
                    return current && current > moment().endOf("day");
                  }}
                  className={errors.orgRelDateErr ? "inputErr " : ""}
                />
                <Input
                  name="upc"
                  label="UPC"
                  maxLength={12}
                  required={true}
                  value={state.upc}
                  error={errors.upcErr}
                  onChange={changeHandler}
                  placeholder={"############"}
                  className={errors.upcErr ? "inputErr " : ""}
                />
              </React.Fragment>
            ) : null}
            <Checkbox
              checked={enableParental}
              className="check-terms"
              onChange={changeEnableParental}
            >
              {dictionary.parentalAdvisory}
              {enableParental ? (
                <Radio
                  required={true}
                  onChange={changeRadio}
                  value={state.parental}
                  options={parentalRadio}
                  className="radio-options"
                />
              ) : null}
            </Checkbox>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col2">
            <label className="allWidth">{dictionary.albumCover}*</label>
            <Upload
              // Disable default upload behavior(the initial request)
              customRequest={() => {}}
              name="avatar"
              showUploadList={false}
              onChange={handleCoverSelector}
              accept={"image/*"}
              beforeUpload={(file) => beforeUploadImage(file, dictionary)}
              maxCount={1}
              listType="picture-card"
              className={(errors.image ? "imageErr " : "") + " avatar-uploader"}
            >
              {!loading && imageUrl ? (
                <img
                  alt="avatar"
                  key={imageUrl}
                  src={imageUrl}
                  id="imgRelease"
                  style={{ width: "100%", height: "inherit" }}
                  onError={(e) => previewImg(e)}
                />
              ) : (
                uploadButton
              )}
            </Upload>
            <div className="cover-desc">{dictionary.coverDesc}</div>
            <div
              style={{
                paddingTop: 5,
              }}
            >
              <GenreSubGenreSelect
                title={dictionary.genre}
                name="genre"
                required={true}
                value={state.subgenres}
                error={errors.genreErr || ""}
                onChange={(e) => handleSelect(e, "subgenres")}
              />
            </div>
          </Col>
          {generalError ? (
            <div className="error-field">{generalError}</div>
          ) : null}

          {loader ? <Loader /> : null}
        </Row>
        <Divider>
          <Button
            className="advanced-btn"
            onClick={() => setShowAdvanceInfo(!showAdvanceInfo)}
            // icon={showAdvanceInfo ? <UpOutlined /> : <DownOutlined />}
          >
            {dictionary.advancedOptions}
          </Button>
        </Divider>
        {showAdvanceInfo ? (
          <AdvanceInfo state={state} setInput={setInput} errors={errors} />
        ) : null}
        <div className="align-right reg-btn">
          <ThemeButton
            size="md"
            onClick={back}
            className="back-btn"
            text={dictionary.back}
            icon={<ArrowLeftOutlined />}
          />
          <ThemeButton
            size="md"
            onClick={() => next(2)}
            text={dictionary.next}
          />
        </div>
      </div>
    </div>
  );
};

export default Information;
