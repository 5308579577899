import React, { useContext, useEffect } from "react";
import Input from "../../../../../Form/Input/GeneralInput";
import { useFieldArray, useFormContext, Controller } from "react-hook-form";
import { Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import CreateCostsEscalationCondition from "./Condition";
import { AppContext } from "../../../../../../context/App";

const CreateCostsEscalation = ({ parentIndex, index, remove }) => {
  const formMethods = useFormContext();
  const { openedContract, setOpenedContract } = useContext(AppContext);
  const { control } = formMethods;

  const {
    fields: costsEscalationCondition,
    append: appendCostsEscalationCondition,
    remove: removeCostsEscalationCondition,
    replace: replaceCostsEscalationCondition,
  } = useFieldArray({
    name: `costsTerms-escalation_${parentIndex}.${index}.conditions`,
  });

  useEffect(() => {
    if (
      openedContract &&
      openedContract[`costsTerms-escalation-conditions_${parentIndex}-${index}`]
    ) {
      replaceCostsEscalationCondition(
        openedContract[
          `costsTerms-escalation-conditions_${parentIndex}-${index}`
        ]
      );
      delete openedContract[
        `costsTerms-escalation-conditions_${parentIndex}-${index}`
      ];
      setOpenedContract({
        ...openedContract,
      });
    }
  }, [openedContract]);

  const addCostsEscalationConditions = () => {
    appendCostsEscalationCondition(
      {
        escalationTracker: "",
        thresholdType: "",
        when: "",
        threshold: "",
      },
      {
        shouldFocus: false,
      }
    );
  };

  useEffect(() => {
    addCostsEscalationConditions();
  }, [appendCostsEscalationCondition]);

  const drawConditions = () => {
    return costsEscalationCondition.map((st, conditionIndex) => {
      return (
        <CreateCostsEscalationCondition
          key={`costs-escalation-condition-${index}`}
          costsIndex={parentIndex}
          parentIndex={index}
          index={conditionIndex}
          addItem={
            conditionIndex >= costsEscalationCondition.length - 1
              ? addCostsEscalationConditions
              : null
          }
          remove={
            conditionIndex < costsEscalationCondition.length - 1
              ? () => removeCostsEscalationCondition(conditionIndex)
              : null
          }
          data={st}
        />
      );
    });
  };

  return (
    <div className="input-row-group input-row-subgroup">
      {drawConditions()}
      <div className="input-row">
        <Controller
          control={control}
          name={`costsTerms-escalation_${parentIndex}.${index}.rate`}
          rules={{
            required: "This field is required",
            min: "Field must be grather or equal than 0",
          }}
          render={({ field, fieldState }) => (
            <Input
              className={"form-box-input"}
              label={"Rate %"}
              placeholder={"Rate %"}
              type="number"
              min={0}
              {...field}
              error={fieldState.error?.message}
            />
          )}
        />
        <Button
          className="remove"
          type="danger"
          onClick={() => remove()}
          icon={<DeleteOutlined />}
        />
      </div>
    </div>
  );
};

export default CreateCostsEscalation;
