import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../../../context/Language";
import Button from "../../../Buttons/GeneralButton";
import SuccessIcon from "../../../Icon/SuccessIcon";
import RollbackOnSuccess from "./rollbackOnSuccess";

const Success = ({ releaseId }) => {
  const { dictionary } = useContext(LanguageContext);

  return (
    <div className="success">
      <h2>{dictionary.createSuccess}</h2>
      <RollbackOnSuccess releaseId={releaseId} />
      <div className="success-desc">{dictionary.createSuccessDesc}.</div>
      <div className="align-right">
        <SuccessIcon className="img-sc" />
        <Link to="/release">
          <Button text={dictionary.releases} size="md" />
        </Link>
      </div>
    </div>
  );
};

export default Success;
