import React, { useState, useEffect } from "react";

function RegressiveTimer({ initialSeconds = 120, onTimeOut = () => {} }) {
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    const timerId = setInterval(() => {
      if (seconds > 0) {
        setSeconds((prevSeconds) => prevSeconds - 1);
      } else {
        clearInterval(timerId);
        onTimeOut();
      }
    }, 1000);

    return () => clearInterval(timerId);
  }, [seconds]);

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  return (
    <div>
      {minutes > 0 ? `${minutes}:` : ""}
      {remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}
    </div>
  );
}

export default RegressiveTimer;
