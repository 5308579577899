import CountryCalls from "../calls/country/country.jsx";

class Country {
  constructor() {
    this.countriesList = {};
  }

  countries = async () => {
    this.countriesList = await CountryCalls.countries();
    return this.countriesList;
  };

  countries_region = async (region) => {
    return await CountryCalls.countries_region(region);
  };

  setPhoneCountries = () => {
    const countries = [...this.countriesList.body.countries];
    return countries.map((el) => {
      let country = {};
      const phoneCode = el._id + " +" + el.phone_code;
      country._id = el._id;
      country.label = phoneCode;
      country.name = phoneCode;
      country.phone_code = el.phone_code;
      country.region = el.region;
      return country;
    });
  };
}

export default new Country();
