import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../context/Language";
import ThanksIcon from "../../components/Icon/ThanksIcon";
import Button from "../../components/Buttons/GeneralButton";
import "./thanks.css";

const Thanks = () => {
  const { dictionary } = useContext(LanguageContext);

  return (
    <React.Fragment>
      <div className="container-box thanks-box">
        <div className="greeting">
          <h1>{dictionary.thanksFor}</h1>
          <h3>{dictionary.thanksMsg}</h3>
          <div className="greeting-body">
            <p>{dictionary.thanksDescTitle}</p>
            {dictionary.thanksDesc}
          </div>
          <Link to="/">
            <Button
              text={dictionary.thanksUse}
              size="lg"
              className="goHome-btn"
            />
          </Link>
          <div className="greet-icon">
            <ThanksIcon />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Thanks;
