import React, { useContext, useState } from "react";
import { notification, DatePicker, Typography, List, Tag, Spin } from "antd";
import { LanguageContext } from "../../../../../context/Language";
import Modal from "../../../../Modal";
import Button from "../../../../Buttons/GeneralButton";
import AdminCalls from "../../../../../classes/admin/admin";
import moment from "moment";

const ScheduleDeliveryActionModal = ({
  onConfirm,
  onCancel,
  isModalVisible,
  action,
  dsps,
  releaseAudioSaladIds,
}) => {
  let tagColor;
  switch (action) {
    case "add":
      tagColor = "blue";
      break;
    case "remove":
      tagColor = "red";
      break;
    default:
      tagColor = "green";
      break;
  }
  const { dictionary } = useContext(LanguageContext);
  const [isLoading, setIsLoading] = useState(false);
  const [newDate, setNewDate] = useState("");
  const onChange = (_, dateString) => {
    setNewDate(dateString);
  };

  const sendHandler = () => {
    setIsLoading(true);
    const dataToSend = {
      date: moment(newDate).utc().format("YYYY-MM-DDTHH:mm:ss"),
      dsps: dsps.map((dsp) => dsp.audiosalad_id),
      action,
      releaseIds: releaseAudioSaladIds ?? [],
    };
    AdminCalls.audioSaladScheduleDelivery(dataToSend)
      .then((rejectStatus) => {
        if (rejectStatus.status === 200) {
          notification.success({
            message: dictionary.success,
            duration: 5,
            key: "releaseDetailNotification",
            placement: "bottomRight",
          });
          onConfirm();
        } else {
          notification.warning({
            message: dictionary.stripeError,
            duration: 5,
            key: "releaseDetailNotification",
            placement: "bottomRight",
          });
        }
      })
      .catch(() => {
        notification.warning({
          message: dictionary.stripeError,
          duration: 5,
          key: "releaseDetailNotification",
          placement: "bottomRight",
        });
      })
      .finally(() => {
        setIsLoading(false);
        onCancel();
      });
  };

  return (
    <Modal
      idModal="release-schedule-dsp-modal"
      handleCancel={() => (!isLoading ? onCancel() : null)}
      isModalVisible={isModalVisible}
      title={<div className="modal-title">{dictionary.runDate}</div>}
      body={
        <div id="release-reject-modal-content">
          {isLoading ? (
            <Spin size="large" className="spin-request-store" />
          ) : null}
          <React.Fragment>
            <Typography.Title level={5}>
              {dictionary.audioSaladScheduleDeliveryConfirm}
            </Typography.Title>
            <DatePicker
              style={{ width: "100%" }}
              inputReadOnly
              showTime
              format={"D MMM, YYYY HH:mm"}
              defaultValue={moment(newDate?.date).local()}
              onChange={onChange}
            />
            <List
              size="small"
              header={<div>{dictionary.detail}</div>}
              dataSource={dsps}
              renderItem={(item) => (
                <List.Item>
                  <Tag color={tagColor}>
                    {dictionary.audioSaladDeliveryActions[action] || action}
                  </Tag>
                  <b>{item.name}</b>
                </List.Item>
              )}
            />
          </React.Fragment>
        </div>
      }
      footer={
        <div id="release-reject-modal-actions" className="modalAdd-btns">
          <Button
            key="cancel"
            className="back-btn"
            text={dictionary.cancel}
            onClick={onCancel}
          />
          <Button
            size="ms"
            onClick={() => sendHandler()}
            text={dictionary.send}
            disabled={isLoading}
          />
        </div>
      }
    />
  );
};

export default ScheduleDeliveryActionModal;
