import React, { useContext, useState, useEffect } from "react";
import { Row, Col } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { LanguageContext } from "../../../context/Language";
import "../../../containers/Register/register.css";
import Select from "../../Form/Select";
import Input from "../../Form/Input";
import Phone from "../../Form/Phone";
import Button from "../../Buttons/GeneralButton";
import Country from "../../../classes/country/country.jsx";
import validator from "validator";

const Details = ({ state, setKeys, setInput, changeSection }) => {

  const { dictionary } = useContext(LanguageContext);
  const [dataCountries, setData] = useState([]);
  const [phoneCountries, setPhoneCountries] = useState([]);
  const [errors, setError] = useState({
    company: false,
    address: false,
    city: false,
    zipCode: false,
    state: false,
    country: false,
    website: false,
    phone: false,
  });

  useEffect(() => {
    if (dataCountries.length === 0) getCountries();
  });

  const getCountries = () => {
    Country.countries().then((countriesArr) => {
      try {
        const countries = [...countriesArr.body.countries];
        let cleanCountries = countries.filter((el) => !(el._id === "WW"));
        setData([...cleanCountries]);
        const phoneCountriesCopy = cleanCountries
        .map((el) => {
          let country = {};
          const phoneCode = el._id + " +" + el.phone_code;
          country._id = el._id;
          country.label = phoneCode;
          country.name = phoneCode;
          country.phone_code = el.phone_code;
          country.region = el.region;
          return country;
        });
        setPhoneCountries([...phoneCountriesCopy]);
      } catch (e) {
        setData([]);
      }
    });
  };

  const countryChangeHandler = (e) => {
    setInput({
      ...state,
      country: e,
      lada: e,
      phoneCode: phoneCountries.find((el) => el._id === e).phone_code.toString(),
    });
  };

  const ladaChangeHandler = (e) => {
    const country = phoneCountries.find((el) => el._id === e);
    setInput({
      ...state,
      lada: e,
      phoneCode: country.phone_code.toString(),
    });
  };

  const inputChangeHandler = (e) => {
    try {
      const { name, value } = e.target;
      setInput({
        ...state,
        [name]: value,
      });
      validate(name, value);
    } catch (e) {
      console.log(e);
    }
  };

  const validate = (name = "", value = "") => {
    let error = false;
    let baseErrors = {
      company: false,
      address: false,
      city: false,
      zipCode: false,
      state: false,
      country: false,
      website: false,
      phone: false,
    };

    setError(baseErrors);

    if (name === "" || name === "company")
      if (validator.isLength(value || state.company, 0, 0)) {
        baseErrors.company = dictionary.emptyField;
        error = true;
      } else if (
        !validator.isAlphanumeric(value || state.company, ["es-ES"], {
          ignore: ".-s'&",
        })
      ) {
        baseErrors.company = dictionary.onlyLettersAndChars;
        error = true;
      }

    if (name === "" || name === "address")
      if (validator.isLength(value || state.address, 0, 0)) {
        baseErrors.address = dictionary.emptyField;
        error = true;
      } else if (
        !validator.isAlphanumeric(value || state.address, ["es-ES"], {
          ignore: ".-s'&,/",
        })
      ) {
        baseErrors.address = dictionary.onlyLettersAndChars;
        error = true;
      }

    if (name === "" || name === "city")
      if (validator.isLength(value || state.city, 0, 0)) {
        baseErrors.city = dictionary.emptyField;
        error = true;
      } else if (
        !validator.isAlpha(value || state.city, ["es-ES"], { ignore: "-s'" })
      ) {
        baseErrors.city = dictionary.onlyLetters;
        error = true;
      }

    if (name === "" || name === "zipCode")
      if (
        (value.length || state.zipCode.length) < 4
        //|| !validator.isPostalCode(value || state.zipCode, "any")
      ) {
        baseErrors.zipCode = dictionary.zipErr;
        error = true;
      }

    if (name === "" || name === "state")
      if (validator.isLength(value || state.state, 0, 0)) {
        baseErrors.state = dictionary.emptyField;
        error = true;
      } else if (
        !validator.isAlpha(value || state.state, ["es-ES"], { ignore: "-s'" })
      ) {
        baseErrors.state = dictionary.onlyLetters;
        error = true;
      }

    if (name === "" || name === "country")
      if (!validator.isAlpha(value.toString() || state.country.toString())) {
        baseErrors.country = dictionary.selectErr;
        error = true;
      }

    if (name === "" || name === "website")
      if ((value.length || state.website.length) > 0) {
        if (
          !validator.isURL(value || state.website, {
            protocols: ["http", "https"],
          })
        ) {
          baseErrors.website = dictionary.websiteErr;
          error = true;
        } else {
          if (state.website[state.website.length - 1] === "/")
            state.website = state.website.slice(0, -1);
        }
      }

    if (name === "" || name === "phone")
      if (validator.isLength(value || state.phone, 0, 0)) {
        baseErrors.phone = dictionary.emptyField;
        error = true;
      } else if (
        (value.length || state.phone.length) < 8 ||
        !validator.isMobilePhone(value || state.phone, "any")
      ) {
        baseErrors.phone = dictionary.phoneErr;
        error = true;
      }

    if(state.lada.length > 0 && !state.phoneCode){
      const country = phoneCountries.find((el) => el._id === state.lada);
      setInput({
        ...state,
        phoneCode: country.phone_code.toString(),
      });
    }
    if (error) setError(baseErrors);
    
    return !error;
  };

  const next = () => {
    if (validate()) {
      setKeys(state);
      changeSection(2);
    }
  };

  const back = () => {
    changeSection(0);
  };

  return (
    <div>
      <div className="forms">
        <Row className="gutter-row">
          <Col xs={24} sm={24} md={24} lg={24}>
            <Input
              name="company"
              required={true}
              value={state.company}
              onChange={inputChangeHandler}
              error={errors.company}
              label={
                state.type === "label"
                  ? dictionary.companyName
                  : dictionary.artistName
              }
              className={errors.company ? "inputErr" : ""}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Input
              name="address"
              required={true}
              value={state.address}
              onChange={inputChangeHandler}
              error={errors.address}
              label={dictionary.address}
              className={errors.address ? "inputErr" : ""}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col1">
            <Input
              name="city"
              required={true}
              value={state.city}
              onChange={inputChangeHandler}
              error={errors.city}
              label={dictionary.city}
              className={errors.city ? "inputErr" : ""}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col2">
            <label>{dictionary.zip}*</label>
            <Input
              name="zipCode"
              maxLength={10}
              required={true}
              value={state.zipCode}
              onChange={inputChangeHandler}
              error={errors.zipCode}
              className={(errors.zipCode ? "inputErr" : "") + " zipdiv"}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col1">
            <Input
              name="state"
              required={true}
              value={state.state}
              onChange={inputChangeHandler}
              error={errors.state}
              label={dictionary.state}
              className={errors.state ? "inputErr" : ""}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col2">
            <label>{dictionary.country}*</label>
            <Select
              name="country"
              showSearch={true}
              value={state.country}
              disableOpt={true}
              placeholder={dictionary.select}
              options={dataCountries}
              onChange={countryChangeHandler}
              style={{ width: "100%" }}
              className={
                (errors.country ? "countErr " : "") + " selector-general"
              }
            />
            {errors.country ? (
              <div className="text-error">{errors.country}</div>
            ) : null}
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col1">
            <Input
              name="website"
              value={state.website}
              onChange={inputChangeHandler}
              error={errors.website}
              label={dictionary.website}
              className={errors.website ? "inputErr" : ""}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col2">
            <Phone
              name="phone"
              required={true}
              maxLength={30}
              value={state.phone}
              onChange={inputChangeHandler}
              error={errors.phone}
              lada={state.lada}
              onChangeSelect={ladaChangeHandler}
              phoneCountries={phoneCountries}
              label={dictionary.phoneNumber}
              className={errors.phone ? "inputErr" : ""}
            />
          </Col>
        </Row>
      </div>
      <div className="align-right reg-btn">
        <Button
          text={dictionary.back}
          size="md"
          icon={<ArrowLeftOutlined />}
          className="back-btn"
          onClick={back}
        />
        <Button text={dictionary.next} size="md" onClick={next} />
      </div>
    </div>
  );
};

export default Details;
