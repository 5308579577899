import React from "react";

const TrashIcon = ({ className = null, onClick = null }) => {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M23.9166 17.0833L23.9166 29.0416"
        stroke="#FF6767"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0834 17.0833L17.0834 29.0416"
        stroke="#FF6767"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.25 10.25V9.25C9.69772 9.25 9.25 9.69772 9.25 10.25H10.25ZM30.75 10.25H31.75C31.75 9.69772 31.3023 9.25 30.75 9.25V10.25ZM10.25 11.25H30.75V9.25H10.25V11.25ZM29.75 10.25V34.1667H31.75V10.25H29.75ZM29.0417 34.875H11.9583V36.875H29.0417V34.875ZM11.25 34.1667V10.25H9.25V34.1667H11.25ZM11.9583 34.875C11.5671 34.875 11.25 34.5579 11.25 34.1667H9.25C9.25 35.6624 10.4626 36.875 11.9583 36.875V34.875ZM29.75 34.1667C29.75 34.5579 29.4329 34.875 29.0417 34.875V36.875C30.5374 36.875 31.75 35.6624 31.75 34.1667H29.75Z"
        fill="#FF6767"
      />
      <path
        d="M6.83337 10.25H34.1667"
        stroke="#FF6767"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.3333 10.25V11.25C27.8856 11.25 28.3333 10.8023 28.3333 10.25H27.3333ZM13.6666 10.25H12.6666C12.6666 10.8023 13.1143 11.25 13.6666 11.25V10.25ZM15.375 6.125H25.625V4.125H15.375V6.125ZM26.3333 6.83333V10.25H28.3333V6.83333H26.3333ZM27.3333 9.25H13.6666V11.25H27.3333V9.25ZM14.6666 10.25V6.83333H12.6666V10.25H14.6666ZM25.625 6.125C26.0162 6.125 26.3333 6.44213 26.3333 6.83333H28.3333C28.3333 5.33756 27.1207 4.125 25.625 4.125V6.125ZM15.375 4.125C13.8792 4.125 12.6666 5.33756 12.6666 6.83333H14.6666C14.6666 6.44213 14.9838 6.125 15.375 6.125V4.125Z"
        fill="#FF6767"
      />
    </svg>
  );
};

export default TrashIcon;
