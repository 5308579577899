import React, { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../../../context/Language";
import Modal from "../../../Modal";
import Button from "../../../Buttons/GeneralButton";
// import AddCard from "./AddCard";
import { Card, Space, Badge, Spin } from "antd";
import StripeCalls from "../../../../classes/calls/payment/stripe";
import { CreditCardOutlined } from "@ant-design/icons";
import "./paymentMethod.css";

const PaymentMethodSelector = ({ show, close, defaultPaymentId }) => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [defaultPayment, setDefaultPayment] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    if (show) retrievePaymentMethodList();
  }, [show]);

  const retrievePaymentMethodList = () => {
    StripeCalls.retrievePaymentMethodList()
      .then((res) => {
        if (res && res.length > 0) setPaymentMethods(res);

        if (error !== "") setError("");

        setLoading(false);
      })
      .catch(() => {
        setError(dictionary.stripeError);
        setLoading(false);
      });
  };

  const updateDefaultPaymentMethod = () => {
    setLoading(true);
    StripeCalls.updatePaymentMethod(defaultPayment)
      .then((res) => {
        if (res) retrievePaymentMethodList();
        else setError(dictionary.stripeError);
      })
      .catch(() => {
        setError(dictionary.stripeError);
        setLoading(false);
      });
  };

  const { dictionary } = useContext(LanguageContext);

  const cardStyles = { width: 330, marginTop: 15 };
  const cardIcon = <CreditCardOutlined style={{ fontSize: "25px" }} />;

  const drawCard = (item) => {
    return (
      <Card
        key={item.id}
        onClick={() => setDefaultPayment(item.id)}
        hoverable
        size={"small"}
        style={cardStyles}
      >
        <Card.Meta
          avatar={cardIcon}
          title={"XXXX XXXX XXXX " + item.last4}
          description={item.brand + " " + item.expiration}
        />
      </Card>
    );
  };

  const drawModalBody = () => {
    return error !== "" ? (
      <p>
        {error} <br />
        <a href="https://support.stripe.com" target="_blank" rel="noreferrer">
          https://support.stripe.com
        </a>
      </p>
    ) : (
      <Space direction="vertical">
        {loading && (
          <div>
            <Spin size="large" className="spinner" />
          </div>
        )}
        {!loading &&
          paymentMethods.map((item) => {
            if (
              defaultPayment === item.id &&
              defaultPaymentId !== defaultPayment
            )
              return (
                <Badge.Ribbon
                  key={item.id}
                  text={dictionary.selected}
                  color="var(--outrageouse-orange)"
                >
                  {drawCard(item)}
                </Badge.Ribbon>
              );

            if (defaultPaymentId !== "" && defaultPaymentId === item.id)
              return (
                <Badge.Ribbon
                  key={item.id}
                  text={dictionary.current}
                  color="var(--martinique)"
                >
                  {drawCard(item)}
                </Badge.Ribbon>
              );

            return drawCard(item);
          })}
      </Space>
    );
  };

  const drawModalAction = () => {
    return error !== "" ? (
      <div className="modalAdd-btns">
        <Button
          key="cancel"
          size="sm"
          className="back-btn"
          text={dictionary.cancel}
          onClick={close}
        />
      </div>
    ) : (
      <div className="modalAdd-btns">
        <Button
          key="cancel"
          size="sm"
          className="back-btn"
          text={dictionary.cancel}
          onClick={close}
        />
        <Button
          size="md"
          key="submit"
          className="footer-btn"
          disabled={
            defaultPayment === "" ||
            defaultPayment === defaultPaymentId ||
            paymentMethods.length === 0 ||
            loading
          }
          onClick={() => updateDefaultPaymentMethod()}
          text={dictionary.update}
        />
      </div>
    );
  };

  return (
    <Modal
      idModal="delete"
      className="add-card"
      handleCancel={close}
      isModalVisible={show}
      title={
        <div className="modal-title">{dictionary.updatePaymentMethod}</div>
      }
      body={drawModalBody()}
      footer={drawModalAction()}
    />
  );
};

export default PaymentMethodSelector;
