import React, { useContext, useState } from "react";
import { LanguageContext } from "../../../../context/Language";
import Button from "../../../Buttons/GeneralButton";
import CheckIcon from "../../../Icon/CheckIcon";

const Plus = ({ active, plusPlan, selectPlan }) => {

  const { dictionary } = useContext(LanguageContext);
  const [benefits] = useState([]);

  const renderBenefits = () => {
    if (benefits.length < 1) {
      for (var i = 1; i < 8; i++) {
        benefits.push(
          <li key={i}>
            <CheckIcon className="check-benefit" />
            {dictionary.plus.benefit[i]}
          </li>
        );
      }
    }
    return benefits;
  };

  return (
    <div
      ref={plusPlan}
      className={
        (active === "plus" ? "selectedPlan " : "") + " pricing-box plus"
      }
    >
      <h3>Priam Plus</h3>
      <div className="price">
        {dictionary.free}{" "}
        <div className="period">15% {dictionary.revenueShare}</div>
      </div>
      <p className="price-subtitle">{dictionary.plus.subtitle}</p>
      <ul>{renderBenefits()}</ul>
      <Button
        size="fullWidth"
        onClick={selectPlan}
        className="price-btn"
        text={dictionary.select}
      />
    </div>
  );
};

export default Plus;
