import React, { useContext } from "react";
import { LanguageContext } from "../../../../../context/Language";
import { Typography } from "antd";

const ContributorsReadView = ({ contributors, labelArtists, withRoles, withFeatured } ) => {

  const { dictionary } = useContext(LanguageContext);

  const drawRoles = (roles) => {
    if (!withRoles) {
      return null;
    }

    const result = [];
    const rolesLength = roles.length;
    for (let i = 0; i < rolesLength; i++) {
      const role = roles[i].role;
      result.push(
        <React.Fragment key={i}>
          {i > 0 ? ", " : ""}
          {dictionary.artistType[role] || role}
        </React.Fragment>
      );
    }
    return <Typography.Text>&nbsp;({result})</Typography.Text>;
  };

  if (withFeatured) {
    let countFeat = 0;
    return labelArtists
      .filter((item) => Object.hasOwnProperty.call(contributors, item._id))
      .sort((a) =>
        contributors[a._id].some((role) => role.role === "main") ? -1 : 1
      )
      .map((contributor, index) => {
        let concatString = "";
        if (index > 0 && index < labelArtists.length - 1) concatString = ", ";

        if (index === labelArtists.length - 1 && index > 0)
          concatString = `${dictionary.and}`;

        if (
          contributors[contributor._id].some(
            (role) => role.role === "feature"
          ) &&
          countFeat === 0
        ) {
          concatString = dictionary.featuring;
          countFeat++;
        }
        return (
          <React.Fragment key={contributor._id}>
            <Typography.Title className="mt-0" level={4}>
              &nbsp;{concatString}&nbsp;
              {contributor.name}
            </Typography.Title>
            {drawRoles(contributors[contributor._id])}
          </React.Fragment>
        );
      });
  }

  return labelArtists
    .filter((item) => Object.hasOwnProperty.call(contributors, item._id))
    .map((contributor, index) => {
      let concatString = "";
      if (index > 0 && index < labelArtists.length - 1) concatString = ", ";

      if (index === labelArtists.length - 1 && index > 0)
        concatString = `${dictionary.and}`;

      return (
        <React.Fragment key={contributor._id}>
          <Typography.Title className="mt-0" level={4}>
            &nbsp;{concatString}&nbsp;
            {contributor.name}
          </Typography.Title>
          {drawRoles(contributors[contributor._id])}
        </React.Fragment>
      );
    });
};

export default ContributorsReadView;
