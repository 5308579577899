import BaseClass from "../";

class DSPCalls extends BaseClass {
  dsps() {
    this.setValues("/dsp/get");
    return this.doGet();
  }
}

export default new DSPCalls();
