import React, { useContext } from "react";
import { useFieldArray /*useFormContext*/ } from "react-hook-form";
import { LanguageContext } from "../../../context/Language";
import EscalationItem from "../../../components/Contracts/Create/Escalations/EscalationItem/EscalationItem";
import EscalationEquivalent from "../../../components/Contracts/Create/Escalations/EscalationEquivalent/EscalationEquivalent";
import { Button, Card, Row, Col } from "antd";

const CreateContractEscalations = () => {
  /*
  const {
    register,
    formState: { errors },
  } = useFormContext();*/
  const { dictionary } = useContext(LanguageContext);

  const {
    fields: escalationfields,
    append: appendEscalation,
    remove: removeEscalation,
  } = useFieldArray({
    name: "escalation",
  });

  const {
    fields: escalationEquivalentfields,
    append: appendEscalationEquivalent,
    remove: removeEscalationEquivalent,
  } = useFieldArray({
    name: "escalationEquivalent",
  });

  const addEscalation = () => {
    appendEscalation({
      name: "",
      units: "",
      grossAmmount: 0,
      netammount: 0,
    });
  };

  const addEscalationEquivalent = () => {
    appendEscalationEquivalent({
      configuration: "",
      grossAmmount: 0,
    });
  };

  const drawEscalation = () => {
    return escalationfields.map((st, index) => {
      return (
        <EscalationItem
          key={st.id}
          data={st}
          index={index}
          remove={() => removeEscalation(index)}
        />
      );
    });
  };

  const drawEscalationEsquivalent = () => {
    return escalationEquivalentfields.map((st, index) => {
      return (
        <EscalationEquivalent
          key={st.id}
          data={st}
          index={index}
          remove={() => removeEscalationEquivalent(index)}
        />
      );
    });
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card bordered={false}>
          <h3>{dictionary.escalations} tracking</h3>
          {drawEscalation()}
          <Button type="primary" onClick={addEscalation} className="add-item">
            + {dictionary.escalations}
          </Button>
        </Card>
      </Col>
      <Col span={24}>
        <Card bordered={false}>
          <h3>Escalation equivalent</h3>
          <p>
            Escalation Equivalents allows you to set unit equivalents, for
            instance if 1000 streams equals one unit sold. Set specific
            equivalents for each configuration below.
          </p>
          <p>
            For instance, if you want to say 1000 streams equals one unit,
            select the configuration below, and enter 1000 in the Amount field.
            When adding up units for the escalations, the system will then
            divide each unit by that amount.
          </p>
          {drawEscalationEsquivalent()}
          <Button
            type="primary"
            onClick={addEscalationEquivalent}
            className="add-item"
          >
            + Escalation Equivalent
          </Button>
        </Card>
      </Col>
    </Row>
  );
};

export default CreateContractEscalations;
