import React, { useState, useContext, useEffect } from "react";
import { Typography, Transfer, Card, Tooltip } from "antd";
import { AppContext } from "../../context/App";
import { QuestionCircleFilled } from "@ant-design/icons";
import { LanguageContext } from "../../context/Language";
import AdminCalls from "../../classes/admin/admin";
import DSPConfrimationActionModal from "../../components/Dsp/ConfirmationActionModal";
import "./DSP.css";

const Upc = () => {
  const { getDsps, dataDsp } = useContext(AppContext);
  const { dictionary } = useContext(LanguageContext);
  const [targetKeys, setTargetKeys] = useState([]);
  const [audioSaladDsps, setAudioSaladDsps] = useState([]);
  const [audioSaladDspsbyKey, setAudioSaladDspsbyKey] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [movedDsps, setMovedDsps] = useState([]);
  const [direction, setDirection] = useState("");

  // Get the DSP's from AudioSalad
  useEffect(() => {
    AdminCalls.audioSaladDSPList()
      .then((data) => {
        if (data && data?.dspList) {
          let ASDspsbyKey = {};
          const dsps = data.dspList.map((dsp) => {
            ASDspsbyKey[dsp.id * 1] = dsp;
            return {
              key: dsp.id * 1,
              title: dsp.name,
              description: dsp.name,
            };
          });
          setAudioSaladDsps(dsps);
          setAudioSaladDspsbyKey(ASDspsbyKey);
        }
      })
      .catch(() => {
        setAudioSaladDsps([]);
      })
      .finally(() => setIsLoading(false));
  }, []);

  // Asing the actual DSP's from Priam
  useEffect(() => {
    if (dataDsp.length !== 0) {
      const priamDsp = [];
      for (const ddsp of dataDsp)
        if (ddsp.audiosalad_id) priamDsp.push(ddsp.audiosalad_id);

      setTargetKeys(priamDsp);
    }
  }, [dataDsp]);

  const onChange = (_, direction, moveKeys) => {
    setMovedDsps(moveKeys);
    setIsModalVisible(true);
    setDirection(direction);
  };

  return (
    <React.Fragment>
      <DSPConfrimationActionModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        movedDsp={movedDsps}
        dspList={audioSaladDspsbyKey}
        direction={direction}
        onConfirm={() => {
          getDsps();
          setIsModalVisible(false);
        }}
        onCancel={() => {
          setIsModalVisible(false);
          setTargetKeys(targetKeys);
        }}
      />
      <div className="container-box dashboard dsps">
        <Card loading={isLoading}>
          <Typography.Title level={2} className="centered">
            {dictionary.dspList}
            <Tooltip
              title={dictionary.dspMeaning}
              placement="right"
              color="#b5b5b5"
              trigger="click"
            >
              <QuestionCircleFilled className="question-icon"/>
            </Tooltip>
          </Typography.Title>
          <Transfer
            dataSource={audioSaladDsps}
            titles={["AudioSalad", "Priam Digital"]}
            targetKeys={targetKeys}
            onChange={onChange}
            pagination
            render={(item) => item.title}
            className="dsp-content"
            locale={dictionary.transferDsp}
          />
        </Card>
      </div>
    </React.Fragment>
  );
};

export default Upc;
