import React, { useState, useContext, useEffect } from "react";
import { notification } from "antd";
import { ArtistContext } from "../../../../context/Artist";
import { LanguageContext } from "../../../../context/Language";
import ArtistList from "./ArtistList";
import DisplayArtist from "./DisplayArtist";
import Logo from "../../../Icon/broken.png";
import AddArtist from "./DisplayArtist/AddArtist";
import Artist from "../../../../classes/artist/artist.jsx";

const ParticipantsForm = ({
  label,
  showCover,
  artistsAll,
  saveArtists,
  nameRelease,
  imageRelease,
  artistsFrontAll,
  validationErrors,
}) => {
  const { dictionary } = useContext(LanguageContext);
  const { getArtists, artistsOptions } = useContext(ArtistContext);
  const [alertText, setAlertText] = useState(null);
  const [listArtist, setArtistList] = useState({
    artist: [],
    writer: [],
    producer: [],
    participant: [],
  });
  const [frontArtist, setFrontArtist] = useState({
    artist: [],
    writer: [],
    participant: [],
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [openData, setOpenData] = useState({
    type: 0,
    role: [],
    name: "",
    _id: "",
  });
  const [errors, setErrors] = useState({
    type: false,
    role: false,
    name: false,
  });
  const [loading] = useState(false);
  const [loaderAdd, setLoaderAdd] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [opened, openDrop] = useState(false);
  const [open, setOpen] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setArtistList(artistsAll);
    if (artistsFrontAll.artist.length) {
      setFrontArtist(artistsFrontAll);
    }
  }, [artistsAll, artistsFrontAll]);

  useEffect(() => {
    let newOpenData = { ...openData };
    setOpenData(newOpenData);
    // eslint-disable-next-line
  }, [openData.type]);

  const previewImg = async () => {
    document.getElementById("album-cover").src = Logo;
  };

  const setParticipant = (type, artist) => {
    let list = listArtist[type];
    let pv = frontArtist[type];
    if (artist.role.length) {
      //saving list for the back xml
      for (let role of artist.role) {
        let newArtistData = { ...artist, role: role };
        list.push(newArtistData);
        setArtistList({
          ...listArtist,
          [type]: list,
        });
      }

      //saving list for the front view
      pv.push(artist);
      setFrontArtist({
        ...frontArtist,
        [type]: pv,
      });
    } else {
      delete artist.role;
      list.push(artist);
      pv.push(artist);
    }
    saveArtists(listArtist, frontArtist);
  };

  const updateParticipant = async (type, newData, oldData) => {
    let list = listArtist[type];
    let pv = frontArtist[type];

    //to update the list to send to back
    for (let i = list.length - 1; i >= 0; i--) {
      if (list[i].name.match(oldData.name)) {
        list.splice(list.indexOf(list[i]), 1);
      }
    }
    if (newData.role.length) {
      for (let role of newData.role) {
        let newArtistData = { ...newData, role: role };
        list.push(newArtistData);
        await setArtistList({
          ...listArtist,
          [type]: list,
        });
      }
    }
    saveArtists(listArtist, frontArtist);

    //to update the front view
    for (var key in pv) {
      if (pv[key].name.match(oldData.name)) {
        pv[key].name = newData.name;
        pv[key].role = newData.role;
      }
    }
    await setFrontArtist({
      ...frontArtist,
      [type]: pv,
    });
  };

  const removeParticipant = async (type, index, name = null, id = null) => {
    const list = listArtist[type];
    let pv = frontArtist[type];
    //to delete in front view
    pv.splice(index, 1);
    await setFrontArtist({
      ...frontArtist,
      [type]: pv,
    });

    //to delete in listArtist to send to back
    for (let i = list.length - 1; i >= 0; i--) {
      if (id && id === list[i].artist_id) {
        list.splice(list.indexOf(list[i]), 1);
      } else if (!id && name === list[i].name) {
        list.splice(list.indexOf(list[i]), 1);
      }
    }
    await setArtistList({
      ...listArtist,
      [type]: list,
    });
    saveArtists(listArtist, frontArtist);
  };

  const handleSelect = (e, name) => {
    setOpenData({
      ...openData,
      [name]: e,
    });
  };

  const handleRadio = (e, name) => {
    setOpenData({
      ...openData,
      [name]: [e.value],
    });
  };

  const showModal = (artist = null, type = 0, newName = null) => {
    openDrop(false);
    if (artist) {
      setIsUpdate(artist);
      setOpenData({
        ...openData,
        type: type,
        role: artist.role || [],
        name: artist.name,
        _id: artist._id,
      });
    } else {
      const rolesArr = type === "artist" ? ["feature"] : [];
      setIsUpdate(false);
      setOpenData({
        type: type,
        role: rolesArr,
        name: newName ? newName : "",
        _id: "",
      });
    }
    setErrors({
      type: null,
      role: null,
      name: null,
    });
    setIsModalVisible(true);
  };

  const handleCancel = async() => {
    setOpen(false);
    setIsNew(false);
    setChecked(false);
    setTimeout(() => {setIsModalVisible(false)}, 400);
  };

  const handleSubmit = async (update = false) => {
    setOpen(false);
    let hasError = false;
    let baseErrors = {
      type: false,
      role: false,
      name: false,
    };
    setErrors(baseErrors);
    if (openData.name === "" || !openData.name) {
      baseErrors.name = dictionary.emptyField;
      hasError = true;
    }
    if (openData.type === 0) {
      baseErrors.type = dictionary.selectErr;
      hasError = true;
    }
    if (openData.role.length === 0) {
      baseErrors.role = dictionary.selectErr;
      hasError = true;
    }

    if (hasError) {
      setErrors(baseErrors);
      return false;
    } else {
      setLoaderAdd(true);
      const newParticipant = {
        name: openData.name,
      };
      if (openData.role) {
        newParticipant.role = openData.role;
      }
      if (openData._id) {
        newParticipant.artist_id = openData._id;
      }
      if (!update) {
        setLoaderAdd(true);
        if(isNew || openData.type === "artist"){
          const newArtist = {
            label_id: label,
            name: openData.name,
            description: openData.name,
            is_featured:
              !openData.role.includes("main") &&
              !openData.role.includes("primary"),
            is_contributor: openData.type === "artist" ? false : !checked,
          };
          const createdArtist = await Artist.create(newArtist, null, null);
          if (createdArtist) {
            newParticipant.artist_id = createdArtist._id;
            getArtists();
            setParticipant(openData.type, newParticipant);
          } else {
            notification.error({
              placement: "topRight",
              duration: 6,
              message: dictionary.errorGeneral,
            });
          }
        } else {
          newParticipant.artist_id = openData._id;
          setParticipant(openData.type, newParticipant);
        }
        setLoaderAdd(false);
      } else {
        //know if updating another or loading a new one:
        if (update) {
          updateParticipant(openData.type, newParticipant, update);
        } else {
          setParticipant(openData.type, newParticipant);
        }
        setLoaderAdd(false);
      }
      setIsModalVisible(false);
      setIsNew(false);
      setChecked(false);
    }
  };

  const onChangeCheckbox = (e) => {
    setChecked(e.target.checked);
  };

  const setDisplayArtist = (knownArtist = null, type) => {
    const newParticipant = {
      artist_id: knownArtist._id,
      name: knownArtist.name,
      role: listArtist.artist.length === 0 ? "main" : "feature",
    };
    if (knownArtist) {
      let list = listArtist[type];
      list.push(newParticipant);
      setArtistList({
        ...listArtist,
        [type]: list,
      });
      let pv = frontArtist[type];
      pv.push(newParticipant);
      setFrontArtist({
        ...frontArtist,
        [type]: pv,
      });
    }
    saveArtists(listArtist, frontArtist);
  };

  const existArtistAlready = (value) => {
    if (artistsOptions === undefined || artistsOptions?.length === 0)
      return false;
    for (const el of artistsOptions) {
      if (el.value === value) {
        return true;
      }
    }
    return false;
  };

  const changeHandlerName = (e, name) => {
    let itExists = existArtistAlready(e);
    if (itExists) {
      setAlertText(dictionary.artistExists);
    } else {
      setAlertText(null);
    }
    setOpenData({
      ...openData,
      [name]: e,
    });
  };

  const changeComplete = (e, name, options) => {
    setOpen(true); 
    setIsNew(false);
    if (!options) {
      return;
    }
    let id = options.filter((el) => {
      if (el.value === e) {
        return el._id;
      }
    });
    setOpenData({
      ...openData,
      [name]: e,
      _id: id[0] ? id[0]._id : "",
    });
  };

  /* 
  // @deprecated
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
 */

  const orderedList = (list = [], roles = false) => {
    const count = list.length;
    if (Array.isArray(list)) {
      return list.map((el, index) => {
        if (count === index + 1 && count > 1) {
          return (
            " " +
            dictionary.and +
            " " +
            (roles ? dictionary.artistType[el] : el.name)
          );
        } else if (count - 2 === index && count > 1) {
          return roles ? dictionary.artistType[el] : el.name;
        } else if (count < 3) {
          return (roles ? dictionary.artistType[el] : el.name) + " ";
        } else {
          return (roles ? dictionary.artistType[el] : el.name) + ", ";
        }
      });
    }
  };

  const artistsPreview = () => {
    const onlyMain = listArtist.artist.filter((el) => el.role.includes("main"));
    const onlyFeat = listArtist.artist.filter((el) =>
      el.role.includes("feature")
    );
    const countFeat = onlyFeat.length;

    let setMains = orderedList(onlyMain);

    let setFeat = onlyFeat.map((el, index) => {
      if (countFeat === index + 1 && countFeat > 1) {
        return " " + dictionary.and + " " + el.name;
      } else if (countFeat < 3) {
        return dictionary.featuring + el.name + " ";
      } else if (index === 0) {
        return dictionary.featuring + el.name + ", ";
      } else {
        return el.name + ", ";
      }
    });
    setMains.push(setFeat);
    return setMains;
  };

  const allProducers = (list, producers) => {
    let contributors = [];
    if (producers) {
      contributors = list.filter((el) => el.role.includes("producer"));
    } else {
      contributors = list.filter(
        (el) => !el.role.includes("producer") || el.role.length > 1
      );
    }
    return orderedList(contributors);
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="preview-rel">
        {showCover ? (
          <img
            alt="album-cover-img"
            className="prev-img"
            src={imageRelease}
            id="album-cover"
            onError={(e) => previewImg(e)}
          />
        ) : null}
        <div className="info-displayed">
          <h2>{nameRelease}</h2>
          <h3>
            {frontArtist.artist.length
              ? artistsPreview()
              : dictionary.noArtists}
          </h3>
          <div className="listed">
            <span>{dictionary.writersAndPublishers}: </span>
            {frontArtist.writer.length !== 0
              ? orderedList(frontArtist.writer)
              : "--"}
          </div>
          <div className="listed">
            <span>{dictionary.producedBy}: </span>
            {frontArtist.participant && frontArtist.participant.length !== 0
              ? allProducers(frontArtist.participant, true)
              : "--"}
          </div>
          <div className="listed">
            <span>{dictionary.participants}: </span>
            {frontArtist.participant && frontArtist.participant.length !== 0
              ? allProducers(frontArtist.participant, false)
              : "--"}
          </div>
        </div>
      </div>
      <DisplayArtist
        type={"artist"}
        opened={opened}
        openDrop={openDrop}
        showModal={showModal}
        listArtist={listArtist}
        frontList={frontArtist.artist}
        list={listArtist.artist}
        frontArtist={frontArtist}
        remove={removeParticipant}
        setArtistList={setArtistList}
        setFrontArtist={setFrontArtist}
        setDisplayArtist={setDisplayArtist}
        error={validationErrors.artist || false}
      />
      <ArtistList
        type={"writer"}
        showModal={showModal}
        list={listArtist.writer}
        frontList={frontArtist.writer}
        orderedList={orderedList}
        remove={removeParticipant}
        handleSelect={handleSelect}
        title={dictionary.writersAndPublishers}
        error={validationErrors.writer || false}
      />
      <ArtistList
        type={"participant"}
        showModal={showModal}
        orderedList={orderedList}
        remove={removeParticipant}
        handleSelect={handleSelect}
        list={listArtist.participant}
        frontList={frontArtist.participant}
        title={dictionary.otherContributors}
        error={validationErrors.producer || false}
      />
      <AddArtist
        open={open}
        isNew={isNew}
        errors={errors}
        checked={checked}
        setOpen={setOpen}
        artist={openData}
        loading={loading}
        isUpdate={isUpdate}
        setIsNew={setIsNew}
        alertText={alertText}
        loaderAdd={loaderAdd}
        listArtist={listArtist}
        handleRadio={handleRadio}
        handleCancel={handleCancel}
        handleSelect={handleSelect}
        handleSubmit={handleSubmit}
        isModalVisible={isModalVisible}
        artistsOptions={artistsOptions}
        changeComplete={changeComplete}
        onChangeCheckbox={onChangeCheckbox}
        changeHandlerName={changeHandlerName}
      />
    </div>
  );
};

export default ParticipantsForm;
