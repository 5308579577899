import React from "react";

const UpcIcon = ({ className = null, onClick = null, color = null }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5C4.55228 5 5 5.44772 5 6L5 14C5 14.5523 4.55228 15 4 15C3.44771 15 3 14.5523 3 14L3 6C3 5.44772 3.44772 5 4 5Z"
        fill={color || "#ffffffa6"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 1C14 0.447715 14.4477 0 15 0H17C18.6569 0 20 1.34315 20 3V5C20 5.55228 19.5523 6 19 6C18.4477 6 18 5.55228 18 5V3C18 2.44772 17.5523 2 17 2H15C14.4477 2 14 1.55228 14 1Z"
        fill={color || "#ffffffa6"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 19C6 19.5523 5.55228 20 5 20L3 20C1.34315 20 1.17422e-07 18.6569 2.62268e-07 17L4.37114e-07 15C4.85396e-07 14.4477 0.447716 14 1 14C1.55229 14 2 14.4477 2 15L2 17C2 17.5523 2.44772 18 3 18L5 18C5.55228 18 6 18.4477 6 19Z"
        fill={color || "#ffffffa6"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 14C19.5523 14 20 14.4477 20 15V17C20 18.6569 18.6569 20 17 20H15C14.4477 20 14 19.5523 14 19C14 18.4477 14.4477 18 15 18H17C17.5523 18 18 17.5523 18 17V15C18 14.4477 18.4477 14 19 14Z"
        fill={color || "#ffffffa6"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 6C0.447715 6 3.91405e-08 5.55228 8.74228e-08 5L2.62268e-07 3C4.07115e-07 1.34315 1.34315 -4.07115e-07 3 -2.62268e-07L5 -8.74228e-08C5.55229 -3.91405e-08 6 0.447715 6 1C6 1.55228 5.55229 2 5 2L3 2C2.44772 2 2 2.44771 2 3L2 5C2 5.55228 1.55228 6 1 6Z"
        fill={color || "#ffffffa6"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 5C8.55228 5 9 5.44772 9 6L9 14C9 14.5523 8.55228 15 8 15C7.44771 15 7 14.5523 7 14L7 6C7 5.44772 7.44772 5 8 5Z"
        fill={color || "#ffffffa6"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 5C16.5523 5 17 5.44772 17 6L17 14C17 14.5523 16.5523 15 16 15C15.4477 15 15 14.5523 15 14L15 6C15 5.44772 15.4477 5 16 5Z"
        fill={color || "#ffffffa6"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5C12.5523 5 13 5.44772 13 6L13 14C13 14.5523 12.5523 15 12 15C11.4477 15 11 14.5523 11 14L11 6C11 5.44772 11.4477 5 12 5Z"
        fill={color || "#ffffffa6"}
      />
    </svg>
  );
};

export default UpcIcon;
