import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/App";
import { UserContext } from "../../context/User";
import ArtistList from "../../components/Catalog/ArtistList";
import "../../components/Catalog/catalog.css";

const Artist = () => {
  const { labels } = useContext(AppContext);
  const { type, isAdmin } = useContext(UserContext);
  const [allLabels, setLabels] = useState();
  const [loading, setLoading] = useState(false);
  const [labelArtist, setLabelArtist] = useState([]);

  const getLabels = async () => {
    setLoading(true);
    try {
      const labelsArray = labels || [];
      if (type === "artist") {
        setLabelArtist(labelsArray[0] ?? []);
      } else {
        setLabels(labelsArray);
      }
      setLoading(false);
    } catch (e) {
      setLabels([]);
      setLabelArtist([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    getLabels();
  }, [labels]);

  return (
    <React.Fragment>
      <div className={isAdmin}>
        <ArtistList
          labels={allLabels}
          loadList={loading}
          labelArtist={labelArtist}
        />
      </div>
    </React.Fragment>
  );
};

export default Artist;
