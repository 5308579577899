import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Empty } from "antd";
import { LanguageContext } from "../../../context/Language";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import Avatar from "react-avatar";
import "./style.css";
import PlusIcon from "../../Icon/PlusIcon";
import User from "../../Icon/userArtist.png";

const List = ({
  type,
  list,
  title,
  remove,
  showModal,
  error,
  plus,
  emptyText,
  redirect,
  route,
  canAdd,
  canUpdate,
  canDelete,
  isColab,
}) => {

  const { dictionary } = useContext(LanguageContext);

  const previewImg = (e, el) => {
    let imgId = document.getElementById("img" + el._id);
    if (imgId) {
      imgId.src = el.image ? el.image : User;
      let image = document.createElement("img");
      image.src = el.image;
      image.addEventListener(
        "error",
        () => {
          var isLoaded = image.complete && image.naturalHeight !== 0;
          if (!isLoaded) {
            imgId.src = User;
          }
        },
        false
      );
    }
    return;
  };

  const drawList = () => {
    const imgSrcBucket = process.env.REACT_APP_S3_BUCKET_CDN_URL || process.env.REACT_APP_STORAGE;
    return list.map((el, index) => {
      let image = el.image
        ? el.image.replace(/.jpg|.png|.jpeg|.heic/gi, function () {
            return "250x250.webp";
          }) //for artists
        : el.image_cover
        ? imgSrcBucket + el._id + "/" + el.image_cover.filename
        : null;
      let name = el.name ? el.name : el.title;
      let title = !el.name
        ? el.title
          ? el.title
          : dictionary.noTitle
        : el.name;
      let headArtist = (el.user) ? true : false;
      return (
        <li key={index} className="cd-data new-artist fadein">
          {image ? (
            <img
              src={image}
              alt={name.substring(0, 3)}
              className="display-av"
              id={"img" + el._id}
              onError={(e) => previewImg(e, el)}
            />
          ) : (
            <Avatar
              round="5px"
              textSizeRatio={4}
              className="display-avtr"
              name={name || dictionary.noTitle}
            />
          )}
          <div className="menu-cd">
            {canUpdate 
              ? <EditOutlined onClick={() => showModal(el, headArtist, type, isColab)} />
              : null}
            {canDelete  && !headArtist
              ? <DeleteOutlined onClick={() => remove(index, el, isColab)} />
              : null}
           </div>
          
          <div className="album">{title}</div>
          {redirect ? <Link ref={redirect} to={route + el._id}></Link> : null}
        </li>
      );
    });
  };

  const plusIcon = plus === true ? <PlusIcon className="add-rel" /> : null;

  return (
    <div className="general-card">
      <Card>
        <p>{title}</p>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24}>
            <div className="list-rel list-artist">
              <ul className="list-cd inline-contend">
                {canAdd ? (
                  <li className="cd-data">
                    <div
                      onClick={() => showModal()}
                      className={"new-artist-add " + (error ? "has-error" : "")}
                    >
                      {plusIcon}
                    </div>
                  </li>
                ) : null}
                {!list.length ? (
                  <li className="cd-data">
                    <Empty
                      className="empty-rel"
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={
                        <div className={"no-release-description"}>
                          {emptyText}
                        </div>
                      }
                    />
                  </li>
                ) : null}
                {drawList()}
              </ul>
              {error ? (
                <p className="text-error relative-text">{error}</p>
              ) : null}
            </div>
          </Col>
        </Row>
        {redirect ? <Link ref={redirect} to={route}></Link> : null}
      </Card>
    </div>
  );
};

export default List;
