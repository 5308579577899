import React, { useContext, useEffect, useState } from "react";
import { notification, Tabs } from "antd";
import { AppContext } from "../../../context/App";
import { UserContext } from "../../../context/User";
import { ArtistContext } from "../../../context/Artist";
import { LanguageContext } from "../../../context/Language";
import { createFileObjectState, URLFileName } from "../../../hooks/files/files";
import List from "../List";
import Tables from "../Tables";
import Modal from "../../Modal";
import validator from "validator";
import Loader from "../../Loader";
import AddArtist from "./AddArtist";
import Search from "../../Form/Search";
import SearchIcon from "../../Icon/SearchIcon";
import Button from "../../Buttons/GeneralButton";
import ArtistCalls from "../../../classes/artist/artist";

const { isEmail } = validator;

const ArtistList = ({
  labels, 
  loadList, 
  labelArtist
}) => {

  const { getArtists, artists, loadingArtists } = useContext(ArtistContext);
  const { dictionary } = useContext(LanguageContext);
  const { permission, type } = useContext(UserContext);
  const { allCountries, ladaOptions } = useContext(AppContext);
  const [colab, setColab] = useState(false);
  const [imageUrl, setImage] = useState([]);
  const [labelList, getLabels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [listArtist, setArtistList] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  const [searchValues, getSearch] = useState(null);
  const [urlOriginal, setImgOriginal] = useState([]);
  const [loadArtist, setLoadArtist] = useState(false);
  const [headArtist, setHeadArtist] = useState(false);
  const [filterArtists, setFilterArtists] = useState([]);
  const [loadingImage, setLoadingImage] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [filterContributors, setFilterContributors] = useState([]);

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [openData, setOpenData] = useState({
    name: "",
    label_id: type === "artist" ? labelArtist?._id : 0,
    image: "",
    description: "",
    dsp: [],
    email: "",
    password: "",
    confirmPassword: "",
    countryPhone: "US",
    code: "1",
    number: "",
    address: "",
    city: "",
    state: "",
    country: 0,
    zip: "",
    update: false,
    hasUser: false,
  });

  const [deleteData, setDeleteData] = useState({
    name: "",
    isLoading: true,
    hasRelease: 0,
    show: false,
    artist_id: "",
  });

  const columns = [
    {
      title: dictionary.picture,
      dataIndex: "details",
      className: "id-category",
    },
    {
      title: dictionary.name,
      dataIndex: "name",
      className: "title-category",
    },
    {
      title: dictionary.label,
      dataIndex: "desc",
      className: "title-category",
    },
    {
      title: dictionary.featured,
      dataIndex: "featured",
      className: "title-category",
    },
    {
      title: dictionary.createdAt,
      dataIndex: "createdAt",
      className: "title-category",
    },
    {
      title: dictionary.updated,
      dataIndex: "updated",
      className: "title-category",
    },
    {
      title: dictionary.isArtist,
      dataIndex: "isArtist",
      className: "title-category",
    },
    {
      title: dictionary.details,
      dataIndex: "detail",
      className: "id-category",
    },
  ];

  useEffect(() => {
    const filterArtist = artists.filter((el) => !el.is_contributor);
    const filterContributor = artists.filter((el) => el.is_contributor);
    setArtistList(artists);
    setFilterContributors(filterContributor);
    setFilterArtists(filterArtist);
  }, [artists]);

  useEffect(() => {
    getAllLabels();
    // eslint-disable-next-line
  }, []);

  const getAllLabels = () => {
    const alllabels = artists.map(({ label }) => label);
    const uniqueObjects = [];
    const seenIds = {};
    for (let i = 0; i < alllabels.length; i++) {
      const obj = alllabels[i];
      if (!(obj._id in seenIds)) {
        uniqueObjects.push(obj);
        seenIds[obj._id] = true;
      }
    }
    let uniqueAndOrdered = uniqueObjects.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    getLabels(uniqueAndOrdered);
  };

  const showModal = (artist = null, isHeadArtist = false, types, isColab) => {
    if (artist) {
      setColab(isColab);
      setHeadArtist(isHeadArtist);
      if (artist.image) {
        const getResized = artist.image.replace(
          /.jpg|.png|.jpeg|.heic/gi,
          function () {
            return "250x250.webp";
          }
        ); //for artists
        const name = URLFileName(artist.image);
        setImage([
          {
            name: name,
            status: "done",
            url: getResized,
          },
        ]);
        setImgOriginal([
          {
            name: name,
            status: "done",
            url: artist.image,
          },
        ]);
        setImageFile(artist.image);
      } else {
        setImage([]);
        setImgOriginal([]);
      }
      if (artist.user) {
        let profile = artist.user.profile;
        setOpenData({
          ...openData,
          name: artist.name,
          _id: artist._id,
          image: artist.image || null,
          description: artist.description,
          label_id: artist.label_id,
          is_featured: artist.is_featured,
          update: true,
          dsp: artist.dsp || [],
          email: artist.user.email || "",
          countryPhone: profile.phone?.country || "US",
          code: profile.phone?.code || "1",
          number: profile.phone?.number || "",
          address: profile.address?.address || "",
          city: profile.address?.city || "",
          state: profile.address?.state || "",
          country: profile.address?.country || 0,
          zip: profile.address?.zip || "",
          hasUser: true,
        });
      } else {
        setOpenData({
          ...openData,
          name: artist.name,
          _id: artist._id,
          image: artist.image || null,
          description: artist.description,
          label_id: artist.label_id,
          dsp: artist.dsp,
          is_featured: artist.is_featured,
          update: true,
          hasUser: false,
        });
      }
    } else {
      setOpenData({
        name: "",
        label_id: type === "artist" ? labelArtist?._id : 0,
        image: "",
        description: "",
        dsp: [],
        email: "",
        password: "",
        confirmPassword: "",
        countryPhone: "US",
        code: "1",
        number: "",
        address: "",
        city: "",
        state: "",
        country: 0,
        zip: "",
        hasUser: false,
      });
      setImageFile(null);
      setImage([]);
    }
    setErrors({
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      label_id: 0,
      address: "",
      city: "",
      state: "",
      zip: "",
      update: false,
    });
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const changeHandler = (e, name) => {
    const value = e.target.value;
    setOpenData({
      ...openData,
      [name]: value,
    });
  };

  const onChangeCheckbox = (e) => {
    setOpenData({
      ...openData,
      is_featured: e.target.checked,
    });
  };

  const selectChangeHandler = (e, name) => {
    if (name === "code") {
      const getCode = ladaOptions.find((el) => el._id === e);
      setOpenData({
        ...openData,
        countryPhone: getCode._id,
        code: String(getCode.phone_code),
      });
    } else {
      setOpenData({
        ...openData,
        [name]: e,
      });
    }
  };

  const setArtist = async (artist) => {
    const list = listArtist;
    list.push(artist);
    setArtistList(list);
  };

  const handleChange = async ({ file }) => {
    setImageFile(file);
    if (file.status === "uploading") {
      setLoadingImage(true);
      createFileObjectState(file, setImage);
    } else if (file.status === "removed" && imageUrl.length) {
      if (imageUrl[0].uid) {
        if (imageUrl[0].uid.includes("__AUTO__")) {
          await ArtistCalls.deleteImageArtist(imageUrl[0].url, openData);
          getArtists();
        }
        setImageFile(null);
        setImage([]);
      }
    }
    setLoadingImage(false);
  };

  const showModalDelete = (index, artist, isColab) => {
    setColab(isColab);
    ArtistCalls.countRelease(artist._id).then((resp) => {
      setDeleteData({
        index,
        name: artist.name,
        hasRelease: resp,
        isLoading: false,
        show: true,
        artist_id: artist._id,
      });
    });
    setDeleteData({
      index,
      name: artist.name,
      isLoading: true,
      show: true,
      artist_id: artist._id,
    });
  };

  const hideModalDelete = () => {
    setDeleteData({
      index: -1,
      name: "",
      hasRelease: 0,
      isLoading: true,
      show: false,
      artist: null,
    });
  };

  const removeParticipant = async () => {
    const listCopy = [...listArtist];
    const index = deleteData.index;
    const deleteElement = listCopy[index];
    setLoading(true);
    await ArtistCalls.deleteArtist(deleteData.artist_id)
      .then((resp) => {
        if (resp.status === 200) {
          if (!deleteElement.artist_id && deleteElement.image) {
            const url = deleteElement.image;
            ArtistCalls.deleteImageArtist(url);
          }
          listCopy.splice(index, 1);
          getArtists();
          setArtistList(listCopy);
        }
        if (resp.body.message === "artistHasRelease") {
          notification.error({
            key: "cantDeleteArtistModal",
            placement: "bottomRight",
            duration: 5,
            message: dictionary.unableDeleteArtist,
          });
        }
      })
      .catch((resp) => {
        console.error("Artist deleted error", resp);
      })
      .finally(() => {
        hideModalDelete();
        setLoading(false);
      });
    // TODO add a response for fail in the modal before close it
  };

  const handleSubmitUpdate = async () => {
    setLoadArtist(true);
    let hasError,
      errorAddress,
      hasAddress = false;
    let errorsCopy = {
      name: "",
      address: "",
      city: "",
      state: "",
      zip: "",
    };
    if (!openData.name) {
      hasError = true;
      errorsCopy.name = "emptyField";
    }
    if (!openData._id) {
      hasError = true;
      // the id user not exists
    }

    // if one address field was filed all need to be mandatory
    if (openData.address || openData.city || openData.state || openData.zip) {
      hasAddress = true;
      if (!openData.address) {
        errorAddress = true;
        errorsCopy.address = "emptyField";
      }
      if (!openData.city) {
        errorAddress = true;
        errorsCopy.city = "emptyField";
      }
      if (!openData.state) {
        errorAddress = true;
        errorsCopy.state = "emptyField";
      }
      if (!openData.country) {
        errorAddress = true;
        errorsCopy.country = "selectErr";
      }
      if (!openData.zip) {
        errorAddress = true;
        errorsCopy.zip = "emptyField";
      }
    }
    setErrors(errorsCopy);
    try {
      if (!hasError && !errorAddress) {
        setLoading(true);
        setIsModalVisible(false);
        let newArtist = {
          artist_id: openData._id,
          name: openData.name,
          description: openData.description || openData.name,
          is_featured: false,
          dsp: openData.dsp || [],
        };

        let profile = {};

        if (openData.number) {
          profile.phone = {
            country: openData.countryPhone,
            code: openData.code,
            number: openData.number,
          };
        }
        if (hasAddress) {
          profile.address = {
            address: openData.address,
            city: openData.city,
            state: openData.state,
            country: openData.country,
            zip: openData.zip,
          };
        }
        const updatedArtist = await ArtistCalls.update(
          newArtist,
          profile,
          imageFile && imageFile.originFileObj
            ? imageFile.originFileObj
            : imageFile
        );
        // Pending next pr, to update the label of artist user
        // if (headArtist) {
        //   let label = {
        //     label_id: labelData._id || labelData.label_id,
        //     name: labelData.name,
        //   };
        //   const updateLabel = await User.updateLabel(label);
        //   console.log(updateLabel);
        // }
        if (updatedArtist) {
          getArtists();
          setLoadArtist(false);
          setIsModalVisible(false);
          notification.success({
            message: dictionary.artistUpdated,
          });
        } else {
          setLoadArtist(false);
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    let hasError,
      errorAddress,
      hasAddress = false;
    let errorsCopy = {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      label_id: 0,
      address: "",
      city: "",
      state: "",
      zip: "",
    };
    if (!openData.name) {
      hasError = true;
      errorsCopy.name = "emptyField";
    }
    if (!openData.label_id || openData.label_id === 0) {
      hasError = true;
      errorsCopy.label_id = "selectErr";
    }
    if (openData.email) {
      if (!isEmail(openData.email)) {
        hasError = true;
        errorsCopy.email = "mailErr";
      }
      if (!openData.password) {
        hasError = true;
        errorsCopy.password = "emptyField";
      } else if (openData.password.length < 8) {
        hasError = true;
        errorsCopy.password = "passErr";
      }
      if (
        openData.password !== "" &&
        openData.confirmPassword !== openData.password
      ) {
        hasError = true;
        errorsCopy.confirmPassword = "confirmPwdErr";
      }

      // if one address field was filed all need to be mandatory
      if (openData.address || openData.city || openData.state || openData.zip) {
        hasAddress = true;
        if (!openData.address) {
          errorAddress = true;
          errorsCopy.address = "emptyField";
        }
        if (!openData.city) {
          errorAddress = true;
          errorsCopy.city = "emptyField";
        }
        if (!openData.state) {
          errorAddress = true;
          errorsCopy.state = "emptyField";
        }
        if (!openData.country) {
          errorAddress = true;
          errorsCopy.country = "selectErr";
        }
        if (!openData.zip) {
          errorAddress = true;
          errorsCopy.zip = "emptyField";
        }
      }
    }
    setErrors(errorsCopy);
    try {
      if (!hasError && !errorAddress) {
        setIsModalVisible(false);
        const newParticipant = {
          name: openData.name,
        };
        const newArtist = {
          label_id: openData.label_id,
          name: openData.name,
          description: openData.description || openData.name,
          is_featured: false,
          dsp: openData.dsp || [],
        };
        let user = null;
        if (openData.email) {
          user = {
            email: openData.email,
            password: openData.password,
            confirmPassword: openData.confirmPassword,
            profile: {},
          };

          if (openData.number) {
            user.profile.phone = {
              country: openData.countryPhone,
              code: openData.code,
              number: openData.number,
            };
          }
          if (hasAddress) {
            user.profile.address = {
              address: openData.address,
              city: openData.city,
              state: openData.state,
              country: openData.country,
              zip: openData.zip,
            };
          }
        }
        const createdArtist = await ArtistCalls.create(
          newArtist,
          user,
          imageFile ? imageFile.originFileObj : null
        );
        if (createdArtist) {
          newParticipant.artist_id = createdArtist._id;
          newParticipant.image = createdArtist.image;
          getArtists();
          await setArtist(newParticipant);
          notification.success({
            message: dictionary.artistSuccess,
          });
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onSearch = (value, type) => {
    getSearch(value.target.value);
    const searcheval = (value.target.value);
    const filterNames =  type === 1 
        ? artists.filter((el) => !el.is_contributor)
        : artists.filter((el) => el.is_contributor);

    let filteredNames = filterNames.filter((el) =>
      el.name.toLowerCase().includes(searcheval.toLowerCase())
    );
    if (type === 1){
      setFilterArtists(filteredNames);
    }else{
      setFilterContributors(filteredNames);
    }
  };

  const searcherBtn  = (type) => {
    return (
      <div className="title-catalog">
        <Search
          name="searcher"
          value={searchValues}
          onSearch={(e) => onSearch(e,type)}
          className="searcher back"
          enterButton={<SearchIcon />}
          placeholder={dictionary.search}
        />
      </div>
    )
  };

  const itemsTabs = [
    {
      key: 'upc',
      label: dictionary.artists,
      children:  <React.Fragment>
        {searcherBtn(1)}
        <List
          plus={true}
          error={false}
          isColab={false}
          released={true}
          showModal={showModal}
          list={filterArtists || []}
          remove={showModalDelete}
          emptyText={dictionary.noArtists}
          canAdd={permission?.artist?.add || false}
          canUpdate={permission?.artist?.update || false}
          canDelete={permission?.artist?.delete || false}
        />
      </React.Fragment>,
    },
    {
      key: '0',
      label: '|',
      children: null,
      disabled: true,
    },
    {
      key: 'isrc',
      label: dictionary.otherContributors,
      children:<React.Fragment>
        {searcherBtn(2)}
        <List
          plus={true}
          error={false}
          isColab={true}
          released={true}
          showModal={showModal}
          list={filterContributors || []}
          remove={showModalDelete}
          emptyText={dictionary.noOtherContributors}
          canUpdate={permission?.artist?.update || false}
          canDelete={permission?.artist?.delete || false}
        />
      </React.Fragment>,
    },
  ];

  return (
    <React.Fragment>
      {loadingArtists || loading ? <Loader /> : null}
      {type === "admin" ? (
        <Tables
          error={false}
          typeTable={1}
          released={true}
          columns={columns}
          subjects={artists}
          loadList={loadList}
          showModal={showModal}
          list={listArtist || []}
          remove={showModalDelete}
          title={dictionary.artists}
          setNewList={setArtistList}
          canUpdate={permission?.artist?.update || false}
          canDelete={permission?.artist?.delete || false}
        />
      ) : (
        <React.Fragment>
          <Tabs
            defaultActiveKey="1"
            centered
            className="tabs-top tab-artist"
            items={itemsTabs}
          />
        </React.Fragment>
      )}
      {permission?.artist?.add || permission?.artist?.update ? (
        <AddArtist
          type={type}
          colab={colab}
          errors={errors}
          labels={labels}
          artist={openData}
          imageUrl={imageUrl}
          setImage={setImage}
          loading={loadingImage}
          loadArtist={loadArtist}
          headArtist={headArtist}
          urlOriginal={urlOriginal}
          labelArtist={labelArtist}
          handleChange={handleChange}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          dataCountries={allCountries}
          changeHandler={changeHandler}
          phoneCountries={ladaOptions}
          isModalVisible={isModalVisible}
          onChangeCheckbox={onChangeCheckbox}
          handleSubmitUpdate={handleSubmitUpdate}
          selectChangeHandler={selectChangeHandler}
        />
      ) : null}
      {permission?.artist?.delete ? (
        <Modal
          idModal={"deleteArtist"}
          handleCancel={handleCancel}
          isModalVisible={deleteData.show}
          title={
            <div className="modal-title">
              {colab ? dictionary.deleteContributor : dictionary.deleteArtist}
            </div>}
          body={
            <div>
              {deleteData.isLoading
                ? dictionary.loading + "..."
                : deleteData.hasRelease > 0
                ? dictionary.unableDeleteArtist
                : (colab 
                  ? dictionary.deleteMessageContributor.replace(
                    "--artist--",
                    deleteData.name) 
                  : dictionary.deleteMessageArtistName.replace(
                    "--artist--",
                    deleteData.name
                  ))}
            </div>
          }
          footer={
            <div className="modalAdd-btns">
              <Button
                key="cancel"
                size="sm"
                className="back-btn"
                text={dictionary.cancel}
                onClick={hideModalDelete}
              />
              <Button
                key="submit"
                size="md"
                text={dictionary.delete}
                disabled={deleteData.hasRelease > 0 || deleteData.isLoading}
                className="footer-btn"
                onClick={removeParticipant}
              />
            </div>
          }
        />
      ) : null}
    </React.Fragment>
  );
};

export default ArtistList;
