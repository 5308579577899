import IsrcCalls from "../calls/isrc/isrc.jsx";

class Isrc {
  getAvailableIsr = async () => {
    return await IsrcCalls.getAvailableIsrcs();
  };

  addIsrc = async (isrc) => {
    return await IsrcCalls.addIsrc(isrc);
  };

  deleteIsrc = async (isrc) => {
    return await IsrcCalls.deleteIsrc(isrc);
  };
}

export default new Isrc();
