import React, { useContext, useState } from "react";
import { LanguageContext } from "../../../../context/Language";
import Button from "../../../Buttons/GeneralButton";
import CheckIcon from "../../../Icon/CheckIcon/";

const Core = ({ active, corePlan, selectPlan, hideSelectBtn }) => {

  const { dictionary } = useContext(LanguageContext);
  const [benefits] = useState([]);

  const renderBenefits = () => {
    if (benefits.length < 1) {
      for (var i = 1; i < 8; i++) {
        benefits.push(
          <li key={i}>
            <CheckIcon className="check-benefit" />
            {dictionary.core.benefit[i]}
          </li>
        );
      }
    }
    return benefits;
  };

  return (
    <div
      ref={corePlan}
      className={
        (active === "core" ? "selectedPlan " : " ") + " pricing-box core"
      }
    >
      <h3>Priam Core</h3>
      <div className="price">
        $ 10 USD&nbsp;<div className="period">/&nbsp; {dictionary.month}</div>
      </div>
      <p className="price-subtitle">{dictionary.core.subtitle}</p>
      <ul>{renderBenefits()}</ul>
      {hideSelectBtn ? null : (
        <Button
          size="fullWidth"
          onClick={selectPlan}
          className="price-btn"
          text={dictionary.select}
        />
      )}
    </div>
  );
};

export default Core;
