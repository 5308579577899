import React from "react";

const RoyaltyIcon = ({ className = null, onClick = null, color = null}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path 
        d="M17.6717 0.0386091C17.1387 0.0574849 16.6937 0.0826526 16.6864 0.0952364C16.6791 0.104674 16.7646 0.309162 16.8795 0.548254C16.9945 0.787347 17.0849 0.985543 17.0825 0.985543C17.0067 1.06105 11.9065 5.07214 11.8821 5.08158C11.8601 5.08787 10.9823 4.86136 9.93347 4.57508C8.88459 4.2888 8.01419 4.05285 8.00196 4.05285C7.9824 4.05285 0.796722 9.72187 0.760048 9.76277C0.747823 9.78164 1.13657 10.5744 1.15613 10.5713C1.17324 10.5713 3.69887 8.57989 6.85773 6.07256C7.54476 5.52831 8.12666 5.08473 8.15355 5.08473C8.18044 5.08787 9.06062 5.32382 10.1071 5.6101L12.0141 6.13233L12.1486 6.02222C15.1094 3.67848 17.4663 1.81922 17.4786 1.81922C17.4859 1.81922 17.5421 1.92933 17.6032 2.05831C17.6644 2.19045 17.7573 2.38864 17.8135 2.5019L17.9138 2.70638L18.4712 1.3725C18.7768 0.639487 19.0335 0.0323172 19.0384 0.0165874C19.0507 -0.00858026 18.9846 -0.00858026 17.6717 0.0386091Z"
        fill={color || "#ffffffa6"}
      />
      <path 
        d="M16.5156 6.98185C16.3274 6.99128 16.0682 7.0196 15.9411 7.03847C14.6257 7.25869 13.6453 7.94765 13.7871 8.55168C13.9313 9.17772 14.946 9.72512 16.232 9.87613C16.6917 9.92961 17.567 9.89815 17.9826 9.81006C18.8408 9.63389 19.5082 9.30356 19.8285 8.89144C20.2417 8.35978 19.8945 7.77148 18.9215 7.35307C18.2344 7.05735 17.4203 6.93151 16.5156 6.98185Z"
        fill={color || "#ffffffa6"}
      />
      <path 
        d="M9.38851 12.031C8.03402 12.1914 6.9778 12.7734 6.88489 13.4121C6.86044 13.5819 6.94846 13.8084 7.11227 13.9972C7.27364 14.1828 7.68439 14.4502 8.01935 14.5886C9.05845 15.0196 10.501 15.07 11.6354 14.7145C12.1709 14.5446 12.6354 14.2803 12.8823 14.0035C13.0388 13.8273 13.1391 13.5756 13.117 13.4152C13.051 12.9685 12.4985 12.5249 11.6794 12.2606C11.1513 12.0908 10.8115 12.0404 10.122 12.0278C9.7797 12.0247 9.44964 12.0247 9.38851 12.031Z"
        fill={color || "#ffffffa6"}
      />
      <path 
        d="M13.7656 10.3329C13.7656 10.9935 13.7705 11.0596 13.817 11.1854C13.9563 11.5566 14.4698 11.931 15.1715 12.1701C16.2864 12.5539 17.834 12.513 18.8829 12.0726C19.3719 11.8681 19.79 11.5441 19.9269 11.2672L20.0002 11.1194V10.3864C20.0002 9.98367 19.9929 9.65334 19.9856 9.65334C19.9782 9.65334 19.922 9.69739 19.8633 9.75087C19.6995 9.89558 19.3328 10.1095 19.0418 10.2322C17.7607 10.7733 15.8829 10.7513 14.6311 10.185C14.3842 10.0749 14.0297 9.85469 13.8732 9.71312L13.7656 9.61874V10.3329Z"
        fill={color || "#ffffffa6"}
      />
      <path 
        d="M13.7656 12.8617C13.7656 13.6356 13.773 13.6796 13.927 13.8999C14.2497 14.3592 15.015 14.7272 16.0027 14.894C16.4404 14.9663 17.3499 14.9663 17.7876 14.894C18.8462 14.7147 19.6824 14.2774 19.9293 13.7803L20.0002 13.6356V12.8994V12.1664L19.8975 12.2482C19.3768 12.6572 18.7117 12.934 17.9098 13.0725C17.5088 13.1417 16.3548 13.1511 15.9661 13.0882C15.1641 12.9592 14.4184 12.6698 13.971 12.3111C13.8756 12.2325 13.7901 12.1696 13.7803 12.1696C13.773 12.1696 13.7656 12.481 13.7656 12.8617Z"
        fill={color || "#ffffffa6"}
      />
      <path 
        d="M2.46959 14.5637C1.89014 14.6297 1.26424 14.809 0.846153 15.0293C0.0148727 15.4665 -0.219842 16.0171 0.212913 16.511C0.594324 16.942 1.26179 17.244 2.21043 17.4139C2.62118 17.4862 3.58449 17.4862 4.02213 17.4107C4.94632 17.2566 5.67736 16.9168 6.0172 16.4858C6.21035 16.2405 6.2617 16.0863 6.21524 15.8881C6.01231 15.023 4.17372 14.3718 2.46959 14.5637Z"
        fill={color || "#ffffffa6"}
      />
      <path 
        d="M6.87109 15.3537C6.87109 15.7218 6.88332 16.0741 6.89554 16.137C6.97134 16.4862 7.40165 16.8575 8.03489 17.1186C8.64612 17.3702 9.18401 17.4678 10.0006 17.4678C10.9908 17.4678 11.7634 17.2853 12.4236 16.8984C12.7658 16.697 13.0543 16.373 13.1057 16.137C13.1326 16.008 13.1399 14.6867 13.113 14.6867C13.1008 14.6867 13.0446 14.7308 12.9834 14.7843C12.492 15.2121 11.6412 15.5267 10.6852 15.6337C10.2133 15.6872 9.32581 15.6557 8.86372 15.5676C8.11068 15.4292 7.42121 15.1366 7.01779 14.7843C6.95667 14.7308 6.90043 14.6867 6.89065 14.6867C6.87843 14.6867 6.87109 14.9888 6.87109 15.3537Z"
        fill={color || "#ffffffa6"}
      />
      <path 
        d="M13.7656 15.3846C13.7656 16.212 13.773 16.2434 14.0101 16.5203C14.3255 16.8883 14.9563 17.1966 15.7142 17.3602C17.2766 17.6937 19.1054 17.3036 19.8046 16.492C19.9953 16.2686 20.0027 16.2308 19.9953 15.4129L19.988 14.6893L19.8535 14.7994C19.389 15.1801 18.6555 15.4695 17.8242 15.6048C17.433 15.6677 16.2326 15.6583 15.8438 15.5891C15.0614 15.4475 14.3671 15.1612 13.9294 14.8026C13.8512 14.7365 13.7827 14.6862 13.7778 14.6862C13.7705 14.6862 13.7656 15.0008 13.7656 15.3846Z"
        fill={color || "#ffffffa6"}
      />
      <path 
        d="M0 17.9267C0 18.7603 0.00488988 18.7918 0.215155 19.0309C0.814165 19.7167 2.30558 20.1194 3.70898 19.9684C4.95345 19.8363 5.97788 19.3266 6.18815 18.732C6.22726 18.6188 6.2346 18.5181 6.2346 17.9015C6.2346 17.5177 6.22726 17.2031 6.21993 17.2031C6.2126 17.2031 6.15881 17.244 6.10013 17.2975C5.65026 17.6939 4.8312 18.0147 3.9608 18.1343C3.43514 18.2035 2.42783 18.1815 1.95595 18.0871C1.20536 17.9392 0.532997 17.653 0.158921 17.3289C0.105132 17.2849 0.0464539 17.2346 0.0317842 17.222C0.00733482 17.2031 0 17.3541 0 17.9267Z"
        fill={color || "#ffffffa6"}
      />
      <path 
        d="M6.87109 17.8701C6.87109 18.2602 6.88332 18.5936 6.89799 18.666C6.91755 18.7509 6.97623 18.8516 7.08869 18.9963C7.42365 19.4273 8.13757 19.7639 9.07154 19.9307C9.49941 20.0062 10.5043 20.0062 10.9297 19.9307C11.5898 19.8111 12.0324 19.6538 12.47 19.3833C12.6998 19.2417 12.7781 19.1725 12.9125 18.9995C13.025 18.8548 13.0837 18.7509 13.1032 18.666C13.1301 18.5433 13.1424 17.2031 13.1179 17.2031C13.113 17.2031 13.0495 17.2503 12.9786 17.3101C12.47 17.7254 11.7243 18.0148 10.8319 18.1375C10.338 18.2035 9.31114 18.1784 8.86372 18.0903C8.07156 17.9298 7.4701 17.6782 7.0349 17.3195C6.95667 17.2566 6.88821 17.2031 6.88332 17.2031C6.87598 17.2031 6.87109 17.5051 6.87109 17.8701Z"
        fill={color || "#ffffffa6"}
      />
      <path 
        d="M13.7656 17.9017C13.7656 18.5151 13.773 18.6189 13.8121 18.7322C14.0028 19.2702 14.9099 19.7641 16.0516 19.9465C16.4233 20.0063 17.4526 19.9969 17.8242 19.9308C18.6946 19.7735 19.4232 19.4463 19.7631 19.0562C19.9978 18.7888 20.0002 18.7731 20.0002 17.93V17.2033L19.8731 17.3102C19.4306 17.672 18.8169 17.9331 18.032 18.0904C17.5577 18.1879 16.3206 18.1942 15.8316 18.103C15.0272 17.9583 14.3035 17.6531 13.8952 17.2945C13.839 17.2442 13.7876 17.2033 13.7803 17.2033C13.773 17.2033 13.7656 17.5179 13.7656 17.9017Z"
        fill={color || "#ffffffa6"}
      />
    </svg>
  );
};

export default RoyaltyIcon;