import React from "react";
import { Button, Col, Row } from "antd";
import { Controller, useFormContext } from "react-hook-form";
import { DeleteOutlined } from "@ant-design/icons";
import Input from "../../../../Form/Input/GeneralInput";

const AliasesItem = ({ index, remove }) => {
  const formMethods = useFormContext();
  const { control } = formMethods;

  return (
    <Row gutter={[16, 16]} justify={"center"} align={"middle"}>
      <Col span={20}>
        <Controller
          control={control}
          name={`alias.${index}.alias`}
          rules={{ required: true }}
          render={({ field }) => <Input {...field} placeholder={"Alias"} />}
        />
      </Col>
      <Col span={4}>
        <Button
          className="remove"
          type="danger"
          onClick={remove}
          icon={<DeleteOutlined />}
        />
      </Col>
    </Row>
  );
};

export default AliasesItem;
