import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useMemo,
} from "react";
import User from "../../classes/user/user.jsx";
import Cookies from "js-cookie";
import Release from "../../classes/release/release";
import AdminCalls from "../../classes/admin/admin";
import { UserContext } from "../User";
import { LanguageContext } from "../Language";

export const ArtistContext = createContext();

export const ArtistProvider = ({ children }) => {
  const { sessionAuth } = useContext(UserContext);
  const { dictionary } = useContext(LanguageContext);
  const [artists, setArtists] = useState([]);
  const [loadingArtists, setLoading] = useState(true);
  const [roles, setRoles] = useState(null);
  const [invertedRoles, setinvertedRoles] = useState(null);
  const [frontRoles, setFrontRoles] = useState(null);
  const [rolesByType, setRolesByType] = useState({}); // [ { [type]:, [...roles] }
  const [contributorTypes, setContributorTypes] = useState([]);
  const [optionsInput, setOptionsInput] = useState([]);
  const [artistsByLabelId, setArtistsByLabelId] = useState({}); // [ { [labelId]:, [...artists] }

  const getArtists = () => {
    setLoading(true);
    let session = Cookies.get("qid");
    if (session) {
      User.getArtists(session)
        .then((res) => {
          let allArtists = [];
          if (res?.body?.artists) {
            allArtists = res?.body?.artists.sort((a, b) =>
              a.name.localeCompare(b.name)
            );
          }
          setArtists(allArtists || []);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    getArtists();
  }, []);

  useEffect(() => {
    if (sessionAuth)
      Release.getRoles()
        .then((roles) => {
          const roleKeys = Object.keys(roles);
          const invertedRoles = {};
          for (const rk of roleKeys) {
            const roleValues = Object.keys(roles[rk]);
            for (const rv of roleValues) {
              invertedRoles[roles[rk][rv]] = {
                type: rk,
                value: rv,
                // ¯\_(ツ)_/¯ everywhere we use plural in the key, but this endpoint is singular
                key: rk + "s",
              };
            }
          }
          setinvertedRoles(invertedRoles);
          setRoles(roles);
        })
        .catch((err) => {
          console.log(err);
        });
  }, [sessionAuth]);

  useEffect(() => {
    if (sessionAuth)
      Release.getFrontRoles()
        .then((roles) => {
          const rolesByType = {};
          const contributorTypes = Object.keys(roles);
          contributorTypes.map((artisType) => {
            rolesByType[artisType] = Object.keys(roles[artisType]);
          });
          setContributorTypes(contributorTypes);
          setRolesByType(rolesByType);
          setFrontRoles(roles);
        })
        .catch((err) => {
          console.log(err);
        });
  }, [sessionAuth]);

  const artistsOptions = useMemo(() => {
    if (optionsInput.length < artists.length) {
      let allOptions = [];
      artists.map((el) => {
        allOptions.push({ key: el._id, value: el.name, _id: el._id });
      });
      setOptionsInput(allOptions);
      return allOptions;
    } else {
      return optionsInput;
    }
  }, [artists]);

  const artistRoleOptions = useMemo(() => {
    if (!Object.hasOwnProperty.call(rolesByType, "artist")) return [];

    return rolesByType["artist"].map((item) => {
      return { label: dictionary.artistType[item] || item, value: item };
    });
  }, [rolesByType]);

  const writerRoleOptions = useMemo(() => {
    if (!Object.hasOwnProperty.call(rolesByType, "writer")) return [];

    return rolesByType["writer"].map((item) => {
      return { label: dictionary.artistType[item] || item, value: item };
    });
  }, [rolesByType]);

  const additionalRoleOptions = useMemo(() => {
    if (!Object.hasOwnProperty.call(rolesByType, "participant")) return [];

    return rolesByType["participant"].map((item) => {
      return { label: dictionary.artistType[item] || item, value: item };
    });
  }, [rolesByType]);

  /**
   * Update artist by the labelId, the owner of the release
   * @param {*} releaseId if labelId is not founded, the releaseId will be used to find the labelId
   * @param {*} labelId
   * @returns
   */
  const updateArtistsByLabelId = (labelId) => {
    if (Object.hasOwnProperty.call(artistsByLabelId, labelId)) return;

    AdminCalls.getArtistsByLabelId(labelId)
      .then((res) => {
        if (res?.artists) {
          setArtistsByLabelId({
            ...artistsByLabelId,
            [labelId]: res.artists,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <ArtistContext.Provider
      value={{
        artists,
        loadingArtists,
        roles,
        invertedRoles,
        getArtists,
        frontRoles,
        artistsOptions,
        rolesByType,
        contributorTypes,
        artistRoleOptions,
        writerRoleOptions,
        additionalRoleOptions,
        updateArtistsByLabelId,
        artistsByLabelId,
      }}
    >
      {children}
    </ArtistContext.Provider>
  );
};
