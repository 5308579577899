import React from "react";

const CardIcon = ({ className = null, onClick = null, color = null }) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M22.5 5.625H4.5C3.87868 5.625 3.375 6.12868 3.375 6.75V20.25C3.375 20.8713 3.87868 21.375 4.5 21.375H22.5C23.1213 21.375 23.625 20.8713 23.625 20.25V6.75C23.625 6.12868 23.1213 5.625 22.5 5.625Z"
        stroke={color}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.875 16.875H12.375"
        stroke={color}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.375 12.375H23.625"
        stroke={color}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.375 10.125H23.625"
        stroke={color}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CardIcon;
