import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../context/Language";
import Button from "../../components/Buttons/GeneralButton";
import "../Thanks/thanks.css";

const Thanks = () => {
  const { dictionary } = useContext(LanguageContext);

  return (
    <React.Fragment>
      <div className="container-box thanks-box">
        <div className="greeting">
          <h1>{dictionary.sorry}</h1>
          <h3>{dictionary.signUpError}</h3>
          <Link to="/register">
            <Button text={dictionary.signUp} size="lg" />
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Thanks;
