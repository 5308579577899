import React from "react";

const EditIcon = ({ className = null, onClick = null }) => {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M7.5 37.5H37.5"
        stroke="#878787"
        strokeWidth="2.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 37.5V38.375C15.2321 38.375 15.4546 38.2828 15.6187 38.1187L15 37.5ZM7.5 37.5H6.625C6.625 37.9832 7.01675 38.375 7.5 38.375V37.5ZM7.5 30L6.88128 29.3812C6.71719 29.5453 6.625 29.7679 6.625 30H7.5ZM28.6742 8.82579L29.2929 9.4445L29.2929 9.4445L28.6742 8.82579ZM31.3258 8.82579L30.7071 9.4445L30.7071 9.4445L31.3258 8.82579ZM36.1742 13.6741L36.7929 13.0554L36.7929 13.0554L36.1742 13.6741ZM36.1742 16.3258L35.5555 15.7071L35.5555 15.7071L36.1742 16.3258ZM15 36.625H7.5V38.375H15V36.625ZM8.375 37.5V30H6.625V37.5H8.375ZM8.11872 30.6187L29.2929 9.4445L28.0555 8.20707L6.88128 29.3812L8.11872 30.6187ZM30.7071 9.4445L35.5555 14.2929L36.7929 13.0554L31.9445 8.20707L30.7071 9.4445ZM35.5555 15.7071L14.3813 36.8812L15.6187 38.1187L36.7929 16.9445L35.5555 15.7071ZM35.5555 14.2929C35.946 14.6834 35.946 15.3165 35.5555 15.7071L36.7929 16.9445C37.8668 15.8706 37.8668 14.1294 36.7929 13.0554L35.5555 14.2929ZM29.2929 9.4445C29.6834 9.05398 30.3166 9.05398 30.7071 9.4445L31.9445 8.20707C30.8706 7.13313 29.1294 7.13313 28.0555 8.20707L29.2929 9.4445Z"
        fill="#878787"
      />
      <path
        d="M22.5 15L30 22.5"
        stroke="#878787"
        strokeWidth="2.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditIcon;
