import React from "react";

const LeftArrowIcon = ({ className = null, onClick = null }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M15.0508 19.9201L8.53078 13C7.76078 12.23 7.76078 12.27 8.53078 11.5L15.0508 4.08008"
        stroke="#7E7E8E"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
  </svg>
  );
};

export default LeftArrowIcon;
