import BaseClass from "..";

const exampleDataArray = [
  {
      "id": "ID_0",
      "name": "Name_0",
      "curveContractId": "CurveContractID_0",
      "periodId": "M1",
      "date": "2024-01-01T06:00:00.000Z",
      "year": 2024,
      "accountingPeriod": "AccountingPeriod_0",
      "periodType": "PeriodType_0",
      "status": "Status_0",
      "allFilesZip": "AllFilesZip_0",
      "salesCsvPublicLink": "SalesCsvPublicLink_0",
      "costsCsvPublicLink": "CostsCsvPublicLink_0",
      "pdfPublicLink": "PdfPublicLink_0",
      "statementOfAccountPublicLink": "StatementOfAccountPublicLink_0",
      "allFilesZipPublicLink": "AllFilesZipPublicLink_0",
      "grossAmount": 1000,
      "netAmount": 800,
      "grossCosts": 200,
      "netCosts": 150,
      "currency": "USD",
      "hasSublabels": false
  },
  {
      "id": "ID_1",
      "name": "Name_1",
      "curveContractId": "CurveContractID_1",
      "periodId": "M2",
      "date": "2024-02-01T06:00:00.000Z",
      "year": 2024,
      "accountingPeriod": "AccountingPeriod_1",
      "periodType": "PeriodType_1",
      "status": "Status_1",
      "allFilesZip": "AllFilesZip_1",
      "salesCsvPublicLink": "SalesCsvPublicLink_1",
      "costsCsvPublicLink": "CostsCsvPublicLink_1",
      "pdfPublicLink": "PdfPublicLink_1",
      "statementOfAccountPublicLink": "StatementOfAccountPublicLink_1",
      "allFilesZipPublicLink": "AllFilesZipPublicLink_1",
      "grossAmount": 1100,
      "netAmount": 900,
      "grossCosts": 250,
      "netCosts": 200,
      "currency": "USD",
      "hasSublabels": true
  },
  {
      "id": "ID_2",
      "name": "Name_2",
      "curveContractId": "CurveContractID_2",
      "periodId": "M3",
      "date": "2024-03-01T06:00:00.000Z",
      "year": 2024,
      "accountingPeriod": "AccountingPeriod_2",
      "periodType": "PeriodType_2",
      "status": "Status_2",
      "allFilesZip": "AllFilesZip_2",
      "salesCsvPublicLink": "SalesCsvPublicLink_2",
      "costsCsvPublicLink": "CostsCsvPublicLink_2",
      "pdfPublicLink": "PdfPublicLink_2",
      "statementOfAccountPublicLink": "StatementOfAccountPublicLink_2",
      "allFilesZipPublicLink": "AllFilesZipPublicLink_2",
      "grossAmount": 1200,
      "netAmount": 1000,
      "grossCosts": 300,
      "netCosts": 250,
      "currency": "USD",
      "hasSublabels": false
  },
  {
      "id": "ID_3",
      "name": "Name_3",
      "curveContractId": "CurveContractID_3",
      "periodId": "M4",
      "date": "2024-04-01T06:00:00.000Z",
      "year": 2024,
      "accountingPeriod": "AccountingPeriod_3",
      "periodType": "PeriodType_3",
      "status": "Status_3",
      "allFilesZip": "AllFilesZip_3",
      "salesCsvPublicLink": "SalesCsvPublicLink_3",
      "costsCsvPublicLink": "CostsCsvPublicLink_3",
      "pdfPublicLink": "PdfPublicLink_3",
      "statementOfAccountPublicLink": "StatementOfAccountPublicLink_3",
      "allFilesZipPublicLink": "AllFilesZipPublicLink_3",
      "grossAmount": 1300,
      "netAmount": 1100,
      "grossCosts": 350,
      "netCosts": 300,
      "currency": "USD",
      "hasSublabels": true
  },
  {
      "id": "ID_4",
      "name": "Name_4",
      "curveContractId": "CurveContractID_4",
      "periodId": "M5",
      "date": "2024-05-01T06:00:00.000Z",
      "year": 2024,
      "accountingPeriod": "AccountingPeriod_4",
      "periodType": "PeriodType_4",
      "status": "Status_4",
      "allFilesZip": "AllFilesZip_4",
      "salesCsvPublicLink": "SalesCsvPublicLink_4",
      "costsCsvPublicLink": "CostsCsvPublicLink_4",
      "pdfPublicLink": "PdfPublicLink_4",
      "statementOfAccountPublicLink": "StatementOfAccountPublicLink_4",
      "allFilesZipPublicLink": "AllFilesZipPublicLink_4",
      "grossAmount": 1400,
      "netAmount": 1200,
      "grossCosts": 400,
      "netCosts": 350,
      "currency": "USD",
      "hasSublabels": false
  },
  {
      "id": "ID_5",
      "name": "Name_5",
      "curveContractId": "CurveContractID_5",
      "periodId": "M6",
      "date": "2024-06-01T06:00:00.000Z",
      "year": 2024,
      "accountingPeriod": "AccountingPeriod_5",
      "periodType": "PeriodType_5",
      "status": "Status_5",
      "allFilesZip": "AllFilesZip_5",
      "salesCsvPublicLink": "SalesCsvPublicLink_5",
      "costsCsvPublicLink": "CostsCsvPublicLink_5",
      "pdfPublicLink": "PdfPublicLink_5",
      "statementOfAccountPublicLink": "StatementOfAccountPublicLink_5",
      "allFilesZipPublicLink": "AllFilesZipPublicLink_5",
      "grossAmount": 1500,
      "netAmount": 1300,
      "grossCosts": 450,
      "netCosts": 400,
      "currency": "USD",
      "hasSublabels": true
  },
  {
      "id": "ID_6",
      "name": "Name_6",
      "curveContractId": "CurveContractID_6",
      "periodId": "M7",
      "date": "2024-07-01T06:00:00.000Z",
      "year": 2024,
      "accountingPeriod": "AccountingPeriod_6",
      "periodType": "PeriodType_6",
      "status": "Status_6",
      "allFilesZip": "AllFilesZip_6",
      "salesCsvPublicLink": "SalesCsvPublicLink_6",
      "costsCsvPublicLink": "CostsCsvPublicLink_6",
      "pdfPublicLink": "PdfPublicLink_6",
      "statementOfAccountPublicLink": "StatementOfAccountPublicLink_6",
      "allFilesZipPublicLink": "AllFilesZipPublicLink_6",
      "grossAmount": 1600,
      "netAmount": 1400,
      "grossCosts": 500,
      "netCosts": 450,
      "currency": "USD",
      "hasSublabels": false
  },
  {
      "id": "ID_7",
      "name": "Name_7",
      "curveContractId": "CurveContractID_7",
      "periodId": "M8",
      "date": "2024-08-01T06:00:00.000Z",
      "year": 2024,
      "accountingPeriod": "AccountingPeriod_7",
      "periodType": "PeriodType_7",
      "status": "Status_7",
      "allFilesZip": "AllFilesZip_7",
      "salesCsvPublicLink": "SalesCsvPublicLink_7",
      "costsCsvPublicLink": "CostsCsvPublicLink_7",
      "pdfPublicLink": "PdfPublicLink_7",
      "statementOfAccountPublicLink": "StatementOfAccountPublicLink_7",
      "allFilesZipPublicLink": "AllFilesZipPublicLink_7",
      "grossAmount": 1700,
      "netAmount": 1500,
      "grossCosts": 550,
      "netCosts": 500,
      "currency": "USD",
      "hasSublabels": true
  },
  {
      "id": "ID_8",
      "name": "Name_8",
      "curveContractId": "CurveContractID_8",
      "periodId": "M9",
      "date": "2024-09-01T06:00:00.000Z",
      "year": 2024,
      "accountingPeriod": "AccountingPeriod_8",
      "periodType": "PeriodType_8",
      "status": "Status_8",
      "allFilesZip": "AllFilesZip_8",
      "salesCsvPublicLink": "SalesCsvPublicLink_8",
      "costsCsvPublicLink": "CostsCsvPublicLink_8",
      "pdfPublicLink": "PdfPublicLink_8",
      "statementOfAccountPublicLink": "StatementOfAccountPublicLink_8",
      "allFilesZipPublicLink": "AllFilesZipPublicLink_8",
      "grossAmount": 1800,
      "netAmount": 1600,
      "grossCosts": 600,
      "netCosts": 550,
      "currency": "USD",
      "hasSublabels": false
  },
  {
      "id": "ID_9",
      "name": "Name_9",
      "curveContractId": "CurveContractID_9",
      "periodId": "M10",
      "date": "2024-10-01T06:00:00.000Z",
      "year": 2024,
      "accountingPeriod": "AccountingPeriod_9",
      "periodType": "PeriodType_9",
      "status": "Status_9",
      "allFilesZip": "AllFilesZip_9",
      "salesCsvPublicLink": "SalesCsvPublicLink_9",
      "costsCsvPublicLink": "CostsCsvPublicLink_9",
      "pdfPublicLink": "PdfPublicLink_9",
      "statementOfAccountPublicLink": "StatementOfAccountPublicLink_9",
      "allFilesZipPublicLink": "AllFilesZipPublicLink_9",
      "grossAmount": 1900,
      "netAmount": 1700,
      "grossCosts": 650,
      "netCosts": 600,
      "currency": "USD",
      "hasSublabels": true
  },
  {
      "id": "ID_10",
      "name": "Name_10",
      "curveContractId": "CurveContractID_10",
      "periodId": "M11",
      "date": "2024-11-01T06:00:00.000Z",
      "year": 2024,
      "accountingPeriod": "AccountingPeriod_10",
      "periodType": "PeriodType_10",
      "status": "Status_10",
      "allFilesZip": "AllFilesZip_10",
      "salesCsvPublicLink": "SalesCsvPublicLink_10",
      "costsCsvPublicLink": "CostsCsvPublicLink_10",
      "pdfPublicLink": "PdfPublicLink_10",
      "statementOfAccountPublicLink": "StatementOfAccountPublicLink_10",
      "allFilesZipPublicLink": "AllFilesZipPublicLink_10",
      "grossAmount": 2000,
      "netAmount": 1800,
      "grossCosts": 700,
      "netCosts": 650,
      "currency": "USD",
      "hasSublabels": false
  },
  {
      "id": "ID_11",
      "name": "Name_11",
      "curveContractId": "CurveContractID_11",
      "periodId": "M12",
      "date": "2024-12-01T06:00:00.000Z",
      "year": 2024,
      "accountingPeriod": "AccountingPeriod_11",
      "periodType": "PeriodType_11",
      "status": "Status_11",
      "allFilesZip": "AllFilesZip_11",
      "salesCsvPublicLink": "SalesCsvPublicLink_11",
      "costsCsvPublicLink": "CostsCsvPublicLink_11",
      "pdfPublicLink": "PdfPublicLink_11",
      "statementOfAccountPublicLink": "StatementOfAccountPublicLink_11",
      "allFilesZipPublicLink": "AllFilesZipPublicLink_11",
      "grossAmount": 2100,
      "netAmount": 1900,
      "grossCosts": 750,
      "netCosts": 700,
      "currency": "USD",
      "hasSublabels": true
  }
];

class RoyaltiesCalls extends BaseClass {
  async getExample(filter) {
    const exampleData = [];
    await new Promise((resolve) => {
      setTimeout(resolve, 600);
    });
    // get the data from exampleData array filter by year and periodId
    exampleData.push(...exampleDataArray.filter((item) => item.year === filter.year && filter.periodId.includes(item.periodId)));
    return {
      status: 200,
      body: {
        statements: exampleData,
      },
    };
  }

  async getExampleSublabels(labelId, filter) {
    await new Promise((resolve) => {
      setTimeout(resolve, 600);
    });

    const exampleData = [{
      labelId: `${labelId}`,
      id: `Sub_${labelId}`,
      name: `Name_${labelId}`,
      curveContractId: `CurveContractID_`,
      periodId: filter.periodId,
      date: new Date(),
      year: filter.year,
      accountingPeriod: `AccountingPeriod_`,
      periodType: `PeriodType_`,
      status: `Status_`,
      allFilesZip: `AllFilesZip_`,
      salesCsvPublicLink: `SalesCsvPublicLink_`,
      costsCsvPublicLink: `CostsCsvPublicLink_`,
      pdfPublicLink: `PdfPublicLink_`,
      statementOfAccountPublicLink: `StatementOfAccountPublicLink_`,
      allFilesZipPublicLink: `AllFilesZipPublicLink_`,
      grossAmount: 1000 * 100,
      netAmount: 800 * 100,
      grossCosts: 200 * 50,
      netCosts: 150 * 50,
      currency: "USD",
    }];

    return {
      status: 200,
      body: {
        statements: exampleData,
      },
    };
  }

  /**
   * 
    * {object} filter {
        year: 2021,
        periodType: "Q1",
        page: 1,
        limit: 100,
      }
     */
  getRoyalties(params) {
    // labelId is not mandatory but we can use it on admin side
    this.setValues("/royalties", {}, params);
    return this.doGet();
  }

  async getSublabelRoyalties(params) {
    this.setValues(`/royalties/`, {}, params);
    return this.doGet();
  }
}

export default new RoyaltiesCalls();
