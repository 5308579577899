import React from "react";

const UploadIcon = ({ className = null, onClick = null }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M9.32031 6.49945L11.8803 3.93945L14.4403 6.49945"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"/>
      <path
        d="M11.8799 14.18V4.01001"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"/>
      <path
        d="M4 12C4 16.42 7 20 12 20C17 20 20 16.42 20 12"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"/>
    </svg>
  );
};

export default UploadIcon;
