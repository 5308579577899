import React, { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../../../../context/Language";
import Button from "../../../../Buttons/GeneralButton";
import Release from "../../../../../classes/release/release";

const Success = ({ releaseId }) => {
  const { dictionary } = useContext(LanguageContext);
  const [rollbackState, setRollbackState] = useState({
    canRollBack: false,
    rollbackRequested: false,
  });

  useEffect(() => {
    if (releaseId)
      Release.canRollback(releaseId).then((res) => {
        if (res && res.canRollBack)
          setRollbackState({
            canRollBack: res.canRollBack,
            rollbackRequested: res.rollbackRequested,
          });
      });
  }, [releaseId]);

  const onClick =
    rollbackState.canRollBack && !rollbackState.rollbackRequested
      ? () => Release.requestRollback(releaseId)
      : () => {};

  const descriptionText = rollbackState.rollbackRequested
    ? dictionary.rollbackRequestedDesc
    : dictionary.requestRollbackDesc.replace(
        "--button-name--",
        dictionary.requestRollback
      );
  return !rollbackState.canRollBack ? null : (
    <div
      style={{
        backgroundColor: "#f4f4f4",
        padding: "15px 15px 0 15px",
        marginBottom: "15px",
      }}
    >
      {descriptionText}
      <div className="align-right">
        <Button
          text={dictionary.requestRollback}
          size="xg"
          disabled={!rollbackState.canRollBack}
          onClick={onClick}
        />
      </div>
    </div>
  );
};

export default Success;
