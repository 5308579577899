import React, { useContext } from "react";
import { LanguageContext } from "../../../context/Language";
import "../progress.css";

const CreateBar = ({
  orangeBar,
  validation,
  nextSection,
  changeSection,
  validStep,
  canMove,
  visibility
}) => {

  const { dictionary } = useContext(LanguageContext);

  const next = async (n) => {

    if (canMove) {
      if (n === 0 || validation["step" + (n - 1)] || (await validStep())) {
        changeSection(n);
        for (let k = 0; k <= 7; k++) {
          if (k > n) {
            orangeBar.current.classList.remove("stepFilled" + k);
            document.getElementById("bar" + k).classList.remove("fillStep");
          } else {
            nextSection(k);
            orangeBar.current.classList.add("stepFilled" + k);
          }
        }
      }
    }
  };

  const renderBar = () => {
    let steps = [
      { id: 0, nameStep: dictionary.start },
      { id: 1, nameStep: dictionary.releaseInfo },
      { id: 2, nameStep: dictionary.rights },
      { id: 3, nameStep: dictionary.participants },
      { id: 4, nameStep: dictionary.tracks },
      { id: 5, nameStep: dictionary.territories },
      { id: 6, nameStep: dictionary.distribution },
      { id: 7, nameStep: dictionary.review },
    ];

    return steps.map((item, index) => {
      let classPosition =
        item.id === 0 ? "left fillStep " : item.id === 7 ? "right " : "";

      return (
        <div
          key={index}
          id={"bar" + item.id}
          onClick={item.id !== 7 ? () => next(item.id): null}
          className={classPosition + " bar "}
        >
          <div>{item.nameStep}</div>
        </div>
      );
    });
  };
  return (
    <div className="bar-text" style={{visibility: visibility}}>
      {renderBar()}
      <div id="orangeBar" ref={orangeBar} className="topBar stepFilled"></div>
    </div>
  );
};

export default CreateBar;
