import React from "react";

const ReleaseIcon = ({ className = null, onClick = null, color = null }) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.7071 0.292922C18.4211 0.00692444 17.991 -0.0786313 17.6173 0.0761497C17.2436 0.230931 17 0.595567 17 1.00003V5.17073C16.6872 5.06018 16.3506 5.00003 16 5.00003C14.3431 5.00003 13 6.34317 13 8.00003C13 9.65688 14.3431 11 16 11C17.6569 11 19 9.65688 19 8.00003V3.41424L19.2929 3.70714C19.6834 4.09766 20.3166 4.09766 20.7071 3.70714C21.0976 3.31661 21.0976 2.68345 20.7071 2.29292L18.7071 0.292922ZM10 3.00003C5.58172 3.00003 2 6.58175 2 11C2 15.4183 5.58172 19 10 19C13.6887 19 16.7967 16.5026 17.7206 13.1046C17.8654 12.5716 18.4149 12.2571 18.9479 12.402C19.4808 12.5469 19.7954 13.0963 19.6505 13.6293C18.496 17.8759 14.6141 21 10 21C4.47715 21 0 16.5229 0 11C0 5.47718 4.47715 1.00003 10 1.00003C11.3001 1.00003 12.5446 1.24866 13.6868 1.70188C14.2001 1.90558 14.4512 2.48686 14.2475 3.00021C14.0438 3.51356 13.4625 3.76457 12.9491 3.56087C12.0379 3.19927 11.0434 3.00003 10 3.00003ZM10 10C9.44772 10 9 10.4477 9 11C9 11.5523 9.44772 12 10 12C10.5523 12 11 11.5523 11 11C11 10.4477 10.5523 10 10 10ZM7 11C7 9.34317 8.34315 8.00003 10 8.00003C11.6569 8.00003 13 9.34317 13 11C13 12.6569 11.6569 14 10 14C8.34315 14 7 12.6569 7 11Z"
      fill={color || "#ffffffa6"}
    />
    </svg>
  );
};

export default ReleaseIcon;
