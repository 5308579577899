import React from "react";
import { Button, Row, Col, Divider } from "antd";
import { useFormContext, Controller } from "react-hook-form";
import { DeleteOutlined } from "@ant-design/icons";
import Input from "../../../../Form/Input/GeneralInput";

const EscalationItem = ({ index, remove }) => {
  const formMethods = useFormContext();
  const { control } = formMethods;

  return (
    <Row gutter={[16, 16]} justify={"center"} align={"middle"}>
      <Col xs={12} md={6}>
        <Controller
          control={control}
          name={`escalation.${index}.name`}
          rules={{
            required: "This field is required",
          }}
          render={({ field, fieldState }) => (
            <Input
              label={"Name"}
              placeholder={"Name"}
              {...field}
              error={fieldState.error?.message}
            />
          )}
        />
      </Col>
      <Col xs={12} md={4}>
        <Controller
          control={control}
          name={`escalation.${index}.units`}
          rules={{
            required: "This field is required",
            min: "Field must be grather or equal than 0",
          }}
          render={({ field, fieldState }) => (
            <Input
              label={"Units"}
              placeholder={"Units"}
              {...field}
              type="number"
              min={0}
              error={fieldState.error?.message}
            />
          )}
        />
      </Col>
      <Col xs={12} md={6}>
        <Controller
          control={control}
          name={`escalation.${index}.grossAmount`}
          rules={{
            required: "This field is required",
            min: "Field must be grather or equal than 0",
          }}
          render={({ field, fieldState }) => (
            <Input
              label={"Gross amount"}
              placeholder={"Gross amount"}
              {...field}
              type="number"
              min={0}
              error={fieldState.error?.message}
            />
          )}
        />
      </Col>
      <Col xs={12} md={6}>
        <Controller
          control={control}
          name={`escalation.${index}.netAmount`}
          rules={{
            required: "This field is required",
            min: "Field must be grather or equal than 0",
          }}
          render={({ field, fieldState }) => (
            <Input
              label={"Net amount"}
              placeholder={"Net amount"}
              {...field}
              type="number"
              min={0}
              error={fieldState.error?.message}
            />
          )}
        />
      </Col>
      <Col xs={24} md={1}>
        <Button
          className="remove"
          type="danger"
          onClick={remove}
          icon={<DeleteOutlined />}
        />
      </Col>
      <Divider />
    </Row>
  );
};

export default EscalationItem;
