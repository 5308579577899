import React, { useContext, useMemo } from "react";
import Input from "../../../../../../Form/Input/GeneralInput";
import { useFormContext, Controller } from "react-hook-form";
import { Button, Select } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { AppContext } from "../../../../../../../context/App/AppProvider";
import DateInput from "../../../../../../Form/Date";

const CreateSalesEscalationCondition = ({
  salesIndex,
  parentIndex,
  index,
  remove,
  addItem,
}) => {
  const formMethods = useFormContext();
  const {
    escalationTrackerContractOptions,
    tresholdTypeContractOptions,
    whenContractOptions,
  } = useContext(AppContext);
  const { control, watch } = formMethods;

  const escalationTracker = watch(
    `salesTerms-escalation-conditions_${salesIndex}-${parentIndex}.${index}.escalationTracker`
  );

  const thresholdType = watch(
    `salesTerms-escalation-conditions_${salesIndex}-${parentIndex}.${index}.thresholdType`
  );

  const isEscalationTracker = useMemo(
    () =>
      (!escalationTracker && !thresholdType) ||
      (escalationTracker && !thresholdType),
    [escalationTracker, thresholdType]
  );

  const isThresholdType = useMemo(
    () =>
      (!escalationTracker && !thresholdType) ||
      (!escalationTracker && thresholdType),

    [thresholdType, escalationTracker]
  );

  return (
    <div className="input-row">
      {isEscalationTracker && (
        <div className={"relative-input"}>
          <Controller
            control={control}
            name={`salesTerms-escalation-conditions_${salesIndex}-${parentIndex}.${index}.escalationTracker`}
            rules={{ required: "This field is required" }}
            render={({ field, fieldState }) => (
              <React.Fragment>
                <label className="allWidth">Esc. Tracker</label>
                <Select
                  className={
                    "fullWidth selector-general" +
                    (fieldState.error ? " input-general-error" : "")
                  }
                  options={escalationTrackerContractOptions}
                  {...field}
                />
                {fieldState.error && (
                  <div className="text-error">{fieldState.error.message}</div>
                )}
              </React.Fragment>
            )}
          />
        </div>
      )}
      {isThresholdType && (
        <div className={"relative-input"}>
          <Controller
            control={control}
            name={`salesTerms-escalation-conditions_${salesIndex}-${parentIndex}.${index}.thresholdType`}
            rules={{ required: "This field is required" }}
            render={({ field, fieldState }) => (
              <React.Fragment>
                <label className="allWidth">Threshold Type</label>
                <Select
                  className={
                    "fullWidth selector-general" +
                    (fieldState.error ? " input-general-error" : "")
                  }
                  options={tresholdTypeContractOptions}
                  {...field}
                />
                {fieldState.error && (
                  <div className="text-error">{fieldState.error.message}</div>
                )}
              </React.Fragment>
            )}
          />
        </div>
      )}
      {isThresholdType && (
        <div className={"relative-input"}>
          <Controller
            control={control}
            name={`salesTerms-escalation-conditions_${salesIndex}-${parentIndex}.${index}.when`}
            rules={{ required: "This field is required" }}
            render={({ field, fieldState }) => (
              <React.Fragment>
                <label className="allWidth">When</label>
                <Select
                  className={
                    "fullWidth selector-general" +
                    (fieldState.error ? " input-general-error" : "")
                  }
                  options={whenContractOptions}
                  {...field}
                />
                {fieldState.error && (
                  <div className="text-error">{fieldState.error.message}</div>
                )}
              </React.Fragment>
            )}
          />
        </div>
      )}
      <Controller
        control={control}
        name={`salesTerms-escalation-conditions_${salesIndex}-${parentIndex}.${index}.threshold`}
        rules={{
          required: "This field is required",
          min: "Field must be grather or equal than 0",
        }}
        render={({ field, fieldState }) =>
          !isThresholdType ? (
            <DateInput
              label={"Threshold"}
              {...field}
              className={fieldState.error ? "input-general-error" : ""}
              required
              error={fieldState.error?.message}
            />
          ) : (
            <Input
              className={"form-box-input"}
              label={"Threshold"}
              placeholder={"Threshold"}
              type="number"
              min={0}
              {...field}
              error={fieldState.error?.message}
            />
          )
        }
      />
      {remove && (
        <Button
          className="remove"
          type="danger"
          onClick={() => remove()}
          icon={<DeleteOutlined />}
        />
      )}
      {addItem && (
        <Button type="primary" onClick={() => addItem()}>
          Add Condition
        </Button>
      )}
    </div>
  );
};

export default CreateSalesEscalationCondition;
