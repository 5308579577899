import BaseClass from "../";

class LanguageCalls extends BaseClass {
  languages = () => {
    this.setValues("/language/get");
    return this.doGet();
  };
}

export default new LanguageCalls();
