import React, { useContext } from "react";
import { useFieldArray } from "react-hook-form";
import { LanguageContext } from "../../../context/Language";
import { Button, Card, Row, Col } from "antd";
import AliasesItem from "../../../components/Contracts/Create/Aliases/AliasesItem/AliasesItem";

const CreateContractAliases = () => {
  const { dictionary } = useContext(LanguageContext);

  const {
    fields: aliasesfields,
    append: appendAlias,
    remove: removeAlias,
  } = useFieldArray({
    name: "alias",
  });

  const addAlias = () => {
    appendAlias({
      alias: "",
    });
  };

  const drawAlias = () => {
    return aliasesfields.map((st, index) => {
      return (
        <AliasesItem
          key={st.id}
          data={st}
          index={index}
          remove={() => removeAlias(index)}
        />
      );
    });
  };

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} lg={18} xl={12}>
        <Card bordered={false}>
          <h3>{dictionary.aliases}</h3>
          {drawAlias()}
          <Button type="primary" onClick={addAlias} className="add-item">
            + {dictionary.alias}
          </Button>
        </Card>
      </Col>
    </Row>
  );
};

export default CreateContractAliases;
