import BaseClass from "../";

class CountryCalls extends BaseClass {
  countries = () => {
    this.setValues("/country/get");
    return this.doGet();
  };

  countries_region = (region) => {
    this.setValues("/country/getByRegion/" + region);
    return this.doGet();
  };
}

export default new CountryCalls();
