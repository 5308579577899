import React, { useContext, useEffect, useMemo, useState } from "react";
import { LanguageContext } from "../../../context/Language/index.js";
import { useFieldArray, useFormContext, Controller } from "react-hook-form";
import "../CreateContract.css";
import TextArea from "antd/lib/input/TextArea";

import { Button, Checkbox, Select, Popover, Spin } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import DateInput from "../../../components/Form/Date/index.js";
import CreateCrossContract from "../../../components/Contracts/Create/Overview/CrossContract";
import Input from "../../../components/Form/Input/index.js";
import { AppContext } from "../../../context/App/AppProvider.js";
import Admin from "../../../classes/admin/admin.jsx";

const CreateContractOverview = () => {
  const { control, watch } = useFormContext();
  const { dictionary } = useContext(LanguageContext);
  const {
    currencyContractOptions,
    accountingPeriodContractOptions,
    categoriesContractOptions,
    labelOptions,
    tariffPlansOptions,
  } = useContext(AppContext);
  const {
    fields: crossContracts,
    append: appendCrossContracts,
    remove: removeCrossContracts,
  } = useFieldArray({
    name: "crossContracts",
  });

  const [companies, setCompanies] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(false);

  const contractType = watch(`contractType`);

  const showProfit = useMemo(
    () => contractType === "620ab4e35b9836178b35f100",
    [contractType]
  );

  useEffect(() => {
    setLoading(true);
    Admin.contracts().then((resp) => {
      if (resp.status === 200) {
        let contractOptions = [];
        resp.body.contract?.forEach((c) => {
          const curveContracts = c.contracts.filter((cc) => cc.curve_id);
          contractOptions = contractOptions.concat(
            curveContracts.map((cc) => ({
              name: cc.curve_id,
              value: cc.name,
            }))
          );
        });
        setContracts(contractOptions);
      } else {
        setContracts([]);
      }
    }).finally(() => {
      setLoading(false);
    });
    Admin.getCompanies().then((companies) => {
      setCompanies(companies);
    });
  }, []);

  const drawCrossContracts = () => {
    return crossContracts.map((cc, index) => {
      return (
        <CreateCrossContract
          key={cc.id}
          data={cc}
          index={index}
          remove={deleteCrossContract}
          contractOptions={contracts}
        />
      );
    });
  };

  const addCrossContract = () => {
    appendCrossContracts({
      when: "",
      contractId: "",
      process: "",
      percentage: "",
    });
  };

  const deleteCrossContract = (index) => {
    removeCrossContracts(index);
  };

  return (
    <div className="form-content">
      {loading && <div className="load-overview"><Spin size="large" className="spinr"  /></div>}
      <div className="form-column">
        <div className="form-box">
          <h3>{dictionary.overview}</h3>
          <Controller
            control={control}
            name="name"
            rules={{
              required: "Name is required",
            }}
            render={({ field, fieldState }) => (
              <Input
                className={"form-box-input"}
                label={dictionary.name}
                placeholder={dictionary.name}
                required
                {...field}
                error={fieldState.error?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="companyId"
            render={({ field, fieldState }) => (
              <div className="form-box-select">
                <label className="allWidth">
                  {dictionary.company}
                  <Popover content={dictionary.companyIdInfo}>
                    <QuestionCircleOutlined style={{ marginLeft: 8 }} />
                  </Popover>
                </label>
                <Select
                  className={
                    "fullWidth selector-general" +
                    (fieldState.error ? " input-general-error" : "")
                  }
                  options={companies.map((company) => ({
                    label: company.name,
                    value: company._id,
                  }))}
                  {...field}
                />
                {fieldState.error ? (
                  <div className="text-error">{fieldState.error.message}</div>
                ) : null}
              </div>
            )}
          />
          <Controller
            control={control}
            name="projectId"
            rules={{
              required: "Project id is required",
              validate: (value) => {
                const isValid = /^[a-zA-Z0-9]{5,10}$/.test(value);
                return isValid || "Invalid Project Id (5-10 characters)";
              }
            }}
            render={({ field, fieldState }) => (
              <Input
                className={"form-box-input"}
                label={"Project Id"}
                placeholder={dictionary.name}
                required
                {...field}
                error={fieldState.error?.message}
              />
            )}
          />
          <div className={"form-box-select"}>
            <Controller
              control={control}
              name="currency"
              rules={{
                required: "Currency is required",
              }}
              render={({ field, fieldState }) => (
                <React.Fragment>
                  <label className="allWidth">
                    {dictionary.currency}<span>*</span>
                  </label>
                  <Select
                    required={true}
                    className={
                      "fullWidth selector-general" +
                      (fieldState.error ? " input-general-error" : "")
                    }
                    options={currencyContractOptions}
                    {...field}
                  />
                  {fieldState.error ? (
                    <div className="text-error">{fieldState.error.message}</div>
                  ) : null}
                </React.Fragment>
              )}
            />
          </div>
          <div className={"form-box-select"}>
            <Controller
              control={control}
              name="accountingPeriod"
              rules={{
                required: "Accounting Period is required",
              }}
              render={({ field, fieldState }) => (
                <React.Fragment>
                  <label className="allWidth">
                    {dictionary.accountingPeriod}<span>*</span>
                  </label>
                  <Select
                    className={
                      "fullWidth selector-general" +
                      (fieldState.error ? " input-general-error" : "")
                    }
                    options={accountingPeriodContractOptions}
                    {...field}

                  />
                  {fieldState.error ? (
                    <div className="text-error">{fieldState.error.message}</div>
                  ) : null}
                </React.Fragment>
              )}
            />
          </div>
          <div className={"form-box-select"}>
            <Controller
              control={control}
              name="contractType"
              rules={{
                required: "Contract Type is required",
              }}
              render={({ field, fieldState }) => (
                <React.Fragment>
                  <label className="allWidth">
                    {dictionary.contractType}<span>*</span>
                  </label>
                  <Select
                    className={
                      "fullWidth selector-general" +
                      (fieldState.error ? " input-general-error" : "")
                    }
                    options={tariffPlansOptions}
                    {...field}
                  />
                  {fieldState.error ? (
                    <div className="text-error">{fieldState.error.message}</div>
                  ) : null}
                </React.Fragment>
              )}
            />
          </div>
          {showProfit && (
            <div className={"form-box-select"}>
              <Controller
                control={control}
                name="profitSharePercentage"
                rules={{
                  required: "Profit Share % is required",
                }}
                render={({ field, fieldState }) => (
                  <Input
                    className={"form-box-input"}
                    label={"Profit Share %"}
                    placeholder={dictionary.company}
                    {...field}
                    error={fieldState.error?.message}
                  />
                )}
              />
            </div>
          )}
        </div>
        <div className="form-box">
          <h3>{dictionary.notes}</h3>
          <Controller
            control={control}
            name="notes"
            render={({ field }) => (
              <TextArea rows={8} className={"form-box-textarea"} {...field} />
            )}
          />
        </div>
      </div>
      <div className="form-column">
        <div className="form-box">
          <div className={"form-box-select"}>
            <h3>{dictionary.finances}</h3>
            <Controller
              control={control}
              name="label"
              rules={{
                required: "Label is required",
              }}
              render={({ field, fieldState }) => (
                <React.Fragment>
                  <label className="allWidth">
                    {dictionary.label}<span>*</span>
                  </label>
                  <Select
                    className={
                      "fullWidth selector-general" +
                      (fieldState.error ? " input-general-error" : "")
                    }
                    options={labelOptions}
                    {...field}
                  />
                  {fieldState.error ? (
                    <div className="text-error">{fieldState.error.message}</div>
                  ) : null}
                </React.Fragment>
              )}
            />
          </div>
          <Controller
            control={control}
            name="minPayout"
            render={({ field }) => (
              <Input
                className={"form-box-input"}
                label={dictionary.minPayout}
                placeholder={dictionary.minPayout}
                type="number"
                min={0}
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="selfBillingInvoices"
            render={({ field }) => (
              <Checkbox {...field} checked={!!field.value}>
                {dictionary.selfBillingInvoices}
              </Checkbox>
            )}
          />
          <Controller
            control={control}
            name="feesInvoices"
            render={({ field }) => (
              <Checkbox {...field} checked={!!field.value}>
                {dictionary.feesInvoices}
              </Checkbox>
            )}
          />
          <Controller
            control={control}
            name="withholdingTax"
            render={({ field }) => (
              <Checkbox {...field} checked={!!field.value}>
                {dictionary.withholdingTax}
              </Checkbox>
            )}
          />
        </div>
        <div className="form-box">
          <h3>{dictionary.autoPayment}</h3>
          <Controller
            control={control}
            name="autoPayment"
            render={({ field }) => (
              <Checkbox {...field}>{dictionary.generateAutoPayment}</Checkbox>
            )}
          />
        </div>
        <div className="form-box">
          <h3>{dictionary.contractDetails}</h3>
          <Controller
            control={control}
            name="startDate"
            rules={{
              required: "Start Date is required",
            }}
            render={({ field, fieldState }) => (
              <DateInput
                label={dictionary.startDate}
                className={fieldState.error ? "input-general-error" : ""}
                {...field}
                required
                error={fieldState.error?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="endDate"
            rules={{
              required: "End Date is required",
            }}
            render={({ field, fieldState }) => (
              <DateInput
                label={dictionary.endDate}
                className={fieldState.error ? "input-general-error" : ""}
                {...field}
                required
                error={fieldState.error?.message}
              />
            )}
          />
          <div className={"form-box-select"}>
            <Controller
              control={control}
              name="categories"
              render={({ field }) => (
                <React.Fragment>
                  <label className="allWidth">{dictionary.categories}</label>
                  <Select
                    mode="multiple"
                    className={"fullWidth selector-general"}
                    options={categoriesContractOptions}
                    {...field}
                  />
                </React.Fragment>
              )}
            />
          </div>
        </div>
        <div className="form-box">
          <h3>{dictionary.files}</h3>
          <p>{dictionary.saveContractBeforeUploadingFiles}</p>
        </div>
        <div className="form-box">
          <h3>{dictionary.crossContract}</h3>
          <p>{dictionary.crossContractDescription1}</p>
          <p>{dictionary.crossContractDescription2}</p>
          {drawCrossContracts()}
          <Button type="primary" onClick={() => addCrossContract()}>
            {dictionary.addCrossContract}
          </Button>
        </div>
        <div className="form-box">
          <h3>{dictionary.summaryStatement}</h3>
          <p>{dictionary.summaryStatementDescription1}</p>
          <p>{dictionary.summaryStatementDescription2}</p>
          <Controller
            control={control}
            name="associatedStatements"
            render={({ field }) => (
              <Checkbox {...field} checked={!!field.value}>
                {dictionary.totalAllAssociatedStatements}
              </Checkbox>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateContractOverview;
