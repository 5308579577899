import TariffPlanCalls from "../calls/tariff_plan/tariff_plan.jsx";

class TariffPlan {
  /**
   * @returns * @returns {null|object}
   */
  async getAll() {
    const res = await TariffPlanCalls.getTariffPlans();
    if (res.status === 200) {
      return res.body;
    }
    return { error: true, status: res.status };
  }
}

export default new TariffPlan();
