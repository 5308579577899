import React from "react";
import { Select } from "antd";
import "../form.css";

const multiSelectInput = ({
  refs = null,
  name = null,
  label = null,
  value = null,
  error = false,
  options = [],
  onChange = null,
  required = false,
  className = null,
  disabled = false,
  placeholder = null,
}) => {
  const { Option } = Select;

  return (
    <div className="relative-input">
      {label ? (
        <label className="allWidth">
          {label}
          <span>{required ? "*" : ""}</span>
        </label>
      ) : null}
      <Select
        ref={refs}
        allowClear
        name={name}
        mode="multiple"
        onChange={onChange}
        defaultValue={value}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        className={className}
        style={{ width: "100%" }}
        optionFilterProp={"label"}
      >
        {options.map((el, index) => {
          return (
            <Option key={index + 1} value={el._id} label={el.name}>
              {el.name}
            </Option>
          );
        })}
      </Select>
      {(error && value.length === 0) ? <div className="multitext-error">{error}</div> : null}
    </div>
  );
};
export default multiSelectInput;
