import React from "react";
import { Col, Select } from "antd";
import CrossFillIcon from "../../../../Icon/CrossFillIcon";
import RoleIcon from "../../../../Icon/RoleIcon";
import { UserOutlined } from "@ant-design/icons";

const ContributorsEditView = ({
  contributorId,
  contributorIterable,
  onChange,
  disabled,
  multiple,
  contributorOptions,
  roleOptions,
  invertedRoles,
  originalContributors,
  addContributorKey,
  labelArtists,
}) => {

  return (
    <React.Fragment>
      <Col xs={21} sm={12} md={12} lg={12} className="row-track-input left">
        <div className="row-preview-track name-icon">
          <div className="icon-head">
            <UserOutlined />
          </div>
        </div>
        <Select
          style={{ width: "100%" }}
          name="contributor"
          defaultValue={contributorId}
          options={contributorOptions}
          onChange={(e) => {
            onChange({
              type: "contributors",
              payload: {
                contributor: labelArtists.find(
                  (item) => item._id === contributorId
                ),
                addKey: addContributorKey,
                invertedRoles,
                type: "contributor",
                original: originalContributors,
                contributorIterable,
                value: {
                  artist_id: labelArtists.find((item) => item._id === e)?._id,
                  name: labelArtists.find((item) => item._id === e)?.name,
                  role: roleOptions[0].value,
                  _id: "new_" + new Date().toISOString(),
                  origin: addContributorKey,
                },
              },
            });
          }}
          className={"select-role selector-general uniselect"} /*className={
            (errors?.roles && contributor.role.length === 0
              ? "inputErrMulti "
              : "") + "select-role selector-general uniselect"
          }*/
          disabled={disabled}
        />
      </Col>
      <Col xs={20} sm={10} md={10} lg={10} className="row-track-input right">
        <div className="row-preview-track role-icon">
          <div className="icon-head">
            <RoleIcon />
          </div>
        </div>
        <Select
          allowClear={multiple}
          style={{ width: "100%" }}
          name="role"
          defaultValue={contributorIterable.map((item) => item.role)}
          mode={multiple ? "multiple" : null}
          options={roleOptions}
          onChange={(e) => {
            onChange({
              type: "contributors",
              payload: {
                type: "change",
                original: originalContributors,
                contributorIterable,
                contributor: labelArtists.find(
                  (item) => item._id === contributorId
                ),
                invertedRoles,
                addKey: addContributorKey,
                value: e,
              },
            });
          }}
          className={"select-role selector-general uniselect multiselect"}
          /*className={
            (errors?.roles && contributor.role.length === 0
              ? "inputErrMulti "
              : "") + "select-role selector-general uniselect"
          }*/
          disabled={disabled}
        />
      </Col>
      <Col xs={3} sm={2} md={2} lg={2} className="row-track-input cross">
        <CrossFillIcon
          key={addContributorKey + contributorId + "cross"}
          color="#000"
          onClick={
            disabled
              ? () => {}
              : () => {
                  onChange({
                    type: "contributors",
                    payload: {
                      type: "delete",
                      original: originalContributors,
                      value: null,
                      contributorIterable,
                    },
                  });
                }
          }
          className={disabled ? "disabled-cross" : ""}
        />
      </Col>
    </React.Fragment>
  );
};

export default ContributorsEditView;
