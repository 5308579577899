import VisitorCalls from "../calls/visitor/visitor.jsx";
import browser from "browser-detect";

class Visitor {
  constructor() {
    this.navSystem = browser();
  }

  login = async (data, token = null, lang) => {
    const device = {
      browser: this.navSystem.name,
      browserVersion: this.navSystem.version,
      os: this.navSystem.os,
    };
    const body = {
      user: {
        email: data.email,
        password: data.passwd,
        device: device,
      },
      browser_language: lang,
    };
    return await VisitorCalls.login(body, token);
  };

  signup = async (data, token, lang) => {
    const profile = {
      company_name: data.company,
      name: data.name,
      surname: data.last,
      phone: {
        country: data.country,
        code: data.phoneCode,
        number: data.phone,
      },
      address: {
        address: data.address,
        city: data.city,
        state: data.state,
        country: data.country,
        zip: data.zipCode,
      },
    };
    if (data.website.length) {
      profile.website = data.website;
    }
    const device = {
      browser: this.navSystem.name,
      browserVersion: this.navSystem.version,
      os: this.navSystem.os,
    };
    const body = {
      user: {
        email: data.email,
        type: data.type,
        password: data.passwd,
        confirmPassword: data.pwdCfm,
        accepted: data.terms,
        membership: data.plan,
        profile: profile,
        device: device,
        verified: data.verified || false,
      },
      browser_language: lang,
    };
    if (data.externalId) {
      if (data.platformExternal === "google") {
        body.user.externalLogin = {
          googleId: data.externalId,
        };
      } else {
        body.user.externalLogin = {
          facebookId: data.externalId,
        };
      }
    }
    return await VisitorCalls.signup(body, token);
  };

  loginFacebook = async () => {
    const resp = await VisitorCalls.loginFacebook();
    if (resp.status === 200 && resp.body.url) {
      document.location.href = resp.body.url;
    }
  };

  loginGoogle = async () => {
    const resp = await VisitorCalls.loginGoogle();
    if (resp.status === 200 && resp.body.url) {
      document.location.href = resp.body.url;
    }
  };

  verifyEmail = async (email = null) => {
    const body = {
      email: email,
    };
    return await VisitorCalls.verifyEmail(body);
  };

  request_code = async (email, language) => {
    return await VisitorCalls.request_code(email, language);
  };

  verify_code = async (email, code) => {
    const result = await VisitorCalls.verify_code(email, code);
    return result.status === 200 ? result.body : null;
  };

  verifyAccount = async (token, e) => {
    const result = await VisitorCalls.verifyAccount(token, e);
    return result;
  };

  sendRecoverPwdMail = async (email, language) => {
    const result = await VisitorCalls.sendRecoverPwdMail(email, language);
    return result;
  };

  resetPwdValidateToken = async (token) => {
    const result = await VisitorCalls.resetPwdValidateToken(token);
    return result;
  };

  resetPassword = async (data) => {
    const result = await VisitorCalls.resetPassword(data);
    return result;
  };

  checkInvitation = async (invitation) => {
    const result = await VisitorCalls.checkInvitation(invitation);
    return result;
  }

  deleteInviteToken = async (token) => {
    const result = await VisitorCalls.deleteInviteToken(token);
    return result;
  }
}

export default new Visitor();
