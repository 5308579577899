import React from "react";
import "../release.css";

const ReleaseStep = ({ title, value, className = "" }) => {

  return (
    <div className={className + " release-element-admin"}>
      <p>{title}</p>
      {value}
    </div>
  );
};

export default ReleaseStep;
