import React from "react";

const ArtistsIcon = ({ className = null, onClick = null, color = null }) => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 0C6.79086 0 5 1.79086 5 4C5 6.20914 6.79086 8 9 8C11.2091 8 13 6.20914 13 4C13 1.79086 11.2091 0 9 0ZM12 15C12 13.3431 13.3431 12 15 12C15.3506 12 15.6872 12.0602 16 12.1707V8C16 7.59554 16.2436 7.2309 16.6173 7.07612C16.991 6.92134 17.4211 7.0069 17.7071 7.29289L19.7071 9.29289C20.0976 9.68342 20.0976 10.3166 19.7071 10.7071C19.3166 11.0976 18.6834 11.0976 18.2929 10.7071L18 10.4142V15C18 16.6569 16.6569 18 15 18C13.3431 18 12 16.6569 12 15ZM0 15C0 12.2386 2.23858 10 5 10H10.5278C11.0801 10 11.5278 10.4477 11.5278 11C11.5278 11.5523 11.0801 12 10.5278 12H5C3.34315 12 2 13.3431 2 15C2 15.5523 2.44772 16 3 16H9.34141C9.8937 16 10.3414 16.4477 10.3414 17C10.3414 17.5523 9.8937 18 9.34141 18H3C1.34315 18 0 16.6569 0 15Z"
        fill={color || "#ffffffa6"}
      />
    </svg>
  );
};

export default ArtistsIcon;
