import React from "react";

const IdIcon = ({ className = null, onClick = null, color = null }) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M10.125 14.625C11.3676 14.625 12.375 13.6176 12.375 12.375C12.375 11.1324 11.3676 10.125 10.125 10.125C8.88236 10.125 7.875 11.1324 7.875 12.375C7.875 13.6176 8.88236 14.625 10.125 14.625Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5 6.75H4.5C3.87868 6.75 3.375 7.25368 3.375 7.875V19.125C3.375 19.7463 3.87868 20.25 4.5 20.25H22.5C23.1213 20.25 23.625 19.7463 23.625 19.125V7.875C23.625 7.25368 23.1213 6.75 22.5 6.75Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.75 20.25C6.75 19.0074 8.26104 18 10.125 18C11.989 18 13.5 19.0074 13.5 20.25"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.25 15.75L15.75 15.75"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.25 12.375L16.875 12.375"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IdIcon;
