import React, { useContext, useState } from "react";
import { LanguageContext } from "../../../context/Language";
import { Row, Col, message } from "antd";
import validator from "validator";
import Button from "../../Buttons/GeneralButton";
import PasswordInput from "../../Form/InputPassword";
import User from "../../../classes/user/user.jsx";
import "../set.css";

const Password = () => {
  const { dictionary } = useContext(LanguageContext);
  const [state, setPassword] = useState({
    password_old: "",
    password_new: "",
    confirm: "",
  });
  const [errors, setError] = useState({
    password_old: false,
    password_new: false,
    confirm: false,
  });
  const [pwdError, setPwdError] = useState(null);

  const validate = (inputName = "", value = "") => {
    let error = false;
    let baseError = {
      password_old: false,
      password_new: false,
      confirm: false,
    };
    setError(baseError);
    if (inputName === "" || inputName === "password_old")
      if (state.password_old.length < 8) {
        baseError.password_old = dictionary.passErr;
        error = true;
      }

    if (inputName === "" || inputName === "password_new")
      if (state.password_new.length < 8) {
        baseError.password_new = dictionary.passErr;
        error = true;
      }

    if (inputName === "" || inputName === "confirm")
      if (
        (state.password_new !== value &&
          inputName === "" &&
          state.password_new !== state.confirm) ||
        validator.isLength(state.confirm, 0, 0)
      ) {
        baseError.confirm = dictionary.confirmPwdErr;
        error = true;
      }

    if (error) {
      setError(baseError);
    }

    return !error;
  };

  const success = () => {
    message.success({
      content: dictionary.passwordSuccess,
      duration: 4,
    });
  };

  const inputChangeHandler = (e) => {
    setPassword({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const savePwd = async () => {
    setPwdError(null);
    if (validate()) {
      const saved = await User.changePassword(state);
      if (!saved.errors) {
        success();
        setPassword({
          password_old: "",
          password_new: "",
          confirm: "",
        });
      } else {
        const message = saved.body.errors[0].message;
        setPwdError(dictionary[message]);
      }
      window.scrollTo(0, 0);
    } else return false;
  };

  return (
    <div className="content-set">
      <h2>{dictionary.changePassword}</h2>
      <div className="forms">
        <Row className="gutter-row">
          <Col xs={24} sm={24} md={24} lg={24}>
            <PasswordInput
              name="password_old"
              value={state.password_old}
              error={errors.password_old}
              onChange={inputChangeHandler}
              label={dictionary.passwordCurrent}
              placeholder={dictionary.pwdChars}
              className={errors.password_old ? "inputErr" : ""}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <PasswordInput
              name="password_new"
              value={state.password_new}
              error={errors.password_new}
              onChange={inputChangeHandler}
              label={dictionary.newPassword}
              placeholder={dictionary.pwdChars}
              className={errors.password_new ? "inputErr" : ""}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <PasswordInput
              name="confirm"
              value={state.confirm}
              onChange={inputChangeHandler}
              error={errors.confirm}
              placeholder={dictionary.pwdChars}
              label={dictionary.passwordConfirm}
              className={errors.confirm ? "inputErr" : ""}
            />
          </Col>
        </Row>
        <div className="error-pwd">{pwdError}</div>
        <div className="align-right reg-btn">
          <Button size="md" onClick={savePwd} text={dictionary.save} />
        </div>
      </div>
    </div>
  );
};

export default Password;
