import React, { useContext, useEffect, useState, useMemo } from "react";
import { notification } from "antd";
import { Link } from "react-router-dom";
import { AppContext } from "../../../../context/App";
import { LanguageContext } from "../../../../context/Language";
import Button from "../../../Buttons/GeneralButton";
import Release from "../../../../classes/release/release";
import Admin from "../../../../classes/admin/admin.jsx";
import LeftArrowIcon from "../../../../components/Icon/LeftArrowIcon";
import "./actions.css";

const Actions = (props) => {
  const { isLoading, setIsLoading, loadRelease } = props;
  const { dictionary } = useContext(LanguageContext);
  const { audiosaladStatus } = useContext(AppContext);
  const [errorMsg, setErrMsg] = useState(null);

  const { dataToUpdate, sendDataToUpdate, setShowReviewNoteModal } = props;

  const actualstatus = useMemo(() => {
    if (audiosaladStatus) {
      return Object.keys(audiosaladStatus).find(
        (key) => audiosaladStatus[key] === props.audiosalad_status
      );
    }
  }, [props.audiosalad_status, audiosaladStatus]);

  useEffect(() => {
    if (errorMsg !== null) {
      notification.error({
        message: errorMsg,
        duration: 15,
        placement: "bottomRight",
      });
    }
  }, [errorMsg]);

  const sendInReview = async () => {
    setErrMsg(null);
    setIsLoading(true);
    const updateStatus = await Admin.updateStatus(props._id);
    let titleRel = props.title;
    if (updateStatus.status === 200) {
      setIsLoading(false);
      if (updateStatus.body.error === "releaseCheckData") {
        setErrMsg(dictionary.releaseCheckDataAdmin);
      } else {
        if (updateStatus.body.error === "releaseInReview") {
          setErrMsg(dictionary.releaseInReview);
        } else {
          let succesRel = dictionary.releaseSendToReview.replace(
            "--name--",
            titleRel
          );
          notification.success({
            message: succesRel,
            duration: 6,
            placement: "bottomRight",
          });
          setTimeout(() => {
            loadRelease();
            window.location.reload();
          }, 2000);
        }
      }
    } else {
      setIsLoading(false);
      setErrMsg(dictionary.stripeError);
    }
  };

  const changeAudiosaladStatus = (status, type) => {
    setIsLoading(true);
    Release.updateAudiosaladStatus(props._id, status).then((res) => {
      if (!res.error && res.message) {
        let succesRel =
          type === 1
            ? dictionary.releaseSendToDistribution.replace(
                "--name--",
                props.title
              )
            : type === 2
            ? dictionary.releaseResendToReview.replace("--name--", props.title)
            : dictionary.releaseRevokeDistribution.replace(
                "--name--",
                props.title
              );
        notification.success({
          message: succesRel,
          duration: 6,
          placement: "bottomRight",
        });
        setIsLoading(false);
        setTimeout(() => {
          loadRelease();
          window.location.reload();
        }, 2000);
      } else {
        notification.warning({
          message: dictionary.stripeError,
          duration: 6,
          placement: "bottomRight",
        });
        setIsLoading(false);
      }
    });
  };

  const reviewButtons = () => {
    let stepActions = "";
    switch (actualstatus) {
      case "readyToSent":
        stepActions = (
          <React.Fragment>
            <Button
              size="ms"
              className="reject"
              onClick={() => setShowReviewNoteModal()}
              text={dictionary.rejectSendToClient}
              disabled={isLoading}
            ></Button>
            <Button
              size="ms"
              className="grn"
              onClick={() => changeAudiosaladStatus("Pending Distribution", 1)}
              text={dictionary.sendForDistribution}
              disabled={isLoading}
            ></Button>
          </React.Fragment>
        );
        break;
      case "inReview":
        stepActions = (
          <div className="in-review-text">{dictionary.inReview}</div>
        );
        break;
      case "pendingDistribution":
        stepActions = (
          <React.Fragment>
            <Button
              size="ms"
              className="reject"
              onClick={() => changeAudiosaladStatus("Ready to Sent", 2)}
              text={dictionary.resendToReview}
              disabled={isLoading}
            ></Button>
            <Button
              size="ms"
              className="grn"
              onClick={() => sendInReview()}
              text={dictionary.approve + "/" + dictionary.sendToAudiosalad}
              disabled={isLoading}
            ></Button>
          </React.Fragment>
        );
        break;
      default:
        break;
    }
    return stepActions;
  };

  return (
    <div className="actions-box">
      {errorMsg ? <div className="error-release-admin">{errorMsg}</div> : null}
      <div className="actions-release">
        <Link to="/release">
          <Button
            key="deny"
            size="ms"
            icon={<LeftArrowIcon />}
            className="return"
            text={dictionary.return}
            disabled={isLoading}
          />
        </Link>
        {Object.keys(dataToUpdate).length > 0 ? null : reviewButtons()}
        {Object.keys(dataToUpdate).length > 0 ? (
          <Button
            key="saveBtnReleaseDetail"
            size="ms"
            className="save"
            text={dictionary.save}
            onClick={sendDataToUpdate}
            disabled={isLoading}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Actions;
