import RoyaltiesCalls from "../calls/royalties/royalties";

class Royalties {
  // TODO: remove
  async getExample(filter) {
    const statements = await RoyaltiesCalls.getExample(filter);
    if (statements.status === 200) {
      return statements.body.statements;
    }
    return [];
  }

  // TODO: remove
  async getExampleSublabels(labelId, filter) {
    const sublabels = await RoyaltiesCalls.getExampleSublabels(labelId, filter);
    if (sublabels.status === 200) {
      return sublabels.body.statements;
    }
    return [];
  }

  /**
   *
   * @param {number} labelId
   * @param {object} filter {
   *  year: 2021,
   * periodId: M1,
   * page: 1,
   * limit: 100,
   * }
   * @returns
   * @memberof Royalties
   * */
  async getSublabelRoyalties(labelId, filter) {
    const sublabels = await RoyaltiesCalls.getSublabelRoyalties({
      year: filter.year,
      period: filter.periodId,
      labelId,
    });
    if (sublabels.status === 200) {
      return sublabels.body.statements;
    }
    return [];
  }

  async getRoyalties(filter) {
    const royalties = await RoyaltiesCalls.getRoyalties(filter);
    if (royalties.status === 200) {
      return royalties.body.statements;
    }
    return [];
  }
}

export default new Royalties();
