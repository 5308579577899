import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../context/Language";
import ThanksIcon from "../../components/Icon/ThanksIcon";

const VerifiedEmail = () => {
  const { dictionary } = useContext(LanguageContext);

  return (
    <React.Fragment>
      <div className="container-box thanks-box">
        <div className="greeting">
          <h1>{dictionary.verifiedAcc}</h1>
          <h3>{dictionary.verifiedAccDes}</h3>
          <p className="greeting-body">
            {dictionary.or} &nbsp;
            <Link to="/">{dictionary.clickHere}</Link>
          </p>
          <div className="greet-icon">
            <ThanksIcon />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VerifiedEmail;
