import React from "react";
import "../progress.css";

const WizardBar = ({ total, step } ) => {

  const bars = [];
  for (let i = 0; i < total; i++) {
    let styles = "bar";
    if (i === 0) styles += " left";
    if (i === total - 1) styles += " right";
    if (i <= step) styles += " filled";

    bars[i] = <div key={i} className={styles} />;
  }
  return <div className="bar-progress">{bars}</div>;
};

export default WizardBar;
