import {
  Card,
  Col,
  Row,
  Input,
  Select,
  Typography,
  DatePicker,
} from "antd";
import React, { useContext, useState, useMemo } from "react";
import { LanguageContext } from "../../../../context/Language";
import { UserContext } from "../../../../context/User";
import { AppContext } from "../../../../context/App";
import GenreSubGenreSelect from "../../../Form/GenreSubGenreSelect";
import moment from "moment";
import "./GeneralInformation.css";

const GeneralInformation = (props) => {
  const {
    genres = [],
    permission = [],
    dispatch,
    dataToUpdate,
    enableEdit,
  } = props;

  const { subGenreObjects } = useContext(UserContext);
  const { languageOptions, dictionary } = useContext(LanguageContext);
  const { advisoryOptions, formatOptions, labelOptions } = useContext(AppContext);

  // only for force the re-render on genres select
  const [genresSelected, setGenresSelected] = useState(null);

  // Options for genre select
  const genresMap = useMemo(() => {
    return genres.map((item) => {
      return subGenreObjects[item.subgenre_id || item.genre_id] || null;
    });
  }, [genres, subGenreObjects]);

  const getPreOrderdate = useMemo(() => {
    const preOrderItem = (dataToUpdate?.permission || permission).find(
      (item) => item.type === "preorder" && item.enabled
    );

    // this cannot exist in the data, because in the xml and here whill be the same
    return preOrderItem ? preOrderItem.start : new Date();
  }, [permission, dataToUpdate]);

  return (
    <div className="general_information">
      <Row>
        <Col>
          <Typography.Title level={3}>
            {dictionary.generalInformation}
          </Typography.Title>
        </Col>
      </Row>
      <Row justify="space-around">
        <Col xs={24} lg={11} className="info-one">
          <Card loading={props.isLoading}>
            <Row justify="space-between" align="middle" className="mt-2 mr-4">
              <Col className="label" md={8} span={24}>
                {dictionary.format}
              </Col>
              <Col md={16} span={24}>
                <Select
                  className="selector-general"
                  defaultValue={dataToUpdate?.type || props.type}
                  options={formatOptions}
                  onChange={(e) => {
                    dispatch({
                      type: "simpleChange",
                      payload: {
                        name: "type",
                        original: props.type,
                        value: e,
                      },
                    });
                  }}
                  disabled={!enableEdit}
                />
              </Col>
            </Row>
            <Row justify="space-between" align="middle" className="mt-2 mr-4">
              <Col className="label" md={8} span={24}>
                {dictionary.releaseDate}
              </Col>
              <Col md={16} span={24}>
                <DatePicker
                  inputReadOnly={true}
                  onChange={(e) => {
                    dispatch({
                      type: "simpleChange",
                      payload: {
                        name: "date",
                        original: props.releaseDate,
                        value: e.toISOString(),
                      },
                    });
                  }}
                  placeholder={dictionary.releaseDate}
                  defaultValue={moment(dataToUpdate?.date || props.date)}
                  value={moment(dataToUpdate?.date || props.date)}
                  format={"D MMM, YYYY"}
                  disabledDate={(current) => {
                    return (
                      current < moment().endOf("day") &&
                      current < moment(dataToUpdate?.date || props.date)
                    );
                  }}
                  disabled={!enableEdit}
                />
              </Col>
            </Row>
            {!props.is_new && (
              <Row justify="space-between" align="middle" className="mt-2 mr-4">
                <Col className="label" md={8} span={24}>
                  {dictionary.originalRelDate}
                </Col>
                <Col md={16} span={24}>
                  <DatePicker
                    inputReadOnly={true}
                    onChange={(e) => {
                      dispatch({
                        type: "simpleChange",
                        payload: {
                          name: "original_date",
                          original: props.original_date,
                          value: e.toISOString(),
                        },
                      });
                    }}
                    placeholder={dictionary.releaseDate}
                    defaultValue={moment(
                      dataToUpdate?.original_date || props.original_date
                    )}
                    value={moment(
                      dataToUpdate?.original_date || props.original_date
                    )}
                    format={"D MMM, YYYY"}
                    disabledDate={(current) => {
                      return (
                        current > moment().endOf("day") &&
                        current >
                          moment(
                            dataToUpdate?.original_date || props.original_date
                          )
                      );
                    }}
                    disabled={!enableEdit}
                  />
                </Col>
              </Row>
            )}
            <Row justify="space-between" align="middle" className="mt-2 mr-4">
              <Col className="label" md={8} span={24}>
                {dictionary.genre}
              </Col>
              <Col md={16} span={24}>
                <GenreSubGenreSelect
                  name="genres"
                  disabled={!enableEdit}
                  defaultValue={genresMap}
                  // The component only re-render if value change
                  value={genresSelected ? genresSelected : genresMap}
                  error={""}
                  onChange={(e) => {
                    setGenresSelected(e);
                    dispatch({
                      type: "objectChange",
                      payload: {
                        name: "genres",
                        original: genresMap,
                        value: e,
                      },
                    });
                  }}
                />
              </Col>
            </Row>
            <Row justify="space-between" align="middle" className="mt-2 mr-4">
              <Col className="label" md={8} span={24}>
                {dictionary.language}
              </Col>
              <Col md={16} span={24}>
                <Select
                  className="selector-general"
                  name="language"
                  defaultValue={dataToUpdate?.language || props.language}
                  options={languageOptions}
                  placeholder={dictionary.select}
                  onChange={(e) => {
                    dispatch({
                      type: "simpleChange",
                      payload: {
                        name: "language",
                        original: props.language,
                        value: e,
                      },
                    });
                  }}
                  disabled={!enableEdit}
                />
              </Col>
            </Row>
            <Row justify="space-between" align="middle" className="mt-2 mr-4">
              <Col className="label" md={8} span={24}>
                {dictionary.label}
              </Col>
              <Col md={16} span={24}>
                <Select
                  className="selector-general"
                  showSearch
                  defaultValue={dataToUpdate.label_id || props.label_id}
                  placeholder="Basic usage"
                  optionFilterProp="children"
                  onChange={(e) => {
                    dispatch({
                      type: "simpleChange",
                      payload: {
                        name: "label_id",
                        original: props.label_id,
                        value: e,
                      },
                    });
                  }}
                  onSearch={() => {}}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={labelOptions}
                  disabled={!enableEdit}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} lg={11}>
          <Card loading={props.isLoading}>
            <Row justify="space-between" align="middle" className="mt-2 mr-4">
              <Col className="label" md={8} span={24}>
                {dictionary.PreOrderDate}
              </Col>
              <Col md={16} span={24}>
                <DatePicker
                  inputReadOnly={true}
                  onChange={(e) => {
                    dispatch({
                      type: "partialPermission",
                      payload: {
                        permissionType: "preorder",
                        name: "start",
                        original: permission,
                        value: e.toISOString(),
                      },
                    });
                  }}
                  placeholder={dictionary.releaseDate}
                  defaultValue={moment(getPreOrderdate)}
                  value={moment(getPreOrderdate)}
                  format={"D MMM, YYYY"}
                  disabledDate={(current) => {
                    return (
                      current < moment().endOf("day") &&
                      current < moment(getPreOrderdate)
                    );
                  }}
                  disabled={!enableEdit}
                />
              </Col>
            </Row>
            <Row justify="space-between" align="middle" className="mt-2 mr-4">
              <Col className="label" md={8} span={24}>
                {dictionary.parentalAdvisory}
              </Col>
              <Col md={16} span={24}>
                <Select
                  className="selector-general"
                  name="advocacy"
                  defaultValue={dataToUpdate?.advocacy || props.advocacy}
                  options={advisoryOptions}
                  placeholder={dictionary.select}
                  onChange={(e) => {
                    dispatch({
                      type: "simpleChange",
                      payload: {
                        name: "advocacy",
                        original: props.advocacy,
                        value: e,
                      },
                    });
                  }}
                  disabled={!enableEdit}
                />
              </Col>
            </Row>
            <Row justify="space-between" align="middle" className="mt-2 mr-4">
              <Col className="label" md={8} span={24}>
                {dictionary.smartLink}
              </Col>
              <Col md={16} span={24}>
                <Input
                  className="input-general"
                  defaultValue={dataToUpdate?.smart_url ?? props.smart_url}
                  onChange={(e) => {
                    dispatch({
                      type: "simpleChange",
                      payload: {
                        name: "smart_url",
                        original: props.smart_url,
                        value: e.target.value,
                      },
                    });
                  }}
                  placeholder="Ex. https://stream.al/XXXX"
                  disabled={!enableEdit}
                />
              </Col>
            </Row>
            <Row justify="space-between" align="middle" className="mt-2 mr-4">
              <Col className="label" md={8} span={24}>
                {dictionary.recordLocation}
              </Col>
              <Col md={16} span={24}>
                <Input
                  className="input-general"
                  defaultValue={
                    dataToUpdate?.record_location ?? props.record_location
                  }
                  onChange={(e) => {
                    dispatch({
                      type: "simpleChange",
                      payload: {
                        name: "record_location",
                        original: props.record_location,
                        value: e.target.value,
                      },
                    });
                  }}
                  placeholder="California, USA"
                  disabled={!enableEdit}
                />
              </Col>
            </Row>
            <Row justify="space-between" align="middle" className="mt-2 mr-4">
              <Col className="label" md={8} span={24}>
                {dictionary.recordingYear}
              </Col>
              <Col md={16} span={24}>
                <Input
                  className="input-general"
                  defaultValue={
                    dataToUpdate?.recording_year ?? props.recording_year
                  }
                  onChange={(e) => {
                    dispatch({
                      type: "simpleChange",
                      payload: {
                        name: "recording_year",
                        original: props.recording_year,
                        value: e.target.value,
                      },
                    });
                  }}
                  placeholder={"2020"}
                  disabled={!enableEdit}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default GeneralInformation;
