import LabelCalls from "../calls/label/label.jsx";

class Label {
  dsps = async (label) => {
    const dsps = await LabelCalls.dsps(label);
    if (dsps.status === 200 && !dsps.errors) {
      return dsps.body.dsps || [];
    }
    return null;
  };
}

export default new Label();
