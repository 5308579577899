import React, { useEffect, useState, useContext } from "react";
import "./pwdreset.css";
import { Row, Col, message } from "antd";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../context/Language";
import Logo from "../../logo.svg";
import PasswordInput from "../../components/Form/InputPassword";
import validator from "validator";
import Button from "../../components/Buttons/GeneralButton";
import Visitor from "../../classes/visitor/visitor";
import Loader from "../../components/Loader/Loader";

const ResetPwd = () => {
  const [loading, setLoading] = useState(false);
  const [getUrl] = useState(window.location.href);  
  const [invalidToken, setInvalidToken] = useState(false);
  const [gotEmail, setEmail] = useState(false);
  const [gotToken, setToken] = useState(false);
  const { dictionary, locale } = useContext(LanguageContext);
  const [state, setPassword] = useState({
    password_new: "",
    confirm: "",
  });
  const [errors, setError] = useState({
    password_new: false,
    confirm: false,
  });
  const [pwdError, setPwdError] = useState(null);

  useEffect(() => {
    const urlObj = new URL(getUrl);
    const valuet = urlObj.searchParams.get('t');
    setToken(valuet);
    if(valuet && valuet.length > 0){
      validateCurrentToken(valuet);
    } else {
      window.location.href = "/";
    }
  }, [getUrl]);

  const validateCurrentToken = (valuet) => {
    setLoading(true);
    Visitor.resetPwdValidateToken(valuet).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        if(res.body.message === "validToken"){
          setInvalidToken(false);
          setEmail(res.body.mail.mail);
        } else {
          setInvalidToken(dictionary[res.body.message]);
        }
      } else {
        window.location.href = "/";
      }
    });
  };

  const validate = (inputName = "", value = "") => {
    let error = false;
    let baseError = {
      password_new: false,
      confirm: false,
    };
    setError(baseError);

    if (inputName === "" || inputName === "password_new")
      if (state.password_new.length < 8) {
        baseError.password_new = dictionary.passErr;
        error = true;
      }

    if (inputName === "" || inputName === "confirm")
      if (
        (state.password_new !== value &&
          inputName === "" &&
          state.password_new !== state.confirm) ||
        validator.isLength(state.confirm, 0, 0)
      ) {
        baseError.confirm = dictionary.confirmPwdErr;
        error = true;
      }

    if (error) {
      setError(baseError);
    }

    return !error;
  };

  const success = () => {
    message.success({
      content: dictionary.passwordSuccess + " " + dictionary.passwordSuccessDesc,
      duration: 5,
      style: {maxWidth: '380px', margin: '0 auto'},
    });
  };

  const inputChangeHandler = (e) => {
    setPassword({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const savePwd = async () => {
    setPwdError(null);
    const language = locale.includes("es") ? "es" : "en";
    if (validate() && !invalidToken) {
      setLoading(true);
      const data = {
        token: gotToken,
        email: gotEmail,
        password_new: {
          new: state.password_new,
          confirm: state.confirm,
        },
        language: language,
      };
      const saved = await Visitor.resetPassword(data);
      if (!saved.errors) {
        success();
        setLoading(true);
        setTimeout(() => {
          window.location.href = "/";
        }, 5000);
      }else {
        setLoading(false);
        setPwdError(dictionary[saved.message]);
      }
      window.scrollTo(0, 0);
    } else return false;
  };

  const returnToLogin = () => {
    return (
      <div className="reg-btn">
        <div className="reg-btn">
            <div className="error-pwd-token">
              {invalidToken}
            </div>
            <Link to="/" className="link-login">
            <Button
              size="md"
              onClick={null}
              text={dictionary.thanksUse}
            />
            </Link>
          </div>
      </div>
    );
  };

  return (
    <div className="container-box fadein sign pwd-new" style={{
      height: '100vh',
      width: '100vw',
      overflow: 'auto',
    }}>
      {loading && <Loader />}
      <img src={Logo} alt="Priam" className="logo" />
      <div className="sign-box">
        {invalidToken ? 
          returnToLogin()
        :
        <React.Fragment>
          {gotEmail ?
            <h2>{dictionary.hi} {gotEmail}, {dictionary.resetPwd}.</h2>
          : null}
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              <PasswordInput
                name="password_new"
                value={state.password_new}
                error={errors.password_new}
                onChange={inputChangeHandler}
                label={dictionary.newPassword}
                placeholder={dictionary.pwdChars}
                className={errors.password_new ? "inputErr" : ""}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <PasswordInput
                name="confirm"
                value={state.confirm}
                onChange={inputChangeHandler}
                error={errors.confirm}
                placeholder={dictionary.pwdChars}
                label={dictionary.passwordConfirm}
                className={errors.confirm ? "inputErr" : ""}
              />
            </Col>
          </Row>
          {pwdError && <div className="error-pwd">{pwdError}</div>}
          <div className="reg-btn">
            <Button size="md" onClick={savePwd} text={dictionary.save} />
          </div>
        </React.Fragment>
        }
      </div>
    </div>
  );
};

export default ResetPwd;
