import React, { useContext, useState } from "react";
import { Divider, notification } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { LanguageContext } from "../../context/Language";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Captcha from "../Captcha";
import Input from "../Form/Input";
import PwdForgot from "./PwdForgot.jsx";
import Google from "../Buttons/GoogleBtn";
import Password from "../Form/InputPassword";
import Button from "../Buttons/GeneralButton";
import Facebook from "../Buttons/FacebookBtn";
import BorderButton from "../Buttons/BorderButton";
import Visitor from "../../classes/visitor/visitor.jsx";
import RegressiveTimer from "../RegressiveTimer/RegressiveTimer.jsx";
import "../../containers/Home/home.css";

const Login = ({ email, setEmail, changeLogin, externalError }) => {
  const { dictionary, locale } = useContext(LanguageContext);
  const [token, setToken] = useState("");
  const [expiredCaptcha, setExpiredCaptcha] = useState(false);
  const [resentCaptcha, setResentCaptcha] = useState(false);
  const [captcha, enableCaptcha] = useState(false);
  const [errorLogin, setErrorLogin] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [retryTimer, setRetryTimer] = useState(0);
  const [state, setInput] = useState({
    passwd: "",
  });
  const [errors, setError] = useState({
    emailErr: false,
    passErr: false,
  });

  const changeHandler = (e) => {
    setInput({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const changeHandlerEmail = (e) => {
    setEmail(e.target.value);
  };

  const keyEnter = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      login();
    }
  };

  const validate = () => {
    let data = null;
    let error = false;
    let errors = {
      emailErr: false,
      passErr: false,
    };
    setError({
      ...errors,
      errors,
    });
    if (
      email.length === 0 ||
      !email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    ) {
      errors.emailErr = dictionary.mailErr;
      error = true;
    }

    if (state.passwd.length < 8) {
      errors.passErr = dictionary.passErr;
      error = true;
    }

    if (!error) {
      data = {
        email: email,
        passwd: state.passwd,
      };
    } else {
      setError({
        ...errors,
        errors,
      });
    }
    return data;
  };

  const login = async () => {
    const data = validate();
    if (data === null) return;

    const login = await Visitor.login(data, token, locale);

    if (login.status === 200) {
      window.location.href = "/";
    }

    if (login.status === 400 && login.body.errors && login.body.errors.length) {
      if (
        login.body.errors.findIndex(
          (err) => err.message === "validateCaptcha"
        ) >= 0
      ) {
        setErrorLogin(dictionary[login.body.errors[0].message]);
        enableCaptcha(true);
      } else if (
        login.body.errors.findIndex(
          (err) => err.message === "expiredCaptcha"
        ) >= 0
      ) {
        setResentCaptcha(false);
        setExpiredCaptcha(true);
      } else if (
        login.body.errors.findIndex(
          (err) => err.message === "inactiveAccount"
        ) >= 0
      ) {
        notification.error({
          message: dictionary.disableAccount,
          duration: 5,
          placement: "topRight",
        });
      } else {
        setErrorLogin(dictionary.MailError);
      }
    }

    if (login.status === 429) {
      setErrorLogin(dictionary.manyTries);
      setRetryTimer(login.body.retrySecs || 120);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    // setEmailSent(false);
  };

  return (
    <div className="sign-box">
      <h2>{dictionary.signIn}</h2>
      {externalError ? (
        <p className="head-form-error">{externalError}</p>
      ) : null}
      <Input
        name="email"
        value={email}
        error={errors.emailErr}
        icon={<MailOutlined />}
        onChange={changeHandlerEmail}
        placeholder={dictionary.email}
        className={(errors.emailErr ? "inputErr " : "") + " email"}
      />
      <Password
        name="passwd"
        value={state.passwd}
        error={errors.passErr}
        onChange={changeHandler}
        onKeyUp={(e) => keyEnter(e)}
        placeholder={dictionary.password}
        className={errors.passErr ? "inputErr" : ""}
      />
      <div className="forgot-pwd">
        <a href="#pwd" onClick={() => showModal()}>
          {dictionary.forgotPassword}
        </a>
      </div>
      <PwdForgot
        handleCancel={handleCancel}
        emailSent={emailSent}
        setEmailSent={setEmailSent}
        isModalVisible={isModalVisible}
      />
      {captcha ? (
        <GoogleReCaptchaProvider
          reCaptchaKey="6Lf1tDseAAAAAN6_aJeASuEybHK-c8fTsMEjBvLL"
          language="es-419"
        >
          <Captcha
            setToken={setToken}
            expired={expiredCaptcha}
            resent={resentCaptcha}
            setResent={setResentCaptcha}
          />
        </GoogleReCaptchaProvider>
      ) : null}
      {errorLogin ? <div className="error-pwd">{errorLogin}</div> : null}
      {retryTimer ? (
        <RegressiveTimer
          initialSeconds={retryTimer}
          onTimeOut={() => {
            setRetryTimer(0);
            setErrorLogin(false);
          }}
        />
      ) : null}
      <Button
        disabled={retryTimer}
        text={dictionary.signIn}
        size="fullWidth"
        onClick={login}
      />
      <Divider plain> {dictionary.or}</Divider>

      <div className="social-btns">
        <Facebook className="btn-click fb" onClick={Visitor.loginFacebook} />
        <Google className="btn-click gl" onClick={Visitor.loginGoogle} />
      </div>
      <p className="p-1">{dictionary.dontHaveAcc}</p>
      <BorderButton
        text={dictionary.registration}
        size="fullWidth"
        onClick={() => changeLogin(true)}
      />
    </div>
  );
};

export default Login;
