export const getAudioChannels = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    
    reader.onload = async (e) => {
      try {
        const buffer = e.target.result;
        const view = new DataView(buffer);

        let channels = 2; // valor por defecto

        if (file.type.includes('wav')) {
          const riff = String.fromCharCode(
            view.getUint8(0),
            view.getUint8(1),
            view.getUint8(2),
            view.getUint8(3)
          );
          const wave = String.fromCharCode(
            view.getUint8(8),
            view.getUint8(9),
            view.getUint8(10),
            view.getUint8(11)
          );

          if (riff === 'RIFF' && wave === 'WAVE') {
            channels = view.getUint16(22, true);
          }
        } else if (file.type.includes('flac')) {
          const flac = String.fromCharCode(
            view.getUint8(0),
            view.getUint8(1),
            view.getUint8(2),
            view.getUint8(3)
          );

          if (flac === 'fLaC') {
            let offset = 4;
            const blockHeader = view.getUint8(offset);
            const blockType = blockHeader & 0x7f;
            
            if (blockType === 0) {
              const streamInfoByte = view.getUint8(offset + 12);
              channels = ((streamInfoByte >> 4) & 0x0F) + 1;
            }
          }
        }

        resolve(channels);
      } catch (error) {
        console.error('Error al analizar audio:', error);
        resolve(2);
      }
    };

    reader.onerror = () => {
      console.error('Error al leer el archivo');
      resolve(2);
    };

    const blob = file.slice(0, 65536);
    reader.readAsArrayBuffer(blob);
  });
};

export const isAtmosAudio = (channels) => channels > 8; 