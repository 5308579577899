import BaseClass from "../";
import axios from "axios";

export default class uploadS3Calls extends BaseClass {
  constructor(folder = "", filename = "") {
    super();
    const re = /(?:\.([^.]+))?$/;
    const ext = re.exec(filename)[1];
    this.folder = folder;
    this.filename = filename;
    this.format = ext;
  }

  createSignedUrl = async (file) => {
    this.setValues(
      "/file/signed",
      {},
      {
        mimetype: file.type,
        filename: this.filename,
        folder: this.folder,
      }
    );
    return await this.doGet()
      .then((response) => {
        if (response.status === 200) {
          return response?.body?.url;
        }
        return null;
      })
      .catch((error) => {
        console.log("error", error);
        return null;
      });
  };

  /*
   * Upload file to S3
   *
   * @param {string} url - url to upload file
   * @param {object} File - file to upload
   * @param {function} progressEvent - custom function to update progress
   * @returns {object} response
   */
  uploadFile = async (url, file, progressEvent = null) => {
    let config = {
      headers: {
        "x-amz-acl": "public-read",
        "Content-Type": file.type,
      },
    };
    if (progressEvent) {
      config.onUploadProgress = progressEvent;
    }
    return await axios
      .put(url, file, config)
      .then((response) => {
        const nonSpecialChars = /[^a-zA-Z0-9]/g;
        const checksum = response.headers.etag;
        return {
          url:
          process.env.REACT_APP_S3_BUCKET_CDN_URL ? 
            process.env.REACT_APP_S3_BUCKET_CDN_URL + this.folder + "/" + this.filename : 
            process.env.REACT_APP_STORAGE + this.folder + "/" + this.filename,
          asset: {
            filename: this.filename,
            mime_type: file.type,
            checksum: checksum.replace(nonSpecialChars, ""),
            format: this.format,
          },
        };
      })
      .catch(() => {
        return null;
      });
  };

  /* *
   *
   * Delete s3 file
   * @param {string} folder - folder to delete file
   * @param {string} filename - filename to delete
   * @returns {object} response
   */
  deleteFile = async () => {
    this.setValues(
      "/file/delete",
      {},
      {
        filename: this.filename,
        folder: this.folder,
      }
    );
    return await this.doDelete()
      .then((response) => {
        if (response.status === 200) {
          return response?.body;
        }
        return null;
      })
      .catch(() => {
        return null;
      });
  };
}
