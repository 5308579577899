import BaseClass from "../";

class UtilCalls extends BaseClass {
  genres() {
    this.setValues("/util/genre");
    return this.doGet();
  }

  subgenres() {
    this.setValues("/util/subgenre");
    return this.doGet();
  }

  subgenresByGenre(genre) {
    this.setValues("/util/subgenre/genre/" + genre);
    return this.doGet();
  }

  genreSubGenreSelect() {
    this.setValues("/util/form/select/genre-subgenre/");
    return this.doGet();
  }
}

export default new UtilCalls();
