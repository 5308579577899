import React, { useContext, useState, useEffect } from "react";
import { LanguageContext } from "../../context/Language";
import Modal from "../Modal";
import Input from "../Form/Input";
import validator from "validator";
import Button from "../Buttons/GeneralButton";
import Visitor from "../../classes/visitor/visitor";
import "./pwd.css";

const PwdForgot = ({
  handleCancel,
  isModalVisible,
  emailSent,
  setEmailSent
}) => {

  const { dictionary, locale } = useContext(LanguageContext);
  const [email, setEmail] = useState("");
  const [errorEmail, setError] = useState(false);
  const [showButton, setShowBtn] = useState(true);
  const [leftTime, setLeftTime] = useState(120);

  const changeHandler = (e) => {
    setEmail(e.target.value);
  };

  useEffect(() => {
    let interval;
    if (!showButton) {
      interval = setInterval(() => {
        setLeftTime((prevTime) => {
          if (prevTime === 0) {
            clearInterval(interval);
            setShowBtn(true);
            return 120; // Reinicia el contador a 5 minutes
          } else {
            return prevTime - 1;
          }
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [showButton]); 

  const validate = () => {
    let data = null;
    let error = false;
    setError(false);
     if (email.length === 0) {
      error = dictionary.emptyField;
    } else if (!validator.isEmail(email)) {
      error = dictionary.mailErr;
    }
    if (!error) {
      data = {email: email};
    } else {
      setError(error);
    }
    return data;
  };

  const sendRecoveryMail = () => {
    const data = validate(true);
    if (data) {
      const language = locale.includes("es") ? "es" : "en";
      Visitor.sendRecoverPwdMail(email, language).then((result) => {
        if (result.status === 200) {
          setEmailSent(dictionary.recoverPwdSuccess.replace("--mail--", email));
          setShowBtn(false)
        } else {
          setError(dictionary[result.message]);
        }
      });
    }
  }

  const formatTime = (secs) => {
    const minutes = Math.floor(secs / 60);
    const formattedSeconds = secs % 60 < 10 ? `0${secs % 60}` : secs % 60;
    return `${minutes}:${formattedSeconds}`;
  };

  const returnToInput = () => {
    setShowBtn(true);
    setEmailSent(false);
    setEmail("");
  }


  return (
    <Modal
      idModal="delete"
      addclassName="modalFP"
      handleCancel={handleCancel}
      isModalVisible={isModalVisible}
      title={dictionary.recoverPwd}
      body={
        <div>
          {!emailSent ? (
          <React.Fragment>
            <div className="recover-desc">
              {dictionary.recoverPwdDesc}
            </div>
            <Input
                name="email"
                value={email}
                onChange={(e) => changeHandler(e, "email")}
                label={null}
                required={true}
                className={errorEmail ? "inputErr" : ""}
                error={errorEmail}
              />
          </React.Fragment>
          ) : (
            <div className="recover-desc">
              <p className="txt-sent">{emailSent}</p>
              <div>
                {dictionary.recoverNotSent} &nbsp;
                {showButton ?
                  <a
                    href="#"
                    onClick={() => sendRecoveryMail()}
                    className="link-resend"
                  >
                    {dictionary.resend}
                  </a>
                : (
                  <p className="resend-txt">
                    {dictionary.requestResend} {formatTime(leftTime)}
                  </p>
                )}
              </div>
            </div>
          )}

        </div>
      }
      footer={
        <div className="modalAdd-btns">
          {!emailSent ? (
            <React.Fragment>
              <Button
                key="cancel"
                size="sm"
                className="back-btn"
                text={dictionary.cancel}
                onClick={handleCancel}
              />
              <Button
                size="md"
                key="delete"
                className="footer-btn"
                onClick={() => sendRecoveryMail()}
                text={dictionary.send}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
               <Button
                key="cancel"
                size="sm"
                className="back-btn"
                text={dictionary.return}
                onClick={returnToInput}
              />
              <Button
                size="md"
                key="delete"
                className="footer-btn"
                onClick={handleCancel}
                text={dictionary.close}
              />
            </React.Fragment>
          )}
        </div>
      }
    />
  );
};

export default PwdForgot;
