import { useState } from "react";

/**
 * Custom hook to validate track data
 * use validateTrack(trackData, isEdit) to validate run validation
 * @returns {object} - { trackIsValid, validateTrackErrorMessages, validateTrack }
 */
const useTrackValidator = () => {
  const trackRules = {
    // _id: { type: "string", required: false },
    // release_id: { type: "string", required: true },
    number: { type: "number", required: true, min: 1 },
    title: { type: "string", required: true, min: 2 },
    type: { type: "string", required: true, min: 3 },
    audio_lang: { type: "string", required: true, length: 2 },
    version: { type: "string", required: true, min: 3 },
    copyright: {
      type: "object",
      required: true,
      item: {
        data: { type: "string", required: true, min: 1 },
        year: { type: "number", required: true, min: 1900 },
      },
    },
    phonographic: {
      type: "object",
      required: true,
      item: {
        data: { type: "string", required: true, min: 1 },
        year: { type: "number", required: true, min: 1900 },
      },
    },
    genres: { type: "array", required: true, min: 1 },
    artists: { type: "array", required: true, min: 1 },
    writers: { type: "array", required: true, min: 1 },
    producers: { type: "array", required: true, min: 1 },
    // categories: { type: "string", required: false }, aparently not used
    lyrics: { type: "string", required: false },
    // length: { type: "number", required: true }, aparently not used
    advocacy: { type: "string", required: true, min: 3 },
    rights_holder: { type: "string", required: true, min: 3 },
    permission: { type: "array", required: true, min: 1 },
    clip_start_time: { type: "string", required: true },
    title_length: { type: "string", required: true },
    clip_length: { type: "string", required: true },
    // s3_url: { type: "string", required: true, min: 3 }, not used in admin
    // asset: { type: "array", required: true, min: 1 }, separated in admin
    // dsp_track: { type: "array", required: true, min: 1 },
    // price_tier: { type: "array", required: true, min: 1 },
    // createdAt: { type: "string", required: true, min: 3 }, mongoose internal
    // updatedAt: { type: "string", required: true, min: 3 }, mongoose internal
    // __v: { type: "number", required: true }, mongo internal
  };

  /**
   * Explicit bussiness rules
   * @param {object} trackData
   * @returns {array} - array of error messages
   */
  const bussinessRules = (trackData, isEdit) => {
    const errors = [];
    // on edit, only got the fields that were changed
    if (isEdit) {
      if (trackData?.artists) {
        const existMain = trackData.artists.filter(
          (item) => item?.role === "main"
        );
        if (existMain.length === 0)
          errors.push("At least one main artist is required");
      }
      if (trackData?.writers) {
        const existSongWriter = trackData.writers.filter(
          (item) => item?.role === "song_writer"
        );
        if (existSongWriter.length === 0)
          errors.push("At least one song writer is required");
      }
    } else {
      const existMain = (trackData?.artists || []).filter(
        (item) => item?.role === "main"
      );
      const existSongWriter = (trackData?.writers || []).filter(
        (item) => item?.role === "song_writer"
      );

      if (existMain.length === 0)
        errors.push("At least one main artist is required");
      if (existSongWriter.length === 0)
        errors.push("At least one song writer is required");
    }

    return errors;
  };

  const [trackIsValid, setTrackIsValid] = useState(false);
  const [validateTrackErrorMessages, setValidateTrackErrorMessages] = useState(
    []
  );

  /**
   * Validate track data and set trackIsValid and validateTrackErrorMessages
   * @param {object} trackData
   * @param {boolean} isEdit - if true, validate only the fields that are present in trackData and ignore the required constraint
   * @returns {array} - array of error messages
   */
  const validateTrack = (trackData, isEdit = false) => {
    const errorMessages = [];

    const iterableKeys = isEdit
      ? Object.keys(trackData)
      : Object.keys(trackRules);
    const existingKeys = isEdit ? trackRules : trackData;
    for (const itk of iterableKeys) {
      if (Object.hasOwnProperty.call(existingKeys, itk)) {
        const { type } = trackRules[itk];
        const params = {
          value: trackData[itk],
          rules: trackRules[itk],
          key: itk,
          isEdit,
        };
        switch (type) {
          case "string": {
            const errors = validateString(params);
            errorMessages.push(...errors);
            break;
          }

          case "number": {
            const errors = validateNumber(params);
            errorMessages.push(...errors);
            break;
          }

          case "object": {
            const errors = validateObject({
              ...params,
              rules: trackRules[itk].item,
            });
            errorMessages.push(...errors);
            break;
          }

          case "array": {
            const errors = validateArray(params);
            errorMessages.push(...errors);
            break;
          }

          default:
            console.error("Invalid type in trackRules");
            break;
        }
      } else {
        if (isEdit) errorMessages.push(`${itk} is not a valid field`);
        else {
          if (trackRules[itk]?.required) {
            errorMessages.push(`${itk} is required`);
          }
        }
      }
    } // end for trackKeys

    const bussinessErrors = bussinessRules(trackData, isEdit);
    errorMessages.push(...bussinessErrors);

    setTrackIsValid(errorMessages.length === 0);
    setValidateTrackErrorMessages(errorMessages);
    return errorMessages;
  };

  /**
   * @param {string} value - evaluated value
   * @param {object} rules - rules to validate against { min, max, length}
   * @param {string} key - evaluated key
   * @returns {array} - array of error messages
   */
  const validateString = ({ value, rules, key }) => {
    const errors = [];
    const { min, max, length } = rules;

    if (length && value.length !== length)
      errors.push(`${key} length should be ${length}`);

    if (min && value.length < min)
      errors.push(`${key} length should be at least ${min}`);

    if (max && value.length > max)
      errors.push(`${key} length should be at most ${max}`);

    return errors;
  };

  const validateNumber = ({ value, rules, key }) => {
    if (typeof value === "string") value = Number(value.replace(/,/g, ""));
    const errors = [];
    const { min, max } = rules;
    if (min && value < min) errors.push(`${key} should be at least ${min}`);
    if (max && value > max) errors.push(`${key} should be at most ${max}`);
    return errors;
  };

  const validateObject = ({ value, rules, key, isEdit }) => {
    const errors = [];
    const iterableKeys = isEdit ? Object.keys(value) : Object.keys(rules);
    const existingKeys = isEdit ? rules : value;

    for (const ik of iterableKeys) {
      if (!Object.hasOwnProperty.call(existingKeys, ik)) {
        if (isEdit) errors.push(`${key + "." + ik} is not a valid field`);
        else
          rules[ik]?.required
            ? errors.push(`${key + "." + ik} is required`)
            : null;
      } else {
        const { type } = rules[ik];
        const params = {
          value: value[ik],
          rules: rules[ik],
          key: key + "." + ik,
          isEdit,
        };
        switch (type) {
          case "string": {
            const innerErrors = validateString(params);
            errors.push(...innerErrors);
            break;
          }

          case "number": {
            const innerErrors = validateNumber(params);
            errors.push(...innerErrors);
            break;
          }

          case "object": {
            const innerErrors = validateObject({
              ...params,
              rules: rules[ik].item,
              key: key + "." + ik,
            });
            errors.push(...innerErrors);
            break;
          }

          case "array": {
            const innerErrors = validateArray(params);
            errors.push(...innerErrors);
            break;
          }

          default:
            console.error("Invalid type in value object");
            break;
        }
      }
    }

    return errors;
  };

  const validateArray = ({ value, rules, key }) => {
    const errors = [];
    if (value.length === 0) return [`${key} should contain at least one item`];

    for (const item of value) {
      const { type, required } = rules;
      if (required) {
        if (type === "string") {
          const errors = validateString(item, rules, key);
          errors.concat(errors);
        }
      }
    }

    return errors;
  };

  return { trackIsValid, validateTrackErrorMessages, validateTrack };
};

export default useTrackValidator;
