import React, { useEffect, useContext, useState } from "react";
import { Table, DatePicker, Popconfirm, Spin, Tooltip, notification} from "antd";
import { UserContext } from "../../../context/User";
import { AppContext } from "../../../context/App";
import { LanguageContext } from "../../../context/Language";
import { StopOutlined, EditOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import moment from "moment";
import Select from "../../Form/Select";
import Search from "../../Form/Search";
import SearchIcon from "../../Icon/SearchIcon";
import ResendIcon from "../../Icon/ResendIcon";
import CancelIcon from "../../Icon/CancelIcon";
import Button from "../../Buttons/GeneralButton";
import CrossIcon from "../../Icon/CrossIcon";
import CheckIcon from "../../Icon/CheckIcon";
import Admin from "../../../classes/admin/admin"
import ModalDeleteInvitation from "../ModalDeleteInvitation";

const TableUsers = ({
  list,
  error,
  columns,
  subjects,
  setNewList,
  title,
  loadList,
  showModalUser,
  search,
  getSearch,
  initialized, 
  setIinitialized,
  getAllUsers,
}) => {

  const { RangePicker } = DatePicker;
  const { dictionary } = useContext(LanguageContext);
  const { permission } = useContext(UserContext);
  const { userTypeOptions, userPlanOptions, affirmationOptions, statusUserOptions } = useContext(AppContext);
  const [dataSource, setTableData] = useState([]);
  const [originalTable, setOriginalTable] = useState([]);
  const [filter, getFilter] = useState(0);
  const [filters, filterOptions] = useState([]);
  const [filterVerified, getFilterVerified] = useState(0);
  const [filterStatus, getFilterStatus] = useState(0);
  const [filterType, getFilterType] = useState(0);
  const [filterPlan, getFilterPlan] = useState(0);
  const [filterCreated, getFilterCreated] = useState([]);
  const [modalDeleteInvitation, setModalDeleteInvitation] = useState({ show: false, id: "", email: "" });

  useEffect(() => {
      getList();
    // eslint-disable-next-line
  }, [list]);

  useEffect(() => {
    getList();
    getFilters();
    // eslint-disable-next-line
  }, [list]);

  useEffect(() => {
    if (!initialized && subjects.length) {
      setOriginalTable([]);
      let newSubjects = list;
      setOriginalTable(newSubjects);
      setIinitialized(true);
      getFilterVerified(0);
      getFilterStatus(0);
      getFilterType(0);
      getFilterPlan(0);
      getFilterCreated([]);
    }
    // eslint-disable-next-line
  }, [list, list.length, subjects.length]);

  const getFilters = () => {
    const list = [];
    for (let i = 1; i < columns.length; i++) {
      let nameCol = columns[i].dataIndex;
      if (nameCol !== "detail" && nameCol !== "details" &&
          nameCol !== "email" &&
          nameCol !== "labels" &&
          nameCol !== "artists" &&
          nameCol !== "completedPayee"
        ) {
          list.push({ _id: nameCol, name: columns[i].title });
        }
    }
    filterOptions(list);
  };

  const saveSection = (
    data,
    filterVar = 0,
    attr,
    isDate = false,
    isBoolean = false
  ) => {
    let dataNames = [...data];
    if (isDate) {
      if (filterVar.length !== 0) {
        dataNames = data.filter((el) => {
          const itemDate = moment.utc(el.createdAt).startOf("day");
          return (
            itemDate >= moment.utc(filterVar[0]).startOf("day") &&
            itemDate <= moment.utc(filterVar[1]).startOf("day")
          );
        });
      }
    } else if (isBoolean) {
      if (filterVar !== 0) {
        dataNames = filterVar
          ? data.filter((el) => el[attr])
          : data.filter((el) => !el[attr]);
      }
    } else {
      if (filterVar !== 0) {
        if (attr === "verified") {
          dataNames = data.filter((el) => el[attr] === filterVar);
        } else if (attr === "plan"){
          dataNames = data.filter((el) =>
          el.contract && el.contract?.type && el.contract?.type.toLowerCase().includes(filterVar.toLowerCase())
        );
        } else if (attr === "type"){
          dataNames = data.filter((el) =>
          el.user_type && el.user_type.toLowerCase().includes(filterVar.toLowerCase())
        );
        } else {
          dataNames = data.filter((el) =>
          el[attr] && el[attr].toLowerCase().includes(filterVar.toLowerCase())
        );
        }
      }
    }
    return dataNames;
  };

  const checkAllFilters = () => {
    let saveType = saveSection(originalTable, filterType, "type");
    let savePlan = saveSection(saveType, filterPlan, "plan");
    let saveVerified = saveSection(savePlan, filterVerified, "verified");
    let saveStatus = saveSection(saveVerified, filterStatus, "status");
    let saveDates = saveSection(saveStatus, filterCreated, null, true);
    return saveDates;
  }

  const typeSelect = (e) => {
    getFilterType(e);
    let savePlan = saveSection(originalTable, filterPlan, "plan");
    let saveVerified = saveSection(savePlan, filterVerified, "verified");
    let saveStatus = saveSection(saveVerified, filterStatus, "status");
    let saveDates = saveSection(saveStatus, filterCreated, null, true);
    let finalFilter = [...saveDates];
    if (e !== 0) {
      finalFilter = saveDates.filter((el) =>
      el.user_type && el.user_type.toLowerCase().includes(e.toLowerCase())
      );
    }
    setNewList(finalFilter);
    if(search) {
      searchInAdmin(search, finalFilter);
    }
  };

  const planSelect = (e) => {
    getFilterPlan(e);
    let saveType = saveSection(originalTable, filterType, "type");
    let saveVerified = saveSection(saveType, filterVerified, "verified");
    let saveStatus = saveSection(saveVerified, filterStatus, "status");
    let saveDates = saveSection(saveStatus, filterCreated, null, true);
    let finalFilter = [...saveDates];
    if (e !== 0) {
      finalFilter = saveDates.filter((el) =>
        el.contract && el.contract?.type && el.contract?.type.toLowerCase().includes(e.toLowerCase())
      );
    }    
    setNewList(finalFilter);
    if(search) {
      searchInAdmin(search, finalFilter);
    }
  };

  const verifiedSelect = (e) => {
    getFilterVerified(e);
    let saveType = saveSection(originalTable, filterType, "type");
    let savePlan = saveSection(saveType, filterPlan, "plan");
    let saveStatus = saveSection(savePlan, filterStatus, "status");
    let saveCreated = saveSection(saveStatus, filterCreated, null, true);
    let finalFilter = [...saveCreated];
    if (e !== 0) {
      finalFilter = saveCreated.filter((el) => el.verified === e);
    }
    setNewList(finalFilter);
    if(search) {
      searchInAdmin(search, finalFilter);
    }
  };

  const statusSelect = (e) => {
    getFilterStatus(e);
    let saveType = saveSection(originalTable, filterType, "type");
    let savePlan = saveSection(saveType, filterPlan, "plan");
    let saveVerified = saveSection(savePlan, filterVerified, "verified");
    let saveCreated = saveSection(saveVerified, filterCreated, null, true);
    let finalFilter = [...saveCreated];
    if (e !== 0) {
      finalFilter = saveCreated.filter((el) => el.status === e);
    }
    setNewList(finalFilter);
    if(search) {
      searchInAdmin(search, finalFilter);
    }
  };

  const datesSelect = (date, isCreated = false, attr) => {
    let saveType = saveSection(originalTable, filterType, "type");
    let savePlan = saveSection(saveType, filterPlan, "plan");
    let saveVerified = saveSection(savePlan, filterVerified, "verified");
    let saveStatus = saveSection(saveVerified, filterStatus, "status");
    let filteredDates = [...saveStatus];
    if (date) {
      let getDates = [
        moment.utc(date[0]).startOf("day"),
        moment.utc(date[1]).startOf("day"),
      ];
      if (isCreated) {
        getFilterCreated(getDates);
      }
      filteredDates = saveStatus.filter((el) => {
        const itemDate = moment.utc(el[attr]).startOf("day");
        return (
          itemDate >= moment.utc(date[0]).startOf("day") &&
          itemDate <= moment.utc(date[1]).startOf("day")
        );
      });
    } else {
      if (isCreated) {
        getFilterCreated([]);
      }
    }
    setNewList(filteredDates);
    if(search) {
      searchInAdmin(search, filteredDates);
    }
  };

  const filtersBar = () => {
    return filters.map((el) => {
      let setOptions = filters;
      let setFunction = (e) => handleSelect(e);
      let setValue = filter;
      switch (el._id) {
          case "type":
          setOptions = userTypeOptions;
          setFunction = (e) => typeSelect(e);
          setValue = filterType;
          break;
        case "plan":
          setOptions = userPlanOptions;
          setFunction = (e) => planSelect(e);
          setValue = filterPlan;
          break;
        case "verified":
          setOptions = affirmationOptions;
          setFunction = (e) => verifiedSelect(e, el);
          setValue = filterVerified;
          break;
        case "status":
          setOptions = statusUserOptions;
          setFunction = (e) => statusSelect(e, el);
          setValue = filterStatus;
          break;
        default:
          break;
      }
      if (el._id === "createdAt") {
        let isCreate = true;
        let attr = el._id;
        return (
          <div key={el._id} className="dateField">
            <div className="label">{dictionary[el._id]}</div>
            <RangePicker
              defaultValue={
               filterCreated
              }
              onChange={(e) => datesSelect(e, isCreate, attr)}
              className="date-range-admn"
            />
          </div>
        );
      } else {
        return (
          <Select
            key={el._id}
            name="section"
            value={setValue}
            options={setOptions}
            placeholder={el.name}
            onChange={setFunction}
            className="selector-general search-sec"
          />
        );
      }
    });
  };

  const showNames = (names) => {
    let all = [];
    for (var i = 0; i < names.length; i++) {
      all.push(<li key={i}>{names[i]}</li>);
    }
    return <ul className="view-stores">{all}</ul>;
  };

  const deleteInvitation = (id, email) => {
    Admin.deleteInvitation(id, email)
      .then((res) => {
        if (res.status === 200) {
          notification.success({
            message: dictionary.invitationDeletedSuccess,
            duration: 3,
            placement: "topRight",
          });
          setModalDeleteInvitation({ show: false, id: "", email: "" });
          let newList = list.filter((el) => el._id !== id);
          setNewList(newList);
        }
      })
      .catch((err) => {
        notification.error({
          message: dictionary.invitationDeleteError,
          duration: 3,
          placement: "topRight",
        });
        setModalDeleteInvitation({ show: false, id: "", email: "" });
        console.log(err);
      })
      .finally(() => {
        setOriginalTable([]);
        setIinitialized(false);
        getAllUsers();
        getSearch(null);
        getFilters();
      });
  };

  const resendInvitation = (email) => {
    Admin.resendInvitation(email)
      .then((res) => {
        if (res.status === 200) {
          notification.success({
            message: dictionary.invitationResendSuccess,
            duration: 3,
            placement: "topRight",
          });
        }
      })
      .catch((err) => {
        notification.error({
          message: dictionary.invitationResendError,
          duration: 3,
          placement: "topRight",
        });
        console.log(err);
      });
  };

  const getArtistList = (userArtists) => {
    let namesList = [];
    userArtists.forEach((el) => {
      namesList.push(el);
    });
    let order = orderedArtists(namesList);
    return order;
  };

  const orderedArtists = (list = []) => {
    let openList = showNames(list);
    if (Array.isArray(list)) {
      return list.map((el, index) => {
        if (index <= 2) {
          if (index === 2 && list.length >= 3) {
            return (
              <Popconfirm
                key={index}
                title={openList}
                placement="bottom"
                okText={dictionary.close}
                icon={null}
                showCancel={false}
              >
                <div className="seemore-btn">{dictionary.seeMore}</div>
              </Popconfirm>
            );
          } else if (
            (index > 0 && list.length < 3) ||
            list.length === 1 ||
            (index === 2 && list.length === 3)
          ) {
            return el;
          } else if (index === 0 && list.length < 3 && list.length > 1) {
            return el + " " + dictionary.and + " ";
          } else {
            return el + ", ";
          }
        }
        return null;
      });
    }
  };

  const getList = async () => {
    let listArray = [];
    let count = 0;
    list.map((el, index) => {
      let allArtists = el.artists ? getArtistList(el.artists[0]) : "";
      let allLabels = el.labels ? getArtistList(el.labels[0]) : "";
      let estatus = dictionary[el.status];
      let plan = el.status === "invited" ? "-" :
        el.contract?.type == "royalty" ? "Plus" : "Core";
      let name = el.profile?.name ?
        (el.profile?.name+ " " + el.profile?.surname)
        : "--";
      let created = el.createdAt
        ? moment.utc(el.createdAt).startOf("day").format("YYYY/MM/DD")
        : null;
      count++;
      return listArray.push({
        index: index,
        key: el._id || index,
        id: count,
        completedPayee: (
          <div key={el._id} className="av-users">
            {el.payee_status && el.payee_status[0] ? (
              <CheckIcon
                color="#00CF85"
                className="payee-check"
              />
            ) : (
              <CrossIcon
                className="payee-cross"
              />
            )}
          </div>
        ),
        detail: ( el.status === "invited" || el.status === "expired" ? (
          <div className="actions-icons hgt" >
            <Tooltip title={dictionary.resend} placement="bottom">
                <span onClick={()=>resendInvitation(el.email)}
                ><ResendIcon className="resend-icon" /></span>
            </Tooltip>
            <Tooltip title={dictionary.cancelInvitation} placement="bottom">
              <span
               onClick={()=>{
                  setModalDeleteInvitation({ 
                    show: true, 
                    id: el._id, 
                    email: 
                    el.email 
                  });
                }}
              ><CancelIcon className="cancelinvite-icon"/></span>
            </Tooltip>
          </div>
          ) : (
          <div className="actions-icons">
              <Link to={"/user/view?u=" + el._id + "&edit=true&section=user"}>
                <EditOutlined className="view-edit-rel"/>
              </Link>
              {el.status === "active" ? (
                <StopOutlined
                  className="disable-detail"
                  onClick={() => showModalUser(el._id, name, "disable")}
                />
              ): (
                <CheckCircleOutlined
                  className="active-detail"
                  onClick={() => showModalUser(el._id, name, "activate")}
                />
              )}
          </div>
          )
        ),
        name: name,
        email: el.email || "",
        createdAt: created,
        plan: plan,
        labels: <div className="list-td-user">{allLabels}</div>,
        artists: <div className="list-td-user">{allArtists}</div>,
        verified: el.verified ? dictionary.yes : dictionary.no,
        type: <div className="user-type">{dictionary[el.user_type]}</div>,
        status: <div className={"tag-step " + el.status}>{estatus}</div>,
      });
    });
    setTableData(listArray);
  };

  const searchInAdmin = (value, list = null) => {
    let getFilterData = checkAllFilters();
    let filteredNames = [...getFilterData];
    if (list) {
      getFilterData = [...list];
    }
    switch (filter) {
      case 0:
        (filteredNames = getFilterData.filter(
          (el) =>
            el.profile?.name?.toLowerCase().includes(value.toLowerCase()) ||
            el.profile?.surname?.toLowerCase().includes(value.toLowerCase()) ||
            el.email.toLowerCase().includes(value.toLowerCase()) ||
            moment(el.createdAt).format("YYYY/MM/DD").includes(value) ||
            el.artists && el.artists[0]?.some(artist => typeof artist === 'string' && artist.toLowerCase().includes(value.toLowerCase())) ||
            el.labels && el.labels[0]?.some(label => typeof label === 'string' && label.toLowerCase().includes(value.toLowerCase()))
        ));
        break;
      default:
        setNewList(getFilterData);
        break;
    }
    setNewList(filteredNames);
  };

  const onSearch = (search) => {
    getSearch(search.target.value);
    searchInAdmin(search.target.value);
  };

  const handleSelect = (e) => {
    getFilter(e);
  };

  return (
    <div className="tables">
      <div className="top-actions-srch">
        <div className="searchers">
          <Search
            name="searcher"
            value={search}
            mobile="responsive"
            onSearch={onSearch}
            className="searcher sec back"
            enterButton={<SearchIcon />}
            placeholder={dictionary.search}
          />
          {filtersBar()}
        </div>
        <Button
          size="md"
          className="add-user"
          text={dictionary.addUser}
          onClick={() => showModalUser("", null, "sendInvitation")}
        />
        {(permission && permission.release && permission.release.add) && (title === "Releases" || title==="Lanzamientos") ?
          <Link to="/newRelease" className="new-release">
            <Button
              size="fullWidth"
              className="new-rel-btn"
              text={dictionary.createNewRel}
            />
          </Link>
        : null } 
      </div>
      <div className="general-card-admin">
        <div className="table-admin inline-contend">
          <div className="header-tab-ad"></div>
          <Spin spinning={loadList} className="spinner">
            <Table
              rowKey="index"
              columns={columns}
              pagination={true}
              dataSource={dataSource}
              className="content-table-admin users"
            />
          </Spin>
        </div>
        {error ? <p className="text-error relative-text">{error}</p> : null}
      </div>
      <ModalDeleteInvitation
        isModalVisible={modalDeleteInvitation}
        hideModalDeleteInvitation={() => setModalDeleteInvitation({ show: false, id: "", email: "" })}
        deleteInvitation={deleteInvitation}
      />
    </div>
  );
  
};

export default TableUsers;