import React, { useContext } from "react";
import { UserOutlined } from "@ant-design/icons";
import { UserContext } from "../../../../context/User";

const Avatar = ({ otherClass = "" }) => {
  const { avatar } = useContext(UserContext);

  if (avatar) {
    return (
      <div
        alt="user"
        className={"avatar-img " + otherClass}
        style={{ backgroundImage: "url(" + avatar + ")" }}
      ></div>
    );
  }

  return <UserOutlined className="avatar-default" />;
};

export default Avatar;
