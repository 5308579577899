import React, { useContext, useMemo, useState, useEffect } from "react";
import { Col, Row, Typography, Table, notification } from "antd";
import { LanguageContext } from "../../../../context/Language";
import { AppContext } from "../../../../context/App/AppProvider";
import moment from "moment";
import Loader from "../../../Loader";
import User from "../../../../classes/user/user";
import Admin from "../../../../classes/admin/admin";
import Button from "../../../Buttons/GeneralButton";
import AddLabel from "../../../Catalog/LabelList/AddLabel";

const ArtistsList = ({ labels, getLabels, userId }) => {

  const { dictionary } = useContext(LanguageContext);
  const { allCountries, ladaOptions, validateNewLabel } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [listLabels, setLabelList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    firstName: "",
    surname: "",
    password: "",
    confirmPassword: "",
    parent: "",
  });
  const [labelData, setLabelData] = useState({
    index: null,
    _id: null,
    name: "",
    email: "",
    firstName: "",
    surname: "",
    password: "",
    confirmPassword: "",
    countryPhone: "US",
    code: "1",
    number: "",
    address: "",
    city: "",
    state: "",
    country: 0,
    zip: "",
    update: false,
    parent: 0,
  });

  const columns = [
    {
      title: " ",
      dataIndex: "id",
      className: "id-category",
    },
    {
      title: dictionary.name,
      dataIndex: "name",
      className: "title-category",
    },
    {
      title: dictionary.createdAt,
      dataIndex: "createdAt",
      className: "title-category",
    },
    {
      title: dictionary.updated,
      dataIndex: "updated",
      className: "title-category",
    },
  ];

  useEffect(() => {
    setLabelList(labels);
  }, [labels]);

  const dataSource  = useMemo(() => {
    let listArray = [];
    labels.map((el, index) => {
      let name = el.name ? el.name : dictionary.noTitle;
      let labelName = el.label
        ? el.label.name
        :  "-";
      let created = el.createdAt
        ? moment.utc(el.createdAt).startOf("day").format("YYYY/MM/DD")
        : null;
      let updated = el.updatedAt
        ? moment.utc(el.updatedAt).startOf("day").format("YYYY/MM/DD")
        : null;
      return listArray.push({
        index: index,
        key: el._id || index,
        id: index + 1,
        name: name,
        desc: labelName,
        createdAt: created,
        updated: updated,
      });
    });
    return (listArray);
  }, [labels]);

  const addLabelInUser = () => {
    setIsModalVisible(true);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const changeHandler = (e, name) => {
    setErrors({
      ...errors,
      [name]: "",
    });
    const value = e.target.value;
    setLabelData({
      ...labelData,
      [name]: value,
    });
  };

  const selectChangeHandler = (e, name) => {
    setErrors({
      ...errors,
      [name]: "",
    });
    if (name === "code") {
      const getCode = ladaOptions.find((el) => el._id === e);
      setLabelData({
        ...labelData,
        countryPhone: getCode._id,
        code: String(getCode.phone_code),
      });
    } else {
      setLabelData({
        ...labelData,
        [name]: e,
      });
    }
  };

  const handleSubmit = () => {
    let hasError = validateNewLabel(labelData, setErrors);
    try {
      if (!hasError) {
        setLoading(true);
        const newLabel = {
          name: labelData.name,
          parent_id: labelData.parent,
        };
        let user = {
          labelParent: userId,  
          email: labelData.email,
          name: labelData.firstName,
          surname: labelData.surname,
          password: labelData.password,
          confirmPassword: labelData.confirmPassword,
          profile: {
            phone: {
              country: labelData.countryPhone,
              code: labelData.code,
              number: labelData.number,
            },
            address: {
              address: labelData.address,
              city: labelData.city,
              state: labelData.state,
              country: labelData.country,
              zip: labelData.zip,
            },
          },
        };
        Admin.addLabel(newLabel, user).then((createdLabel) => {
          if (createdLabel.status !== 200) {
            if (createdLabel.body?.errors?.zip) {
              setErrors({
                ...errors,
                zip: "zipErr",
              });
            } else if (
              createdLabel?.body?.errors &&
              createdLabel?.body?.errors[0].message === "alreadyEmail"
            ) {
              setErrors({
                ...errors,
                email: "alreadyEmail",
              });
            }
            return;
          } else if (createdLabel.status === 200) {
            handleCancel();
            newLabel.label_id = createdLabel._id;
            setLabelList([...listLabels, newLabel]);
            getLabels();
            notification.success({
              message: dictionary.labelCreated,
            });
          }
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  const handleSubmitUpdate = async () => {
    let hasError = false;
    let errorsCopy = {name: ""};
    if (!labelData.name) {
      hasError = true;
      errorsCopy.name = "emptyField";
    }
    try {
      if (!hasError) {
        setLoading(true);
        let label = {
          label_id: labelData._id || labelData.label_id,
          name: labelData.name,
        };
        const updateLabel = await User.updateLabel(label);
        if (updateLabel) {
          getLabels();
          setLabelList([...listLabels]);
          notification.success({
            message: dictionary.labelUpdated,
          });
        }
        handleCancel();
      }
    } catch (e) {
      console.error(e);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  return (
    <div className="general_information user-detls">
      <Typography.Title level={3} className="row-flex-btwn">
        {dictionary.labelList}
        <Button
          key="cancel"
          size="sm"
          text={dictionary.createLabel}
          onClick={addLabelInUser}
        />
      </Typography.Title>
      <Row justify="space-around" className="user-div">
        <Col xs={24} lg={24} className="info-one">
          <div className="table-admin inline-contend">
            <Table
              rowKey="index"
              columns={columns}
              pagination={labels.lenght > 10 ? true : false}
              dataSource={dataSource}
              className="content-table-admin users-artistlist"
            />
          </div>
        </Col>
      </Row>
      {loading ? <Loader /> : null}
      <AddLabel
        errors={errors}
        label={labelData}
        messageArtist={false}
        listLabels={listLabels}
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        dataCountries={allCountries}
        changeHandler={changeHandler}
        isModalVisible={isModalVisible}
        phoneCountries={ladaOptions}
        handleSubmitUpdate={handleSubmitUpdate}
        selectChangeHandler={selectChangeHandler}
      />
    </div>
  );
};

export default ArtistsList;