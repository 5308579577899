import React from "react";

const HomeIcon = ({ className = null, onClick = null, color = null }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7071 0.292893C10.3166 -0.0976311 9.68342 -0.0976311 9.29289 0.292893L2.29325 7.29254L2.29254 7.29325L0.292893 9.29289C-0.0976311 9.68342 -0.0976311 10.3166 0.292893 10.7071C0.683418 11.0976 1.31658 11.0976 1.70711 10.7071L2 10.4142V17C2 18.6569 3.34315 20 5 20H15C16.6569 20 18 18.6569 18 17V10.4142L18.2929 10.7071C18.6834 11.0976 19.3166 11.0976 19.7071 10.7071C20.0976 10.3166 20.0976 9.68342 19.7071 9.29289L10.7071 0.292893Z" 
        fill={color || "#ffffffa6"}
      />
    </svg>
  );
};

export default HomeIcon;
