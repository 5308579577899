import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../context/App";
import { UserContext } from "../../context/User";
import { LanguageContext } from "../../context/Language";
import LabelList from "../../components/Catalog/LabelList";
import Search from "../../components/Form/Search";
import SearchIcon from "../../components/Icon/SearchIcon";
import "../../components/Catalog/catalog.css";

const Label = () => {
  const {type, isAdmin} = useContext(UserContext);
  const {labels, getLabels} = useContext(AppContext);
  const {dictionary} = useContext(LanguageContext);
  const [search, getSearch] = useState(null);
  const [dataLabels, setLabels] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getLabelsList();
    // eslint-disable-next-line
  }, [labels]);

  const getLabelsList = async () => {
    setLoading(true);
    try {
      const labelsArray = labels || [];
      setLabels(labelsArray);
      setLoading(false);
    } catch (e) {
      setLabels([]);
      setLoading(false);
    }
  };

  const onSearch = (value) => {
    getSearch(value.target.value);
  };

  return (
    <React.Fragment>
      <div className={isAdmin}>
        {type !== "admin" ? (
          <div className="title-catalog">
            <div className="filters">
              <Search
                name="searcher"
                value={search}
                onSearch={onSearch}
                className="searcher back"
                enterButton={<SearchIcon />}
                placeholder={dictionary.search}
              />
            </div>
          </div>
        ) : null}
        <LabelList
          loadList={loading}
          labels={dataLabels}
          searchValues={search}
          getLabels={getLabelsList}
          getLabelsFunct={getLabels}
        />
      </div>
    </React.Fragment>
  );
};

export default Label;
