import React, { useContext } from "react";
import { AutoComplete } from "antd";
import "../form.css";
import { LanguageContext } from "../../../context/Language";

const Autocomplete = ({
  options,
  placeholder,
  value = null,
  label = null,
  error = false,
  onChange = null,
  required = false,
  maxLength = 1300,
  className = null,
  disabled = false,
  addNewOpt = false,
  open = false,
  setOpen = null,
  handleNotFoundClick = null,
}) => {
  const { dictionary } = useContext(LanguageContext);

  return (
    <div className="relative-input">
      {label ? (
        <label>
          {label}
          <span>{required ? "*" : ""}</span>
        </label>
      ) : null}
      <AutoComplete
        disabled={disabled}
        options={options}
        onChange={onChange}
        value={value}
        maxLength={maxLength}
        className={className}
        style={{ width: "100%" }}
        placeholder={placeholder}
        notFoundContent={addNewOpt ? (
          <div onClick={handleNotFoundClick} className="new-list-artist">
            {dictionary.addNew }: {value}
          </div>
          ) : null}
        filterOption={(inputValue, option) =>
          option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
        defaultOpen={false}
        open={open}
        onClick={() => setOpen(!open)}
      />
      {(error && value.length === 0) ? <div className="text-error">{error}</div> : null}
    </div>
  );
};

export default Autocomplete;
