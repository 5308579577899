import React from "react";

const DspIcon = ({ className = null, onClick = null, color = null }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <circle
        cx="9.99991"
        cy="9.99991"
        r="6.86905"
        stroke={color || "#ffffffa6"}
        strokeWidth="1.5"
      />
      <path
        d="M15.2383 4.70948L16.8882 3.05956"
        stroke={color || "#ffffffa6"}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M3.33301 16.9214L4.84823 15.4062"
        stroke={color || "#ffffffa6"}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path d="M16.9209 16.9214L15.2373 15.2378"
        stroke={color || "#ffffffa6"}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path d="M3.333 3.3335L4.74721 4.74772"
        stroke={color || "#ffffffa6"}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <circle
        cx="18.0952"
        cy="18.0952"
        r="1.15476"
        stroke={color || "#ffffffa6"}
        strokeWidth="1.5"
      />
      <circle
        cx="1.90476"
        cy="18.0952"
        r="1.15476"
        stroke={color || "#ffffffa6"}
        strokeWidth="1.5"
      />
      <circle
        cx="1.90476"
        cy="1.90476"
        r="1.15476"
        stroke={color || "#ffffffa6"}
        strokeWidth="1.5"
      />
      <circle
        cx="18.0952"
        cy="1.90476"
        r="1.15476"
        stroke={color || "#ffffffa6"}
        strokeWidth="1.5"
      />
      <circle
        cx="8.5"
        cy="12.5"
        r="1.5"
        stroke={color || "#ffffffa6"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10 13V6"
        stroke={color || "#ffffffa6"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10 6L13 6.4V8L10 7.6V6Z"
        stroke={color || "#ffffffa6"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="10.0905"
        y1="6.50827" 
        x2="13.0409" 
        y2="7.05151"
        stroke={color || "#ffffffa6"}
      />
    </svg>
  );
};

export default DspIcon;
