import UtilCalls from "../calls/util/util";

class Util {
  async genres() {
    const genres = await UtilCalls.genres();
    if (genres.status === 200) {
      return genres.body.genres;
    }
    return [];
  }

  async subgenres() {
    const subgenres = await UtilCalls.subgenres();
    if (subgenres.status === 200) {
      return subgenres.body.subgenres;
    }
    return [];
  }

  async subgenresByGenre(genre) {
    const subgenresByGenre = await UtilCalls.subgenresByGenre(genre);
    if (subgenresByGenre.status === 200) {
      return subgenresByGenre.body.subgenres;
    }
    return [];
  }

  async genreSubGenreSelect() {
    const genreSubGenre = await UtilCalls.genreSubGenreSelect();
    if (genreSubGenre.status === 200) {
      return genreSubGenre.body.subgenres;
    }
    return [];
  }
}

export default new Util();
