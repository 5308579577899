import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import CreateSalesTerms from "../../../components/Contracts/Create/Terms/Sales/SalesTerms";
import { Button } from "antd";
import CreateDeductionsTerms from "../../../components/Contracts/Create/Terms/Deductions";
import CreateMechanicalDeductionsTerms from "../../../components/Contracts/Create/Terms/MechanicalDeductions";
import CreateWitholdingTaxTerms from "../../../components/Contracts/Create/Terms/WitholdingTax";
import CreateCostsTerms from "../../../components/Contracts/Create/Terms/Costs";

const CreateContractTerms = () => {
  const { control } = useFormContext();

  const {
    fields: salesTerms,
    append: appendSalesTerms,
    remove: removeSalesTerms,
  } = useFieldArray({
    control,
    name: "salesTerms",
  });
  const addSalesTerms = () => {
    appendSalesTerms(
      {
        catType: "",
        catalogueGroup: "",
        territory: "",
        channel: "",
        configuration: "",
        priceCategory: "",
        source: "",
        type: "",
        rate: 0,
        multiplier: 1,
        reductionRate: 0,
        unitRate: 0,
        reserve: 0,
      },
      {
        shouldFocus: false,
      }
    );
  };

  const {
    fields: deductionTerms,
    append: appendDeductionTerms,
    remove: removeDeductionTerms,
  } = useFieldArray({
    name: "deductionTerms",
  });
  const addDeductionsTerms = () => {
    appendDeductionTerms(
      {
        catType: "",
        catalogueGroup: "",
        territory: "",
        channel: "",
        configuration: "",
        priceCategory: "",
        source: "",
        deductionBasis: "",
        type: "",
        unitRate: 0,
        rate: 0,
      },
      {
        shouldFocus: false,
      }
    );
  };

  const {
    fields: mechanicalDeductionsTerms,
    append: appendMechanicalDeductionsTerms,
    remove: removeMechanicalDeductionsTerms,
  } = useFieldArray({
    name: "mechanicalDeductionsTerms",
  });
  const addMechanicalDeductionsTerms = () => {
    appendMechanicalDeductionsTerms(
      {
        catType: "",
        catalogueGroup: "",
        territory: "",
        channel: "",
        configuration: "",
        priceCategory: "",
        source: "",
        deductionType: "",
        type: "",
        rate: 0,
        reserve: 0,
        unitRate: 0,
      },
      {
        shouldFocus: false,
      }
    );
  };

  const {
    fields: withholdingTaxDeductionsTerms,
    append: appendWithholdingTaxDeductionsTerms,
    remove: removeWithholdingTaxDeductionsTerms,
  } = useFieldArray({
    name: "withholdingTaxDeductionsTerms",
  });
  const addWitholdingTaxTerms = () => {
    appendWithholdingTaxDeductionsTerms(
      {
        catType: "",
        catalogueGroup: "",
        territory: "",
        channel: "",
        configuration: "",
        priceCategory: "",
        source: "",
        deductionBasis: "",
        type: "",
        rate: 0,
        unitRate: 0,
      },
      {
        shouldFocus: false,
      }
    );
  };

  const {
    fields: costsTerms,
    append: appendCostsTerms,
    remove: removeCostsTerms,
  } = useFieldArray({
    name: "costsTerms",
  });
  const addCostsTerms = () => {
    appendCostsTerms(
      {
        territory: "",
        type: "",
        rate: 0,
      },
      {
        shouldFocus: false,
      }
    );
  };

  const drawSalesTerms = () => {
    return salesTerms.map((st, index) => {
      return (
        <CreateSalesTerms
          key={st.id}
          data={st}
          index={index}
          remove={() => removeSalesTerms(index)}
        />
      );
    });
  };

  const drawDeductionsTerms = () => {
    return deductionTerms.map((st, index) => {
      return (
        <CreateDeductionsTerms
          key={st.id}
          data={st}
          index={index}
          remove={() => removeDeductionTerms(index)}
        />
      );
    });
  };

  const drawMechanicalDeductionsTerms = () => {
    return mechanicalDeductionsTerms.map((st, index) => {
      return (
        <CreateMechanicalDeductionsTerms
          key={st.id}
          data={st}
          index={index}
          remove={() => removeMechanicalDeductionsTerms(index)}
        />
      );
    });
  };

  const drawWitholdingTaxTerms = () => {
    return withholdingTaxDeductionsTerms.map((st, index) => {
      return (
        <CreateWitholdingTaxTerms
          key={st.id}
          data={st}
          index={index}
          remove={() => removeWithholdingTaxDeductionsTerms(index)}
        />
      );
    });
  };

  const drawCostsTerms = () => {
    return costsTerms.map((st, index) => {
      return (
        <CreateCostsTerms
          key={st.id}
          data={st}
          index={index}
          remove={() => removeCostsTerms(index)}
        />
      );
    });
  };

  return (
    <div className="form-content">
      <div className="form-column">
        <div className="form-box">
          <h3>Sales</h3>
          {drawSalesTerms()}
          <Button
            type="primary"
            onClick={() => addSalesTerms()}
            className="add-item"
          >
            Add Sales
          </Button>
        </div>
        <div className="form-box">
          <h3>Deductions</h3>
          {drawDeductionsTerms()}
          <Button
            type="primary"
            onClick={() => addDeductionsTerms()}
            className="add-item"
          >
            Add Deductions
          </Button>
        </div>
        <div className="form-box">
          <h3>Mechanical Deduction</h3>
          {drawMechanicalDeductionsTerms()}
          <Button
            type="primary"
            onClick={() => addMechanicalDeductionsTerms()}
            className="add-item"
          >
            Add Mechanical Deduction
          </Button>
        </div>
        <div className="form-box">
          <h3>Withholding Tax Deudctions</h3>
          {drawWitholdingTaxTerms()}
          <Button
            type="primary"
            onClick={() => addWitholdingTaxTerms()}
            className="add-item"
          >
            Add Witholding Tax Deduction
          </Button>
        </div>
        <div className="form-box">
          <h3>Costs</h3>
          {drawCostsTerms()}
          <Button
            type="primary"
            onClick={() => addCostsTerms()}
            className="add-item"
          >
            Add Costs
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreateContractTerms;
