import React from "react";
import { createRoot } from 'react-dom/client';
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { UserProvider } from "./context/User";
import { LanguageProvider } from "./context/Language";
import { ArtistProvider } from "./context/Artist";
import { AppProvider } from "./context/App";
import { UploadServiceProvider } from "./context/Services";

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <LanguageProvider>
      <UserProvider>
        <AppProvider>
          <ArtistProvider>
            <UploadServiceProvider>
              <App />
            </UploadServiceProvider>
          </ArtistProvider>
        </AppProvider>
      </UserProvider>
    </LanguageProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
