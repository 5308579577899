import React, { useContext } from "react";
import Input from "../../../../Form/Input/GeneralInput";
import { useFormContext, Controller } from "react-hook-form";
import { Button, Select } from "antd";
import { AppContext } from "../../../../../context/App/AppProvider";

const CreateCrossContract = ({ index, remove, contractOptions }) => {
  const formMethods = useFormContext();
  const { crossContractWhenOptions, crossContractProcessOptions } =
    useContext(AppContext);
  const { control } = formMethods;

  return (
    <div className="cross-contract">
      <div className="cross-contract-column">
        <div className={"form-box-select"}>
          <Controller
            control={control}
            name={`crossContracts.${index}.when`}
            rules={{ required: "This field is required" }}
            render={({ field, fieldState }) => (
              <React.Fragment>
                <label className="allWidth">When</label>
                <Select
                  options={crossContractWhenOptions}
                  className={
                    "fullWidth selector-general" +
                    (fieldState.error ? " input-general-error" : "")
                  }
                  {...field}
                />
                {fieldState.error && (
                  <div className="text-error">{fieldState.error.message}</div>
                )}
              </React.Fragment>
            )}
          />
        </div>
        <div className={"form-box-select"}>
          <Controller
            control={control}
            name={`crossContracts.${index}.contractId`}
            render={({ field, fieldState }) => (
              <React.Fragment>
                <label className="allWidth">Contract</label>
                <Select
                  options={contractOptions}
                  className={
                    "fullWidth selector-general" +
                    (fieldState.error ? " input-general-error" : "")
                  }
                  {...field}
                />
                {fieldState.error && (
                  <div className="text-error">{fieldState.error.message}</div>
                )}
              </React.Fragment>
            )}
          />
        </div>
        <Button type="danger" onClick={() => remove(index)}>
          Remove
        </Button>
      </div>
      <div className="cross-contract-column">
        <div className={"form-box-select"}>
          <Controller
            control={control}
            name={`crossContracts.${index}.process`}
            rules={{ required: "This field is required" }}
            render={({ field, fieldState }) => (
              <React.Fragment>
                <label className="allWidth">Process</label>
                <Select
                  options={crossContractProcessOptions}
                  className={
                    "fullWidth selector-general" +
                    (fieldState.error ? " input-general-error" : "")
                  }
                  {...field}
                />
                {fieldState.error && (
                  <div className="text-error">{fieldState.error.message}</div>
                )}
              </React.Fragment>
            )}
          />
        </div>
        <Controller
          control={control}
          name={`crossContracts.${index}.percentage`}
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <Input
              className={"form-box-input"}
              label={"Percentage"}
              placeholder={"Percentage"}
              type="number"
              min={0}
              {...field}
              error={fieldState.error?.message}
            />
          )}
        />
      </div>
    </div>
  );
};

export default CreateCrossContract;
