import BaseClass from "../";

class IsrcCalls extends BaseClass {
  getAvailableIsrcs = () => {
    this.setValues("/isrc/availables");
    return this.doGet();
  };

  addIsrc = (isrc) => {
    this.setValues("/isrc/add/", isrc);
    return this.doPost();
  };

  deleteIsrc = (isrc) => {
    this.setValues("/isrc/delete/" + isrc);
    return this.doDelete();
  };
}

export default new IsrcCalls();
